import React, { Component } from "react";
import { Row, Card, Col, Form } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import axios from "axios";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const textbox_radius = { borderRadius: "40px" };

const customStyles = {
  control: (base) => ({
    ...base,
    height: 80,
    minHeight: 80,
    borderRadius: "40px",
    paddingLeft: "60px !important",
  }),
};

export default class ViewAsCandidateGeneral extends Component {
  state = {
    ud_bio: "",
    ud_birhday: "",
    ud_gender: 0,
    phone: "",
    ud_address: "",
    email: "",

    username: "",
    firstname: "",
    lastname: "",

    tambon_id: 1,
    country_id: 19,

    // เงินเดือน
    us_salary_start: 0,
    us_salary_end: 0,

    // ประเภทงาน
    job_function_post: [],
    list_job_function: [],
    default_job_function: [],

    // ประเภทการจ้างงาน
    job_type_post: [],
    list_job_type: [],
    default_job_type: [],
  };

  refreshData = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          let user_detail = res.user_main_detail;

          this.setState({
            username: res.username,
            firstname: res.firstname,
            lastname: res.lastname,
            email: res.email,
            phone: res.phone,
          });
          if (user_detail !== undefined) {
            let tambon = user_detail?.user_tambon;
            let country = user_detail?.user_country;

            this.setState({
              ud_bio: user_detail.ud_bio,
              ud_birhday: user_detail.ud_birhday,
              ud_gender: user_detail.ud_gender,
              ud_address: user_detail.ud_address,
              tambon_id: tambon?.tambon_id,
              country_id: user_detail.country_id,
              defaultCountry: {
                value: country.country_id,
                label:
                  country.country_name_th +
                  " - " +
                  country.country_official_name_th,
              },
            });
            // console.log(user_detail.email);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  getSalaryData = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `userpart2/salary/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          // console.log(res);

          this.setState({
            us_salary_start: res.us_salary_start,
            us_salary_end: res.us_salary_end,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getJobType = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `userpart2/job_type/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          let arr = [];
          let arr2 = [];

          for (let i = 0; i < res.length; i++) {
            let obj = res[i];
            let user_job_type = obj.user_job_type;
            let label = user_job_type.jt_name;

            // Set Select Form
            arr.push({
              value: obj.jt_id,
              label: label,
            });
            // Set Post
            arr2.push({
              jt_id: obj.jt_id,
            });
          }
          new Promise((accept) => {
            setTimeout(() => {
              this.setState(
                {
                  default_job_type: arr,
                  job_type_post: arr2,
                },
                accept
              );
            }, 1000);
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getJobFunction = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/job_function/${user_id}`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          let arr = [];
          let arr2 = [];

          for (let i = 0; i < res.length; i++) {
            let obj = res[i];
            let user_job_function = obj.user_job_function;
            let label = user_job_function.jbc_name;
            // Set Select Form
            arr.push({
              value: obj.jbc_id,
              label: label,
            });
            // Set Post
            arr2.push({
              jbc_id: obj.jbc_id,
            });
          }
          new Promise((accept) => {
            setTimeout(() => {
              this.setState(
                {
                  default_job_function: arr,
                  job_function_post: arr2,
                },
                accept
              );
            }, 1000);
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.refreshData();
    this.getSalaryData();
    this.getJobType();
    this.getJobFunction();
  }

  render() {
    const { default_job_type, default_job_function } = this.state;

    return (
      <div style={{ paddingTop: 25 }}>
        <Card>
          <Card.Body>
            <Card.Title>{lang_data?.general}</Card.Title>
            <Row>
              <Col sm="12" lg="6" md="6">
                <Form.Group style={{ paddingTop: "30px" }}>
                  <Form.Label>{this.state.firstname}</Form.Label>
                  <div className="form-inside left-inner-addon">
                    <Form.Control
                      type="text"
                      id="firstname"
                      size="lg"
                      defaultValue={this.state.firstname}
                      readOnly
                      placeholder={this.state.firstname}
                      style={textbox_radius}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person-fill icon-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    </svg>
                  </div>
                </Form.Group>
              </Col>
              <Col sm="12" lg="6" md="6">
                <Form.Group style={{ paddingTop: "30px" }}>
                  <Form.Label>{lang_data?.last_name}</Form.Label>
                  <div className="form-inside left-inner-addon">
                    <Form.Control
                      type="text"
                      id="lastname"
                      defaultValue={this.state.lastname}
                      readOnly
                      placeholder={lang_data?.last_name}
                      style={textbox_radius}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person-fill icon-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    </svg>
                  </div>
                </Form.Group>
              </Col>
              <Col sm="12" lg="6" md="6">
                <Form.Group style={{ paddingTop: "30px" }}>
                  <Form.Label>{lang_data?.phone_number_label}</Form.Label>

                  <div className="form-inside left-inner-addon">
                    <Form.Control
                      type="text"
                      id="phone"
                      readOnly
                      value={Functions.formatPhoneNumber(this.state.phone)}
                      placeholder={lang_data?.phone_number_label}
                      style={textbox_radius}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-telephone-fill icon-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                      />
                    </svg>
                  </div>
                </Form.Group>
              </Col>

              <Col sm="12" lg="6" md="6">
                <Form.Group style={{ paddingTop: "30px" }}>
                  <Form.Label>{lang_data?.email_label}</Form.Label>
                  <div className="form-inside left-inner-addon">
                    <Form.Control
                      type="email"
                      id="email"
                      value={this.state.email}
                      readOnly
                      placeholder={lang_data?.email_label}
                      style={textbox_radius}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-envelope-fill icon-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                    </svg>
                  </div>
                </Form.Group>
              </Col>

              <Col sm="12" lg="6" md="6">
                <Form.Group style={{ paddingTop: "30px" }}>
                  <Form.Label>{lang_data?.birthday_label}</Form.Label>
                  <div className="form-inside left-inner-addon">
                    <DatePicker
                      id="ud_birhday"
                      style={{
                        height: "80px",
                        width: "100%",
                        borderRadius: "40px",
                      }}
                      containerStyle={{
                        width: "100%",
                      }}
                      disabled
                      value={Functions.IsoDatetoShortDate(
                        this.state.ud_birhday
                      )}
                      format="DD/MM/YYYY"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-calendar2-date-fill icon-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.402 10.246c.625 0 1.184-.484 1.184-1.18 0-.832-.527-1.23-1.16-1.23-.586 0-1.168.387-1.168 1.21 0 .817.543 1.2 1.144 1.2z" />
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zm-4.118 9.79c1.258 0 2-1.067 2-2.872 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684c.047.64.594 1.406 1.703 1.406zm-2.89-5.435h-.633A12.6 12.6 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675V7.354z" />
                    </svg>
                  </div>
                </Form.Group>
              </Col>

              <Col sm="12" lg="3" md="6">
                <Form.Group style={{ paddingTop: "30px" }}>
                  <Form.Label>{lang_data?.salary_start}</Form.Label>

                  <div className="form-inside left-inner-addon">
                    <Form.Control
                      type="text"
                      value={Functions.formatNumberWithComma(
                        this.state.us_salary_start
                      )}
                      readOnly
                      style={textbox_radius}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-cash-coin icon-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                      />
                      <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                      <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                      <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                    </svg>
                  </div>
                </Form.Group>
              </Col>
              <Col sm="12" lg="3" md="6">
                <Form.Group style={{ paddingTop: "30px" }}>
                  <Form.Label>{lang_data?.expected_salary2} </Form.Label>

                  <div className="form-inside left-inner-addon">
                    <Form.Control
                      type="text"
                      id="us_salary_end"
                      value={Functions.formatNumberWithComma(
                        this.state.us_salary_end
                      )}
                      readOnly
                      style={textbox_radius}
                    />

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-cash-coin icon-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                      />
                      <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                      <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                      <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                    </svg>
                  </div>
                </Form.Group>
              </Col>
              <Col sm="12" lg="6" md="6">
                <Form.Group className="mb-3" style={{ paddingTop: "30px" }}>
                  <Form.Label>{lang_data?.job_type_time}</Form.Label>
                  <div className="form-inside">
                    <Select
                      styles={customStyles}
                      placeholder={lang_data?.job_type_time}
                      defaultValue={default_job_type}
                      key={default_job_type}
                      isDisabled
                      isMulti
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-clock-fill icon-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    </svg>
                  </div>
                </Form.Group>
              </Col>
              <Col sm="12" lg="6" md="6">
                <Form.Group className="mb-3" style={{ paddingTop: "30px" }}>
                  <Form.Label>{lang_data?.job_category}</Form.Label>
                  <div className="form-inside">
                    <Select
                      placeholder={lang_data?.job_category}
                      defaultValue={default_job_function}
                      key={default_job_function}
                      isMulti
                      isDisabled
                      styles={customStyles}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-briefcase-fill icon-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z" />
                    </svg>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
