import React, { Component, Suspense } from "react";
import {
  Card,
  Container,
  Col,
  Row,
  Button,
  Form,
  ListGroup,
  Image,
  Badge,
} from "react-bootstrap";

import axios from "axios";
import "rc-slider/assets/index.css";
import ProfileMain from "./ProfileMain";
import HistoryVideo from "./HistoryVideo";
import ProfileDetail from "./ProfileDetail";
import Functions from "../../functions";
import Common from "../../common";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const user_id = Common.token;
const present_day = new Date().toISOString().split("T")[0];
export default class Profile extends Component {
  state = {
    filecore: "",
    filecore_cover: "",
    profilePageControl: "profile",

    job_type_data: [],
    job_fuction_data: [],

    us_salary_start: 0,
    us_salary_end: 0,

    ud_image_profile: "",
    ud_image_cover: "",
    ud_image_cover_position: "",
    ud_verify: 0,
    ud_bio: "",
    ud_birhday: present_day,
    ud_gender: 0,
    ud_address: "",
    ud_code: "",
    tambon_id: 0,
    country_id: 0,

    // โควต้า
    quota_position: 0,
    used_position: 0,
    quota_video: 0,
    used_video: 0,
    package_name: "",
    exire_date: "",

    uc_company_name: "",
  };
  refreshData = async () => {
    try {
      await axios
        .get(Common.API_URL + `user/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          let user_detail = res?.user_main_detail;
          let user_company = res?.user_main_company;
          // username
          this.setState({
            username: res?.username,
          });

          if (user_detail !== undefined) {
            this.setState({
              ud_image_profile: user_detail?.ud_image_profile,
              ud_image_cover: user_detail?.ud_image_cover,
              ud_image_cover_position: user_detail?.ud_image_cover_position,
              ud_verify: user_detail?.ud_verify,
              ud_bio: user_detail?.ud_bio,
              ud_birhday: user_detail?.ud_birhday,
              ud_gender: user_detail?.ud_gender,
              ud_address: user_detail?.ud_address,
              ud_code: user_detail?.ud_code,
              tambon_id: user_detail?.tambon_id,
              country_id: user_detail?.country_id,
            });
          }
          if (user_company !== undefined) {
            this.setState({
              uc_company_name: user_company?.uc_company_name,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleOffcanvasClose = () => {
    this.setState({
      openOffcanvas: false,
    });
  };

  readURL = (event) => {
    if (event.target.files && event.target.files[0]) {
      var output = document.getElementById("imgprofile");
      output.src = URL.createObjectURL(event.target.files[0]);
      output.onload = function () {
        URL.revokeObjectURL(output.src); // free memory
      };
      this.setState({
        filecore: event,
      });
    }
  };

  uploadImageProfile = async (event) => {
    let file = event.target.files[0];
    const formdata = new FormData();
    formdata.append("file", file);
    try {
      await axios
        .post(Common.API_FILE + "file/upload", formdata, Common.options2)
        .then((res) => {
          const r = res?.data;
          const path = r?.path;
          this.handleSubmit(path, this.state.ud_image_cover);
        });
    } catch (error) {
      console.log(error);
    }
  };

  DeleteImage = async (image) => {
    if (image === "" || image === null) {
      return false;
    }
    const url = new URL(image);
    const searchParams = url.searchParams;
    const path = searchParams.get("path");
    try {
      await axios.delete(
        Common.API_FILE + `file/delete?path=${path}`,
        Common.options2
      );
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = async (new_image_prifile, new_image_cover) => {
    this.DeleteImage(this.state.ud_image_profile);
    try {
      await axios
        .put(
          Common.API_URL + `user/detail/${user_id}`,
          {
            ud_image_profile: new_image_prifile,
            ud_image_cover: new_image_cover,
            ud_image_cover_position: this.state.ud_image_cover_position,
            ud_verify: this.state.ud_verify,
            ud_bio: this.state.ud_bio,
            ud_birhday: this.state.ud_birhday,
            ud_gender: this.state.ud_gender,
            ud_address: this.state.ud_address,
            tambon_id: this.state.tambon_id,
            country_id: this.state.country_id,
          },
          Common.options
        )
        .then((res) => {
          window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };
  onClickUploadImg = () => {
    document.getElementById("image_profile_upload").click();
  };

  onSubmit = () => {
    this.uploadImageProfile(this.state.filecore);
  };

  checkQuota = async () => {
    try {
      await axios
        .get(Common.API_URL + `payment/check/quota/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({
            quota_position: res?.quota_position,
            used_position: res?.used_position,
            quota_video: res?.quota_video,
            used_video: res?.used_video,
            package_name: res?.package_name,
            exire_date: res?.exire_date,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.refreshData();
    this.checkQuota();
  }

  render() {
    const { profilePageControl, ud_verify, uc_company_name } = this.state;

    return (
      <div>
        <div>
          <ProfileMain />
        </div>

        <Container>
          <Row
            style={{
              paddingTop: "20px",
              padding: "10px",
            }}
          >
            {/* User Profile Card */}
            <Col sm="12" md="6" lg="3">
              <Card>
                <Card.Header align="center">
                  <Form.Control
                    type="file"
                    size="sm"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => this.readURL(e)}
                    id="image_profile_upload"
                    style={{ display: "none" }}
                  />
                  <div className="gallery">
                    <li>
                      {Functions.check_empty_value(
                        this.state.ud_image_profile
                      ) === false ? (
                        <Image
                          src={this.state.ud_image_profile}
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "cover",
                          }}
                          id="imgprofile"
                          thumbnail
                          roundedCircle
                        />
                      ) : (
                        <Image
                          src={require("../../asset/images/no-user-image-icon-26.jpg")}
                          style={{ width: "200px", height: "200px" }}
                          id="imgprofile"
                          thumbnail
                          roundedCircle
                        />
                      )}

                      <span
                        style={{ cursor: "pointer" }}
                        onClick={this.onClickUploadImg}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          className="bi bi-camera-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                          <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                        </svg>
                      </span>
                    </li>
                  </div>
                  <div>
                    {uc_company_name}{" "}
                    {ud_verify === 1 && (
                      <Image
                        src={require("../../asset/images/checklist.webp")}
                        style={{ width: 20, height: 20 }}
                      />
                    )}
                  </div>
                  <Form.Control
                    type="file"
                    size="sm"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => this.readURL(e, "imgprofile")}
                    id="imageupload"
                    style={{ display: "none" }}
                  />
                  {this.state.filecore !== "" && (
                    <Button
                      variant="success"
                      onClick={this.onSubmit}
                      disabled={this.state.filecore === "" ? true : false}
                    >
                      {lang_data?.save}
                    </Button>
                  )}
                </Card.Header>
                {/* Quota Information */}
                <div style={{ padding: 5 }}>
                  <Row>
                    <Col>
                      <div align="left"> {lang_data?.position}</div>
                    </Col>
                    <Col>
                      <div align="right">
                        {this.state.used_position} {" / "}{" "}
                        {this.state.quota_position}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div align="left">{lang_data?.video}</div>
                    </Col>
                    <Col>
                      <div align="right">
                        {this.state.used_video} {" / "} {this.state.quota_video}
                      </div>
                    </Col>
                  </Row>
                  <div align="center">
                    {this.state.exire_date && (
                      <span>
                        {lang_data?.expiration_date} :{" "}
                        <Badge bg="warning">
                          {Functions.IsoDatetoShortDate(this.state.exire_date)}
                        </Badge>
                      </span>
                    )}
                  </div>
                </div>
                {/* Menu */}
                <ListGroup variant="flush">
                  <ListGroup.Item
                    onClick={(e) =>
                      this.setState({ profilePageControl: "profile" })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-person-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    </svg>{" "}
                    {lang_data?.profile}
                  </ListGroup.Item>

                  <ListGroup.Item
                    onClick={(e) =>
                      this.setState({ profilePageControl: "video_hitory" })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-person-video2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                      <path d="M2 1a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zM1 3a1 1 0 0 1 1-1h2v2H1zm4 10V2h9a1 1 0 0 1 1 1v9c0 .285-.12.543-.31.725C14.15 11.494 12.822 10 10 10c-3.037 0-4.345 1.73-4.798 3zm-4-2h3v2H2a1 1 0 0 1-1-1zm3-1H1V8h3zm0-3H1V5h3z" />
                    </svg>{" "}
                    {lang_data?.history_video}
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={(e) => (window.location.href = "/dashboard")}
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-graph-up"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M0 0h1v15h15v1H0zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07"
                      />
                    </svg>{" "}
                    {lang_data?.dashboard}
                  </ListGroup.Item>
                </ListGroup>
                {/*End Menu */}
              </Card>
            </Col>
            {/* End User Profile Card */}
            {/*Show Content  */}
            <Col sm="12" md="6" lg="8">
              <Suspense
                fallback={
                  <div align="center">
                    <h1>Loading...</h1>
                  </div>
                }
              >
                <div>
                  {profilePageControl === "profile" && <ProfileDetail />}
                  {profilePageControl === "video_hitory" && <HistoryVideo />}
                </div>
              </Suspense>
            </Col>
            {/*Show Content  */}
          </Row>
        </Container>
      </div>
    );
  }
}
