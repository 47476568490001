import React, { Component } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Container,
  Image,
  Form,
  InputGroup,
  Spinner,
  Modal,
} from "react-bootstrap";
import Linkify from "linkify-react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import axios from "axios";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const user_id_authen = Common.token;
const user_type_authen = Common.user_type;
const admin_id = Common.admin_id;
const CardStyle = styled.div`
  // https://freefrontend.com/bootstrap-chats/
  .card {
    background: #fff;
    transition: 0.5s;
    border: 0;
    margin-bottom: 30px;
    border-radius: 0.55rem;
    position: relative;
    width: 100%;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  }
  .chat-app .people-list {
    width: 280px;
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px;
    z-index: 7;
  }

  .chat-app .chat {
    margin-left: 280px;
    border-left: 1px solid #eaeaea;
  }

  .people-list {
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .people-list .chat-list li {
    padding: 10px 15px;
    list-style: none;
    border-radius: 3px;
  }

  .people-list .chat-list li:hover {
    background: #efefef;
    cursor: pointer;
  }

  .people-list .chat-list li.active {
    background: #efefef;
  }

  .people-list .chat-list li .name {
    font-size: 15px;
  }

  .people-list .chat-list img {
    width: 45px;
    border-radius: 50%;
  }

  .people-list img {
    float: left;
    border-radius: 50%;
  }

  .people-list .about {
    float: left;
    padding-left: 8px;
  }

  .people-list .status {
    color: #999;
    font-size: 13px;
  }

  .chat .chat-header {
    padding: 15px 20px;
    border-bottom: 2px solid #f4f7f6;
  }

  .chat .chat-header img {
    float: left;
    border-radius: 40px;
    width: 40px;
  }

  .chat .chat-header .chat-about {
    float: left;
    padding-left: 10px;
  }

  .chat .chat-history {
    padding: 20px;
    border-bottom: 2px solid #fff;
  }

  .chat .chat-history ul {
    padding: 0;
  }

  .chat .chat-history ul li {
    list-style: none;
    margin-bottom: 30px;
  }

  .chat .chat-history ul li:last-child {
    margin-bottom: 0px;
  }

  .chat .chat-history .message-data {
    margin-bottom: 15px;
  }

  .chat .chat-history .message-data img {
    border-radius: 40px;
    width: 40px;
  }

  .chat .chat-history .message-data-time {
    color: #434651;
    padding-left: 6px;
  }

  .chat .chat-history .message {
    color: #444;
    padding: 18px 20px;
    line-height: 26px;
    font-size: 16px;
    border-radius: 7px;
    display: inline-block;
    position: relative;
  }

  .chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
  }

  .chat .chat-history .my-message {
    background: #efefef;
  }

  .chat .chat-history .my-message:after {
    bottom: 100%;
    left: 30px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #efefef;
    border-width: 10px;
    margin-left: -10px;
  }

  .chat .chat-history .other-message {
    background: #e8f1f3;
    text-align: right;
  }

  .chat .chat-history .other-message:after {
    border-bottom-color: #e8f1f3;
    left: 93%;
  }

  .chat .chat-message {
    padding: 20px;
  }

  .online,
  .offline,
  .me {
    margin-right: 2px;
    font-size: 8px;
    vertical-align: middle;
  }

  .online {
    color: #86c541;
  }

  .offline {
    color: #e47297;
  }

  .me {
    color: #1d8ecd;
  }

  .float-right {
    float: right;
  }

  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  @media only screen and (max-width: 767px) {
    .chat-app .people-list {
      height: 465px;
      width: 100%;
      overflow-x: auto;
      background: #fff;
      left: -400px;
      display: none;
    }
    .chat-app .people-list.open {
      left: 0;
    }
    .chat-app .chat {
      margin: 0;
    }
    .chat-app .chat .chat-header {
      border-radius: 0.55rem 0.55rem 0 0;
    }
    .chat-app .chat-history {
      height: 300px;
      overflow-x: auto;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .chat-app .chat-list {
      height: 650px;
      overflow-x: auto;
    }
    .chat-app .chat-history {
      height: 600px;
      overflow-x: auto;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .chat-app .chat-list {
      height: 480px;
      overflow-x: auto;
    }
    .chat-app .chat-history {
      height: calc(100vh - 350px);
      overflow-x: auto;
    }
  }
`;
const ScrollbarStyle = styled.div`
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #88c141 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    // background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #88c141;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
`;

const socket = new WebSocket(Common.BASE_SOCKET + user_id_authen);
const GetDataForm = () => {
  const { user_id } = useParams();

  return <Messenger user_id={user_id} />;
};
export default GetDataForm;
class Messenger extends Component {
  messagesEndRef = React.createRef();
  state = {
    chat_id: "",
    chat_msg: "",
    user_sender: "",
    user_recipient: "",

    data: [],
    socket: null,
    param: [],

    page: 1,
    per_page: 10,
    search_value: "",
    typing: false,

    firstname: "",
    lastname: "",
    ud_image_profile: "",
    uc_company_name: "",
    ud_code: "",
    openModalForm: false,
    loading_cover: true,
    status_readed: false,
  };
  getBoxChat = async (user_recipient) => {
    try {
      await axios
        .post(
          Common.API_URL + "chat/msg/box/list",
          {
            user_sender: user_id_authen,
            user_recipient: user_recipient,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
          this.statusReaded(user_recipient);
        });
    } catch (error) {
      console.log(error);
    }
  };
  getHistoryChat = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `chat/msg/history/list/${user_id_authen}`,
          {
            page: this.state.page,
            per_page: this.state.per_page,
            search_value: this.state.search_value,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ historys: res.data, param: res });
        });
    } catch (error) {
      console.log(error);
      window.location.href = "/";
    }
  };
  getUserRecipient = async (user_recipient) => {
    this.setState({
      loading_cover: true,
    });
    try {
      await axios
        .get(Common.API_URL + `user/${user_recipient}`, Common.options)
        .then((response) => {
          let res = response.data;
          let user_detail = res?.user_main_detail;
          let user_company = res?.user_main_company;
          this.setState({
            firstname: res?.firstname,
            lastname: res?.lastname,
          });
          if (user_detail !== undefined) {
            this.setState({
              ud_image_profile: user_detail?.ud_image_profile,
              ud_code: user_detail?.ud_code,
            });
          }
          if (user_company !== undefined) {
            this.setState({
              uc_company_name: user_company?.uc_company_name,
            });
          }
        });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        loading_cover: false,
      });
    }
  };

  runSocket = () => {
    //// https://stackoverflow.com/questions/76391085/websocket-connection-failing-between-reactjs-frontend-and-fastapi-backend-on-azu
    socket.onopen = function (event) {
      console.log("WebSocket connected");
    };
    // socket.onmessage = function (event) {
    //   console.log("Received message:", event?.data);
    //   //   this.handleMsg(event);
    //   console.log(event);
    // };
    socket.onmessage = this.handleMsg;
    this.setState({
      socket: socket,

      // Create an interval to send echo messages to the server
      //   interval: setInterval(() => socket.send("echo"), 1000),
    });
  };
  handleMsg = (e) => {
    const user_recipient = this.props.user_id;
    // client_id รับค่าที่ส่งมาจากอีกเครื่อง
    const [client_id, msg] = e?.data.split(",");
    // let client_id = e.data;
    // ถ้า client_id ที่ส่งมาไม่ตรงกับผู้รับให้หยุดทำงาน
    if (client_id !== user_recipient) {
      // console.log(" error client_id " + client_id);
      return false;
    }
    if (msg.length > 1) {
      this.setState({ typing: true });
    } else {
      this.setState({ typing: false });
    }

    this.getBoxChat(this.props.user_id);
    this.getHistoryChat();
  };

  sendEventTyping = () => {
    const { chat_msg } = this.state;
    if (chat_msg !== "") {
      socket.send(chat_msg);
    }
  };
  sendEventClick = () => {
    const { chat_msg } = this.state;
    socket.send(chat_msg);
    this.handleSend(chat_msg);
  };
  sendEventEnter = (e) => {
    if (e.keyCode === 13) {
      const { chat_msg } = this.state;
      socket.send(chat_msg);
      this.handleSend(chat_msg);
    }
  };

  handleSend = (chat_msg) => {
    if (chat_msg === "") {
      return false;
    }
    const user_recipient = this.props.user_id;
    try {
      axios
        .post(
          Common.API_URL + "chat/msg/create",
          {
            chat_msg: chat_msg,
            user_sender: user_id_authen,
            user_recipient: user_recipient,
          },
          Common.options
        )
        .then((res) => {
          this.getBoxChat(this.props.user_id);
          this.handleSaveHistory(user_id_authen, user_recipient);
          this.handleSaveHistory(user_recipient, user_id_authen);
          document.getElementById("chat_msg").value = "";
          this.setState({ chat_msg: "" });
          socket.send("");
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleSendUpdate = () => {
    if (this.state.chat_msg === "" || this.state.chat_id === "") {
      return false;
    }
    const user_recipient = this.props.user_id;
    try {
      axios
        .put(
          Common.API_URL + `chat/msg/update/${this.state.chat_id}`,
          {
            chat_msg: this.state.chat_msg,
            user_sender: user_id_authen,
            user_recipient: user_recipient,
          },
          Common.options
        )
        .then((res) => {
          this.getBoxChat(this.props.user_id);
          this.setState({ chat_msg: "", chat_id: "", openModalForm: false });
          socket.send("");
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDelete = () => {
    if (!window.confirm("Confirm Delete")) {
      return true;
    }
    try {
      axios
        .delete(
          Common.API_URL + `chat/msg/delete/${this.state.chat_id}`,
          Common.options
        )
        .then((res) => {
          this.getBoxChat(this.props.user_id);
          this.setState({ chat_msg: "", chat_id: "", openModalForm: false });
          socket.send("");
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleSaveHistory = (user_sender_history, user_recipient_history) => {
    try {
      axios
        .post(
          Common.API_URL + "chat/msg/history/create",
          {
            user_sender_history: user_sender_history,
            user_recipient_history: user_recipient_history,
          },
          Common.options
        )
        .then((res) => {
          this.getHistoryChat();
        });
    } catch (error) {
      console.log(error);
    }
  };
  setReaded = (user_sender) => {
    try {
      axios.put(
        Common.API_URL + "chat/msg/readed/update?all=0",
        {
          user_sender: user_sender,
          user_recipient: user_id_authen,
        },
        Common.options
      );
    } catch (error) {
      console.log(error);
    }
  };
  // เช็คว่าคู่แชทอ่านหรือยัง
  statusReaded = (user_sender) => {
    try {
      axios
        .post(
          Common.API_URL + "chat/msg/readed/status",
          {
            user_sender: user_id_authen,
            user_recipient: user_sender,
          },
          Common.options
        )
        .then((res) => {
          const r = res.data;
          if (r?.status === "yes") {
            this.setState({ status_readed: true });
          } else {
            this.setState({ status_readed: false });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  targetUser = (user_recipient) => {
    this.getBoxChat(user_recipient);
    this.getUserRecipient(user_recipient);
    this.setReaded(user_recipient);
  };

  componentDidMount() {
    this.getBoxChat(this.props.user_id);
    this.getUserRecipient(this.props.user_id);
    this.setReaded(this.props.user_id);
    this.scrollToBottom();
    this.runSocket();
    this.getHistoryChat();
  }
  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    const {
      data,
      historys,
      typing,
      firstname,
      openModalForm,
      loading_cover,
      param,
      page,
      status_readed,
    } = this.state;
    const user_recipient = this.props.user_id;
    function custom_sort(a, b) {
      return (
        new Date(a.create_date).getTime() - new Date(b.create_date).getTime()
      );
    }
    const data_sort = data.sort(custom_sort);
    return (
      <div>
        <Container>
          <div style={{ paddingTop: 20 }}>
            <CardStyle>
              <ScrollbarStyle>
                <div className="container">
                  <div className="row clearfix">
                    <div className="col-lg-12">
                      <div className="card chat-app">
                        <div id="plist" className="people-list">
                          <Form.Group>
                            <Form.Control
                              placeholder={lang_data?.search}
                              onChange={(e) => [
                                this.setState({
                                  search_value: e.target.value,
                                }),
                                this.getHistoryChat(),
                              ]}
                              onKeyUp={(e) => [
                                this.setState({
                                  search_value: e.target.value,
                                }),
                                this.getHistoryChat(),
                              ]}
                            />
                          </Form.Group>

                          <ul
                            className="list-unstyled chat-list mt-2 mb-0"
                            style={{
                              overflowY: "auto",
                              height: 400,
                            }}
                          >
                            {historys?.map((rs, index) => (
                              <li
                                className={
                                  user_recipient === rs.user_recipient_history
                                    ? "clearfix active"
                                    : "clearfix"
                                }
                                key={index}
                                onClick={() =>
                                  this.targetUser(rs.user_recipient_history)
                                }
                              >
                                <Link
                                  to={"/messenger/" + rs.user_recipient_history}
                                  style={{ textDecoration: "none" }}
                                >
                                  {Functions.check_empty_value(
                                    rs.history_user_recipient?.user_main_detail
                                      ?.ud_image_profile
                                  ) === false ? (
                                    <Image
                                      src={
                                        rs.history_user_recipient
                                          ?.user_main_detail?.ud_image_profile
                                      }
                                      style={{
                                        width: 50,
                                        height: 50,
                                      }}
                                      alt="avatar"
                                      thumbnail
                                      roundedCircle
                                    />
                                  ) : (
                                    <Image
                                      src={require("../../asset/images/no-user-image-icon-26.jpg")}
                                      style={{
                                        width: 50,
                                        height: 50,
                                      }}
                                      alt="avatar"
                                      thumbnail
                                      roundedCircle
                                    />
                                  )}
                                  <div className="about">
                                    <div className="name">
                                      {rs.history_user_recipient?.firstname}{" "}
                                      {rs.history_user_recipient?.lastname}
                                    </div>
                                    <div className="status">
                                      {" "}
                                      {Functions.format_date_time(
                                        rs.update_date
                                      )}{" "}
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>

                          {parseInt(user_type_authen) !== 1 && (
                            <div align="center">
                              <Button
                                variant="info"
                                href={"/messenger/" + admin_id}
                              >
                                {lang_data?.connect_admin}
                              </Button>
                            </div>
                          )}

                          <div style={{ paddingTop: "70px" }} align="center">
                            <div style={{ width: "200px" }}>
                              <InputGroup className="mb-3" size="sm">
                                <InputGroup.Text>
                                  {" "}
                                  {lang_data?.page}
                                </InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  defaultValue={page}
                                  onChange={(e) => [
                                    this.setState({
                                      page: e.target.value,
                                    }),
                                    this.getHistoryChat(),
                                  ]}
                                  onKeyUp={(e) => [
                                    this.setState({
                                      page: e.target.value,
                                    }),
                                    this.getHistoryChat(),
                                  ]}
                                  onClick={(e) => [
                                    this.setState({
                                      page: e.target.value,
                                    }),
                                    this.getHistoryChat(),
                                  ]}
                                  style={{ textAlign: "center" }}
                                />
                                <InputGroup.Text>
                                  {lang_data?.total_page} : {param.total_page}{" "}
                                </InputGroup.Text>
                              </InputGroup>
                            </div>
                          </div>
                        </div>
                        {loading_cover === true && (
                          <div align="center">
                            <Image
                              src={require("../../asset/images/Loading_2.gif")}
                            />
                          </div>
                        )}
                        {user_recipient === "list" &&
                          loading_cover === false && (
                            <div align="center">
                              <Image
                                src={require("../../asset/images/chat2.png")}
                                style={{
                                  width: 150,
                                  height: 150,
                                }}
                              />
                              <span>
                                <h2>Chat</h2>
                              </span>
                            </div>
                          )}
                        {firstname !== "" && loading_cover === false && (
                          <div className="chat">
                            <div className="chat-header clearfix">
                              <div className="row">
                                <div className="col-lg-6">
                                  {Functions.check_empty_value(
                                    this.state.ud_image_profile
                                  ) === false ? (
                                    <Image
                                      src={this.state.ud_image_profile}
                                      style={{
                                        width: 50,
                                        height: 50,
                                      }}
                                      alt="avatar"
                                      thumbnail
                                      roundedCircle
                                    />
                                  ) : (
                                    <Image
                                      src={require("../../asset/images/no-user-image-icon-26.jpg")}
                                      style={{
                                        width: 50,
                                        height: 50,
                                      }}
                                      alt="avatar"
                                      thumbnail
                                      roundedCircle
                                    />
                                  )}

                                  <div className="chat-about">
                                    <h6 className="m-b-0">
                                      {this.state.firstname}{" "}
                                      {this.state.lastname}{" "}
                                      {this.state.uc_company_name !== "" && (
                                        <span>
                                          : {this.state.uc_company_name}
                                        </span>
                                      )}
                                    </h6>
                                    <small>{this.state.ud_code}</small>
                                  </div>
                                </div>
                                <div
                                  className="col-lg-6 hidden-sm"
                                  align="right"
                                >
                                  {/* ปุ่ม */}
                                </div>
                              </div>
                            </div>
                            <div
                              className="chat-history"
                              style={{
                                overflowY: "auto",
                                height: 300,
                              }}
                            >
                              <ul className="m-b-0">
                                {data_sort?.map((rs, index) => (
                                  <li className="clearfix" key={index}>
                                    <div
                                      className="message-data"
                                      align={
                                        rs.user_sender === user_id_authen
                                          ? "right"
                                          : "left"
                                      }
                                    >
                                      {rs.user_sender === user_id_authen ? (
                                        <div>
                                          {" "}
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.setState({
                                                openModalForm: true,
                                                chat_id: rs.chat_id,
                                                chat_msg: rs.chat_msg,
                                              })
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              className="bi bi-gear"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                              <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                            </svg>
                                          </span>{" "}
                                          <span className="message-data-time">
                                            {Functions.format_date_time(
                                              rs.create_date
                                            )}
                                          </span>{" "}
                                          {Functions.check_empty_value(
                                            rs.chat_user_sender
                                              ?.user_main_detail
                                              ?.ud_image_profile
                                          ) === false ? (
                                            <Image
                                              src={
                                                rs.chat_user_sender
                                                  ?.user_main_detail
                                                  ?.ud_image_profile
                                              }
                                              style={{
                                                width: 50,
                                                height: 50,
                                              }}
                                              alt="avatar"
                                              thumbnail
                                              roundedCircle
                                            />
                                          ) : (
                                            <Image
                                              src={require("../../asset/images/no-user-image-icon-26.jpg")}
                                              style={{
                                                width: 50,
                                                height: 50,
                                              }}
                                              alt="avatar"
                                              thumbnail
                                              roundedCircle
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <div>
                                          {Functions.check_empty_value(
                                            rs.chat_user_sender
                                              ?.user_main_detail
                                              ?.ud_image_profile
                                          ) === false ? (
                                            <Image
                                              src={
                                                rs.chat_user_sender
                                                  ?.user_main_detail
                                                  ?.ud_image_profile
                                              }
                                              style={{
                                                width: 50,
                                                height: 50,
                                              }}
                                              alt="avatar"
                                              thumbnail
                                              roundedCircle
                                            />
                                          ) : (
                                            <Image
                                              src={require("../../asset/images/no-user-image-icon-26.jpg")}
                                              style={{
                                                width: 50,
                                                height: 50,
                                              }}
                                              alt="avatar"
                                              thumbnail
                                              roundedCircle
                                            />
                                          )}
                                          <span className="message-data-time">
                                            {Functions.format_date_time(
                                              rs.create_date
                                            )}
                                          </span>{" "}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        rs.user_sender === user_id_authen
                                          ? "message other-message float-right"
                                          : "message my-message"
                                      }
                                    >
                                      <Linkify options={{ target: "_blank" }}>
                                        {rs.chat_msg}
                                      </Linkify>
                                    </div>
                                  </li>
                                ))}
                                <div ref={this.messagesEndRef} />
                              </ul>
                              {status_readed === true && data.length > 0 && (
                                <div
                                  style={{ fontSize: 10, textAlign: "right" }}
                                >
                                  {lang_data?.read_it}
                                </div>
                              )}
                            </div>

                            <div className="chat-message clearfix">
                              {typing === true && (
                                <div>
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />{" "}
                                  {lang_data?.typing}
                                </div>
                              )}

                              <InputGroup className="mb-3">
                                <Button
                                  variant="outline-primary"
                                  onClick={this.sendEventClick}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-cursor-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z" />
                                  </svg>
                                </Button>
                                <Form.Control
                                  placeholder={lang_data?.text_box_chat}
                                  onChange={(e) =>
                                    this.setState({ chat_msg: e.target.value })
                                  }
                                  onKeyDown={(e) => [
                                    this.sendEventEnter(e),
                                    this.sendEventTyping(),
                                  ]}
                                  id="chat_msg"
                                />
                              </InputGroup>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollbarStyle>
            </CardStyle>

            <Modal
              show={openModalForm}
              onHide={() => this.setState({ openModalForm: false })}
            >
              <Modal.Header closeButton>
                <Modal.Title> {lang_data?.text_editing}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={this.state.chat_msg}
                    onChange={(e) =>
                      this.setState({ chat_msg: e.target.value })
                    }
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => this.setState({ openModalForm: false })}
                >
                  {lang_data?.close}
                </Button>
                <Button variant="danger" onClick={this.handleDelete}>
                  {lang_data?.delete}
                </Button>
                <Button variant="success" onClick={this.handleSendUpdate}>
                  {lang_data?.editing}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Container>
      </div>
    );
  }
}
