const exports = {};
exports.app_name = "jinjijob";
exports.API_URL = "https://jinjijob-api-production.up.railway.app/"; //Production
// exports.API_URL = "http://127.0.0.1:5213/"; // Local
// exports.Voter = "http://localhost:3200/"; //Local
exports.Voter = "https://voter.jinjijob.com/"; //Production
exports.API_FILE = "https://file.jinjijob.com/";
// exports.BASE_SOCKET = "ws://127.0.0.1:5213/chat/ws/";
exports.BASE_SOCKET = "wss://jinjijob-api-production.up.railway.app/chat/ws/";
exports.options = {
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer gGaQfRuJ80k4JTErVxA5V9NQ8OB9fP",
  },
};
exports.options2 = {
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: "ChOqEpReyas9AdravufR",
  },
};
exports.token = localStorage.getItem("token");
exports.user_type = localStorage.getItem("user_type");
exports.langSet = localStorage.getItem("langSet");
exports.admin_id = "79235bc6da9c4d01a6806426";
exports.customSelectStyles = {
  control: (base) => ({
    ...base,
    height: 47,
    minHeight: 47,
  }),
};
export default exports;
