import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";
import Common from "../../common";
import Functions from "../../functions";
import axios from "axios";
import Swal from "sweetalert2";
const degree_list = Functions.degree_type;
export default class CandidateDetailEducation extends Component {
  state = {
    edu_id: 0,
    edu_degree: 0,
    edu_faculty: "",
    edu_major: "",
    edu_graduation_year: "",
    edu_gpa: 0,
    active: 1,
    institution_id: 0,
    data: [],
  };
  filterDegree = (id) => {
    if (id === 0 || id === undefined || id === null) {
      return false;
    }
    var r = degree_list.filter(function (entry) {
      return entry.degree_id === id;
    });

    return r;
  };

  refreshData = async () => {
    try {
      await axios
        .get(
          Common.API_URL + `user/education/${this.props.user_id}`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDeleteSubmit = async (edu_id) => {
    Swal.fire({
      title: "ยืนยันการลบ",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่, ต้องการลบข้อมูลนี้",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(Common.API_URL + `user/education/${edu_id}`, Common.options)
            .then((res) => {
              this.clearState();
              this.refreshData();

              Swal.fire({
                title: "เรียบร้อย",
                text: "ทำรายการสำเร็จ",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  clearState = () => {
    this.setState({
      edu_id: 0,
      edu_degree: "",
      edu_faculty: "",
      edu_major: "",
      edu_graduation_year: "",
      edu_gpa: 0,
      active: 1,
      institution_id: 0,
    });
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { data } = this.state;
    return (
      <div>
        {data !== undefined && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ปีที่จบการศึกษา</th>
                <th>สถาบัน</th>
                <th>คณะ / วิทยาลัย / แผนก</th>
                <th>สาขาวิชา</th>
                <th>ระดับการศึกษา</th>
                <th>GPA</th>
                <th>ลบ</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((rs, index) => (
                <tr key={index}>
                  <td>{rs.edu_graduation_year}</td>
                  <td>{rs.edu_institution.institution_name}</td>
                  <td>{rs.edu_faculty}</td>
                  <td>{rs.edu_major}</td>
                  <td>
                    {
                      this.filterDegree(parseInt(rs.edu_degree))[0]
                        .degree_name_eng
                    }
                  </td>
                  <td>{rs.edu_gpa}</td>
                  <td align="center">
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => this.handleDeleteSubmit(rs.edu_id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                      </svg>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    );
  }
}
