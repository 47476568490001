import React, { Component } from "react";

import {
  Badge,
  Breadcrumb,
  ButtonGroup,
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  Image,
  Modal,
} from "react-bootstrap";

import Common from "../../common";
import Functions from "../../functions";
import axios from "axios";
import Swal from "sweetalert2";
import styled from "styled-components";

import EmptyImageLogo from "../../asset/images/company_nologo.png";
import EmptyImageCover from "../../asset/images/4920290.jpg";
const CardStyle = styled.div`
  .animate {
    transition: all 0.5s ease;
  }

  .card-body {
    height: auto;
  }

  .img-top {
    overflow: hidden;
    width: auto;
    height: 200px;
    /*   max-height: 30vh; */
  }

  .img-top:hover img {
    transform: scale(1.5);
    filter: grayscale(0%);
  }

  .img-top a span {
    display: block;
    position: absolute;
    background-color: #00ffd1;
    cursor: pointer;
    /*   z-index: 2; */
  }

  .img-top a span:nth-child(1) {
    background-color: #00ffd1;
    right: 0;
    top: 200px;
    width: 100%;
    height: 5px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease-in-out;
  }

  .img-top:hover a span:nth-child(1) {
    background-color: #00ffd1;
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.5s ease-in-out;
  }

  .img-top a span:nth-child(2) {
    background-color: #00ffd1;
    right: 0;
    top: 0;
    width: 100%;
    height: 5px;
    border-radius: 5px 5px 0 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease-in-out;
  }

  .img-top:hover a span:nth-child(2) {
    background-color: #00ffd1;
    transform: scaleX(1);
    transform-origin: right;
    transition: transform 0.5s ease-in-out;
  }

  .img-profile {
    width: 100px;
    height: 100px;
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 0 5px white;
  }

  .img-profile:hover {
    box-shadow: 0 0 0 5px #00ffd1;
    transition: box-shadow 0.3s ease-in-out;
  }

  .img-profile:hover img {
    transform: scale(1.5);
    filter: grayscale(0%);
  }

  .card-body img {
    /*   aspect-ratio: 1/1; */
    margin: auto;
    width: 100%;
    height: auto;
    vertical-align: top;
    /* filter: grayscale(100%); */
  }

  .media-bar {
    height: 50px;
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    background-color: #222;
  }
`;
export default class HrCompany extends Component {
  state = {
    per_page: 25,
    data: [],
    isOpenModal: false,
    search_value: "",
    page: 1,
    param: [],
    user_id: "",

    username: "",
    password: "",
    firstname: "",
    lastname: "",
    email: "",
    user_image_prifile: "",
    user_image_cover: "",
    user_image_cover_position: "",
    user_type: 2,
    active: 0,

    uc_id: 0,
    openFormVerify: false,
    detail_data: [],
    preview_file: "",
  };
  refreshData = async () => {
    try {
      await axios
        .post(
          Common.API_URL + "user/all?typeuser=2",
          {
            page: this.state.page,
            per_page: 25,
            search_value: this.state.search_value,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res.data, param: res });
          //   console.log(res.data);
          //   alert(JSON.stringify(res.data));
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleEditClick = async (res) => {
    let active = res.active;
    let setactvie;
    if (active === 1) {
      setactvie = 0;
    } else {
      setactvie = 1;
    }

    await new Promise((accept) =>
      this.setState(
        {
          user_id: res.user_id,
          username: res.username,
          firstname: res.firstname,
          lastname: res.lastname,
          email: res.email,
          phone: res.phone,
          user_image_prifile: res.user_image_prifile,
          user_image_cover: res.user_image_cover,
          user_image_cover_position: res.user_image_cover_position,
          user_type: res.user_type,
          active: setactvie,
        },
        accept
      )
    );
    this.handleUpdateSubmit();
  };

  handleUpdateSubmit = () => {
    try {
      axios
        .put(
          Common.API_URL + `user/${this.state.user_id}`,
          {
            username: this.state.username,
            password: this.state.password,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            user_image_prifile: this.state.user_image_prifile,
            user_image_cover: this.state.user_image_cover,
            user_image_cover_position: this.state.user_image_cover_position,
            user_type: this.state.user_type,
            active: this.state.active,
          },
          Common.options
        )
        .then((res) => {
          this.clearState();
          this.refreshData();
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDeleteSubmit = async (user_id) => {
    Swal.fire({
      title: "ยืนยันการลบ",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่, ต้องการลบข้อมูลนี้",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(Common.API_URL + `user/${user_id}`, Common.options)
            .then((res) => {
              this.clearState();
              this.refreshData();

              Swal.fire({
                title: "เรียบร้อย",
                text: "ทำรายการสำเร็จ",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  setVerify = async (ud_verify) => {
    try {
      axios
        .put(
          Common.API_URL + "user/update/verify",
          {
            user_id: this.state.user_id,
            ud_verify: ud_verify,
          },
          Common.options
        )
        .then((res) => {
          this.clearState();
          this.refreshData();
        });
    } catch (error) {
      console.log(error);
    }
  };

  // File
  getDetail = async (uc_id) => {
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/company/detail/${uc_id}?ucd_type=3`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({
            detail_data: res,
            openFormVerify: true,
            preview_file: "",
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  DeleteDetail = (ucd_id, path) => {
    if (!window.confirm("Confirm Delete")) {
      return false;
    }

    try {
      axios
        .delete(
          Common.API_URL + `userpart2/company/detail/${ucd_id}`,

          Common.options
        )
        .then((res) => {
          this.getDetail(this.state.uc_id);
          this.DeleteFile(path);
          if (this.state.detail_data?.length <= 1) {
            this.setVerify(0);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  DeleteFile = async (file) => {
    if (file === "" || file === null) {
      return false;
    }
    const arr1 = file.split("upload")[1];
    const arr2 = arr1.split("?")[0];
    const path = "upload" + arr2;
    // console.log(path);
    try {
      await axios.delete(
        Common.API_FILE + `?route=delete&path=${path}`,
        Common.options2
      );
    } catch (error) {
      console.log(error);
    }
  };

  clearState = () => {
    this.setState({
      user_id: "",
      username: "",
      password: "",
      firstname: "",
      lastname: "",
      email: "",
      user_image_prifile: "",
      user_image_cover: "",
      user_image_cover_position: "",
      user_type: 2,
      active: 0,
      openFormVerify: false,
      detail_data: [],
      preview_file: "",
    });
  };
  getQueryStringName = (value) => {
    const url = new URL(value);
    const searchParams = url.searchParams;
    const res = searchParams.get("name");
    return res;
  };

  onChangeFilter = () => {
    // search_value
    this.refreshData();
  };
  componentDidMount() {
    this.refreshData();
  }

  render() {
    const {
      data,
      param,
      page,
      openFormVerify,
      detail_data,
      preview_file,
      user_id,
    } = this.state;

    return (
      <div>
        <Row>
          <Col sm={8}>
            <h3>ตัวแทนบริษัท</h3>
          </Col>
          <Col sm={4}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">หน้าหลัก</Breadcrumb.Item>

              <Breadcrumb.Item active>ตัวแทนบริษัท</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row style={{ paddingBottom: "30px" }}>
          <Col sm={9}>ทั้งหมด {param.total_filter_data} </Col>
          <Col sm={3}>
            <Form.Control
              type="text"
              placeholder="ค้นหา"
              onChange={(e) => [
                this.setState({
                  search_value: e.target.value,
                }),
                this.onChangeFilter(),
              ]}
              onKeyUp={(e) => [
                this.setState({
                  search_value: e.target.value,
                }),
                this.onChangeFilter(),
              ]}
            />
          </Col>
        </Row>

        <CardStyle>
          <Row>
            {data?.map((rs, index) => (
              <Col lg="3" md="6" sm="12" key={index}>
                <div className="card mb-2 h-100">
                  <div className="img-top">
                    {Functions.check_empty_value(
                      rs?.user_main_detail?.ud_image_cover
                    ) === false ? (
                      <Image
                        src={rs?.user_main_detail?.ud_image_cover}
                        className="card-img-top img-fluid animate"
                      />
                    ) : (
                      <Image
                        src={EmptyImageCover}
                        className="card-img-top img-fluid animate"
                      />
                    )}
                  </div>

                  <div className="card-body position-relative">
                    <div className="img-profile position-absolute top-0 start-50 translate-middle">
                      <div>
                        {Functions.check_empty_value(
                          rs.user_main_company?.uc_company_cover
                        ) === false ? (
                          <Image
                            src={rs.user_main_company?.uc_company_cover}
                            className="animate"
                          />
                        ) : (
                          <Image src={EmptyImageLogo} className="animate" />
                        )}
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col text-center">
                        <div align="center">
                          <Badge bg="info">
                            {rs.user_main_detail?.ud_code}
                          </Badge>
                        </div>
                        <h4>
                          <span>{rs.user_main_company?.uc_company_name}</span>
                        </h4>

                        <div>
                          <span>
                            ชื่อผู้ติดต่อ : {rs.firstname} {rs.lastname}
                          </span>
                        </div>
                        <div>
                          {rs.user_main_detail?.ud_verify === 1 && (
                            <Badge bg="success">
                              บัญชีนี้ได้รับการตรวจสอบแล้ว
                            </Badge>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <Row>
                      <Col lg="9" md="6">
                        <div align="center">
                          Create Date{" "}
                          {Functions.format_date_time(rs.create_date)}
                        </div>
                      </Col>
                      <Col lg="3" md="6">
                        <div align="right">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            defaultChecked={rs.active === 1 ? true : false}
                            onChange={() => this.handleEditClick(rs)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div align="center">
                  <span>
                    <Button variant="info" href={`/${rs.user_id}/view`}>
                      รายละเอียด
                    </Button>{" "}
                    <Button
                      variant="warning"
                      onClick={() => [
                        this.getDetail(rs.user_main_company?.uc_id),
                        this.setState({
                          user_id: rs.user_id,
                          uc_id: rs.user_main_company?.uc_id,
                        }),
                      ]}
                    >
                      ตรวจสอบ
                    </Button>{" "}
                    <Button
                      variant="danger"
                      onClick={(e) => this.handleDeleteSubmit(rs?.user_id)}
                    >
                      ลบ
                    </Button>
                  </span>
                </div>
              </Col>
            ))}
          </Row>
        </CardStyle>

        <div style={{ paddingTop: "70px" }} align="center">
          <div style={{ width: "200px" }}>
            <InputGroup className="mb-3" size="sm">
              <InputGroup.Text>หน้า</InputGroup.Text>
              <Form.Control
                type="number"
                defaultValue={page}
                onChange={(e) => [
                  this.setState({
                    page: e.target.value,
                  }),
                  this.onChangeFilter(),
                ]}
                onKeyUp={(e) => [
                  this.setState({
                    page: e.target.value,
                  }),
                  this.onChangeFilter(),
                ]}
                onClick={(e) => [
                  this.setState({
                    page: e.target.value,
                  }),
                  this.onChangeFilter(),
                ]}
                style={{ textAlign: "center" }}
              />
              <InputGroup.Text>จำนวนหน้า : {param.total_page} </InputGroup.Text>
            </InputGroup>
          </div>
        </div>

        <Modal
          show={openFormVerify}
          onHide={() =>
            this.setState({
              openFormVerify: false,
            })
          }
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>ตรวจสอบเอกสาร</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {detail_data?.map((rs, i) => (
                <span key={i}>
                  <ButtonGroup>
                    <Button
                      variant="outline-danger"
                      onClick={() =>
                        this.setState({
                          preview_file: rs.ucd_value,
                        })
                      }
                    >
                      {this.getQueryStringName(rs.ucd_value)}
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() =>
                        this.DeleteDetail(rs?.ucd_id, rs.ucd_value)
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash3-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                      </svg>
                    </Button>
                  </ButtonGroup>{" "}
                </span>
                // <div>{this.getQueryStringName(FILE_URL + rs.ucd_value)}</div>
              ))}
            </div>
            <div>
              {preview_file !== "" && (
                <iframe
                  style={{ width: "100%", height: "750px", paddingTop: "45px" }}
                  src={preview_file}
                  title="PDF File"
                ></iframe>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() =>
                this.setState({
                  openFormVerify: false,
                })
              }
            >
              ปิด
            </Button>
            <Button
              variant="primary"
              disabled={
                user_id === "" || detail_data?.length === 0 ? true : false
              }
              onClick={() => this.setVerify(1)}
            >
              อนุมัติ
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
