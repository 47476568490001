import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Container,
  Dropdown,
  Nav,
  NavDropdown,
  Navbar,
  Image,
  Modal,
  Button,
  Table,
} from "react-bootstrap";
import axios from "axios";
import styled from "styled-components";
import Swal from "sweetalert2";
import Messenger from "./component_general/Messenger";
import Profile from "./component_hrcompany/Profile";
import JobPosition from "./component_hrcompany/JobPosition";
import Favorite from "./component_hrcompany/Favorite";
import JobPositionApplyList from "./component_hrcompany/JobPositionApplyList";
import ProfileCalendar from "./component_hrcompany/ProfileCalendar";
import Resume from "./component_general/Resume";
import CompanyProfile from "./component_general/CompanyProfile";
import MyCandidate from "./component_hrcompany/MyCandidate";
import PackageSelect from "./component_hrcompany/PackageSelect";
import PaymentForm from "./component_hrcompany/PaymentForm";
import JobView from "./component_general/JobView";
import ViewAsCandidate from "./component_general/ViewAsCandidate";
import AlertContent from "./component_hrcompany/Alert";
import Dashboard from "./component_hrcompany/Dashboard";
import ErrorPage from "./ErrorPage";
import Logout from "../public_page/Logout";
import Common from "../common";
import Logo from "../asset/images/Jinjijob4.png";
import Functions from "../functions";
import lang from "../lang/lang";
const user_id = Common.token;
const langSet = Common.langSet;
const lang_data = lang(langSet);

const NotificationButtonStyle = styled.div`
  .notification {
    // background-color: #555;
    color: white;
    text-decoration: none;
    padding: 5px 1px;
    position: relative;
    display: inline-block;
    border-radius: 2px;
  }

  // .notification:hover {
  //   background: #a3adc9;
  // }

  .notification .badge {
    position: absolute;
    top: -1px;
    right: -2px;
    padding: 5px 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: red;
    color: white;
  }
  .notification .badge-text {
    position: absolute;
    bottom: 0;
    right: 0px;
    padding: 2px 2px;
    width: auto;
    height: auto;
    border-radius: 14%;
    background-color: gold;
    color: white;
    font-size: 8px;
    text-align: center;
  }
`;

const navStyle = {
  fontSize: "24px",
  fontWeight: "bold",
};
const linkStyle = {
  color: "#0E4C81",
  paddingLeft: "50px",
};

// const domain = window.location.href;
export default class SectionHR extends Component {
  state = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    ud_verify: 0,
    ud_image_profile: require("../asset/images/profile.png"),
    openFormOTP: false,
    alerFail: false,

    cartModal: false,
    data: [],

    // โควต้า
    quota_position: 0,
    used_position: 0,
    quota_video: 0,
    used_video: 0,
    package_name: "",
    exire_date: "",

    total_chat: 0,
    historys: [],
    alerts: [],
    total_alert: 0,
  };
  getUser = async () => {
    try {
      await axios
        .get(Common.API_URL + `user/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          let user_detail = res.user_main_detail;
          this.setState({
            firstname: res.firstname,
            lastname: res.lastname,
            email: res.email,
            phone: res.phone,
          });
          if (user_detail !== undefined) {
            this.setState({
              ud_image_profile:
                Functions.check_empty_value(user_detail?.ud_image_profile) ===
                false
                  ? user_detail?.ud_image_profile
                  : require("../asset/images/profile.png"),
              ud_verify: user_detail?.ud_verify,
              openFormOTP: user_detail?.ud_verify !== 1 ? true : false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  getCart = async () => {
    try {
      await axios
        .get(Common.API_URL + `payment/cart/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({
            data: res,
            cartModal: res.length > 0 ? true : false,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmitPayment = () => {
    try {
      axios
        .post(
          Common.API_URL + "payment/order/create",
          {
            order_status: 0,
            user_id: user_id,
          },
          Common.options
        )
        .then((res) => {
          window.location = "/payment";
        })
        .catch((err) => {
          Swal.fire({
            title: lang_data?.notify_title_error,
            text: lang_data?.notify_text_error,
            icon: "error",
            confirmButtonText: lang_data?.notify_confirm_error,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDelete = (cart_id) => {
    Swal.fire({
      title: lang_data?.notify_title_delete,
      text: lang_data?.notify_text_delete,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: lang_data?.notify_confirm_delete,
      cancelButtonText: lang_data?.cancelled,
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire("Deleted!", "Your file has been deleted.", "success");
        try {
          axios
            .delete(
              Common.API_URL + `payment/cart/delete/${cart_id}`,
              Common.options
            )
            .then((res) => {
              // window.location.reload();
              // this.PackageUsage();
              this.getCart();
              // this.setState({ cartModal: false, cart: 0, data: {} });
            });
        } catch (error) {
          Swal.fire({
            title: lang_data?.notify_title_error,
            text: lang_data?.notify_text_error,
            icon: "error",
            confirmButtonText: lang_data?.notify_confirm_error,
          });
        }
      }
    });
  };

  checkQuota = async () => {
    try {
      await axios
        .get(Common.API_URL + `payment/check/quota/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({
            quota_position: res?.quota_position,
            used_position: res?.used_position,
            quota_video: res?.quota_video,
            used_video: res?.used_video,
            package_name: res?.package_name,
            exire_date: res?.exire_date,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  switchLanguage = (val) => {
    localStorage.setItem("langSet", val);
    window.location.reload();
  };
  getTotalChat = async () => {
    try {
      await axios
        .get(Common.API_URL + `chat/msg/total/${user_id}`, Common.options)
        .then((response) => {
          const res = response.data;
          this.setState({
            total_chat: res?.total,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getHistoryChat = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `chat/msg/history/list/${user_id}`,
          {
            page: 1,
            per_page: 10,
            search_value: "",
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ historys: res.data });
        });
    } catch (error) {
      console.log(error);
      window.location.href = "/";
    }
  };

  setReaded = (user_sender) => {
    try {
      axios
        .put(
          Common.API_URL + "chat/msg/readed/update?all=0",
          {
            user_sender: user_sender,
            user_recipient: user_id,
          },
          Common.options
        )
        .then((res) => {
          window.location.href = "/messenger/" + user_sender;
        });
    } catch (error) {
      console.log(error);
    }
  };
  getAlert = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `general/alert/list/${user_id}?accept=3`,
          {
            page: 1,
            per_page: 10,
            search_value: "",
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;

          this.setState({ alerts: res.data });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getAlertTotalUnAccept = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `general/alert/list/${user_id}?accept=0`,
          {
            page: 1,
            per_page: 1,
            search_value: "",
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;

          this.setState({ total_alert: res.total_data });
        });
    } catch (error) {
      console.log(error);
    }
  };
  setAlertAccept = (alert_id, page) => {
    try {
      axios
        .get(Common.API_URL + `general/alert/set/${alert_id}`, Common.options)
        .then((res) => {
          window.location.href = page;
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.checkQuota();
    this.getCart();
    this.getUser();
    this.getTotalChat();
    this.getHistoryChat();
    this.getAlert();
    this.getAlertTotalUnAccept();
  }

  render() {
    const {
      cartModal,
      data,
      package_name,
      total_chat,
      historys,
      alerts,
      total_alert,
    } = this.state;
    let total_price = data?.reduce(function (_this, val) {
      return _this + val.pd_cart?.package_price;
    }, 0);
    return (
      <div>
        <NotificationButtonStyle>
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
            <Container>
              <Navbar.Brand href="/">
                <Image
                  variant="top"
                  src={Logo}
                  style={{ width: "85px", height: "60px" }}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto" style={navStyle}>
                  <Nav.Link href="/" style={linkStyle}>
                    {package_name !== "" ? package_name : "  "}
                  </Nav.Link>
                </Nav>

                <Nav>
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle
                      id="dropdown-autoclose-true"
                      variant="light"
                    >
                      <Image
                        variant="top"
                        src={
                          langSet === "en"
                            ? require("../asset/images/united-kingdom.png")
                            : require("../asset/images/thailand.png")
                        }
                        style={{ width: "45px", height: "45px" }}
                        roundedCircle
                        thumbnail
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#"
                        onClick={() => this.switchLanguage("th")}
                      >
                        Thai - ไทย
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => this.switchLanguage("en")}
                      >
                        English - อังกฤษ
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>

                <Nav>
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle variant="light">
                      <div className="notification">
                        <Image
                          variant="top"
                          src={require("../asset/images/comments.png")}
                          style={{ width: "45px", height: "45px" }}
                          roundedCircle
                          thumbnail
                        />
                        <span className="badge">
                          {total_chat <= 99 ? total_chat : "99+"}
                        </span>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {historys?.map((rs, index) => (
                        <Dropdown.Item
                          href="#"
                          key={index}
                          onClick={() =>
                            this.setReaded(rs.user_recipient_history)
                          }
                        >
                          {Functions.check_empty_value(
                            rs.history_user_recipient?.user_main_detail
                              ?.ud_image_profile
                          ) === false ? (
                            <Image
                              src={
                                rs.history_user_recipient?.user_main_detail
                                  ?.ud_image_profile
                              }
                              style={{
                                width: 50,
                                height: 50,
                              }}
                              alt="avatar"
                              thumbnail
                              roundedCircle
                            />
                          ) : (
                            <Image
                              src={require("../asset/images/no-user-image-icon-26.jpg")}
                              style={{
                                width: 50,
                                height: 50,
                              }}
                              alt="avatar"
                              thumbnail
                              roundedCircle
                            />
                          )}{" "}
                          {rs.history_user_recipient?.firstname}{" "}
                          {rs.history_user_recipient?.lastname}
                          <div
                            style={{
                              fontSize: 12,
                              width: 200,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {Functions.format_date_time(rs.update_date)}
                          </div>
                        </Dropdown.Item>
                      ))}

                      <Dropdown.Divider />
                      <Dropdown.Item href="/messenger/list">
                        <div align="center">{lang_data?.all_message}</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
                <Nav>
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle variant="light">
                      <div className="notification">
                        <Image
                          variant="top"
                          src={require("../asset/images/notification-bell.png")}
                          style={{ width: "45px", height: "45px" }}
                          roundedCircle
                          thumbnail
                        />
                        <span className="badge">
                          {total_alert <= 99 ? total_alert : "99+"}
                        </span>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div
                        style={{
                          width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {alerts?.map((rs, index) => (
                          <Dropdown.Item
                            href="#"
                            key={index}
                            onClick={() =>
                              this.setAlertAccept(
                                rs?.alert_id,
                                rs?.alert_route_page
                              )
                            }
                            style={{
                              backgroundColor:
                                rs?.alert_accept === 0 ? "#dadde3" : "",
                            }}
                          >
                            {rs?.alert_description}

                            <div
                              style={{
                                fontSize: 12,
                                width: 200,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {Functions.format_date_time(rs.create_date)}
                            </div>
                          </Dropdown.Item>
                        ))}
                      </div>
                      <Dropdown.Divider />
                      <Dropdown.Item href="/alert/list">
                        <div align="center"> {lang_data?.all_notify}</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>

                <Nav>
                  <div className="notification">
                    <Image
                      variant="top"
                      src={this.state.ud_image_profile}
                      style={{ width: "45px", height: "45px" }}
                      roundedCircle
                      thumbnail
                    />
                  </div>
                  <NavDropdown
                    title={this.state.firstname + " " + this.state.lastname}
                  >
                    <NavDropdown.Item href={"/package"}>
                      {lang_data?.package_label}
                    </NavDropdown.Item>
                    <NavDropdown.Item href={"/payment"}>
                      {lang_data?.order_label}
                    </NavDropdown.Item>
                    <NavDropdown.Item href={"/account"}>
                      {lang_data?.account}
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/logout">
                      {lang_data?.logout}
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </NotificationButtonStyle>

        {/* Content */}
        <Router>
          <Routes>
            <Route path="/" element={<Profile />} />
            <Route path="/account" element={<Profile />} />
            <Route path="/job-position" element={<JobPosition />} />
            <Route path="/favorite" element={<Favorite />} />
            <Route path="/messenger/:user_id" element={<Messenger />} />
            <Route
              path="apply-list/:jp_id"
              element={<JobPositionApplyList />}
            />
            <Route
              path="/candidate/:name/:user_id"
              element={<ViewAsCandidate />}
            />
            <Route path="/resume/:user_id/view" element={<Resume />} />
            <Route path="/job/:jp_position/:jp_id" element={<JobView />} />
            <Route path="/mycandidate" element={<MyCandidate />} />
            <Route path="/calendar" element={<ProfileCalendar />} />
            <Route path="/:user_id/view" element={<CompanyProfile />} />
            <Route path="/:company/:user_id" element={<CompanyProfile />} />
            <Route path="/package" element={<PackageSelect />} />
            <Route path="/payment" element={<PaymentForm />} />
            <Route path="/alert/list" element={<AlertContent />} />
            <Route path="/dashboard" element={<Dashboard />} />
            {/* 
            <Route path="/:name/apply-list" element={<ApplyList />} />

        
          
          
            <Route
              path="/:name/apply-list/:jp_id"
              element={<JobPositionApplyList />}
            />
            <Route path="/:name/package" element={<PackageSelect />} />
           
          */}
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
        {/* End Content */}

        <Modal show={cartModal}>
          <Modal.Header>
            <Modal.Title> {lang_data?.shoppingCart}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table>
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}>
                    {lang_data?.package_label}{" "}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {lang_data?.price_label} ( {lang_data?.bath} )
                  </th>
                  <th style={{ textAlign: "right" }}>#</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((items, i) => (
                  <tr key={i}>
                    <td>{items.pd_cart?.package_name}</td>
                    <td align="right">
                      {" "}
                      {Functions.formatNumberWithComma(
                        items.pd_cart?.package_price
                      )}{" "}
                    </td>
                    <td align="right">
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => this.handleDelete(items.cart_id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trash-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                        </svg>
                      </Button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={2} align="right">
                    <h5> {Functions.formatNumberWithComma(total_price)}</h5>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button
              variant="secondary"
              onClick={() =>
                this.setState({
                  cartModal: false,
                })
              }
            >
              ปิด
            </Button> */}

            <Button
              disabled={data.length >= 1 ? false : true}
              variant="primary"
              onClick={this.handleSubmitPayment}
            >
              {lang_data?.shopping}{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Footer */}
        {/* <div style={{ paddingTop: "50px" }}></div>
        <Navbar expand="lg" bg="light" variant="light">
          <Container>
            <Navbar.Brand>{app_name}</Navbar.Brand>
          </Container>
        </Navbar> */}
      </div>
    );
  }
}
