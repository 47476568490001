import React, { Component } from "react";
import { Table, Form } from "react-bootstrap";
import axios from "axios";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const d = new Date();
const year_present = d.getFullYear();
const list_year = Functions.get_year_option(year_present - 25, year_present);
const user_id = Common.token;
export default class ReportPopularPosition extends Component {
  state = {
    year: year_present,
    data: [],
  };

  refreshData = async () => {
    try {
      await axios
        .get(
          Common.API_URL +
            `dashboard/report/position/apply/${user_id}/${this.state.year}`,
          Common.options
        )
        .then((response) => {
          const res = response.data;
          this.setState({
            data: res,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  filterYear = async (e) => {
    await new Promise((accept) => {
      this.setState(
        {
          year: e.target.value,
        },
        accept
      );
    });
    this.refreshData();
  };
  componentDidMount() {
    this.refreshData();
  }
  render() {
    const { data, year } = this.state;
    return (
      <div>
        <div style={{ paddingTop: 10, paddingBottom: 15 }}>
          <Form.Select onChange={this.filterYear} defaultValue={year}>
            <option>ปี</option>
            {list_year.map((value, index) => (
              <option value={value} key={index}>
                {value}
              </option>
            ))}
          </Form.Select>
        </div>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th> {lang_data?.position}</th>
              <th> {lang_data?.total_candidate}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((r, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{r?.jp_position}</td>
                <td align="right">{r?.total_apply}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}
