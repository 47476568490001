import React, { Component } from "react";
import { Alert, Button, InputGroup, Form } from "react-bootstrap";
import Common from "../common";
import axios from "axios";
import OtpInput from "react-otp-input";
import "../asset/Login.css";
import lang from "../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
export default class Login extends Component {
  state = {
    msg: "",
    openFormOtp: 0,

    user_id: "",
    username: "",
    password: "",
    password_confirm: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    user_type: 0,
    active: 0,

    otp_code: 0,
    otp_ref: "",

    show_pass1: false,
    show_pass2: false,
  };

  getUser = async () => {
    if (this.state.user_id === "") {
      return false;
    }
    try {
      await axios
        .get(Common.API_URL + `user/${this.state.user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({
            username: res.username,
            firstname: res.firstname,
            lastname: res.lastname,
            email: res.email,
            phone: res.phone,
            user_type: res.user_type,
            active: res.active,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  checkPhone = () => {
    if (this.state.phone.length < 10 || this.state.phone === "") {
      this.setState({ msg: "Error !" });
      return false;
    }
    try {
      axios
        .get(
          Common.API_URL + "user/check/" + this.state.phone,

          Common.options
        )
        .then((res) => {
          let user = res.data;
          this.setState({ openFormOtp: 1, user_id: user.user_id, msg: "" });
        })
        .catch((err) => {
          // Handle error
          this.setState({
            msg: "Error !",
          });
          // console.log(err);
        });
    } catch (error) {
      this.setState({
        msg: "Error !",
      });
      console.log(error);
    }
  };
  getUserOTP = async () => {
    if (this.state.user_id === "") {
      return false;
    }
    try {
      await axios
        .get(
          Common.API_URL + `user/otp/get/${this.state.user_id}`,

          Common.options
        )
        .then((response) => {
          const res = response.data;
          this.setState({
            otp_ref: res?.otp_ref,
            msg: "",
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  verifyUserOTP = async () => {
    if (this.state.otp_code === "" || this.state.otp_ref === "") {
      return false;
    }
    try {
      await axios
        .put(
          Common.API_URL + "user/otp/verify",
          {
            otp_code: this.state.otp_code,
            otp_ref: this.state.otp_ref,
            user_id: this.state.user_id,
          },
          Common.options
        )
        .then((response) => {
          this.getUser();
          this.setState({ openFormOtp: 2, msg: "" });
        });
    } catch (error) {
      console.log(error);
      this.setState({
        alerFail: true,
      });
    }
  };

  setOtp = (e) => {
    this.setState({
      otp_code: e,
    });
  };

  updateUser = async () => {
    if (this.state.password !== this.state.password_confirm) {
      return false;
    }
    if (this.state.user_id === "") {
      return false;
    }
    try {
      await axios
        .put(
          Common.API_URL + `user/${this.state.user_id}`,
          {
            username: this.state.username,
            password: this.state.password,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: this.state.phone,
            user_type: this.state.user_type,
            active: this.state.active,
          },
          Common.options
        )
        .then((response) => {
          const r = response.data;
          localStorage.setItem("token", r?.user_id);
          localStorage.setItem("user_type", r?.user_type);
          window.location = "/";
        });
    } catch (error) {
      console.log(error);
      this.setState({
        alerFail: true,
      });
    }
  };

  phoneHidden = (phone) => {
    let result = phone.slice(6, 10);
    return "xxxxxx" + result;
  };
  render() {
    const { msg, openFormOtp, otp_code, otp_ref, show_pass1, show_pass2 } =
      this.state;
    return (
      <div>
        <div className="wrapper">
          <div className="form-signin">
            <h2 className="form-signin-heading">
              {lang_data?.forgot_password}
            </h2>
            {openFormOtp === 0 && (
              <div>
                <div className="inner-addon">
                  {msg !== "" && <Alert variant="danger">{msg}</Alert>}
                  <label className="control-label">
                    {lang_data?.phone_number_label}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => this.setState({ phone: e.target.value })}
                  />
                </div>{" "}
                <div align="center">
                  <Button variant="primary" onClick={this.checkPhone}>
                    {lang_data?.next_step}
                  </Button>
                </div>
              </div>
            )}
            {openFormOtp === 1 && (
              <div>
                <div className="inner-addon">
                  {msg !== "" && <Alert variant="danger">{msg}</Alert>}
                  <label className="control-label">
                    {lang_data?.next_step} {this.phoneHidden(this.state.phone)}
                  </label>
                  <OtpInput
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    onChange={this.setOtp}
                    value={otp_code}
                    inputStyle={{ width: "100%", height: 35 }}
                  />
                  <div align="center" style={{ paddingTop: 10.0 }}>
                    {otp_ref !== "" ? "Ref : " + otp_ref : ""}
                  </div>
                </div>{" "}
                <div align="left">
                  <Button variant="link" size="sm" onClick={this.getUserOTP}>
                    GET OTP
                  </Button>
                </div>
                <div align="center">
                  <Button
                    variant="primary"
                    onClick={this.verifyUserOTP}
                    disabled={otp_ref === "" ? true : false}
                  >
                    {lang_data?.next_step}
                  </Button>
                </div>
              </div>
            )}
            {openFormOtp === 2 && (
              <div>
                <div className="inner-addon">
                  {msg !== "" && <Alert variant="danger">{msg}</Alert>}

                  <Form.Group>
                    <Form.Label> {lang_data?.new_password}</Form.Label>
                    <label style={{ color: "red" }}> *</label>

                    <InputGroup>
                      <Form.Control
                        type={show_pass1 === false ? "password" : "text"}
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                        id="password"
                        placeholder={lang_data?.new_password}
                      />
                      {show_pass1 === false ? (
                        <Button
                          variant="outline-secondary"
                          onClick={() => this.setState({ show_pass1: true })}
                          style={{ height: 45 }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-eye-slash-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                          </svg>
                        </Button>
                      ) : (
                        <Button
                          variant="outline-secondary"
                          onClick={() => this.setState({ show_pass1: false })}
                          style={{ height: 45 }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                          </svg>
                        </Button>
                      )}
                    </InputGroup>
                  </Form.Group>
                </div>{" "}
                <div className="inner-addon">
                  <label className="control-label">
                    {lang_data?.confirm_new_password}
                  </label>

                  <Form.Group>
                    <Form.Label>{lang_data?.new_password}</Form.Label>
                    <label style={{ color: "red" }}> *</label>

                    <InputGroup>
                      <Form.Control
                        type={show_pass2 === false ? "password" : "text"}
                        onChange={(e) =>
                          this.setState({ password_confirm: e.target.value })
                        }
                        id="password"
                        placeholder={lang_data?.new_password}
                      />
                      {show_pass2 === false ? (
                        <Button
                          variant="outline-secondary"
                          onClick={() => this.setState({ show_pass2: true })}
                          style={{ height: 45 }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-eye-slash-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                          </svg>
                        </Button>
                      ) : (
                        <Button
                          variant="outline-secondary"
                          onClick={() => this.setState({ show_pass2: false })}
                          style={{ height: 45 }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                          </svg>
                        </Button>
                      )}
                    </InputGroup>
                  </Form.Group>
                </div>
                <div align="center">
                  <Button variant="primary" onClick={this.updateUser}>
                    {lang_data?.change_password}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
