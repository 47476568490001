import React, { Component } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button, Image, Modal } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const user_id_authen = Common.token;
let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
let localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
const GetDataForm = () => {
  const { jp_position, jp_id } = useParams();

  return <JobView jp_position={jp_position} jp_id={jp_id} />;
};
export default GetDataForm;
class JobView extends Component {
  state = {
    data: [],
    favorite_id: 0,
  };
  refreshData = async () => {
    let jp_id = this.props.jp_id;
    try {
      await axios
        .get(
          Common.API_URL + `j/job_position/${jp_id}`,

          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  confirmApply = async (jp_id) => {
    Swal.fire({
      title: lang_data?.notify_title_apply,
      text: lang_data?.notify_text_apply,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: lang_data?.notify_confirm_apply,
      cancelButtonText: lang_data?.cancelled,
    }).then((result) => {
      if (result.isConfirmed) {
        this.handleSubmit(jp_id);
      }
    });
  };

  handleSubmit = async (jp_id) => {
    try {
      await axios
        .post(
          Common.API_URL + "j/job_position/apply/create",
          {
            jp_id: jp_id,
            user_id: user_id_authen,
            jpa_conf_url: "",
            jpa_appointment: localISOTime,
          },
          Common.options
        )
        .then((response) => {
          Swal.fire({
            title: lang_data?.notify_title_success,
            text: lang_data?.notify_text_success,
            icon: "success",
            confirmButtonText: lang_data?.notify_confirm_success,
          });
        });
    } catch (error) {
      // console.log(error);
      Swal.fire({
        title: lang_data?.notify_title_error,
        text: lang_data?.notify_text_error,
        icon: "error",
        confirmButtonText: lang_data?.notify_confirm_error,
      });
    }
  };

  componentDidMount() {
    this.refreshData();
  }
  render() {
    const { data } = this.state;
    return (
      <div>
        <div style={{ paddingTop: 50, paddingBottom: 50 }}>
          <Container>
            <div
              className="modal show"
              style={{ display: "block", position: "initial" }}
            >
              <Modal.Dialog size="lg">
                <Modal.Body>
                  <div style={{ paddingBottom: 25 }}>
                    <Row>
                      <Col>
                        <Button
                          variant="danger"
                          onClick={() => this.confirmApply(data?.jp_id)}
                        >
                          {lang_data?.apply_now}
                        </Button>{" "}
                        <Button
                          variant="outline-success"
                          href={"/company/" + data?.user_id}
                          target="_blank"
                        >
                          {lang_data?.company_form_head}
                        </Button>
                      </Col>
                      <Col>
                        <div align="right">
                          <h3> {data?.jp_position}</h3>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    <div>
                      <Image
                        src={
                          Functions.check_empty_value(
                            data?.job_user?.user_main_detail?.ud_image_cover
                          ) === true
                            ? require("../../asset/images/4920290.jpg")
                            : data?.job_user?.user_main_detail?.ud_image_cover
                        }
                        style={{
                          width: "100%",
                          height: "300px",
                          objectFit: "cover",
                          objectPosition: `0 ${data?.job_user?.user_main_detail?.ud_image_cover_position}%`,
                        }}
                      />
                    </div>
                    <hr />
                    <div>
                      <Image
                        src={
                          Functions.check_empty_value(
                            data?.job_user?.user_main_company?.uc_company_cover
                          ) === true
                            ? require("../../asset/images/1594898327img.png")
                            : data?.job_user?.user_main_company
                                ?.uc_company_cover
                        }
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>

                    <div style={{ paddingTop: "15px" }}>
                      <Image
                        src={
                          require("../../asset/images/Job-functions-2.svg")
                            .default
                        }
                        style={{ width: "32px", height: "32px" }}
                      />{" "}
                      <strong> {data?.jp_position}</strong>
                    </div>
                    <div style={{ paddingTop: "15px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-building"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z" />
                        <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z" />
                      </svg>{" "}
                      {data?.job_user?.user_main_company?.uc_company_name}
                    </div>

                    <div style={{ paddingTop: "10px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-geo-alt-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                      </svg>{" "}
                      {data?.job_province?.province_eng} /{" "}
                      {data?.job_province?.province_thai}
                    </div>

                    <div style={{ paddingTop: "20px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-cash-coin"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                        />
                        <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                        <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                        <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                      </svg>{" "}
                      {Functions.formatNumberWithComma(data?.jp_salary_start)}
                      {" - "}
                      {Functions.formatNumberWithComma(data?.jp_salary_end)}
                      {" บาท "}
                    </div>

                    <div style={{ paddingTop: "10px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-exclude"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm12 2H5a1 1 0 0 0-1 1v7h7a1 1 0 0 0 1-1V4z" />
                      </svg>{" "}
                      <strong> {lang_data?.main_job}</strong>
                      {data?.job_detail !== undefined && (
                        <div>
                          <div>
                            {data?.job_detail
                              .filter((e) => e.jpd_type === "1")
                              .map((rd, i) => (
                                <div key={i}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-balloon-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M8.48 10.901C11.211 10.227 13 7.837 13 5A5 5 0 0 0 3 5c0 2.837 1.789 5.227 4.52 5.901l-.244.487a.25.25 0 1 0 .448.224l.04-.08c.009.17.024.315.051.45.068.344.208.622.448 1.102l.013.028c.212.422.182.85.05 1.246-.135.402-.366.751-.534 1.003a.25.25 0 0 0 .416.278l.004-.007c.166-.248.431-.646.588-1.115.16-.479.212-1.051-.076-1.629-.258-.515-.365-.732-.419-1.004a2.376 2.376 0 0 1-.037-.289l.008.017a.25.25 0 1 0 .448-.224l-.244-.487ZM4.352 3.356a4.004 4.004 0 0 1 3.15-2.325C7.774.997 8 1.224 8 1.5c0 .276-.226.496-.498.542-.95.162-1.749.78-2.173 1.617a.595.595 0 0 1-.52.341c-.346 0-.599-.329-.457-.644Z"
                                    />
                                  </svg>{" "}
                                  <span>{rd?.jpd_name}</span>
                                </div>
                              ))}
                          </div>

                          <div style={{ paddingTop: "10px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-info-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                            </svg>{" "}
                            <strong> {lang_data?.detail}</strong>
                            {data?.job_detail
                              .filter((e) => e.jpd_type === "2")
                              .map((rd, i) => (
                                <div key={i}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-balloon-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M8.48 10.901C11.211 10.227 13 7.837 13 5A5 5 0 0 0 3 5c0 2.837 1.789 5.227 4.52 5.901l-.244.487a.25.25 0 1 0 .448.224l.04-.08c.009.17.024.315.051.45.068.344.208.622.448 1.102l.013.028c.212.422.182.85.05 1.246-.135.402-.366.751-.534 1.003a.25.25 0 0 0 .416.278l.004-.007c.166-.248.431-.646.588-1.115.16-.479.212-1.051-.076-1.629-.258-.515-.365-.732-.419-1.004a2.376 2.376 0 0 1-.037-.289l.008.017a.25.25 0 1 0 .448-.224l-.244-.487ZM4.352 3.356a4.004 4.004 0 0 1 3.15-2.325C7.774.997 8 1.224 8 1.5c0 .276-.226.496-.498.542-.95.162-1.749.78-2.173 1.617a.595.595 0 0 1-.52.341c-.346 0-.599-.329-.457-.644Z"
                                    />
                                  </svg>{" "}
                                  <span>{rd?.jpd_name}</span>
                                </div>
                              ))}
                          </div>

                          <div style={{ paddingTop: "10px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-lightbulb-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z" />
                            </svg>{" "}
                            <strong>{lang_data?.feature} </strong>
                            {data?.job_detail
                              .filter((e) => e.jpd_type === "3")
                              .map((rd, i) => (
                                <div key={i}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-balloon-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M8.48 10.901C11.211 10.227 13 7.837 13 5A5 5 0 0 0 3 5c0 2.837 1.789 5.227 4.52 5.901l-.244.487a.25.25 0 1 0 .448.224l.04-.08c.009.17.024.315.051.45.068.344.208.622.448 1.102l.013.028c.212.422.182.85.05 1.246-.135.402-.366.751-.534 1.003a.25.25 0 0 0 .416.278l.004-.007c.166-.248.431-.646.588-1.115.16-.479.212-1.051-.076-1.629-.258-.515-.365-.732-.419-1.004a2.376 2.376 0 0 1-.037-.289l.008.017a.25.25 0 1 0 .448-.224l-.244-.487ZM4.352 3.356a4.004 4.004 0 0 1 3.15-2.325C7.774.997 8 1.224 8 1.5c0 .276-.226.496-.498.542-.95.162-1.749.78-2.173 1.617a.595.595 0 0 1-.52.341c-.346 0-.599-.329-.457-.644Z"
                                    />
                                  </svg>{" "}
                                  <span>{rd?.jpd_name}</span>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Modal.Body>
              </Modal.Dialog>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
