import React, { Component } from "react";
import styled from "styled-components";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ViewAsCandidateGeneral from "./ViewAsCandidateGeneral";
import ViewAsCandidateAddress from "./ViewAsCandidateAddress";
import ViewAsCandidateStrength from "./ViewAsCandidateStrength";
import ViewAsCandidateHobbies from "./ViewAsCandidateHobbies";
import ViewAsCandidateRewards from "./ViewAsCandidateRewards";
import Common from "../../common";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const StepStyle = styled.div`
  /* https://codepen.io/thdeux/pen/zBGNrM */
  .md-stepper-horizontal {
    display: table;
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0 3px 8px -6px rgba(0, 0, 0, 0.5);
  }
  .md-stepper-horizontal .md-step {
    display: table-cell;
    position: relative;
    padding: 24px;
  }
  .md-stepper-horizontal .md-step:hover,
  .md-stepper-horizontal .md-step:active {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .md-stepper-horizontal .md-step:active {
    border-radius: 15% / 75%;
  }
  .md-stepper-horizontal .md-step:first-child:active {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .md-stepper-horizontal .md-step:last-child:active {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .md-stepper-horizontal .md-step:hover .md-step-circle {
    background-color: #757575;
  }
  .md-stepper-horizontal .md-step:first-child .md-step-bar-left,
  .md-stepper-horizontal .md-step:last-child .md-step-bar-right {
    display: none;
  }
  .md-stepper-horizontal .md-step .md-step-circle {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    background-color: #999999;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
  }
  .md-stepper-horizontal.green .md-step.active .md-step-circle {
    background-color: #00ae4d;
  }
  .md-stepper-horizontal.orange .md-step.active .md-step-circle {
    /* background-color:#F96302; */
    background-color: #88c141;
  }
  .md-stepper-horizontal .md-step.active .md-step-circle {
    background-color: rgb(33, 150, 243);
  }
  .md-stepper-horizontal .md-step.done .md-step-circle:before {
    font-family: "FontAwesome";
    font-weight: 100;
    content: "\f00c";
  }
  .md-stepper-horizontal .md-step.done .md-step-circle *,
  .md-stepper-horizontal .md-step.editable .md-step-circle * {
    display: none;
  }
  .md-stepper-horizontal .md-step.editable .md-step-circle {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .md-stepper-horizontal .md-step.editable .md-step-circle:before {
    font-family: "FontAwesome";
    font-weight: 100;
    content: "\f040";
  }
  .md-stepper-horizontal .md-step .md-step-title {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 600;
  }
  .md-stepper-horizontal .md-step .md-step-title,
  .md-stepper-horizontal .md-step .md-step-optional {
    text-align: center;
    color: rgba(0, 0, 0, 0.26);
  }
  .md-stepper-horizontal .md-step.active .md-step-title {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
  }
  .md-stepper-horizontal .md-step.active.done .md-step-title,
  .md-stepper-horizontal .md-step.active.editable .md-step-title {
    font-weight: 600;
  }
  .md-stepper-horizontal .md-step .md-step-optional {
    font-size: 12px;
  }
  .md-stepper-horizontal .md-step.active .md-step-optional {
    color: rgba(0, 0, 0, 0.54);
  }
  .md-stepper-horizontal .md-step .md-step-bar-left,
  .md-stepper-horizontal .md-step .md-step-bar-right {
    position: absolute;
    top: 45px;
    height: 1px;
    border-top: 1px solid #dddddd;
  }
  .md-stepper-horizontal .md-step .md-step-bar-right {
    right: 0%;
    left: 55%;
    margin-left: 20px;
  }
  .md-stepper-horizontal .md-step .md-step-bar-left {
    left: 0;
    right: 55%;
    margin-right: 20px;
  }
`;

export default class ProfileDetail extends Component {
  state = {
    stepperActive: 1,
  };
  setStepper = async (value) => {
    // console.log(value);
    await new Promise((accept) =>
      this.setState(
        {
          stepperActive: value,
        },
        accept
      )
    );
  };

  render() {
    const { stepperActive } = this.state;
    let content;
    if (stepperActive === 1) {
      content = <ViewAsCandidateGeneral user_id={this.props.user_id} />;
    } else if (stepperActive === 2) {
      content = <ViewAsCandidateAddress user_id={this.props.user_id} />;
    } else if (stepperActive === 3) {
      content = <ViewAsCandidateStrength user_id={this.props.user_id} />;
    } else if (stepperActive === 4) {
      content = <ViewAsCandidateHobbies user_id={this.props.user_id} />;
    } else if (stepperActive === 5) {
      content = <ViewAsCandidateRewards user_id={this.props.user_id} />;
    }
    return (
      <div>
        <StepStyle>
          <div className="md-stepper-horizontal orange">
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip style={{ padding: "0px" }}>
                  {lang_data?.general}
                </Tooltip>
              }
            >
              <div
                className={stepperActive === 1 ? "md-step active" : "md-step"}
                style={{ cursor: "pointer" }}
                onClick={() => this.setStepper(1)}
              >
                <div className="md-step-circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-person-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                  </svg>
                </div>
                <div className="md-step-title">{lang_data?.general}</div>
                <div className="md-step-bar-left"></div>
                <div className="md-step-bar-right"></div>
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip style={{ padding: "0px" }}>
                  {lang_data?.address_label}
                </Tooltip>
              }
            >
              <div
                className={stepperActive === 2 ? "md-step active" : "md-step"}
                style={{ cursor: "pointer" }}
                onClick={() => this.setStepper(2)}
              >
                <div className="md-step-circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-geo-alt-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                </div>
                <div className="md-step-title">{lang_data?.address_label}</div>
                <div className="md-step-bar-left"></div>
                <div className="md-step-bar-right"></div>
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip style={{ padding: "0px" }}>
                  {lang_data?.strength_title}
                </Tooltip>
              }
            >
              <div
                className={stepperActive === 3 ? "md-step active" : "md-step"}
                style={{ cursor: "pointer" }}
                onClick={() => this.setStepper(3)}
              >
                <div className="md-step-circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-emoji-smile-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />
                  </svg>
                </div>
                <div className="md-step-title">{lang_data?.strength_title}</div>
                <div className="md-step-bar-left"></div>
                <div className="md-step-bar-right"></div>
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip style={{ padding: "0px" }}>
                  {lang_data?.hobbie_header}
                </Tooltip>
              }
            >
              <div
                className={stepperActive === 4 ? "md-step active" : "md-step"}
                style={{ cursor: "pointer" }}
                onClick={() => this.setStepper(4)}
              >
                <div className="md-step-circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-play-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                  </svg>
                </div>
                <div className="md-step-title">{lang_data?.hobbie_header}</div>
                <div className="md-step-bar-left"></div>
                <div className="md-step-bar-right"></div>
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip style={{ padding: "0px" }}>
                  {lang_data?.reward_certificate}
                </Tooltip>
              }
            >
              <div
                className={stepperActive === 5 ? "md-step active" : "md-step"}
                style={{ cursor: "pointer" }}
                onClick={() => this.setStepper(5)}
              >
                <div className="md-step-circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-trophy-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935z" />
                  </svg>
                </div>
                <div className="md-step-title">
                  {" "}
                  {lang_data?.reward_short_text}
                </div>
                <div className="md-step-bar-left"></div>
                <div className="md-step-bar-right"></div>
              </div>
            </OverlayTrigger>
          </div>
        </StepStyle>

        {content}
      </div>
    );
  }
}
