import React, { Component } from "react";
import {
  Button,
  Card,
  Container,
  Form,
  Tab,
  Tabs,
  InputGroup,
} from "react-bootstrap";
import Common from "../../common";
import Swal from "sweetalert2";
import axios from "axios";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const user_id = Common.token;
export default class Setting extends Component {
  state = {
    username: "",
    password: "",
    password_confirm: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    user_type: 0,
    active: 0,

    old_password: "",
    new_password: "",
    confirm_password: "",

    show_pass1: false,
    show_pass2: false,
    show_pass3: false,
  };

  refreshData = async () => {
    try {
      await axios
        .get(Common.API_URL + `user/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({
            username: res.username,
            firstname: res.firstname,
            lastname: res.lastname,
            email: res.email,
            phone: res.phone,
            user_type: res.user_type,
            active: res.active,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  changePassword = async () => {
    if (
      this.state.old_password === "" ||
      this.state.password === "" ||
      this.state.confirm_password === ""
    ) {
      Swal.fire({
        title: lang_data?.notify_title_error,
        text: lang_data?.notify_text_invalid_data,
        icon: "error",
        confirmButtonText: lang_data?.notify_confirm_success,
      });
      return false;
    }
    if (this.state.new_password.length < 8) {
      Swal.fire({
        title: lang_data?.notify_title_error,
        text: lang_data?.notify_text_password1,
        icon: "error",
        confirmButtonText: lang_data?.notify_confirm_success,
      });
      return false;
    }

    if (this.state.new_password !== this.state.confirm_password) {
      Swal.fire({
        title: lang_data?.notify_title_error,
        text: lang_data?.notify_text_password2,
        icon: "error",
        confirmButtonText: lang_data?.notify_confirm_success,
      });
      return false;
    }

    try {
      await axios
        .put(
          Common.API_URL + `user/change/password/${user_id}`,
          {
            old_password: this.state.old_password,
            new_password: this.state.new_password,
            confirm_password: this.state.confirm_password,
          },
          Common.options
        )
        .then((response) => {
          this.setState({
            old_password: "",
            new_password: "",
            confirm_password: "",
          });
          document.getElementById("old_password").value = "";
          document.getElementById("new_password").value = "";
          document.getElementById("confirm_password").value = "";
          Swal.fire({
            title: lang_data?.notify_title_success,
            text: lang_data?.notify_text_success,
            icon: "success",
            confirmButtonText: lang_data?.notify_confirm_success,
          });
        })
        .catch((err) => {
          Swal.fire({
            title: lang_data?.notify_title_error,
            text: lang_data?.notify_text_error,
            icon: "error",
            confirmButtonText: lang_data?.notify_confirm_error,
          });
        });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: lang_data?.notify_title_error,
        text: lang_data?.notify_text_error,
        icon: "error",
        confirmButtonText: lang_data?.notify_confirm_error,
      });
    }
  };

  closeUser = async () => {
    Swal.fire({
      title: lang_data?.notify_title_close_account,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: lang_data?.notify_confirm_close_account,
      cancelButtonText: lang_data?.cancelled,
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .put(
              Common.API_URL + `user/${user_id}`,
              {
                username: this.state.username,
                password: "",
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
                phone: this.state.phone,
                user_type: this.state.user_type,
                active: this.state.active,
              },
              Common.options
            )
            .then((response) => {
              window.location = "/logout";
            });
        } catch (error) {
          console.log(error);
          Swal.fire({
            title: lang_data?.notify_title_error,
            text: lang_data?.notify_text_error,
            icon: "error",
            confirmButtonText: lang_data?.notify_confirm_error,
          });
        }

        window.location = "/logout";
      }
    });
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { show_pass1, show_pass2, show_pass3, username } = this.state;
    return (
      <div>
        <div style={{ paddingTop: "45px", paddingBottom: "45px" }}>
          <Container>
            <div>
              <h3> {lang_data?.setting_header}</h3>
            </div>
            <Tabs defaultActiveKey="change_pass" id="uncontrolled-tab-example">
              <Tab eventKey="change_pass" title={lang_data?.change_password}>
                <div style={{ padding: 10 }}>
                  <Card>
                    <Card.Body>
                      <div
                        style={{
                          display: " inline-block",
                          marginLeft: "25%",
                          marginRight: "25%",
                          width: "50%",
                        }}
                      >
                        <Form.Group>
                          <Form.Label> {lang_data?.old_password}</Form.Label>
                          <label style={{ color: "red" }}> *</label>
                          <InputGroup>
                            <Form.Control
                              type={show_pass1 === false ? "password" : "text"}
                              onChange={(e) =>
                                this.setState({ old_password: e.target.value })
                              }
                              id="old_password"
                            />
                            {show_pass1 === false ? (
                              <Button
                                variant="outline-secondary"
                                onClick={() =>
                                  this.setState({ show_pass1: true })
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-eye-slash-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                </svg>
                              </Button>
                            ) : (
                              <Button
                                variant="outline-secondary"
                                onClick={() =>
                                  this.setState({ show_pass1: false })
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-eye-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                </svg>
                              </Button>
                            )}
                          </InputGroup>
                        </Form.Group>

                        <Form.Group>
                          <Form.Label>{lang_data?.new_password}</Form.Label>
                          <label style={{ color: "red" }}> *</label>

                          <InputGroup>
                            <Form.Control
                              type={show_pass2 === false ? "password" : "text"}
                              onChange={(e) =>
                                this.setState({ new_password: e.target.value })
                              }
                              id="new_password"
                            />
                            {show_pass2 === false ? (
                              <Button
                                variant="outline-secondary"
                                onClick={() =>
                                  this.setState({ show_pass2: true })
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-eye-slash-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                </svg>
                              </Button>
                            ) : (
                              <Button
                                variant="outline-secondary"
                                onClick={() =>
                                  this.setState({ show_pass2: false })
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-eye-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                </svg>
                              </Button>
                            )}
                          </InputGroup>
                        </Form.Group>

                        <Form.Group>
                          <Form.Label>
                            {lang_data?.confirm_new_password}
                          </Form.Label>
                          <label style={{ color: "red" }}> *</label>

                          <InputGroup>
                            <Form.Control
                              type={show_pass3 === false ? "password" : "text"}
                              onChange={(e) =>
                                this.setState({
                                  confirm_password: e.target.value,
                                })
                              }
                              id="confirm_password"
                            />
                            {show_pass3 === false ? (
                              <Button
                                variant="outline-secondary"
                                onClick={() =>
                                  this.setState({ show_pass3: true })
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-eye-slash-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                </svg>
                              </Button>
                            ) : (
                              <Button
                                variant="outline-secondary"
                                onClick={() =>
                                  this.setState({ show_pass3: false })
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-eye-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                </svg>
                              </Button>
                            )}
                          </InputGroup>
                        </Form.Group>

                        <Form.Group>
                          <div align="center" style={{ paddingTop: 10 }}>
                            <Button onClick={this.changePassword}>
                              {lang_data?.change_password}
                            </Button>
                          </div>
                        </Form.Group>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Tab>
              <Tab eventKey="close_user" title={lang_data?.close_account}>
                <div style={{ padding: 10 }}>
                  <Card body>
                    <div>{lang_data?.close_account_warning}</div>
                    <Button
                      variant="danger"
                      onClick={(e) => [
                        this.setState({
                          active: 0,
                        }),
                        this.closeUser(),
                      ]}
                      disabled={username === "" ? true : false}
                    >
                      {lang_data?.close_account}
                    </Button>{" "}
                  </Card>
                </div>
              </Tab>
            </Tabs>
          </Container>
        </div>
      </div>
    );
  }
}
