import React, { Component } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Button,
  ButtonGroup,
  Container,
  Image,
  Row,
  Col,
  Form,
  Modal,
  Card,
  InputGroup,
  Nav,
  Table,
  // OverlayTrigger,
  // Popover,
} from "react-bootstrap";
import Joyride from "react-joyride";
import styled from "styled-components";
import Swal from "sweetalert2";
import axios from "axios";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import ReactPlayer from "react-player";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const base_voter = Common.Voter;
const user_id = Common.token;
const admin_id = Common.admin_id;
// const host = window.location.protocol + "//" + window.location.host;
let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
let localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
const GetDataForm = () => {
  const { jp_id } = useParams();
  return <JobPositionApplyList jp_id={jp_id} />;
};
export default GetDataForm;

const CardStyle = styled.div`
  ///https://codepen.io/ShortCode/pen/RwyRZKN
  .card {
    // padding: 15px;
    width: 100%;
    height: 450px;
    background: #ffffff;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.7);
    // user-select: none;
  }

  .cover-photo {
    position: relative;
    //background: url(https://i.imgur.com/jxyuizJ.jpeg);
    background-size: cover;
    height: 180px;
    border-radius: 5px 5px 0 0;
  }

  .profile {
    position: absolute;
    width: 120px;
    //   bottom: -60px;
    top: 115px;
    left: 25px;
    border-radius: 50%;
    border: 2px solid #222;
    background: #222;
    padding: 5px;
  }

  .profile-name {
    //font-size: 20px;
    margin: 15px 0 0 120px;
    color: black;
  }

  .about {
    margin-top: 30px;
    line-height: 1.6;
  }

  .button {
    border: none;
    // color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }

  .btn:hover {
    background: #7ce3ff;
    color: #222;
  }

  .icons {
    width: 180px;
    margin: 0 auto 10px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }

  .icons i {
    cursor: pointer;
    padding: 5px;
    font-size: 18px;
    transition: 0.2s;
  }

  .footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    // color: white;
    text-align: center;
  }

  .icons i:hover {
    color: #7ce3ff;
  }
`;

const BadgesStyle = styled.div`
  .demo-preview {
    padding-top: 60px;
    padding-bottom: 10px;
    margin: auto;
    text-align: center;
  }
  .demo-preview .badge {
    margin-right: 10px;
  }
  .badge {
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    padding: 3px 6px;
    border: 1px solid transparent;
    min-width: 10px;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 99999px;
  }

  .badge.badge-default {
    background-color: #b0bec5;
  }

  .badge.badge-primary {
    background-color: #2196f3;
  }

  .badge.badge-secondary {
    background-color: #323a45;
  }

  .badge.badge-success {
    background-color: #64dd17;
  }

  .badge.badge-warning {
    background-color: #ffd600;
  }

  .badge.badge-info {
    background-color: #29b6f6;
  }

  .badge.badge-danger {
    background-color: #ef1c1c;
  }

  .badge.badge-outlined {
    background-color: transparent;
  }

  .badge.badge-outlined.badge-default {
    border-color: #b0bec5;
    color: #b0bec5;
  }

  .badge.badge-outlined.badge-primary {
    border-color: #2196f3;
    color: #2196f3;
  }

  .badge.badge-outlined.badge-secondary {
    border-color: #323a45;
    color: #323a45;
  }

  .badge.badge-outlined.badge-success {
    border-color: #64dd17;
    color: #64dd17;
  }

  .badge.badge-outlined.badge-warning {
    border-color: #ffd600;
    color: #ffd600;
  }

  .badge.badge-outlined.badge-info {
    border-color: #29b6f6;
    color: #29b6f6;
  }

  .badge.badge-outlined.badge-danger {
    border-color: #ef1c1c;
    color: #ef1c1c;
  }
`;

// jpa_type 0 = สมัครงาน , 1 = เลือกเพื่อโหวด , 2 = Final Candidate
class JobPositionApplyList extends Component {
  state = {
    jp_id: 0,
    search_value: "",
    page: 1,
    per_page: 25,
    param: [],
    // data
    data: [],
    main_data: [],
    video_path: "",
    portfolio_id: 0,
    total_select: 0,
    total_final: 0,
    get_content_apply: [],
    //
    openModalVideo: false,
    openModalVote: false,
    openModalAppointment: false,
    openModalVotedList: false,
    jpa_type_filter: [0],
    jpa_status_filter: [],

    // Vote
    vote_id: "",
    vote_password: "",
    vote_close: 0,
    link_vote: "",

    // Select
    jpa_appointment: localISOTime,
    jpa_conf_url: "",

    // Set Active
    job_active: 0,

    // User ที่เลือก
    user_select: "",
    // โควต้า
    quota_position: 0,
    used_position: 0,
    quota_video: 0,
    used_video: 0,
    package_name: "",
    exire_date: "",

    appm_control: 0,
    fullName: "",

    // คนที่โหวต
    voted_list: [],

    steps: [
      {
        target: ".tab1",
        content:
          "พิจารณาและคัดกรองผู้สมัครแต่ละคน และกดปุ่มยอมรับเพื่อเลือกผู้สมัครและเข้าสู่ขั้นตอนการเลือกสัมภาษณ์",
      },
      {
        target: ".tab2",
        content:
          "รายชื่อผู้สมัครที่ผ่านการตอบรับจากคุณ สำหรับเตรียมโหวตเพื่อเข้ารับการสัมภาษณ์ เมื่อรายชื่อครบแล้วกรุณาคลิกปุ่มโหวตเพื่อส่ง Link ให้ผู้บริหาร / ผู้อำนวยการ หัวหน้าหรือบุคคลากรที่เกี่ยวข้อง",
      },
      {
        target: ".vote_button",
        content:
          "ส่ง Link โหวตไปยังผู้บริหาร / ผู้อำนวยการ หัวหน้าหรือบุคคลากรที่เกี่ยวข้องในการพิจารณาคัดเลือกผู้สมัครเพื่อนัดสัมภาษณ์",
      },
      {
        target: ".close_vote_button",
        content:
          "เมื่อทำารโหวตจาก Link ที่ส่งให้ผู้ที่เกี่ยวข้องโหวตเสร็จแล้วจากนั้นปิดโหวตเพื่อเสร็จสิ้นการโหวตและรวบรวมรายชื่อใหม่เพื่อนัดสัมภาษณ์",
      },
      {
        target: ".tab3",
        content: `คลิ๊กปุ่ม "นัดหมาย" เพื่อนัดสัมภาษณ์ผูู้สมัครที่ได้รับการเลือก สามารถทำนัดสัมภาษณ์กี่รอบก็ได้ เมื่อสัมภาษณ์เสร็จสิ้นแล้ว ให้คลิ๊กปุ่ม "คัดเลือก" ผู้ผ่านการสัมภาษณ์รอบสุดท้าย`,
      },
      {
        target: ".confirm_candidate_button",
        content:
          "กดปุ่มนี้เพื่อเสร็จสิ้นกระบวนการเลือกและพิจารณาผู้สมัคร เมื่อทำรายการเสร็จสิ้นแล้วจะไม่สามารถแก้ไขปรับเปลี่ยนได้",
      },
      {
        target: ".tab4",
        content: "รายชื่อผู้ผ่านการคัดเลือกรอบสุดท้าย สำหรับดำเนินการว่าจ้าง",
      },
    ],
  };
  GetMainData = async () => {
    const jp_id = this.props.jp_id;
    try {
      await axios
        .get(Common.API_URL + `j/job_position/${jp_id}`, Common.options)
        .then((response) => {
          let res = response.data;

          this.setState({ main_data: res, job_active: res.active });
          // console.log(res.active);
        });
    } catch (error) {
      console.log(error);
      window.location = "/";
    }
  };
  updateStatusJobPost = () => {
    Swal.fire({
      title: lang_data?.notify_title_final_selected,
      text: lang_data?.notify_text_final_selected,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: lang_data?.notify_confirm_success,
      cancelButtonText: lang_data?.cancelled,
    }).then((result) => {
      if (result.isConfirmed) {
        const jp_id = this.props.jp_id;
        try {
          axios
            .get(
              Common.API_URL + `j/job_position/close_job/${jp_id}`,
              Common.options
            )
            .then((res) => {
              this.GetMainData();
              this.handleAlertMultiple();
              Swal.fire({
                title: lang_data?.notify_title_success,
                text: lang_data?.notify_text_success,
                icon: "success",
                confirmButtonText: lang_data?.notify_confirm_success,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  refreshData = async () => {
    const jp_id = this.props.jp_id;
    try {
      await axios
        .post(
          Common.API_URL + `j/job_position/apply/list/${jp_id}`,
          {
            page: this.state.page,
            per_page: this.state.per_page,
            jpa_type: this.state.jpa_type_filter,
            jpa_step: [],
            jpa_status: [],
            search_value: this.state.search_value,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res.data, param: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  refreshDataVideo = async (user_id) => {
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/portfolio/${user_id}?portfolio_type=3`,
          Common.options
        )
        .then((response) => {
          let res = response.data;

          if (
            res?.portfolio_path !== null &&
            res?.portfolio_path !== undefined
          ) {
            this.setState({
              video_path: res.portfolio_path,
              portfolio_id: res.portfolio_id,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  getSelect = async () => {
    const jp_id = this.props.jp_id;
    try {
      await axios
        .post(
          Common.API_URL + `j/job_position/apply/list/${jp_id}`,
          {
            page: this.state.page,
            per_page: this.state.per_page,
            jpa_type: [1, 2],
            jpa_step: [],
            jpa_status: [],
            search_value: this.state.search_value,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ total_select: res.total_filter_data });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getFinal = async () => {
    const jp_id = this.props.jp_id;
    try {
      await axios
        .post(
          Common.API_URL + `j/job_position/apply/list/${jp_id}`,
          {
            page: this.state.page,
            per_page: this.state.per_page,
            jpa_type: [],
            jpa_step: [],
            jpa_status: [1],
            search_value: this.state.search_value,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ total_final: res.total_filter_data });
          // console.log(res.total_filter_data);
        });
    } catch (error) {
      console.log(error);
    }
  };
  selectCandidate = async (rs, jpa_type) => {
    const jpa_id = rs.jpa_id;
    let step;
    if (jpa_type === 1) {
      step = 1;
    } else if (jpa_type === 2) {
      step = 2;
    } else {
      step = 0;
    }

    try {
      await axios
        .put(
          Common.API_URL + `j/job_position/apply/${jpa_id}`,
          {
            jpa_type: jpa_type,
            jpa_step: step,
            jpa_status: rs.jpa_status,
            jpa_conf_url: rs.jpa_conf_url,
            jpa_appointment: this.state.jpa_appointment,
            jp_id: rs.jp_id,
            user_id: rs.user_id,
          },
          Common.options
        )
        .then((response) => {
          this.refreshData();
          this.getSelect();
          this.getFinal();
          if (parseInt(jpa_type) === 1) {
            //  แจ้งเตือนไปยัง Candidate ได้รับการตอบรับจาก HR
            this.handleAlert(
              `สมัครงานตำแหน่ง ${this.state.main_data?.jp_position} ได้รับการตอบรับแล้ว`,
              4,
              "/applylist",
              rs.user_id
            );
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleAlert = (
    alert_description,
    alert_doc_type,
    alert_route_page,
    user_id
  ) => {
    try {
      axios.post(
        Common.API_URL + "general/alert/create",
        {
          alert_description: alert_description,
          alert_route_page: alert_route_page,
          alert_doc_type: alert_doc_type,
          user_id: user_id,
        },
        Common.options
      );
    } catch (error) {
      console.log(error);
    }
  };

  handleAlertMultiple = () => {
    // แจ้งเตือนไปยัง Candidate ทราบสถานะการสมัครงาน
    const data = this.state.data;
    if (data.length <= 0) {
      return false;
    }
    let arr = [];
    data.forEach((item) => {
      arr.push({
        alert_description: `ผลการสมัครงานตำแหน่ง ${this.state.main_data?.jp_position}`,
        alert_route_page: "/applylist",
        alert_doc_type: 6,
        user_id: item?.main?.user_id,
      });
    });

    try {
      axios.post(
        Common.API_URL + "general/alert/multiple/create",
        arr,
        Common.options
      );
    } catch (error) {
      console.log(error);
    }
  };

  completeCandidate = async (rs, jpa_status) => {
    const jpa_id = rs.jpa_id;
    try {
      await axios
        .put(
          Common.API_URL + `j/job_position/apply/${jpa_id}`,
          {
            jpa_type: rs.jpa_type,
            jpa_step: rs.jpa_step,
            jpa_status: jpa_status,
            jpa_conf_url: rs.jpa_conf_url,
            jpa_appointment: rs.jpa_appointment,
            jp_id: rs.jp_id,
            user_id: rs.user_id,
          },
          Common.options
        )
        .then((response) => {
          this.refreshData();
          this.getSelect();
          this.getFinal();
        });
    } catch (error) {
      console.log(error);
    }
  };

  updateAppointmentCandidate = async () => {
    const rs = this.state.get_content_apply;
    const jpa_id = rs?.jpa_id;
    if (jpa_id === undefined || jpa_id === "") {
      return false;
    }
    try {
      await axios
        .put(
          Common.API_URL + `j/job_position/apply/${jpa_id}`,
          {
            jpa_type: rs.jpa_type,
            jpa_step: 3,
            jpa_status: rs.jpa_status,
            jpa_conf_url: this.state.jpa_conf_url,
            jpa_appointment: this.state.jpa_appointment,
            jp_id: rs.jp_id,
            user_id: rs.user_id,
          },
          Common.options
        )
        .then((response) => {
          this.refreshData();
          this.getSelect();
          this.setState({ openModalAppointment: false, get_content_apply: [] });
          // แจ้งเตือนไปยัง Candidate เพื่อแจ้งตารางนัดหมาย
          this.handleAlert(
            `นัดหมายสัมภาษณ์งานตำแหน่ง ${
              this.state.main_data?.jp_position
            } วันที่ ${Functions.format_date_time_picker(
              this.state.jpa_appointment
            )}`,
            5,
            "/calendar",
            rs.user_id
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  FilterData = async (value) => {
    // let jpa_type =
    //   e.target.value === "" || e.target.value === undefined
    //     ? []
    //     : [e.target.value];
    let jpa_type;
    let jpa_status;
    if (value === 0) {
      // jpa_type = [0, 1, 2];
      jpa_type = [0];
      jpa_status = [];
    } else if (value === 1) {
      jpa_type = [1];
      jpa_status = [0];
    } else if (value === 2) {
      jpa_type = [2];
      jpa_status = [0];
    } else if (value === 3) {
      jpa_type = [2];
      jpa_status = [1];
    } else {
      jpa_type = [0];
      jpa_status = [0];
    }

    await new Promise((accept) => {
      this.setState(
        {
          jpa_type_filter: jpa_type,
          jpa_status_filter: jpa_status,
        },
        accept
      );
    });
    this.refreshData();
  };

  logVideoCreate = async () => {
    if (this.state.user_select === "") {
      return false;
    }
    if (this.state.used_video >= this.state.quota_video) {
      return false;
    }
    try {
      await axios
        .post(
          Common.API_URL + "j/job_position/video",
          {
            user_select: this.state.user_select,
            user_owner: user_id,
            active: 1,
          },
          Common.options
        )
        .then((response) => {
          this.setState({
            user_select: this.state.user_select,
          });
          this.refreshData();
          this.checkQuota();
        });
    } catch (error) {
      console.log(error);
    }
  };

  checkQuota = async () => {
    try {
      await axios
        .get(Common.API_URL + `payment/check/quota/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({
            quota_position: res?.quota_position,
            used_position: res?.used_position,
            quota_video: res?.quota_video,
            used_video: res?.used_video,
            package_name: res?.package_name,
            exire_date: res?.exire_date,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  favoriteCreate = async (user_select) => {
    try {
      await axios
        .post(
          Common.API_URL + "general/favorite/create",
          {
            user_select: user_select,
            user_owner: user_id,
          },
          Common.options
        )
        .then((response) => {
          this.refreshData();
        });
    } catch (error) {
      console.log(error);
    }
  };
  favoritDelete = async (favorite_id) => {
    try {
      await axios
        .delete(
          Common.API_URL + `general/favorite/${favorite_id}`,
          Common.options
        )
        .then((response) => {
          this.refreshData();
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleCreateVote = () => {
    const jp_id = this.props.jp_id;

    try {
      axios
        .post(
          Common.API_URL + `j/job_position/vote/${jp_id}`,
          {
            vote_password: this.state.vote_password,
            vote_close: this.state.vote_close,
            jp_id: jp_id,
            user_id: user_id,
          },
          Common.options
        )
        .then((res) => {
          this.getVote();

          Swal.fire({
            title: lang_data?.notify_title_success,
            icon: "success",
            confirmButtonText: lang_data?.notify_confirm_success,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleCloseVote = async () => {
    if (this.state.vote_id === "") {
      Swal.fire({
        title: lang_data?.notify_title_error,
        text: lang_data?.notify_text_error,
        icon: "error",
        confirmButtonText: lang_data?.notify_confirm_error,
      });
      return false;
    }

    Swal.fire({
      title: lang_data?.notify_title_close_vote,
      text: lang_data?.notify_text_close_vote,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: lang_data?.notify_confirm_close_vote,
      cancelButtonText: lang_data?.cancelled,
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .get(
              Common.API_URL +
                `j/job_position/voted/close_voted/${this.state.vote_id}`,
              Common.options
            )
            .then((res) => {
              Swal.fire({
                title: lang_data?.notify_title_success,
                text: lang_data?.notify_text_success,
                icon: "success",
                confirmButtonText: lang_data?.notify_confirm_success,
              });

              this.setState({
                openModalVote: false,
              });

              this.refreshData();
              this.getVote();
              this.getFinal();
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  getVote = async () => {
    const jp_id = this.props.jp_id;
    try {
      await axios
        .get(Common.API_URL + `j/job_position/vote/${jp_id}`, Common.options)
        .then((response) => {
          let res = response.data;

          this.setState({
            vote_id: res.vote_id,
            vote_close: res.vote_close,
            vote_password: res.vote_password,
            link_vote: base_voter + "?voter=" + res.vote_id,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  AppointmentDate = async (e) => {
    let getmonth = e.month.number;
    let getyear = e.year;
    let getday = e.day;
    let time =
      Functions.twoDigit(e.hour) +
      ":" +
      Functions.twoDigit(e.minute) +
      ":" +
      "00";
    let fulldate = getyear + "-" + getmonth + "-" + getday + " " + time;
    this.setState({ jpa_appointment: fulldate });
    // console.log(fulldate);
  };
  copyTextToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);

      // console.log("Text copied to clipboard successfully!");
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  GetVoteList = async (jpa_id) => {
    if (jpa_id === "" || jpa_id === undefined) {
      return false;
    }
    const jp_id = this.props.jp_id;
    try {
      await axios
        .get(
          Common.API_URL + `j/job_position/vote/list/${jp_id}/${jpa_id}`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ voted_list: res, openModalVotedList: true });
        });
    } catch (error) {
      console.log(error);
      window.location = "/";
    }
  };
  componentDidMount() {
    this.refreshData();
    this.getSelect();
    this.getFinal();
    this.GetMainData();
    this.getVote();
    this.checkQuota();
  }

  render() {
    const {
      param,
      page,
      data,
      main_data,
      openModalVideo,
      video_path,
      total_select,
      total_final,
      openModalVote,
      openModalAppointment,
      vote_id,
      vote_close,
      link_vote,
      job_active,
      quota_video,
      used_video,
      appm_control,
      vote_password,
      openModalVotedList,
      voted_list,
      steps,
    } = this.state;

    return (
      <div>
        <div style={{ paddingTop: "40px", height: "100vh" }}>
          <Container>
            <Joyride steps={steps} />
            <Card>
              <Card.Body>
                <div align="center">
                  <h3>{main_data?.jp_position}</h3>
                </div>

                <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
                  <Col>
                    <div align="left">
                      <Button
                        variant="warning"
                        size="lg"
                        onClick={this.handleCloseVote}
                        disabled={vote_close === 1 ? true : false}
                        className="close_vote_button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-stop-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.5 5A1.5 1.5 0 0 0 5 6.5v3A1.5 1.5 0 0 0 6.5 11h3A1.5 1.5 0 0 0 11 9.5v-3A1.5 1.5 0 0 0 9.5 5h-3z" />
                        </svg>{" "}
                        {lang_data?.close_vote}
                      </Button>{" "}
                    </div>
                  </Col>

                  <Col>
                    <div align="right">
                      <Button
                        variant="success"
                        disabled={
                          job_active === 2 || total_final === 0 ? true : false
                        }
                        size="lg"
                        onClick={() => this.updateStatusJobPost()}
                        className="confirm_candidate_button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-trophy-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935z" />
                        </svg>{" "}
                        {lang_data?.confirm_candidate}{" "}
                        <Badge bg="warning">{total_final}</Badge>
                      </Button>{" "}
                      <Button
                        variant="primary"
                        disabled={
                          vote_close === 1 || total_select === 0 ? true : false
                        }
                        size="lg"
                        onClick={() =>
                          vote_id !== ""
                            ? this.setState({
                                openModalVote: true,
                              })
                            : this.handleCreateVote()
                        }
                        className="vote_button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-megaphone-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z" />
                        </svg>{" "}
                        {lang_data?.vote}{" "}
                        <Badge bg="warning">{total_select}</Badge>
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div align="right">
                  {lang_data?.interview_video} [{used_video} / {quota_video}]
                </div>
              </Card.Body>
            </Card>

            <Nav
              justify
              variant="tabs"
              defaultActiveKey="TabApply"
              style={{ paddingTop: "25px", paddingBottom: "25px" }}
            >
              <Nav.Item className="tab1" id="tab1">
                <Nav.Link
                  eventKey="TabApply"
                  onClick={() => this.FilterData(0)}
                >
                  {lang_data?.apply_list}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab2">
                <Nav.Link
                  eventKey="TabSelected"
                  onClick={() => this.FilterData(1)}
                >
                  {lang_data?.selected_interview}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab3">
                <Nav.Link
                  eventKey="TabFinal"
                  onClick={() => this.FilterData(2)}
                >
                  {lang_data?.appointment_interview}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab4">
                <Nav.Link
                  eventKey="TabCompleteCandidate"
                  onClick={() => this.FilterData(3)}
                >
                  {lang_data?.final_result}
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Card>
              <Card.Body>
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder={lang_data?.search}
                    onChange={(e) => [
                      this.setState({
                        search_value: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    onKeyUp={(e) => [
                      this.setState({
                        search_value: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                  />
                </Form.Group>
              </Card.Body>
            </Card>
            <CardStyle>
              <Row>
                {data?.map((rs, index) => (
                  <Col
                    key={index}
                    lg="4"
                    md="6"
                    style={{ paddingBottom: "25px", paddingTop: "15px" }}
                  >
                    <div className="card">
                      <Image
                        src={
                          Functions.check_empty_value(
                            rs?.main?.jpa_user?.user_main_detail?.ud_image_cover
                          ) === true
                            ? require("../../asset/images/4920290.jpg")
                            : rs?.main?.jpa_user?.user_main_detail
                                ?.ud_image_cover
                        }
                        className="cover-photo"
                      />
                      <Image
                        src={
                          Functions.check_empty_value(
                            rs?.main?.jpa_user?.user_main_detail
                              ?.ud_image_profile
                          ) === true
                            ? require("../../asset/images/no-user-image-icon-26.jpg")
                            : rs?.main?.jpa_user?.user_main_detail
                                ?.ud_image_profile
                        }
                        className="profile"
                        style={{ width: 110, height: 110 }}
                      />
                      <strong className="profile-name">
                        <Button
                          href={`/candidate/${
                            rs?.main?.jpa_user?.firstname +
                            "_" +
                            rs?.main?.jpa_user?.lastname
                          }/${rs?.main?.jpa_user?.user_id}`}
                          target="_blank"
                          variant="link"
                        >
                          {rs?.main?.jpa_user?.firstname}{" "}
                          {rs?.main?.jpa_user?.lastname}
                        </Button>
                      </strong>
                      <div className="about" style={{ paddingBottom: "25px" }}>
                        <BadgesStyle>
                          <div>
                            {rs?.candidate_job_function.map((ra, i) => (
                              <span key={i}>
                                <div className="badge badge-primary badge-outlined">
                                  {ra?.user_job_function?.jbc_name}
                                </div>{" "}
                              </span>
                            ))}
                          </div>
                          <div>
                            {rs?.candidate_job_type.map((rb, i) => (
                              <span key={i}>
                                <div className="badge badge-success badge-outlined">
                                  {rb?.user_job_type?.jt_name}
                                </div>{" "}
                              </span>
                            ))}
                          </div>
                          <div>
                            <span>
                              <div className="badge badge-danger badge-outlined">
                                {lang_data?.expected_salary}{" "}
                                {Functions.formatNumberWithComma(
                                  rs?.candidate_salary?.us_salary_start
                                )}
                                {" - "}
                                {Functions.formatNumberWithComma(
                                  rs?.candidate_salary?.us_salary_end
                                )}
                              </div>
                            </span>
                          </div>
                        </BadgesStyle>
                      </div>
                      <div className="footer">
                        <div>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.5em",
                              cursor: "pointer",
                            }}
                            onClick={() => this.GetVoteList(rs?.main?.jpa_id)}
                          >
                            <u>
                              {lang_data?.result_interview} : {rs?.total_voted}
                            </u>
                          </span>
                        </div>

                        {rs?.main?.jpa_step === 2 && (
                          <div>
                            {" "}
                            {lang_data?.appointment} :{" "}
                            {Functions.format_date_time_picker(
                              rs?.main?.jpa_appointment
                            )}{" "}
                            <Button
                              variant="link"
                              href={rs?.main?.jpa_conf_url}
                              target="_blank"
                              disabled={
                                rs?.main?.jpa_conf_url === "" ? true : false
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-link-45deg"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
                              </svg>
                            </Button>
                          </div>
                        )}
                        {/*  jpa_type = 0 */}

                        {rs?.main?.jpa_type === 0 && (
                          <ButtonGroup style={{ width: "100%" }}>
                            <Button
                              variant={
                                rs?.favorite_id === 0 ? "warning" : "danger"
                              }
                              onClick={() =>
                                rs?.favorite_id === 0
                                  ? this.favoriteCreate(
                                      rs?.main?.jpa_user?.user_id
                                    )
                                  : this.favoritDelete(rs?.favorite_id)
                              }
                            >
                              {lang_data?.liked}{" "}
                            </Button>

                            <Button
                              variant="info"
                              onClick={() => [
                                this.refreshDataVideo(
                                  rs?.main?.jpa_user?.user_id
                                ),
                                this.setState({
                                  openModalVideo: true,
                                  video_path: "",
                                  user_select: rs?.main?.jpa_user?.user_id,
                                  fullName: `${rs?.main?.jpa_user?.firstname} ${rs?.main?.jpa_user?.lastname}`,
                                }),
                              ]}
                              disabled={
                                used_video >= quota_video && rs?.log_id === 0
                                  ? true
                                  : false
                              }
                              title="Video"
                            >
                              {lang_data?.interview_video}{" "}
                            </Button>
                            <Button
                              variant={
                                rs?.main?.jpa_type === 0 ? "primary" : "success"
                              }
                              onClick={() =>
                                rs?.main?.jpa_type === 0
                                  ? this.selectCandidate(rs?.main, 1)
                                  : this.selectCandidate(rs?.main, 0)
                              }
                              disabled={
                                rs?.main?.jpa_step === 4 || vote_close === 1
                                  ? true
                                  : false
                              }
                              title="Selected"
                            >
                              {lang_data?.accept_candidate}
                            </Button>
                          </ButtonGroup>
                        )}
                        {/*  jpa_type = 1 */}
                        {rs?.main?.jpa_type === 1 && (
                          <ButtonGroup style={{ width: "100%" }}>
                            <Button
                              variant={
                                rs?.favorite_id === 0 ? "warning" : "danger"
                              }
                              onClick={() =>
                                rs?.favorite_id === 0
                                  ? this.favoriteCreate(
                                      rs?.main?.jpa_user?.user_id
                                    )
                                  : this.favoritDelete(rs?.favorite_id)
                              }
                            >
                              {lang_data?.liked}
                            </Button>
                            <Button
                              variant="info"
                              onClick={() => [
                                this.refreshDataVideo(
                                  rs?.main?.jpa_user?.user_id
                                ),
                                this.setState({
                                  openModalVideo: true,
                                  video_path: "",
                                  user_select: rs?.main?.jpa_user?.user_id,
                                  fullName: `${rs?.main?.jpa_user?.firstname} ${rs?.main?.jpa_user?.lastname}`,
                                }),
                              ]}
                              disabled={
                                used_video >= quota_video && rs?.log_id === 0
                                  ? true
                                  : false
                              }
                            >
                              {lang_data?.interview_video}
                            </Button>
                            <Button
                              variant={
                                rs?.main?.jpa_type === 0 ? "primary" : "danger"
                              }
                              onClick={() =>
                                rs?.main?.jpa_type === 0
                                  ? this.selectCandidate(rs?.main, 1)
                                  : this.selectCandidate(rs?.main, 0)
                              }
                            >
                              {lang_data?.cancelled}
                            </Button>
                          </ButtonGroup>
                        )}
                        {/*  jpa_type = 2 */}
                        {rs?.main?.jpa_type === 2 && (
                          <ButtonGroup style={{ width: "100%" }}>
                            <Button
                              variant={
                                rs?.favorite_id === 0 ? "warning" : "danger"
                              }
                              onClick={() =>
                                rs?.favorite_id === 0
                                  ? this.favoriteCreate(
                                      rs?.main?.jpa_user?.user_id
                                    )
                                  : this.favoritDelete(rs?.favorite_id)
                              }
                            >
                              {lang_data?.liked}
                            </Button>
                            <Button
                              variant="info"
                              onClick={() => [
                                this.refreshDataVideo(
                                  rs?.main?.jpa_user?.user_id
                                ),
                                this.setState({
                                  openModalVideo: true,
                                  video_path: "",
                                  user_select: rs?.main?.jpa_user?.user_id,
                                  fullName: `${rs?.main?.jpa_user?.firstname} ${rs?.main?.jpa_user?.lastname}`,
                                }),
                              ]}
                              disabled={
                                used_video >= quota_video && rs?.log_id === 0
                                  ? true
                                  : false
                              }
                            >
                              {lang_data?.interview_video}
                            </Button>
                            <Button
                              variant={
                                rs?.main?.jpa_status === 0
                                  ? "success"
                                  : "danger"
                              }
                              onClick={() =>
                                rs?.main?.jpa_status === 0
                                  ? this.completeCandidate(rs?.main, 1)
                                  : this.completeCandidate(rs?.main, 0)
                              }
                              disabled={job_active !== 1 ? true : false}
                            >
                              {rs?.main?.jpa_status === 1
                                ? lang_data?.cancelled
                                : lang_data?.select_candidate}
                            </Button>
                            <Button
                              disabled={job_active === 0 ? true : false}
                              variant="primary"
                              onClick={() =>
                                this.setState({
                                  openModalAppointment: true,
                                  get_content_apply: rs?.main,
                                  jpa_appointment: rs?.main?.jpa_appointment,
                                  jpa_conf_url: rs?.main?.jpa_conf_url,
                                })
                              }
                            >
                              {lang_data?.appointment}
                            </Button>
                          </ButtonGroup>
                        )}
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </CardStyle>

            <div style={{ paddingTop: "70px" }} align="center">
              <div style={{ width: "200px" }}>
                <InputGroup className="mb-3" size="sm">
                  <InputGroup.Text>{lang_data?.page}</InputGroup.Text>
                  <Form.Control
                    type="number"
                    defaultValue={page}
                    onChange={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    onKeyUp={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    onClick={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    style={{ textAlign: "center" }}
                  />
                  <InputGroup.Text>
                    {lang_data?.total_page} : {param.total_page}{" "}
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <Modal
              show={openModalVideo}
              onHide={() =>
                this.setState({
                  openModalVideo: false,
                })
              }
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {Functions.check_empty_value(video_path) === true ? (
                  <div className="d-grid gap-2">
                    <Button
                      variant="primary"
                      size="lg"
                      onClick={() => [
                        this.handleAlert(
                          `คำขอให้เพิ่ม Video สัมภาษณ์ของคุณ ${this.state.fullName}`,
                          7,
                          `/candidate/${this.state.user_select}/${this.state.fullName}`,
                          admin_id
                        ),
                        this.setState({
                          openModalVideo: false,
                        }),
                        Swal.fire({
                          title: lang_data?.notify_title_success,
                          text: lang_data?.notify_text_request_video,
                          icon: "success",
                          confirmButtonText: lang_data?.notify_confirm_success,
                        }),
                      ]}
                    >
                      {lang_data?.want_interview_video}
                    </Button>
                  </div>
                ) : (
                  <div>
                    <ReactPlayer
                      url={video_path}
                      previewTabIndex={0}
                      controls={true}
                      playsinline={true}
                      width={"100%"}
                      height={600}
                      onPlay={this.logVideoCreate}
                    />
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() =>
                    this.setState({
                      openModalVideo: false,
                    })
                  }
                >
                  {lang_data?.close}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={openModalVote}
              onHide={() =>
                this.setState({
                  openModalVote: false,
                })
              }
            >
              <Modal.Header closeButton>
                <Modal.Title> {lang_data?.vote}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>Vote link</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control defaultValue={link_vote} readOnly />
                    <Button
                      variant="outline-secondary"
                      onClick={() => this.copyTextToClipboard(link_vote)}
                    >
                      <svg
                        viewBox="0 0 448 512"
                        width="20"
                        title="copy"
                        id="copyIcon"
                        aria-label="Copy the link"
                      >
                        <path d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z" />
                      </svg>
                    </Button>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label> {lang_data?.password_label} </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      id="vote_password"
                      onChange={(e) =>
                        this.setState({
                          vote_password: e.target.value,
                        })
                      }
                      value={vote_password}
                    />

                    <Button
                      variant="success"
                      onClick={() => this.handleCreateVote()}
                    >
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-shield-fill-check"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0m2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647z"
                        />
                      </svg> */}
                      {lang_data?.save_password}
                    </Button>
                  </InputGroup>

                  {this.state.vote_password === "" && (
                    <Form.Text muted>
                      <span style={{ color: "red" }}>
                        {lang_data?.warning_msg1}
                      </span>
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group>
                  <div align="center">
                    {/* <Image
                      src={`https://chart.googleapis.com/chart?cht=qr&chl=${link_vote}&chs=180x180&choe=UTF-8`}
                    /> */}
                    <Image
                      src={`https://quickchart.io/qr?text==${link_vote}&size=200`}
                    />
                    <p>
                      <Button
                        variant="outline-info"
                        target="_black"
                        href={link_vote}
                        size="sm"
                      >
                        Click
                      </Button>
                    </p>
                  </div>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() =>
                    this.setState({
                      openModalVote: false,
                    })
                  }
                >
                  {lang_data?.close}
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Appointment */}
            <Modal
              show={openModalAppointment}
              onHide={() =>
                this.setState({
                  openModalAppointment: false,
                })
              }
            >
              <Modal.Header closeButton>
                <Modal.Title> {lang_data?.appointment}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group style={{ paddingBottom: 15, paddingTop: 15 }}>
                  <Form.Label> {lang_data?.date_label}</Form.Label>
                  <DatePicker
                    id="jpa_appointment"
                    style={{
                      height: "45px",
                      width: "100%",
                    }}
                    containerStyle={{
                      width: "100%",
                    }}
                    format="DD/MM/YYYY HH:mm"
                    plugins={[<TimePicker position="bottom" hideSeconds />]}
                    onChange={(e) => this.AppointmentDate(e)}
                    editable={false}
                    value={Functions.format_date_time_picker(
                      this.state.jpa_appointment
                    )}
                  />
                </Form.Group>
                <Form.Group style={{ paddingBottom: 15, paddingTop: 15 }}>
                  <Form.Check
                    inline
                    label={lang_data?.interview_office}
                    name="group1"
                    type="radio"
                    onChange={(e) => this.setState({ appm_control: 0 })}
                    checked={appm_control === 0 ? true : false}
                    disabled={this.state.jpa_conf_url !== "" ? true : false}
                  />
                  <Form.Check
                    inline
                    label="Conference"
                    name="group1"
                    type="radio"
                    onChange={(e) => this.setState({ appm_control: 1 })}
                    checked={appm_control === 1 ? true : false}
                  />
                </Form.Group>

                {appm_control === 1 || this.state.jpa_conf_url !== "" ? (
                  <Form.Group style={{ paddingBottom: 15, paddingTop: 15 }}>
                    <Form.Label> Conference URL</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Conference URL"
                      id="jpa_conf_url"
                      onChange={(e) =>
                        this.setState({ jpa_conf_url: e.target.value })
                      }
                      value={this.state.jpa_conf_url}
                    />
                  </Form.Group>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() =>
                    this.setState({
                      openModalAppointment: false,
                    })
                  }
                >
                  {lang_data?.close}
                </Button>
                <Button
                  variant="primary"
                  onClick={this.updateAppointmentCandidate}
                >
                  {lang_data?.save}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={openModalVotedList}
              onHide={() =>
                this.setState({
                  openModalVotedList: false,
                })
              }
            >
              <Modal.Header closeButton>
                <Modal.Title>{lang_data?.vote_list} </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{lang_data?.first_name}</th>
                      <th>{lang_data?.day_and_time}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {voted_list?.map((r, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{r?.voted_fullname}</td>
                        <td>{Functions.format_date_time(r.create_date)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() =>
                    this.setState({
                      openModalVotedList: false,
                    })
                  }
                >
                  {lang_data?.close}
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </div>
      </div>
    );
  }
}
