import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Nav, NavDropdown, Navbar, Image, Dropdown } from "react-bootstrap";
import axios from "axios";
import Common from "../common";
import Functions from "../functions";
import styled from "styled-components";
import ErrorPage from "./ErrorPage";
import Logout from "../public_page/Logout";
import Candidate from "./component_superadmin/Candidate";
import CandidateDetail from "./component_superadmin/CandidateDetail";
import HrCompany from "./component_superadmin/HrCompany";
import Order from "./component_superadmin/Order";
import Package from "./component_superadmin/Package";
import Promotion from "./component_superadmin/Promotion";
import Messenger from "./component_general/Messenger";
import AlertContent from "./component_superadmin/Alert";
import CompanyProfile from "./component_general/CompanyProfile";
import MasterDataBank from "./component_superadmin/MasterDataBank";
import MasterDataJobFunction from "./component_superadmin/MasterDataJobFunction";
import MasterDataJobType from "./component_superadmin/MasterDataJobType";
import MasterDataWorkingStyle from "./component_superadmin/MasterDataWorkingStyle";
import MasterDataSkill from "./component_superadmin/MasterDataSkill";
import MasterDataBusinessType from "./component_superadmin/MasterDataBusinessType";
import MasterDataInstitution from "./component_superadmin/MasterDataInstitution";
const app_name = Common.app_name;
const user_id = Common.token;
const NotificationButtonStyle = styled.div`
  .notification {
    // background-color: #555;
    color: white;
    text-decoration: none;
    padding: 5px 1px;
    position: relative;
    display: inline-block;
    border-radius: 2px;
  }

  // .notification:hover {
  //   background: #a3adc9;
  // }

  .notification .badge {
    position: absolute;
    top: -1px;
    right: -2px;
    padding: 5px 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: red;
    color: white;
  }
`;

export default class SectionSuperAdmin extends Component {
  state = {
    firstname: "",
    lastname: "",
    historys: [],
    total_chat: 0,
    alerts: [],
    total_alert: 0,
  };
  refreshData = async () => {
    try {
      await axios
        .get(Common.API_URL + `user/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({
            firstname: res.firstname,
            lastname: res.lastname,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getTotalChat = async () => {
    try {
      await axios
        .get(Common.API_URL + `chat/msg/total/${user_id}`, Common.options)
        .then((response) => {
          const res = response.data;
          this.setState({
            total_chat: res?.total,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getHistoryChat = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `chat/msg/history/list/${user_id}`,
          {
            page: 1,
            per_page: 10,
            search_value: "",
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ historys: res.data });
        });
    } catch (error) {
      console.log(error);
      window.location.href = "/";
    }
  };
  setReaded = (user_sender) => {
    try {
      axios
        .put(
          Common.API_URL + "chat/msg/readed/update?all=0",
          {
            user_sender: user_sender,
            user_recipient: user_id,
          },
          Common.options
        )
        .then((res) => {
          window.location.href = "/messenger/" + user_sender;
        });
    } catch (error) {
      console.log(error);
    }
  };
  getAlert = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `general/alert/list/${user_id}?accept=3`,
          {
            page: 1,
            per_page: 10,
            search_value: "",
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;

          this.setState({ alerts: res.data });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getAlertTotalUnAccept = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `general/alert/list/${user_id}?accept=0`,
          {
            page: 1,
            per_page: 1,
            search_value: "",
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;

          this.setState({ total_alert: res.total_data });
        });
    } catch (error) {
      console.log(error);
    }
  };
  setAlertAccept = (alert_id, page) => {
    try {
      axios
        .get(Common.API_URL + `general/alert/set/${alert_id}`, Common.options)
        .then((res) => {
          window.location.href = page;
        });
    } catch (error) {
      console.log(error);
    }
  };
  blankFun = () => {
    window.open(`https://www.jinjijob.com/auth/${user_id}`, "_blank");
  };
  componentDidMount() {
    this.refreshData();
    this.getTotalChat();
    this.getHistoryChat();
    this.getAlert();
    this.getAlertTotalUnAccept();
  }
  render() {
    const { firstname, lastname, total_chat, historys, alerts, total_alert } =
      this.state;

    return (
      <div>
        <NotificationButtonStyle>
          <Navbar collapseOnSelect expand="lg" bg="success" variant="dark">
            <Container>
              <Navbar.Brand href="/" style={{ textTransform: "uppercase" }}>
                {app_name} Admin
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/candidate">ผู้สมัคร</Nav.Link>
                  <Nav.Link href="/hr">ฝ่ายบุคคล</Nav.Link>
                  <Nav.Link href="/orders">คำสั่งซื้อ</Nav.Link>
                  <NavDropdown title="แพ็กเกจและโปรโมชัน">
                    <NavDropdown.Item href="/package">แพ็กเกจ</NavDropdown.Item>
                    <NavDropdown.Item href="/promotion">
                      โปรโมชัน
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown title="ข้อมูลตั้งต้น">
                    <NavDropdown.Item href="/job-function">
                      ประเภทงาน
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/job-type">
                      เวลาการทำงาน
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/working-style">
                      รูปแบบการทำงาน
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/skills">
                      ความสามารถ / ทักษะ
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/business_type">
                      ประเภทธุรกิจ
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/institution">
                      สถาบันศึกษา
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/bank">ธนาคาร</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="สาระประโยชน์">
                    <NavDropdown.Item onClick={() => this.blankFun()}>
                      จัดการเว็บไซต์
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>

                <Nav>
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle variant="success">
                      <div className="notification">
                        <Image
                          variant="top"
                          src={require("../asset/images/comments.png")}
                          style={{ width: "45px", height: "45px" }}
                          roundedCircle
                          thumbnail
                        />
                        <span className="badge">
                          {total_chat <= 99 ? total_chat : "99+"}
                        </span>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {historys?.map((rs, index) => (
                        <Dropdown.Item
                          href="#"
                          key={index}
                          onClick={() =>
                            this.setReaded(rs.user_recipient_history)
                          }
                        >
                          {Functions.check_empty_value(
                            rs.history_user_recipient?.user_main_detail
                              ?.ud_image_profile
                          ) === false ? (
                            <Image
                              src={
                                rs.history_user_recipient?.user_main_detail
                                  ?.ud_image_profile
                              }
                              style={{
                                width: 50,
                                height: 50,
                              }}
                              alt="avatar"
                              thumbnail
                              roundedCircle
                            />
                          ) : (
                            <Image
                              src={require("../asset/images/no-user-image-icon-26.jpg")}
                              style={{
                                width: 50,
                                height: 50,
                              }}
                              alt="avatar"
                              thumbnail
                              roundedCircle
                            />
                          )}{" "}
                          {rs.history_user_recipient?.firstname}{" "}
                          {rs.history_user_recipient?.lastname}
                          <div
                            style={{
                              fontSize: 12,
                              width: 200,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {Functions.format_date_time(rs.update_date)}
                          </div>
                        </Dropdown.Item>
                      ))}
                      <Dropdown.Divider />
                      <Dropdown.Item href="/messenger/list">
                        <div align="center">ข้อความทั้งหมด</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
                <Nav>
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle variant="success">
                      <div className="notification">
                        <Image
                          variant="top"
                          src={require("../asset/images/notification-bell.png")}
                          style={{ width: "45px", height: "45px" }}
                          roundedCircle
                          thumbnail
                        />
                        <span className="badge">
                          {total_alert <= 99 ? total_alert : "99+"}
                        </span>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div
                        style={{
                          width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {alerts?.map((rs, index) => (
                          <Dropdown.Item
                            href="#"
                            key={index}
                            onClick={() =>
                              this.setAlertAccept(
                                rs?.alert_id,
                                rs?.alert_route_page
                              )
                            }
                            style={{
                              backgroundColor:
                                rs?.alert_accept === 0 ? "#dadde3" : "",
                            }}
                          >
                            {rs?.alert_description}

                            <div
                              style={{
                                fontSize: 12,
                                width: 200,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {Functions.format_date_time(rs.create_date)}
                            </div>
                          </Dropdown.Item>
                        ))}
                      </div>
                      <Dropdown.Divider />
                      <Dropdown.Item href="/alert/list">
                        <div align="center">แจ้งเตือนทั้งหมด</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>

                <Nav>
                  <NavDropdown title={firstname + " " + lastname}>
                    <NavDropdown.Item href="/logout">
                      ออกจากระบบ
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </NotificationButtonStyle>
        {/* Content */}

        <Container fluid style={{ paddingTop: "15px" }}>
          <Router>
            <Routes>
              <Route path="/" element={<Candidate />} />
              <Route path="/messenger/:user_id" element={<Messenger />} />
              <Route path="/candidate" element={<Candidate />} />
              <Route path="/alert/list" element={<AlertContent />} />
              <Route
                path="/candidate/:user_id/:name"
                element={<CandidateDetail />}
              />
              <Route path="/:user_id/view" element={<CompanyProfile />} />
              <Route path="/hr" element={<HrCompany />} />
              <Route path="/orders" element={<Order />} />
              <Route path="/package" element={<Package />} />
              <Route path="/promotion" element={<Promotion />} />
              <Route path="/job-function" element={<MasterDataJobFunction />} />
              <Route path="/job-type" element={<MasterDataJobType />} />
              <Route
                path="/working-style"
                element={<MasterDataWorkingStyle />}
              />
              <Route path="/skills" element={<MasterDataSkill />} />
              <Route path="/institution" element={<MasterDataInstitution />} />
              <Route path="/bank" element={<MasterDataBank />} />
              <Route
                path="/business_type"
                element={<MasterDataBusinessType />}
              />
              <Route path="/logout" element={<Logout />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Router>
        </Container>
      </div>
    );
  }
}
