import React, { Component } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Button,
  Image,
  Nav,
  Dropdown,
  Offcanvas,
  InputGroup,
  Navbar,
} from "react-bootstrap";
import Slider from "rc-slider";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import axios from "axios";
import styled from "styled-components";
import Common from "../common";
import Functions from "../functions";
import IconJobSearch from "../asset/images/job-search.png";
import lang from "../lang/lang";
import "rc-slider/assets/index.css";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const navStyle = {
  fontSize: "24px",
  fontWeight: "bold",
};
const linkStyle = {
  color: "#0E4C81",
  paddingLeft: "50px",
};

const customStyles = {
  control: (base) => ({
    ...base,
    // width: 350,
    height: 50,
    minHeight: 50,
    borderRadius: "7px",
    paddingLeft: "40px !important",
  }),
  groupHeading: (base) => ({
    ...base,
    flex: "1 1",
    color: "black",
    fontSize: "20px",
    margin: 0,
  }),
  container: (provided) => ({
    ...provided,
    width: "auto",
  }),
  valueContainer: (base) => ({
    ...base,
    overflowX: "auto",
    flexWrap: "unset",
  }),
  multiValue: (base) => ({
    ...base,
    flex: "1 0 auto",
  }),
};
const cropText = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  width: "100px",
};
const CardStyle = styled.div`
  .feature {
    position: relative;
    width: 100%;
    height: 29rem;
    margin: 2rem auto;
    padding: 2em;
    border-radius: 0.75em;
    box-shadow: 5px 5px 20px rgba(0 0 0/0.15);
    text-align: left;
    transition: transform 200ms ease-in;
  }

  // .feature:hover {
  //   transform: scale(1.03);
  // }

  .feature__desc {
    margin-top: 0.5em;
    color: black;
  }

  .feature__img {
    position: absolute;
    bottom: 10%;
    right: 10%;
  }

  .feature-one {
    border-top: 5px solid #cdd7d9;
  }

  /* media queries */

  @media (min-width: 1000px) {
    section {
      max-width: 200rem;
    }

    .section__title {
      margin: 0 auto;
      max-width: 40%;
      font-size: 2rem;
    }

    .section__desc {
      max-width: 55ch;
      margin: 1rem auto 1rem;
    }

    .features {
      display: flex;
      gap: 2rem;
    }

    .feature-one {
      margin: auto 0;
    }
    .footer-content {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    }
  }
`;

const ScrollbarStyle = styled.div`
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #88c141 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    // background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #88c141;
    border-radius: 8px;
    border: 3px solid #ffffff;
  }
`;
export default class JobPosition extends Component {
  state = {
    defaultJobtype: [],
    defaultWorkingStyle: [],
    defaultCountry: [],
    defaultProvince: [],
    defaultJobFunction: [],
    // ประเภทงาน (เวลาการทำงาน)
    list_job_type: [],
    // รูปแบบการทำงาน
    list_working_style: [],

    // จังหวัด
    province_data: [],
    // Job post
    data: [],
    page: 1,
    per_page: 25,
    jp_salary_start: 0,
    jp_salary_end: 15000,
    province_id_list: [],
    jbc_id_list: [],
    country_id_list: [],
    jt_id_list: [],
    ws_id_list: [],
    search_value: "",
    param: [],
    get_content: [],

    //Form Filter
    openCanvasFilter: false,

    favorite_id: 0,
  };

  getJobPost = async () => {
    try {
      await axios
        .post(
          Common.API_URL + "j/job_position/list",
          {
            page: this.state.page,
            per_page: this.state.per_page,
            jp_salary_start: this.state.jp_salary_start,
            jp_salary_end: this.state.jp_salary_end,
            province_id: this.state.province_id_list,
            jbc_id: this.state.jbc_id_list,
            country_id: this.state.country_id_list,
            jt_id: this.state.jt_id_list,
            ws_id: this.state.ws_id_list,
            search_value: this.state.search_value,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res.data, param: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getJobFunctionMasterData = async (search) => {
    const response = await axios.get(
      Common.API_URL + "masterdata/job_parent?search=" + search,
      Common.options
    );
    const list = response.data;
    let arr = [];
    for (let i = 0; i < list.length; i++) {
      let obj = list[i];
      let jbp_jbc = obj.jbp_jbc;

      // Sub
      let arr2 = [];
      for (let i2 = 0; i2 < jbp_jbc.length; i2++) {
        let obj2 = jbp_jbc[i2];
        arr2.push({
          value: obj2.jbc_id,
          label: obj2.jbc_name,
          group: obj.jbp_name,
        });
      }

      arr.push({
        label: obj.jbp_name,
        options: arr2,
      });
    }
    const options = arr;
    return options;
  };

  getProvince = async () => {
    try {
      await axios
        .get(Common.API_URL + "masterdata/province", Common.options)
        .then((response) => {
          let list = response.data;

          var arr = [];
          for (let i = 0; i < list.length; i++) {
            let obj = list[i];
            arr.push({
              value: obj.province_id,
              label: obj.province_eng + " / " + obj.province_thai,
            });
          }
          //   console.log(arr);

          new Promise((accept) => {
            setTimeout(() => {
              this.setState(
                {
                  province_data: arr,
                },
                accept
              );
            }, 1000);
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getWorkingStyleMasterData = () => {
    try {
      axios
        .get(Common.API_URL + "masterdata/working_style", Common.options)
        .then((response) => {
          let list = response.data;
          let arr = [];
          // Main
          for (let i = 0; i < list.length; i++) {
            let obj = list[i];
            arr.push({
              value: obj.ws_id,
              label: obj.ws_name,
            });
          }

          new Promise((accept) => {
            setTimeout(() => {
              this.setState(
                {
                  list_working_style: arr,
                },
                accept
              );
            }, 1000);
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getJobTypeMasterData = () => {
    try {
      axios
        .get(Common.API_URL + "masterdata/job_type", Common.options)
        .then((response) => {
          let list = response.data;
          let arr = [];
          // Main
          for (let i = 0; i < list.length; i++) {
            let obj = list[i];
            arr.push({
              value: obj.jt_id,
              label: obj.jt_name,
            });
          }

          new Promise((accept) => {
            setTimeout(() => {
              this.setState(
                {
                  list_job_type: arr,
                },
                accept
              );
            }, 1000);
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getCountry = async (newValue) => {
    const response = await axios.post(
      Common.API_URL + "masterdata/country",
      {
        page: 1,
        per_page: 25,
        search_value: newValue,
      },
      Common.options
    );
    const options = response?.data?.data?.map((item) => ({
      value: item.country_id,
      label: item.country_name_eng + " - " + item.country_name_th,
    }));
    return options;
  };

  filterProvince = async (e) => {
    // console.log(e);
    let data = e;
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let obj = data[i];
      arr.push(obj.value);
    }
    // console.log(JSON.stringify(arr));
    new Promise((accept) => {
      this.setState(
        {
          province_id_list: arr,
          defaultProvince: e,
        },
        accept
      );
    });
    // this.getJobPost();
  };
  filterJobFunction = async (e) => {
    // console.log(e);
    let data = e;
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let obj = data[i];
      arr.push(obj.value);
    }
    // console.log(JSON.stringify(arr));
    new Promise((accept) => {
      this.setState(
        {
          jbc_id_list: arr,
          defaultJobFunction: e,
        },
        accept
      );
    });
  };

  filterJobType = async (e) => {
    // console.log(e);
    let data = e;
    let arr = [];

    for (let i = 0; i < data.length; i++) {
      let obj = data[i];
      arr.push(obj.value);
    }
    // console.log(JSON.stringify(arr));
    new Promise((accept) => {
      this.setState(
        {
          jt_id_list: arr,
          defaultJobtype: e,
        },
        accept
      );
    });
  };
  filterWorkStyle = async (e) => {
    // console.log(e);
    let data = e;
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let obj = data[i];
      arr.push(obj.value);
    }
    // console.log(JSON.stringify(arr));
    new Promise((accept) => {
      this.setState(
        {
          ws_id_list: arr,
          defaultWorkingStyle: e,
        },
        accept
      );
    });
  };
  filterCountry = async (e) => {
    // console.log(e);
    let data = e;
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let obj = data[i];
      arr.push(obj.value);
    }
    // console.log(JSON.stringify(arr));
    new Promise((accept) => {
      this.setState(
        {
          country_id_list: arr,
          defaultCountry: e,
        },
        accept
      );
    });
  };

  onChangeSalary = async (e) => {
    // console.log(e);

    new Promise((accept) => {
      this.setState(
        {
          jp_salary_start: e[0],
          jp_salary_end: e[1],
        },
        accept
      );
    });
  };

  clearFilter = async () => {
    document.getElementById("q").value = "";
    await new Promise((accept) => {
      this.setState(
        {
          defaultJobtype: [],
          defaultWorkingStyle: [],
          defaultCountry: [],
          defaultProvince: [],
          defaultJobFunction: [],

          jp_salary_start: 0,
          jp_salary_end: 15000,
          province_id_list: [],
          jbc_id_list: [],
          country_id_list: [],
          jt_id_list: [],
          ws_id_list: [],
          search_value: "",
          get_content: [],
        },
        accept
      );
    });

    this.getJobPost();
  };
  switchLanguage = (val) => {
    localStorage.setItem("langSet", val);
    window.location.reload();
  };
  componentDidMount() {
    this.getJobFunctionMasterData();
    this.getJobTypeMasterData();
    this.getWorkingStyleMasterData();
    this.getProvince();
    this.getJobPost();
  }

  render() {
    const {
      list_job_type,
      province_data,
      data,
      get_content,
      province_id_list,
      country_id_list,
      jbc_id_list,
      jt_id_list,
      ws_id_list,
      openCanvasFilter,
      list_working_style,
      defaultJobtype,
      defaultWorkingStyle,
      defaultCountry,
      defaultProvince,
      defaultJobFunction,
      favorite_id,
      param,
      page,
    } = this.state;
    return (
      <div>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
          <Container>
            <Navbar.Brand href="/">
              <Image
                variant="top"
                src={require("../asset/images/Jinjijob4.png")}
                style={{ width: "85px", height: "60px" }}
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto" style={navStyle}>
                <Nav.Link href="/job-search" style={linkStyle}>
                  {lang_data.jobSearch}
                </Nav.Link>
              </Nav>

              <Nav>
                <Dropdown className="d-inline mx-2">
                  <Dropdown.Toggle id="dropdown-autoclose-true" variant="light">
                    <Image
                      variant="top"
                      src={
                        langSet === "en"
                          ? require("../asset/images/united-kingdom.png")
                          : require("../asset/images/thailand.png")
                      }
                      style={{ width: "45px", height: "45px" }}
                      roundedCircle
                      thumbnail
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#"
                      onClick={() => this.switchLanguage("th")}
                    >
                      Thai - ไทย
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => this.switchLanguage("en")}
                    >
                      English - อังกฤษ
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>

              <Nav>
                <div style={{ paddingRight: 10 }}>
                  <Button
                    variant="primary"
                    onClick={() => (window.location = "/")}
                  >
                    {lang_data?.login}
                  </Button>
                </div>
                <div style={{ paddingRight: 10 }}>
                  <Button
                    variant="success"
                    onClick={() => (window.location = "/candidate/register")}
                  >
                    {lang_data.register_member}
                  </Button>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <ScrollbarStyle>
          <div style={{ backgroundColor: "#289CCB" }}>
            <Container>
              <Row style={{ paddingTop: "10px" }}>
                <Col lg="4" md="6" sm="12">
                  <div className="form-inside">
                    <Form.Control
                      id="q"
                      placeholder={lang_data.text_box_search}
                      style={{
                        height: "50px",
                        borderRadius: "7px",
                        paddingLeft: "45px",
                      }}
                      onChange={(e) =>
                        this.setState({
                          search_value: e.target.value,
                        })
                      }
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </div>
                </Col>
                <Col lg="3" md="6" sm="12">
                  <div className="form-inside">
                    <Select
                      id="province_id"
                      placeholder={lang_data.work_location}
                      options={province_data}
                      onChange={this.filterProvince}
                      styles={customStyles}
                      isMulti
                      isOptionDisabled={() => province_id_list.length >= 3}
                      defaultValue={defaultProvince}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-geo-alt-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                    </svg>
                  </div>
                </Col>
                <Col lg="3" md="6" sm="12">
                  <div className="form-inside">
                    <AsyncSelect
                      placeholder={lang_data?.job_category}
                      styles={customStyles}
                      cacheOptions
                      defaultOptions
                      isMulti
                      loadOptions={this.getJobFunctionMasterData}
                      getOptionLabel={(option) => option.label}
                      formatGroupLabel={(data) => (
                        <div style={{ fontWeight: "bold" }}>{data.label}</div>
                      )}
                      isOptionDisabled={() => jbc_id_list.length >= 3}
                      onChange={this.filterJobFunction}
                      defaultValue={defaultJobFunction}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-briefcase-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z" />
                    </svg>
                  </div>
                </Col>

                <Col lg="2" md="6" sm="12">
                  <Button size="lg" variant="primary" onClick={this.getJobPost}>
                    {lang_data.search}
                  </Button>
                </Col>
              </Row>
              <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                <Button
                  variant="info"
                  size="sm"
                  onClick={() => this.setState({ openCanvasFilter: true })}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-funnel-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z" />
                  </svg>{" "}
                  {lang_data.filter_label}
                </Button>{" "}
                <Button variant="warning" size="sm" onClick={this.clearFilter}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-repeat"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192Zm3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z" />
                  </svg>{" "}
                  {lang_data.clear_label}
                </Button>
              </div>
            </Container>
          </div>

          <Container>
            <div style={{ paddingTop: "20px" }}>
              <Row>
                <Col lg="4" md="6" sm="12">
                  <div
                    style={{
                      overflowY: "auto",
                      height: "100vh",
                    }}
                  >
                    <CardStyle>
                      {data?.map((rs, index) => (
                        <div
                          key={index}
                          style={{ paddingBottom: "15px", cursor: "pointer" }}
                          onClick={() => [
                            this.setState({
                              get_content: rs,
                            }),
                            this.refreshFavorit(rs.jp_id),
                          ]}
                        >
                          <div className="feature feature-one">
                            <Image
                              src={
                                rs.job_user?.user_main_company
                                  ?.uc_company_cover === undefined ||
                                rs.job_user?.user_main_company
                                  ?.uc_company_cover === ""
                                  ? require("../asset/images/1594898327img.png")
                                  : rs.job_user?.user_main_company
                                      ?.uc_company_cover
                              }
                              style={{ width: 100, height: 100 }}
                              thumbnail
                            />

                            <h4 className="feature__title">
                              <span style={cropText}>
                                <Image
                                  src={
                                    require("../asset/images/Job-functions-2.svg")
                                      .default
                                  }
                                  style={{ width: "32px", height: "32px" }}
                                />{" "}
                                {rs.job_jbc?.jbc_name}
                              </span>
                            </h4>
                            <div>
                              <h5> {rs.jp_position}</h5>
                            </div>
                            <div
                              className="feature__desc"
                              style={{ paddingTop: "10px" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-building"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z" />
                                <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z" />
                              </svg>{" "}
                              {rs.job_user?.user_main_company?.uc_company_name}{" "}
                              {rs.job_user?.user_main_detail?.ud_verify ===
                                1 && (
                                <Image
                                  src={require("../asset/images/checklist.webp")}
                                  style={{ width: 20, height: 20 }}
                                />
                              )}
                            </div>
                            <div style={{ paddingTop: "10px" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-geo-alt-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                              </svg>{" "}
                              {rs.job_province?.province_eng} /{" "}
                              {rs.job_province?.province_thai}
                            </div>
                            <div style={{ paddingTop: "15px" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-cash-coin"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                                />
                                <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                                <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                              </svg>{" "}
                              {Functions.formatNumberWithComma(
                                rs.jp_salary_start
                              )}
                              {" - "}
                              {Functions.formatNumberWithComma(
                                rs.jp_salary_end
                              )}
                              {"  "} {lang_data.bath}
                            </div>
                            <div style={{ paddingTop: "10px" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-exclude"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm12 2H5a1 1 0 0 0-1 1v7h7a1 1 0 0 0 1-1V4z" />
                              </svg>{" "}
                              <strong> {lang_data.main_job}</strong>
                              {rs?.job_detail
                                .filter((e) => e.jpd_type === "1")
                                .map((rd, i) => (
                                  <div key={i}>
                                    <span style={cropText}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-balloon-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M8.48 10.901C11.211 10.227 13 7.837 13 5A5 5 0 0 0 3 5c0 2.837 1.789 5.227 4.52 5.901l-.244.487a.25.25 0 1 0 .448.224l.04-.08c.009.17.024.315.051.45.068.344.208.622.448 1.102l.013.028c.212.422.182.85.05 1.246-.135.402-.366.751-.534 1.003a.25.25 0 0 0 .416.278l.004-.007c.166-.248.431-.646.588-1.115.16-.479.212-1.051-.076-1.629-.258-.515-.365-.732-.419-1.004a2.376 2.376 0 0 1-.037-.289l.008.017a.25.25 0 1 0 .448-.224l-.244-.487ZM4.352 3.356a4.004 4.004 0 0 1 3.15-2.325C7.774.997 8 1.224 8 1.5c0 .276-.226.496-.498.542-.95.162-1.749.78-2.173 1.617a.595.595 0 0 1-.52.341c-.346 0-.599-.329-.457-.644Z"
                                        />
                                      </svg>{" "}
                                      {rd.jpd_name}
                                    </span>
                                  </div>
                                ))}
                            </div>

                            <div className="footer-content">
                              <span>
                                {Functions.timeDiffCalc(
                                  new Date(rs.jp_posting_date),
                                  new Date()
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </CardStyle>
                  </div>
                  <div
                    style={{ paddingTop: "70px", paddingBottom: "70px" }}
                    align="center"
                  >
                    <div style={{ width: "200px" }}>
                      <InputGroup className="mb-3" size="sm">
                        <InputGroup.Text> {lang_data.page}</InputGroup.Text>
                        <Form.Control
                          type="number"
                          defaultValue={page}
                          onChange={(e) => [
                            this.setState({
                              page: e.target.value,
                            }),
                            this.getJobPost(),
                          ]}
                          onKeyUp={(e) => [
                            this.setState({
                              page: e.target.value,
                            }),
                            this.getJobPost(),
                          ]}
                          onClick={(e) => [
                            this.setState({
                              page: e.target.value,
                            }),
                            this.getJobPost(),
                          ]}
                          style={{ textAlign: "center" }}
                        />
                        <InputGroup.Text>
                          {lang_data.total_page} : {param.total_page}{" "}
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  </div>
                </Col>
                <Col>
                  <Card>
                    <Card.Body>
                      {get_content?.jp_id !== undefined ? (
                        <div>
                          <div style={{ paddingBottom: "25px" }}>
                            <Row>
                              <Col>
                                <Button
                                  variant="danger"
                                  onClick={() => (window.location = "/")}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    fill="currentColor"
                                    className="bi bi-hand-index-thumb-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8.5 1.75v2.716l.047-.002c.312-.012.742-.016 1.051.046.28.056.543.18.738.288.273.152.456.385.56.642l.132-.012c.312-.024.794-.038 1.158.108.37.148.689.487.88.716.075.09.141.175.195.248h.582a2 2 0 0 1 1.99 2.199l-.272 2.715a3.5 3.5 0 0 1-.444 1.389l-1.395 2.441A1.5 1.5 0 0 1 12.42 16H6.118a1.5 1.5 0 0 1-1.342-.83l-1.215-2.43L1.07 8.589a1.517 1.517 0 0 1 2.373-1.852L5 8.293V1.75a1.75 1.75 0 0 1 3.5 0z" />
                                  </svg>{" "}
                                  {lang_data.apply_now}
                                </Button>{" "}
                              </Col>
                              <Col>
                                <div align="right">
                                  <Button
                                    variant={
                                      favorite_id === 0
                                        ? "outline-success"
                                        : "outline-danger"
                                    }
                                    onClick={() =>
                                      favorite_id === 0
                                        ? this.favoriteCreate(
                                            get_content?.jp_id
                                          )
                                        : this.favoritDelete(
                                            favorite_id,
                                            get_content?.jp_id
                                          )
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                      className="bi bi-suit-heart-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                                    </svg>
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div>
                            <Image
                              src={
                                Functions.check_empty_value(
                                  get_content?.job_user?.user_main_detail
                                    ?.ud_image_cover
                                ) === true
                                  ? require("../asset/images/4920290.jpg")
                                  : get_content?.job_user?.user_main_detail
                                      ?.ud_image_cover
                              }
                              style={{
                                width: "100%",
                                height: "300px",
                                objectFit: "cover",
                                objectPosition: `0 ${get_content?.job_user?.user_main_detail?.ud_image_cover_position}%`,
                              }}
                            />
                          </div>
                          <hr />
                          <div>
                            <Image
                              src={
                                Functions.check_empty_value(
                                  get_content?.job_user?.user_main_company
                                    ?.uc_company_cover
                                ) === true
                                  ? require("../asset/images/1594898327img.png")
                                  : get_content?.job_user?.user_main_company
                                      ?.uc_company_cover
                              }
                              style={{ width: "100px", height: "100px" }}
                            />
                          </div>

                          <div style={{ paddingTop: "15px" }}>
                            <Image
                              src={
                                require("../asset/images/Job-functions-2.svg")
                                  .default
                              }
                              style={{ width: "32px", height: "32px" }}
                            />{" "}
                            <strong> {get_content?.jp_position}</strong>
                          </div>
                          <div style={{ paddingTop: "15px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-building"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z" />
                              <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z" />
                            </svg>{" "}
                            {
                              get_content?.job_user?.user_main_company
                                ?.uc_company_name
                            }
                          </div>

                          <div style={{ paddingTop: "10px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-geo-alt-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                            </svg>{" "}
                            {get_content?.job_province?.province_eng} /{" "}
                            {get_content?.job_province?.province_thai}
                          </div>

                          <div style={{ paddingTop: "20px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-cash-coin"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                              />
                              <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                              <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                              <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                            </svg>{" "}
                            {Functions.formatNumberWithComma(
                              get_content?.jp_salary_start
                            )}
                            {" - "}
                            {Functions.formatNumberWithComma(
                              get_content?.jp_salary_end
                            )}
                            {"  "} {lang_data.bath}
                          </div>

                          <div style={{ paddingTop: "10px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-exclude"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm12 2H5a1 1 0 0 0-1 1v7h7a1 1 0 0 0 1-1V4z" />
                            </svg>{" "}
                            <strong> {lang_data.main_job}</strong>
                            {get_content?.job_detail
                              .filter((e) => e.jpd_type === "1")
                              .map((rd, i) => (
                                <div key={i}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-balloon-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M8.48 10.901C11.211 10.227 13 7.837 13 5A5 5 0 0 0 3 5c0 2.837 1.789 5.227 4.52 5.901l-.244.487a.25.25 0 1 0 .448.224l.04-.08c.009.17.024.315.051.45.068.344.208.622.448 1.102l.013.028c.212.422.182.85.05 1.246-.135.402-.366.751-.534 1.003a.25.25 0 0 0 .416.278l.004-.007c.166-.248.431-.646.588-1.115.16-.479.212-1.051-.076-1.629-.258-.515-.365-.732-.419-1.004a2.376 2.376 0 0 1-.037-.289l.008.017a.25.25 0 1 0 .448-.224l-.244-.487ZM4.352 3.356a4.004 4.004 0 0 1 3.15-2.325C7.774.997 8 1.224 8 1.5c0 .276-.226.496-.498.542-.95.162-1.749.78-2.173 1.617a.595.595 0 0 1-.52.341c-.346 0-.599-.329-.457-.644Z"
                                    />
                                  </svg>{" "}
                                  <span>{rd.jpd_name}</span>
                                </div>
                              ))}
                          </div>

                          <div style={{ paddingTop: "10px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-info-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                            </svg>{" "}
                            <strong> {lang_data.detail}</strong>
                            {get_content?.job_detail
                              .filter((e) => e.jpd_type === "2")
                              .map((rd, i) => (
                                <div key={i}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-balloon-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M8.48 10.901C11.211 10.227 13 7.837 13 5A5 5 0 0 0 3 5c0 2.837 1.789 5.227 4.52 5.901l-.244.487a.25.25 0 1 0 .448.224l.04-.08c.009.17.024.315.051.45.068.344.208.622.448 1.102l.013.028c.212.422.182.85.05 1.246-.135.402-.366.751-.534 1.003a.25.25 0 0 0 .416.278l.004-.007c.166-.248.431-.646.588-1.115.16-.479.212-1.051-.076-1.629-.258-.515-.365-.732-.419-1.004a2.376 2.376 0 0 1-.037-.289l.008.017a.25.25 0 1 0 .448-.224l-.244-.487ZM4.352 3.356a4.004 4.004 0 0 1 3.15-2.325C7.774.997 8 1.224 8 1.5c0 .276-.226.496-.498.542-.95.162-1.749.78-2.173 1.617a.595.595 0 0 1-.52.341c-.346 0-.599-.329-.457-.644Z"
                                    />
                                  </svg>{" "}
                                  <span>{rd.jpd_name}</span>
                                </div>
                              ))}
                          </div>
                          <div style={{ paddingTop: "10px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-lightbulb-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z" />
                            </svg>{" "}
                            <strong> {lang_data.feature}</strong>
                            {get_content?.job_detail
                              .filter((e) => e.jpd_type === "3")
                              .map((rd, i) => (
                                <div key={i}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-balloon-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M8.48 10.901C11.211 10.227 13 7.837 13 5A5 5 0 0 0 3 5c0 2.837 1.789 5.227 4.52 5.901l-.244.487a.25.25 0 1 0 .448.224l.04-.08c.009.17.024.315.051.45.068.344.208.622.448 1.102l.013.028c.212.422.182.85.05 1.246-.135.402-.366.751-.534 1.003a.25.25 0 0 0 .416.278l.004-.007c.166-.248.431-.646.588-1.115.16-.479.212-1.051-.076-1.629-.258-.515-.365-.732-.419-1.004a2.376 2.376 0 0 1-.037-.289l.008.017a.25.25 0 1 0 .448-.224l-.244-.487ZM4.352 3.356a4.004 4.004 0 0 1 3.15-2.325C7.774.997 8 1.224 8 1.5c0 .276-.226.496-.498.542-.95.162-1.749.78-2.173 1.617a.595.595 0 0 1-.52.341c-.346 0-.599-.329-.457-.644Z"
                                    />
                                  </svg>{" "}
                                  <span>{rd.jpd_name}</span>
                                </div>
                              ))}
                          </div>
                        </div>
                      ) : (
                        <div align="center" style={{ padding: "75px" }}>
                          <Image
                            src={IconJobSearch}
                            style={{ width: "300px", height: "300px" }}
                          />
                          <h3>
                            {param?.total_data} {lang_data?.position}
                          </h3>
                          <label>{lang_data?.job_preiview_select}</label>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </ScrollbarStyle>

        <Offcanvas
          show={openCanvasFilter}
          onHide={() => this.setState({ openCanvasFilter: false })}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{lang_data?.filter_label}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ScrollbarStyle>
              <Row>
                <Col>
                  <div align="left">
                    {Functions.formatNumberWithComma(
                      this.state.jp_salary_start
                    )}
                  </div>
                </Col>
                <Col>
                  <div align="right">
                    {Functions.formatNumberWithComma(this.state.jp_salary_end)}
                  </div>
                </Col>
              </Row>
              <Form.Group>
                <Slider
                  range
                  allowCross={false}
                  defaultValue={[
                    this.state.jp_salary_start,
                    this.state.jp_salary_end,
                  ]}
                  dots
                  step={7000}
                  onChange={this.onChangeSalary}
                  min={0}
                  max={150000}
                />
                <div align="center"> {lang_data?.salary_label}</div>
              </Form.Group>
              <Form.Group>
                <Form.Label> {lang_data?.job_type_time}</Form.Label>
                <div className="form-inside">
                  <Select
                    options={list_job_type}
                    isMulti
                    styles={customStyles}
                    placeholder={lang_data?.job_type_time}
                    onChange={this.filterJobType}
                    defaultValue={defaultJobtype}
                    isOptionDisabled={() => jt_id_list.length >= 3}
                  />

                  <Image
                    src={require("../asset/images/Job-type3.svg").default}
                    style={{ width: "24px", height: "24px" }}
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label> {lang_data?.working_style}</Form.Label>{" "}
                <div className="form-inside">
                  <Select
                    options={list_working_style}
                    isMulti
                    styles={customStyles}
                    placeholder={lang_data?.working_style}
                    onChange={this.filterWorkStyle}
                    defaultValue={defaultWorkingStyle}
                    isOptionDisabled={() => ws_id_list.length >= 3}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-buildings-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 .5a.5.5 0 0 0-.724-.447l-8 4A.5.5 0 0 0 6 4.5v3.14L.342 9.526A.5.5 0 0 0 0 10v5.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V14h1v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V.5ZM2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-1 2v1H2v-1h1Zm1 0h1v1H4v-1Zm9-10v1h-1V3h1ZM8 5h1v1H8V5Zm1 2v1H8V7h1ZM8 9h1v1H8V9Zm2 0h1v1h-1V9Zm-1 2v1H8v-1h1Zm1 0h1v1h-1v-1Zm3-2v1h-1V9h1Zm-1 2h1v1h-1v-1Zm-2-4h1v1h-1V7Zm3 0v1h-1V7h1Zm-2-2v1h-1V5h1Zm1 0h1v1h-1V5Z" />
                  </svg>
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label> {lang_data?.country_label}</Form.Label>{" "}
                <div className="form-inside">
                  <AsyncSelect
                    placeholder={lang_data?.country_label}
                    styles={customStyles}
                    cacheOptions
                    defaultOptions
                    loadOptions={this.getCountry}
                    getOptionLabel={(option) => option.label}
                    onChange={this.filterCountry}
                    value={defaultCountry}
                    isOptionDisabled={() => country_id_list.length >= 3}
                  />

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-geo-alt-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                </div>
              </Form.Group>
              <Form.Group>
                <div className="d-grid gap-2" style={{ paddingTop: "25px" }}>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => [
                      this.setState({ openCanvasFilter: false }),
                      this.getJobPost(),
                    ]}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </Button>
                </div>
              </Form.Group>
            </ScrollbarStyle>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    );
  }
}
