import React, { Component } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import ReactPlayer from "react-player";
import Common from "../../common";
import Functions from "../../functions";
import { Button } from "react-bootstrap";
import {
  Container,
  //   Card,
  //   Col,
  //   Row,
  //   Nav,
  Image,
  //   ListGroup,
} from "react-bootstrap";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const CardStyle = styled.div`
  #container {
    width: 800px;
    margin: 30px auto 0 auto;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    overflow: hidden;

    @media only screen and (max-width: 600px) {
      width: 400px;
    }

    #hero-img {
      width: 100%;
      height: 300px;
      background: url("http://emilcarlsson.se/assets/forest.jpg") no-repeat
        center center;
      background-size: cover;
    }
    .cover {
      width: 100%;
      height: 300px;
      background-size: cover;
    }

    #profile-img {
      width: 160px;
      height: 160px;
      margin: -80px auto 0 auto;
      border: 6px solid #fff;
      //   border-radius: 50%;
      //   box-shadow: 0 0 5px rgba(90, 90, 90, 0.3);

      //   img {
      //     width: 100%;
      //     background: #fff;
      //     border-radius: 50%;
      //   }
    }

    #content {
      text-align: center;
      //   width: 520px;
      margin: 0 auto;
      padding: 0 0 50px 0;

      h1 {
        font-size: 29px;
        font-weight: 500;
        margin: 20px 0 0 0;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4;
        color: #666;
        margin: 15px 0 40px 0;
      }
    }
  }
`;
const user_id_authen = Common.token;
const GetDataForm = () => {
  const { user_id, name } = useParams();

  return <CompanyProfile name={name} user_id={user_id} />;
};
export default GetDataForm;
class CompanyProfile extends Component {
  state = {
    data: [],
    detail_data: [],
    favorite_id: 0,
    youtube_path: "",
  };

  getProfile = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          let user_company = res?.user_main_company;
          this.setState({
            data: res,
          });
          this.getDetail(user_company?.uc_id);
          // console.log(user_detail.ud_email);
        });
    } catch (error) {
      // console.log(error);
      window.location = "/";
    }
  };

  favoriteCreate = async (user_select) => {
    try {
      await axios
        .post(
          Common.API_URL + "general/favorite/create",
          {
            user_select: user_select,
            user_owner: user_id_authen,
          },
          Common.options
        )
        .then((response) => {
          this.refreshFavorit();
        });
    } catch (error) {
      console.log(error);
    }
  };
  favoritDelete = async (favorite_id) => {
    try {
      await axios
        .delete(
          Common.API_URL + `general/favorite/${favorite_id}`,
          Common.options
        )
        .then((response) => {
          this.refreshFavorit();
        });
    } catch (error) {
      console.log(error);
    }
  };
  refreshFavorit = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `general/favorite/${user_id_authen}`,
          {
            page: 1,
            per_page: 1,
            search_value: this.props.user_id,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          let favorite_id =
            res?.data[0]?.favorite_id === undefined
              ? 0
              : res?.data[0]?.favorite_id;
          // console.log(favorite_id);
          this.setState({ favorite_id: favorite_id });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getDetail = async (uc_id) => {
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/company/detail/${uc_id}?ucd_type=0`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({
            detail_data: res,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  refreshDataYoutube = async () => {
    const user_id = this.props.user_id;
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/portfolio/${user_id}?portfolio_type=2`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          if (
            res?.portfolio_path !== null &&
            res?.portfolio_path !== undefined
          ) {
            this.setState({
              youtube_path: res.portfolio_path,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.getProfile();
    this.refreshDataYoutube();
  }
  render() {
    const { data, detail_data, youtube_path } = this.state;
    return (
      <div>
        <Container>
          <CardStyle>
            <div id="container">
              {/* <div id="hero-img"></div> */}

              {Functions.check_empty_value(
                data?.user_main_detail?.ud_image_cover
              ) === false ? (
                <Image
                  src={data?.user_main_detail?.ud_image_cover}
                  style={{
                    width: "100%",
                    height: "300px",
                    objectFit: "cover",
                    objectPosition:
                      "100% " +
                      data?.user_main_detail?.ud_image_cover_position +
                      "%",
                  }}
                  variant="top"
                  id="imgcover"
                />
              ) : (
                <Image
                  src={require("../../asset/images/4920290.jpg")}
                  style={{
                    width: "100%",
                    height: "300px",
                    objectFit: "cover",
                    objectPosition: "100% 0",
                  }}
                  variant="top"
                  id="imgcover"
                />
              )}

              <div id="profile-img">
                {Functions.check_empty_value(
                  data?.user_main_company?.uc_company_cover
                ) === false ? (
                  <Image
                    src={data?.user_main_company?.uc_company_cover}
                    style={{ width: 160, height: 160 }}
                    thumbnail
                    roundedCircle
                  />
                ) : (
                  <Image
                    src={require("../../asset/images/no-user-image-icon-26.jpg")}
                    style={{ width: 160, height: 160 }}
                    thumbnail
                    roundedCircle
                  />
                )}
              </div>
              <div id="content">
                <h1>
                  {data?.user_main_company?.uc_company_name}{" "}
                  {data?.user_main_detail?.ud_verify === 1 && (
                    <Image
                      src={require("../../asset/images/checklist.webp")}
                      style={{ width: 25, height: 25 }}
                    />
                  )}
                </h1>
              </div>
              <div style={{ padding: 20 }}>
                <div style={{ paddingBottom: 10 }}>
                  <Button
                    variant="info"
                    href={"/messenger/" + this.props.user_id}
                  >
                    {lang_data?.send_message}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chat-dots-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8c0 3.866-3.582 7-8 7a9 9 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0m4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                    </svg>
                  </Button>
                </div>
                <div style={{ paddingBottom: 10 }}>
                  <strong> {lang_data?.business_type_label}</strong> :{" "}
                  {data?.user_main_company?.company_business?.bt_name}
                </div>
                <div style={{ paddingBottom: 10 }}>
                  <strong>Tax ID</strong> :{" "}
                  {data?.user_main_detail?.ud_personal}
                </div>
                <div style={{ paddingBottom: 10 }}>
                  <strong> {lang_data?.address_label}</strong> :{" "}
                  {data?.user_main_detail?.ud_address}{" "}
                  {data?.user_main_detail?.user_tambon?.tambon_eng} /{" "}
                  {data?.user_main_detail?.user_tambon?.tambon_thai} -{" "}
                  {data?.user_main_detail?.user_tambon?.district_eng} /{" "}
                  {data?.user_main_detail?.user_tambon?.district_thai} -{" "}
                  {data?.user_main_detail?.user_tambon?.province_eng} /{" "}
                  {data?.user_main_detail?.user_tambon?.province_thai}{" "}
                  {data?.user_main_detail?.user_tambon?.postcode}
                </div>
                <div style={{ paddingBottom: 10 }}>
                  <strong> {lang_data?.country_label}</strong> :{" "}
                  {data?.user_main_detail?.user_country?.country_name_eng} /{" "}
                  {data?.user_main_detail?.user_country?.capital_name_th}
                </div>
                <div style={{ paddingBottom: 10 }}>
                  <strong> {lang_data?.business_opeation_title}</strong>
                  {detail_data
                    ?.filter((r) => r.ucd_type === 1)
                    .map((rs, i) => (
                      <li key={rs.ucd_id}>{rs.ucd_value}</li>
                    ))}
                </div>
                <div style={{ paddingBottom: 10 }}>
                  <strong> {lang_data?.welfare_benefits_title}</strong>
                  {detail_data
                    ?.filter((r) => r.ucd_type === 2)
                    .map((rs, i) => (
                      <li key={rs.ucd_id}>{rs.ucd_value}</li>
                    ))}
                </div>
                <div style={{ paddingBottom: 10 }}>
                  <strong> {lang_data?.contact_name}</strong> :{" "}
                  {data?.firstname} {data?.lastname}
                  <div>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-envelope-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                      </svg>{" "}
                      {data?.email}
                    </span>{" "}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-telephone-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                        />
                      </svg>{" "}
                      {Functions.formatPhoneNumber(data?.phone)}
                    </span>
                  </div>
                </div>
                <div style={{ paddingBottom: 10 }}>
                  <strong> {lang_data?.video_presentation_company}</strong>
                  <ReactPlayer
                    url={youtube_path}
                    previewTabIndex={0}
                    controls={true}
                    playsinline={false}
                    width={"100%"}
                    height={450}
                  />
                </div>
              </div>
            </div>
          </CardStyle>
          <div style={{ paddingBottom: 30 }}></div>
        </Container>
      </div>
    );
  }
}
