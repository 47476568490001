import React, { Component } from "react";
import { Breadcrumb, Row, Card, Col, Container } from "react-bootstrap";
import AlertList from "../component_general/AlertList";

export default class Alert extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col sm={8}>
            <h3>แจ้งเตือน</h3>
          </Col>
          <Col sm={4}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">หน้าหลัก</Breadcrumb.Item>

              <Breadcrumb.Item active>แจ้งเตือน</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Container>
          <Card>
            <Card.Body>
              <AlertList />
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}
