import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";
import Common from "../../common";
import axios from "axios";
import Swal from "sweetalert2";
export default class CandidateDetailExperience extends Component {
  state = {
    exp_id: 0,
    exp_comapany: "",
    exp_year_start: "",
    exp_year_end: "",
    exp_last_position: "",
    exp_last_salary: 0,
    exp_responsibility: "",
    active: 1,
    data: [],
  };

  refreshData = async () => {
    try {
      await axios
        .get(
          Common.API_URL + `user/experience/${this.props.user_id}`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDeleteSubmit = async (exp_id) => {
    Swal.fire({
      title: "ยืนยันการลบ",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่, ต้องการลบข้อมูลนี้",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(
              Common.API_URL + `user/experience/${exp_id}`,
              Common.options
            )
            .then((res) => {
              this.clearState();
              this.refreshData();
            });
        } catch (error) {
          console.log(error);
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: "Do you want to continue",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
        }
      }
    });
  };

  clearState = () => {
    this.setState({
      exp_id: 0,
      exp_comapany: "",
      exp_year_start: "",
      exp_year_end: "",
      exp_last_position: "",
      exp_last_salary: 0,
      exp_responsibility: "",
      active: 1,
    });
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { data } = this.state;
    return (
      <div>
        {data !== undefined && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ช่วงปีที่ทำงาน</th>
                <th>ชื่อบริษัท</th>
                <th>ตำแหน่งล่าสุด</th>
                <th>เงินเดือนล่าสุด (บาท)</th>
                <th>หน้าที่</th>
                <th>ลบ</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((rs, index) => (
                <tr key={index}>
                  <td>
                    {rs.exp_year_start} - {rs.exp_year_end}
                  </td>
                  <td>{rs.exp_comapany}</td>
                  <td>{rs.exp_last_position}</td>
                  <td>{rs.exp_last_salary}</td>
                  <td>{rs.exp_responsibility}</td>
                  <td align="center">
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => this.handleDeleteSubmit(rs.exp_id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                      </svg>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    );
  }
}
