import React, { Component, Suspense } from "react";
import {
  Button,
  Container,
  Card,
  Form,
  Row,
  Col,
  InputGroup,
  Image,
} from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const ProfileMain = React.lazy(() => import("./ProfileMain"));

const user_id = Common.token;

const BASE_FILE = Common.FILE_URL;

export default class Favorite extends Component {
  state = {
    search_value: "",
    page: 1,
    per_page: 25,
    param: [],
    // data
    data: [],
  };

  refreshData = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `general/favorite/job/${user_id}`,
          {
            page: this.state.page,
            per_page: this.state.per_page,
            search_value: this.state.search_value,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res.data, param: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  favoritDelete = async (favorite_id) => {
    try {
      await axios
        .delete(
          Common.API_URL + `general/favorite/job/${favorite_id}`,
          Common.options
        )
        .then((response) => {
          this.refreshData();
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { data, param, page } = this.state;
    return (
      <div>
        <div>
          <Suspense
            fallback={
              <div align="center">
                <Image src={require("../../asset/images/Loading_2.gif")} />
              </div>
            }
          >
            <ProfileMain />
          </Suspense>
        </div>

        <Container>
          <div style={{ paddingTop: "40px", height: "100vh" }}>
            <div align="right" style={{ paddingBottom: "25px" }}>
              <Row>
                <Col>
                  <div align="left">
                    <h3> {lang_data?.favorite}</h3>
                  </div>
                </Col>
                <Col>
                  <div align="right"></div>
                </Col>
              </Row>
            </div>

            <div style={{ paddingBottom: "15px" }}>
              <Card>
                <Card.Body>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder={lang_data?.search}
                      onChange={(e) => [
                        this.setState({
                          search_value: e.target.value,
                        }),
                        this.refreshData(),
                      ]}
                      onKeyUp={(e) => [
                        this.setState({
                          search_value: e.target.value,
                        }),
                        this.refreshData(),
                      ]}
                    />
                  </Form.Group>
                </Card.Body>
              </Card>
            </div>

            <Row>
              {data?.map((rs, index) => (
                <Col lg="4" md="6" sm="12" key={index}>
                  <Card>
                    <Card.Body>
                      <Image
                        src={
                          Functions.check_empty_value(
                            rs?.favorite_job?.job_user?.user_image_cover
                          ) === true
                            ? require("../../asset/images/4920290.jpg")
                            : BASE_FILE +
                              rs?.favorite_job?.job_user?.user_image_cover
                        }
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                          objectPosition: "0 10%",
                        }}
                      />

                      <Card.Title> {rs?.favorite_job?.jp_position}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-building"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z" />
                          <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z" />
                        </svg>{" "}
                        {
                          rs?.favorite_job?.job_user?.user_main_company
                            ?.uc_company_name
                        }
                      </Card.Subtitle>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-geo-alt-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>{" "}
                        {
                          rs?.favorite_job?.job_user?.user_main_detail
                            ?.user_tambon?.province_eng
                        }
                        {" / "}
                        {
                          rs?.favorite_job?.job_user?.user_main_detail
                            ?.user_tambon?.province_thai
                        }
                      </div>
                      <div align="center" style={{ paddingTop: "25px" }}>
                        <Link
                          to={`/job/${rs.favorite_job?.jp_position}/${rs.favorite_job?.jp_id}`}
                          target="_blank"
                        >
                          <Button variant="primary" size="sm">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-person-lines-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                            </svg>
                          </Button>
                        </Link>{" "}
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => this.favoritDelete(rs?.favorite_id)}
                          title="Favorite"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-suit-heart-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                          </svg>{" "}
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

            <div style={{ paddingTop: "70px" }} align="center">
              <div style={{ width: "200px" }}>
                <InputGroup className="mb-3" size="sm">
                  <InputGroup.Text>{lang_data?.page}</InputGroup.Text>
                  <Form.Control
                    type="number"
                    defaultValue={page}
                    onChange={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    onKeyUp={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    onClick={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    style={{ textAlign: "center" }}
                  />
                  <InputGroup.Text>
                    {lang_data?.total_page} : {param.total_page}
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
