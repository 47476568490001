import React, { Component } from "react";
import {
  Form,
  Nav,
  Button,
  ButtonGroup,
  Offcanvas,
  OverlayTrigger,
  Popover,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const user_id = Common.token;
const langSet = Common.langSet;
const lang_data = lang(langSet);
const style = (React.CSSProperties = {
  height: 400,
  marginBottom: 20,
  marginLeft: 5,
});
const parentStyle = { overflow: "hidden" };
const present_day = new Date().toISOString().split("T")[0];
export default class ProfileMain extends Component {
  state = {
    filecore: "",
    filecore_cover: "",
    slide_number: 0,
    coverObjectPosition: "100% 0%",

    openOffcanvas: false,

    ud_image_profile: "",
    ud_image_cover: "",
    ud_image_cover_position: "",
    ud_verify: 0,
    ud_bio: "",
    ud_birhday: present_day,
    ud_gender: 0,
    ud_address: "",
    ud_code: "",
    tambon_id: 1,
    country_id: 19,
    loading_cover: true,
  };

  refreshData = async () => {
    try {
      this.setState({
        loading_cover: true,
      });
      await axios
        .get(Common.API_URL + `user/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          let user_detail = res?.user_main_detail;
          if (user_detail !== undefined) {
            this.setState({
              ud_image_profile: user_detail?.ud_image_profile,
              ud_image_cover: user_detail?.ud_image_cover,
              ud_image_cover_position: user_detail?.ud_image_cover_position,
              ud_verify: user_detail?.ud_verify,
              ud_bio: user_detail?.ud_bio,
              ud_birhday: user_detail?.ud_birhday,
              ud_gender: user_detail?.ud_gender,
              ud_address: user_detail?.ud_address,
              ud_code: user_detail?.ud_code,
              tambon_id: user_detail?.tambon_id,
              country_id: user_detail?.country_id,

              // set ตำแหน่งปก
              slide_number: user_detail?.ud_image_cover_position,
              coverObjectPosition: `100% ${user_detail?.ud_image_cover_position}%`,
            });
          }
        });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        loading_cover: false,
      });
    }
  };

  handleOffcanvasClose = () => {
    this.setState({
      openOffcanvas: false,
    });
  };
  readURL = (event) => {
    if (event.target.files && event.target.files[0]) {
      var output = document.getElementById("imgcover");
      output.src = URL.createObjectURL(event.target.files[0]);
      output.onload = function () {
        URL.revokeObjectURL(output.src); // free memory
      };
      this.setState({
        filecore_cover: event,
        slide_number: 0,
        coverObjectPosition: "100% 0%",
      });
    }
  };
  uploadImageCover = async (event) => {
    let file = event.target.files[0];
    const formdata = new FormData();
    formdata.append("file", file);
    try {
      await axios
        .post(Common.API_FILE + "file/upload", formdata, Common.options2)
        .then((res) => {
          const r = res?.data;
          const path = r?.path;
          this.handleSubmit(this.state.ud_image_profile, path);
        });
    } catch (error) {
      console.log(error);
    }
  };

  DeleteImage = async (image) => {
    if (image === "" || image === null) {
      return false;
    }
    const url = new URL(image);
    const searchParams = url.searchParams;
    const path = searchParams.get("path");
    try {
      await axios.delete(
        Common.API_FILE + `file/delete?path=${path}`,
        Common.options2
      );
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = async (new_image_prifile, new_image_cover) => {
    if (this.state.filecore_cover !== "") {
      this.DeleteImage(this.state.ud_image_cover);
    }
    try {
      await axios
        .put(
          Common.API_URL + `user/detail/${user_id}`,
          {
            ud_image_profile: new_image_prifile,
            ud_image_cover: new_image_cover,
            ud_image_cover_position: this.state.slide_number,
            ud_verify: this.state.ud_verify,
            ud_bio: this.state.ud_bio,
            ud_birhday: this.state.ud_birhday,
            ud_gender: this.state.ud_gender,
            ud_address: this.state.ud_address,
            tambon_id: this.state.tambon_id,
            country_id: this.state.country_id,
          },
          Common.options
        )
        .then((res) => {
          window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClickUploadCover = () => {
    document.getElementById("coverupload").click();
  };

  onSubmit = () => {
    this.uploadImageCover(this.state.filecore_cover);
  };

  setCoverPosition = (e) => {
    // console.log(e);
    this.setState({
      slide_number: e,
      coverObjectPosition: `100% ${e}%`,
    });
  };
  saveCoverPosition = (e) => {
    this.handleSubmit(this.state.ud_image_profile, this.state.ud_image_cover);
  };
  cancle = () => {
    document.getElementById("imgcover").src = this.state.ud_image_cover;
    this.setState({
      coverObjectPosition: `100% ${this.state.ud_image_cover_position}%`,
      filecore_cover: "",
      slide_number: 0,
    });
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { slide_number, coverObjectPosition, loading_cover } = this.state;

    const popover = (
      <Popover id="popover-basic">
        <Popover.Body>
          <div style={parentStyle}>
            <div style={style}>
              <Slider
                min={0}
                max={100}
                defaultValue={slide_number}
                vertical
                onChange={this.setCoverPosition}
              />
            </div>
          </div>
          {slide_number} %
        </Popover.Body>
      </Popover>
    );

    return (
      <div>
        {/* Cover */}

        {loading_cover === true ? (
          <div align="center">
            <Image src={require("../../asset/images/Loading_2.gif")} />
          </div>
        ) : Functions.check_empty_value(this.state.ud_image_cover) === false ? (
          <Image
            src={this.state.ud_image_cover}
            style={{
              width: "100%",
              height: "450px",
              objectFit: "cover",
              objectPosition: coverObjectPosition,
            }}
            variant="top"
            id="imgcover"
          />
        ) : (
          <Image
            src={require("../../asset/images/4920290.jpg")}
            style={{
              width: "100%",
              height: "450px",
              objectFit: "cover",
              objectPosition: "100% 0",
            }}
            variant="top"
            id="imgcover"
          />
        )}
        {/* End Cover */}
        {/* Nav Menu */}
        <Nav fill variant="tabs" style={{ paddingTop: "5px" }}>
          <Nav.Item>
            <Link to={"/account"} style={{ textDecoration: "none" }}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-person-lines-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                </svg>
              </div>{" "}
              {lang_data?.account}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to={"/job-position"} style={{ textDecoration: "none" }}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-person-vcard-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5ZM9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8Zm1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5Zm-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96c.026-.163.04-.33.04-.5ZM7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
                </svg>
              </div>{" "}
              {lang_data?.jobPost}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to={"/mycandidate"} style={{ textDecoration: "none" }}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-person-workspace"
                  viewBox="0 0 16 16"
                >
                  <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                </svg>
              </div>{" "}
              {lang_data?.complete_list}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to={"/favorite"} style={{ textDecoration: "none" }}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-suit-heart-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                </svg>
              </div>{" "}
              {lang_data?.favorite}
            </Link>
          </Nav.Item>

          <Nav.Item>
            <Link to={"/calendar"} style={{ textDecoration: "none" }}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-calendar-date-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zm5.402 9.746c.625 0 1.184-.484 1.184-1.18 0-.832-.527-1.23-1.16-1.23-.586 0-1.168.387-1.168 1.21 0 .817.543 1.2 1.144 1.2z" />
                  <path d="M16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-6.664-1.21c-1.11 0-1.656-.767-1.703-1.407h.683c.043.37.387.82 1.051.82.844 0 1.301-.848 1.305-2.164h-.027c-.153.414-.637.79-1.383.79-.852 0-1.676-.61-1.676-1.77 0-1.137.871-1.809 1.797-1.809 1.172 0 1.953.734 1.953 2.668 0 1.805-.742 2.871-2 2.871zm-2.89-5.435v5.332H5.77V8.079h-.012c-.29.156-.883.52-1.258.777V8.16a12.6 12.6 0 0 1 1.313-.805h.632z" />
                </svg>
              </div>
              {lang_data?.carlendarEvent}
            </Link>
          </Nav.Item>

          <Nav.Item>
            <Link
              to={"/" + user_id + "/view"}
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-eye-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
              </div>
              {lang_data?.viewAs}
            </Link>
          </Nav.Item>

          <Nav.Item>
            <div
              style={{ cursor: "pointer", color: "#0A58CA" }}
              onClick={(e) => this.setState({ openOffcanvas: true })}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-camera-fill"
                viewBox="0 0 16 16"
              >
                <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
              </svg>{" "}
            </div>
            {lang_data?.changeCover}
          </Nav.Item>
        </Nav>
        {/*End Nav Menu */}
        {/* Form edit cover photo */}
        <Offcanvas
          show={this.state.openOffcanvas}
          onHide={this.handleOffcanvasClose}
          placement="bottom"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title> {lang_data?.changeCover}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form.Control
              type="file"
              size="sm"
              accept=".png, .jpg, .jpeg"
              onChange={(e) => this.readURL(e)}
              id="coverupload"
              style={{ display: "none" }}
            />

            <div align="center">
              <ButtonGroup>
                <OverlayTrigger
                  trigger="click"
                  placement="left"
                  overlay={popover}
                >
                  <Button
                    variant="outline-dark"
                    disabled={this.state.ud_image_cover === "" ? true : false}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-sliders"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"
                      />
                    </svg>{" "}
                    {lang_data?.position}
                  </Button>
                </OverlayTrigger>
                <Button
                  variant="outline-dark"
                  onClick={this.onClickUploadCover}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-camera-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                    <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                  </svg>{" "}
                  {lang_data?.upload}
                </Button>
              </ButtonGroup>
              <div>
                {(this.state.filecore_cover !== "" ||
                  this.state.ud_image_cover_position !==
                    this.state.coverObjectPosition) && (
                  <div align="center" style={{ paddingTop: "20px" }}>
                    <Button variant="secondary" onClick={this.cancle}>
                      {lang_data?.cancelled}
                    </Button>{" "}
                    {this.state.ud_image_cover_position !== 0 &&
                      this.state.filecore_cover === "" && (
                        <Button
                          variant="primary"
                          onClick={this.saveCoverPosition}
                        >
                          {lang_data?.save}
                        </Button>
                      )}
                    {this.state.filecore_cover !== "" && (
                      <Button variant="primary" onClick={this.onSubmit}>
                        {lang_data?.save}
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        {/*End Form edit cover photo */}
      </div>
    );
  }
}
