import React, { Component } from "react";
import {
  Badge,
  Breadcrumb,
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  Image,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import Common from "../../common";
import Functions from "../../functions";
import axios from "axios";

import EmptyImageUser from "../../asset/images/no-user-image-icon-26.jpg";
import EmptyImageCover from "../../asset/images/4920290.jpg";

const CardStyle = styled.div`
  .animate {
    transition: all 0.5s ease;
  }

  .card-body {
    height: auto;
  }

  .img-top {
    overflow: hidden;
    width: auto;
    height: 200px;
    /*   max-height: 30vh; */
  }

  .img-top:hover img {
    transform: scale(1.5);
    filter: grayscale(0%);
  }

  .img-top a span {
    display: block;
    position: absolute;
    background-color: #00ffd1;
    cursor: pointer;
    /*   z-index: 2; */
  }

  .img-top a span:nth-child(1) {
    background-color: #00ffd1;
    right: 0;
    top: 200px;
    width: 100%;
    height: 5px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease-in-out;
  }

  .img-top:hover a span:nth-child(1) {
    background-color: #00ffd1;
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.5s ease-in-out;
  }

  .img-top a span:nth-child(2) {
    background-color: #00ffd1;
    right: 0;
    top: 0;
    width: 100%;
    height: 5px;
    border-radius: 5px 5px 0 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease-in-out;
  }

  .img-top:hover a span:nth-child(2) {
    background-color: #00ffd1;
    transform: scaleX(1);
    transform-origin: right;
    transition: transform 0.5s ease-in-out;
  }

  .img-profile {
    width: 100px;
    height: 100px;
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 0 5px white;
  }

  .img-profile:hover {
    box-shadow: 0 0 0 5px #00ffd1;
    transition: box-shadow 0.3s ease-in-out;
  }

  .img-profile:hover img {
    transform: scale(1.5);
    filter: grayscale(0%);
  }

  .card-body img {
    /*   aspect-ratio: 1/1; */
    margin: auto;
    width: 100%;
    height: auto;
    vertical-align: top;
    /* filter: grayscale(100%); */
  }

  .media-bar {
    height: 50px;
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    background-color: #222;
  }
`;
export default class Candidate extends Component {
  state = {
    data: [],
    search_value: "",
    page: 1,
    per_page: 25,
    param: [],
    user_id: "",

    isOpenModal: false,
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    user_image_prifile: "",
    user_image_cover: "",
    user_image_cover_position: "",
    user_type: 3,
    active: 0,
  };
  refreshData = async () => {
    try {
      await axios
        .post(
          Common.API_URL + "user/all?typeuser=3",
          {
            page: this.state.page,
            per_page: this.state.per_page,
            search_value: this.state.search_value,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res.data, param: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleEditClick = async (res) => {
    let active = res.active;
    let setactvie;
    if (active === 1) {
      setactvie = 0;
    } else {
      setactvie = 1;
    }

    await new Promise((accept) =>
      this.setState(
        {
          user_id: res.user_id,
          username: res.username,
          firstname: res.firstname,
          lastname: res.lastname,
          email: res.email,
          phone: res.phone,
          user_image_prifile: res.user_image_prifile,
          user_image_cover: res.user_image_cover,
          user_image_cover_position: res.user_image_cover_position,
          user_type: res.user_type,
          active: setactvie,
        },
        accept
      )
    );
    this.handleUpdateSubmit();
  };

  handleUpdateSubmit = () => {
    try {
      axios
        .put(
          Common.API_URL + `user/${this.state.user_id}`,
          {
            username: this.state.username,
            password: "",
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: this.state.phone,
            user_image_prifile: this.state.user_image_prifile,
            user_image_cover: this.state.user_image_cover,
            user_image_cover_position: this.state.user_image_cover_position,
            user_type: this.state.user_type,
            active: this.state.active,
          },
          Common.options
        )
        .then((res) => {
          this.clearState();
          this.refreshData();
        });
    } catch (error) {
      console.log(error);
      this.setState({ msg: "Invalid data" });
    }
  };

  handleDeleteSubmit = async (user_id) => {
    Swal.fire({
      title: "ยืนยันการลบ",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่, ต้องการลบข้อมูลนี้",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(Common.API_URL + `user/${user_id}`, Common.options)
            .then((res) => {
              this.clearState();
              this.refreshData();

              Swal.fire({
                title: "เรียบร้อย",
                text: "ทำรายการสำเร็จ",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  clearState = () => {
    this.setState({
      user_id: "",
      username: "",
      password: "",
      firstname: "",
      lastname: "",
      email: "",
      user_image_prifile: "",
      user_image_cover: "",
      user_image_cover_position: "",
      user_type: 3,
      active: 0,
    });
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { data, param, page } = this.state;

    return (
      <div>
        <Row>
          <Col sm={8}>
            <h3>ผู้สมัคร</h3>
          </Col>
          <Col sm={4}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">หน้าหลัก</Breadcrumb.Item>

              <Breadcrumb.Item active>ผู้สมัคร</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row style={{ paddingBottom: "30px" }}>
          <Col sm={9}>ทั้งหมด {param.total_filter_data} </Col>
          <Col sm={3}>
            <Form.Control
              type="text"
              placeholder="ค้นหา"
              onChange={(e) => [
                this.setState({
                  search_value: e.target.value,
                }),
                this.refreshData(),
              ]}
              onKeyUp={(e) => [
                this.setState({
                  search_value: e.target.value,
                }),
                this.refreshData(),
              ]}
            />
          </Col>
        </Row>

        <div>
          <CardStyle>
            <Row>
              {data?.map((rs, index) => (
                <Col
                  lg="3"
                  md="6"
                  sm="12"
                  key={index}
                  style={{ paddingBottom: "50px" }}
                >
                  <div className="card mb-2 h-100">
                    <div className="img-top">
                      {Functions.check_empty_value(
                        rs.user_main_detail?.ud_image_cover
                      ) === false ? (
                        <Image
                          src={rs.user_main_detail?.ud_image_cover}
                          className="card-img-top img-fluid animate"
                        />
                      ) : (
                        <Image
                          src={EmptyImageCover}
                          className="card-img-top img-fluid animate"
                        />
                      )}
                    </div>

                    <div className="card-body position-relative">
                      <div className="img-profile position-absolute top-0 start-50 translate-middle">
                        {Functions.check_empty_value(
                          rs.user_main_detail?.ud_image_profile
                        ) === false ? (
                          <Image
                            src={rs.user_main_detail?.ud_image_profile}
                            className="animate"
                          />
                        ) : (
                          <Image src={EmptyImageUser} className="animate" />
                        )}
                      </div>
                      <div className="row mt-5">
                        <div className="col text-center">
                          <div align="center">
                            <Badge bg="info">
                              {rs.user_main_detail?.ud_code}
                            </Badge>
                          </div>
                          <h4>
                            {rs?.firstname} {rs?.lastname}
                          </h4>
                          <div>{rs?.email}</div>
                          <div>
                            {rs.user_main_detail?.ud_verify === 1 && (
                              <Badge bg="success">ยืนยัน OTP แล้ว</Badge>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <Row>
                        <Col lg="9" md="6">
                          <div>
                            Create Date{" "}
                            {Functions.format_date_time(rs?.create_date)}
                          </div>
                        </Col>
                        <Col lg="3" md="6">
                          <div align="right">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              defaultChecked={rs?.active === 1 ? true : false}
                              onChange={() => this.handleEditClick(rs)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div align="center">
                    <span>
                      <LinkContainer
                        to={`/candidate/${rs?.user_id}/${
                          rs?.firstname + " " + rs?.lastname
                        }`}
                      >
                        <Button variant="info">รายละเอียด</Button>
                      </LinkContainer>{" "}
                    </span>
                    <span>
                      <Button
                        variant="danger"
                        onClick={(e) => this.handleDeleteSubmit(rs?.user_id)}
                      >
                        ลบ
                      </Button>
                    </span>
                  </div>
                </Col>
              ))}
            </Row>
          </CardStyle>
        </div>

        <div style={{ paddingTop: "70px" }} align="center">
          <div style={{ width: "200px" }}>
            <InputGroup className="mb-3" size="sm">
              <InputGroup.Text>หน้า</InputGroup.Text>
              <Form.Control
                type="number"
                defaultValue={page}
                onChange={(e) => [
                  this.setState({
                    page: e.target.value,
                  }),
                  this.refreshData(),
                ]}
                onKeyUp={(e) => [
                  this.setState({
                    page: e.target.value,
                  }),
                  this.refreshData(),
                ]}
                onClick={(e) => [
                  this.setState({
                    page: e.target.value,
                  }),
                  this.refreshData(),
                ]}
                style={{ textAlign: "center" }}
              />
              <InputGroup.Text>จำนวนหน้า : {param.total_page} </InputGroup.Text>
            </InputGroup>
          </div>
        </div>
      </div>
    );
  }
}
