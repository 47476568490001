import React, { Component } from "react";
import { Card, Container, Table } from "react-bootstrap";
import axios from "axios";
import ReactPlayer from "react-player";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const user_id = Common.token;
export default class HistoryVideo extends Component {
  state = {
    data: [],
  };
  refreshData = async () => {
    try {
      await axios
        .get(Common.API_URL + `j/job_position/video/${user_id}`, Common.options)
        .then((response) => {
          const res = response.data;
          this.setState({
            data: res,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  componentDidMount() {
    this.refreshData();
  }
  render() {
    const { data } = this.state;
    return (
      <div>
        <h1> {lang_data?.history_video}</h1>
        <Container>
          <Card>
            <Card.Body>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{lang_data?.video_label}</th>
                    <th>
                      {lang_data?.first_name}-{lang_data?.last_name}
                    </th>
                    <th>{lang_data?.date_label}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((rs, i) => (
                    <tr key={i}>
                      <td align="center">{i + 1}</td>
                      <td align="center">
                        <ReactPlayer
                          url={rs?.portfolio_path}
                          previewTabIndex={0}
                          controls={true}
                          playsinline={false}
                          width={300}
                          height={300}
                        />
                      </td>
                      <td>
                        {rs?.firstname} {rs?.lastname}
                      </td>
                      <td>{Functions.format_date_time(rs.create_date)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}
