import React, { Component } from "react";
import { Row, Col, Badge } from "react-bootstrap";
import axios from "axios";
import styled from "styled-components";
import Common from "../../common";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const CardStyle = styled.div`
  .feature {
    position: relative;
    // max-width: 21rem;
    max-width: 100%;
    height: 17rem;
    margin: 2rem auto;
    padding: 2em;
    border-radius: 0.75em;
    box-shadow: 5px 5px 20px rgba(0 0 0/0.15);
    text-align: left;
    transition: transform 200ms ease-in;
  }

  .feature__desc {
    margin-top: 0.5em;
    color: #a3adc9;
  }

  .feature__img {
    position: absolute;
    bottom: 10%;
    right: 10%;
  }

  .feature-one {
    border-top: 5px solid #549ef2;
  }

  /* media queries */

  @media (min-width: 1000px) {
    body {
      align-items: center;
      min-height: 100vh;
    }

    section {
      max-width: 200rem;
    }

    .section__title {
      margin: 0 auto;
      max-width: 40%;
      font-size: 2rem;
    }

    .section__desc {
      max-width: 55ch;
      margin: 1rem auto 1rem;
    }

    .features {
      display: flex;
      gap: 2rem;
    }

    .feature-one {
      margin: auto 0;
    }
  }
`;
export default class ProfileSkills extends Component {
  state = {
    data: [],
  };

  refreshData = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/skill_profile/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  groupConvert = (value) => {
    let text;
    if (value === 1) {
      text = "Computer Skills";
    } else if (value === 2) {
      text = "Competency Skills ";
    } else if (value === 3) {
      text = "General";
    } else {
      text = "-";
    }
    return text;
  };

  componentDidMount() {
    this.refreshData();
  }
  render() {
    const { data } = this.state;

    return (
      <div>
        <h1>{lang_data?.specializedSkills}</h1>

        <div style={{ paddingTop: "25px" }}>
          <CardStyle>
            <Row>
              {data?.map((rs, index) => (
                <Col lg="4" md="6" sm="12" key={index}>
                  <div className="feature feature-one">
                    <h3 className="feature__title">
                      {rs.skill_profile_child.skill_name}
                    </h3>
                    <Badge bg="secondary">
                      {" "}
                      {this.groupConvert(
                        parseInt(rs.skill_profile_child.skill_group_type)
                      )}
                    </Badge>
                    <p className="feature__desc">{rs.skill_profile_detail}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </CardStyle>
        </div>
      </div>
    );
  }
}
