import React, { Component } from "react";
import { Button, Form, Table } from "react-bootstrap";
import axios from "axios";
import Common from "../../common";
export default class JobPositionFormDetail extends Component {
  state = {
    jpd_data: [],
  };
  getDetail = async () => {
    let jp_id = this.props.jp_id;
    let jpd_type = this.props.jpd_type;
    try {
      await axios
        .get(
          Common.API_URL + `j/job_position/detail/${jp_id}/${jpd_type}`,
          Common.options
        )
        .then((response) => {
          this.setState({ jpd_data: response.data });
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmitDetail = () => {
    const page_code = this.props.page_code;
    let jpd_name = document.getElementById("jpd_name" + page_code).value;
    if (jpd_name === "") {
      return false;
    }

    let jp_id = this.props.jp_id;
    let jpd_type = this.props.jpd_type;
    try {
      axios
        .post(
          Common.API_URL + "j/job_position/detail/create",
          {
            jpd_name: jpd_name,
            jpd_type: jpd_type,
            jp_id: jp_id,
          },
          Common.options
        )
        .then((res) => {
          document.getElementById("jpd_name" + page_code).value = "";
          this.getDetail();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleUpdateSubmitDetail = (jpd_id) => {
    const page_code = this.props.page_code;
    let jp_id = this.props.jp_id;
    let jpd_type = this.props.jpd_type;
    let jpd_name = document.getElementById(
      "jpd_name_edit" + jpd_id + page_code
    ).value;
    if (jpd_name === "") {
      return false;
    }

    try {
      axios
        .put(
          Common.API_URL + `j/job_position/detail/${jpd_id}`,
          {
            jpd_name: jpd_name,
            jpd_type: jpd_type,
            jp_id: jp_id,
          },
          Common.options
        )
        .then((res) => {
          this.getDetail();
          this.DetailFormClose(jpd_id + page_code);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDeleteSubmitDetail = (jpd_id) => {
    if (!window.confirm("Confirm Delete")) {
      return true;
    }
    try {
      axios
        .delete(
          Common.API_URL + `j/job_position/detail/${jpd_id}`,

          Common.options
        )
        .then((res) => {
          this.getDetail();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  DetailFormOpen = (index) => {
    document.getElementById("content_qf_edit" + index).style.display = "none";
    document.getElementById("form_qf_edit" + index).style.display = "block";

    document.getElementById("button_open_qf_edit" + index).style.display =
      "none";
    document.getElementById("button_close_qf_edit" + index).style.display =
      "block";
  };
  DetailFormClose = (index) => {
    document.getElementById("content_qf_edit" + index).style.display = "block";
    document.getElementById("form_qf_edit" + index).style.display = "none";

    document.getElementById("button_open_qf_edit" + index).style.display =
      "block";
    document.getElementById("button_close_qf_edit" + index).style.display =
      "none";
  };

  componentDidMount() {
    this.getDetail();
  }

  render() {
    const { jpd_data } = this.state;
    const page_code = this.props.page_code;
    const jpd_type = this.props.jpd_type;
    return (
      <div>
        <Table>
          <tbody>
            {jpd_data?.map((rs, i) => (
              <tr key={rs.jpd_id}>
                <td>
                  <div id={"content_qf_edit" + rs.jpd_id + page_code}>
                    {rs.jpd_name}
                  </div>
                  <div
                    style={{ display: "none" }}
                    id={"form_qf_edit" + rs.jpd_id + page_code}
                  >
                    <Form.Control
                      id={"jpd_name_edit" + rs.jpd_id + page_code}
                      size="sm"
                      type="text"
                      defaultValue={rs.jpd_name}
                      maxLength={128}
                    />
                  </div>
                </td>
                <td>
                  <div id={"button_open_qf_edit" + rs.jpd_id + page_code}>
                    <Button
                      variant="warning"
                      onClick={() => this.DetailFormOpen(rs.jpd_id + page_code)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pencil-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                      </svg>
                    </Button>{" "}
                    <Button
                      variant="danger"
                      onClick={() => this.handleDeleteSubmitDetail(rs.jpd_id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                      </svg>
                    </Button>
                  </div>
                  <div
                    id={"button_close_qf_edit" + rs.jpd_id + page_code}
                    style={{ display: "none" }}
                  >
                    <Button
                      variant="warning"
                      onClick={() =>
                        this.DetailFormClose(rs.jpd_id + page_code)
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-dash-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
                        />
                      </svg>
                    </Button>{" "}
                    <Button
                      variant="success"
                      onClick={() => this.handleUpdateSubmitDetail(rs.jpd_id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-check2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                      </svg>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <Form.Control
                  id={"jpd_name" + page_code}
                  size="sm"
                  type="text"
                  disabled={
                    jpd_data.length >= 3 && jpd_type === 1 ? true : false
                  }
                  maxLength={128}
                />
              </td>
              <td>
                <Button
                  onClick={this.handleSubmitDetail}
                  disabled={
                    jpd_data.length >= 3 && jpd_type === 1 ? true : false
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                  </svg>
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
