import React, { Component } from "react";
import {
  Button,
  Card,
  Row,
  Col,
  Image,
  Form,
  InputGroup,
  Tab,
  Tabs,
  Table,
  Modal,
  Alert,
} from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import ReactPlayer from "react-player";
import axios from "axios";
import Common from "../../common";
import Functions from "../../functions";
import EmptyImageProfile from "../../asset/images/company_nologo.png";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const user_id = Common.token;
const present_day = new Date().toISOString().split("T")[0];
const customStyles = {
  control: (base) => ({
    ...base,
    height: 45,
    minHeight: 45,
  }),
};
const customStyles2 = {
  control: (base) => ({
    ...base,
    height: 45,
    minHeight: 45,
    border: "1px solid red",
  }),
};

export default class ProfileUpdate extends Component {
  state = {
    // main
    username: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    active: 0,
    // detail
    ud_image_profile: "",
    ud_image_cover: "",
    ud_image_cover_position: "",
    ud_bio: "",
    ud_birhday: present_day,
    ud_gender: 0,
    ud_personal: "",
    ud_address: "",
    ud_verify: 0,
    // company
    uc_id: 0,
    uc_company_name: "",
    uc_company_website: "",
    uc_company_remark1: "",
    uc_company_remark2: "",
    uc_company_cover: "",
    detail_data: [],
    detail_data2: [],
    detail_data3: [],

    youtube_path: "",
    filecore_logo: "",

    bt_id: 0,
    bt_data: [],

    list_business: [],
    defaultBusiness: {
      value: 0,
      label: "Business Type - ประเภทธุรกิจ",
    },

    country_id: 19,
    list_country: [],
    defaultCountry: {
      value: 19,
      label: "Thailand - ไทย",
    },

    tambon_id: 0,
    list_tambon: [],
    defaultTambon: {
      value: 0,
      label: "",
    },

    validateForm: false,
    openFormVerify: false,
    file_tmp1: "",
    file_name1: "",
    file_tmp2: "",
    file_name2: "",
  };
  openFormVerifySet = (bool) => {
    this.setState({ openFormVerify: bool });
  };
  refreshData = async () => {
    try {
      await axios
        .get(Common.API_URL + `user/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          let user_detail = res?.user_main_detail;
          let user_company = res?.user_main_company;
          this.setState({
            username: res.username,
            firstname: res.firstname,
            lastname: res.lastname,
            email: res.email,
            phone: res.phone,
          });

          if (user_detail !== undefined) {
            let tambon = user_detail?.user_tambon;
            let country = user_detail?.user_country;
            if (tambon !== null && tambon !== undefined && tambon !== "") {
              let label =
                tambon.tambon_eng +
                " / " +
                tambon.tambon_thai +
                " - " +
                tambon.district_eng +
                " / " +
                tambon.district_thai +
                " - " +
                tambon.province_eng +
                " / " +
                tambon.province_thai +
                " - " +
                tambon.postcode;

              this.setState({
                tambon_id: user_detail?.tambon_id,
                defaultTambon: { value: tambon.tambon_id, label: label },
              });
            }

            this.setState({
              ud_image_profile: user_detail?.ud_image_profile,
              ud_image_cover: user_detail?.ud_image_cover,
              ud_image_cover_position: user_detail?.ud_image_cover_position,
              ud_verify: user_detail?.ud_verify,
              ud_bio: user_detail?.ud_bio,
              ud_birhday: user_detail?.ud_birhday,
              ud_gender: user_detail?.ud_gender,
              ud_personal: user_detail?.ud_personal,
              ud_address: user_detail?.ud_address,
              country_id: user_detail?.country_id,
              defaultCountry: {
                value: country.country_id,
                label:
                  country.country_name_eng + " - " + country.country_name_th,
              },
              // openFormVerify: user_detail?.ud_verify === 1 ? false : true,
            });
            // console.log(user_detail?.ud_verify);
          }

          if (user_company !== undefined) {
            let label2 = user_company?.company_business.bt_name;
            let uc_id = user_company?.uc_id;
            this.getDetailA(uc_id);
            this.getDetailB(uc_id);
            this.getDetailC(uc_id);
            this.setState({
              uc_id: uc_id,
              uc_company_name: user_company?.uc_company_name,
              uc_company_website: user_company?.uc_company_website,
              uc_company_remark1: user_company?.uc_company_remark1,
              uc_company_remark2: user_company?.uc_company_remark2,
              uc_company_cover: user_company?.uc_company_cover,
              bt_id: user_company?.bt_id,
              defaultBusiness: { value: user_company?.bt_id, label: label2 },
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  readURL = (event) => {
    if (event.target.files && event.target.files[0]) {
      var output = document.getElementById("imglogo");
      output.src = URL.createObjectURL(event.target.files[0]);
      output.onload = function () {
        URL.revokeObjectURL(output.src); // free memory
      };
      this.setState({
        filecore_logo: event,
      });
    }
  };
  getBusinessType = async () => {
    try {
      await axios
        .get(Common.API_URL + "masterdata/business_type", Common.options)
        .then((response) => {
          let list = response.data;
          var arr = [];
          for (let i = 0; i < list.length; i++) {
            let obj = list[i];
            arr.push({
              value: obj.bt_id,
              label: obj.bt_name,
            });
          }
          //   console.log(arr);
          this.setState({
            list_business: arr,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getCountry = (newValue) => {
    // console.log(newValue);
    try {
      axios
        .post(
          Common.API_URL + "masterdata/country",
          {
            page: 1,
            per_page: 25,
            search_value: newValue,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          //   console.log(res.data);
          let list = res.data;
          var arr = [];
          for (let i = 0; i < list.length; i++) {
            let obj = list[i];
            arr.push({
              value: obj.country_id,
              label: obj.country_name_eng + " - " + obj.country_name_th,
            });
          }
          //   console.log(arr);
          this.setState({
            list_country: arr,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getLocation = (newValue) => {
    // console.log(inputValue);
    try {
      axios
        .post(
          Common.API_URL + "masterdata/tambon",
          {
            page: 1,
            per_page: 25,
            search_value: newValue,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          //   console.log(res.data);
          let list = res.data;
          let arr = [];
          for (let i = 0; i < list.length; i++) {
            let obj = list[i];

            // console.log(obj.rs_id);
            let label =
              obj.tambon_eng +
              " / " +
              obj.tambon_thai +
              " - " +
              obj.district_eng +
              " / " +
              obj.district_thai +
              " - " +
              obj.province_eng +
              " / " +
              obj.province_thai +
              " - " +
              obj.postcode;

            arr.push({
              value: obj.tambon_id,
              label: label,
            });
          }
          //   console.log(arr);

          new Promise((accept) => {
            setTimeout(() => {
              this.setState(
                {
                  list_tambon: arr,
                },
                accept
              );
            }, 1000);
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  setBt_id = async (e) => {
    this.setState({ bt_id: e.value, defaultBusiness: e });
  };
  setCountry_id = async (e) => {
    this.setState({ country_id: e.value, defaultCountry: e });
  };
  setLocation_id = async (e) => {
    this.setState({ tambon_id: e.value, defaultTambon: e });
  };
  onClickUploadCover = () => {
    document.getElementById("logoUpload").click();
  };

  handleSubmit = () => {
    if (
      this.state.firstname === "" ||
      this.state.lastname === "" ||
      this.state.phone === "" ||
      this.state.email === "" ||
      this.state.ud_personal === "" ||
      this.state.country_id === 0 ||
      this.state.bt_id === 0 ||
      this.state.uc_company_name === "" ||
      this.state.ud_address === "" ||
      this.state.tambon_id === 0
    ) {
      Swal.fire({
        title: lang_data?.notify_title_error,
        text: lang_data?.notify_text_error,
        icon: "error",
        confirmButtonText: lang_data?.notify_confirm_error,
      });
      this.setState({ validateForm: true });
      return false;
    }

    if (this.state.youtube_path !== "") {
      this.formYoutube();
    }
    let phone_match = String(this.state.phone)
      .replace(/\D+/g, "")
      .replace(/^1/, "")
      .match(/([^\d]*\d[^\d]*){1,10}$/)[0];

    try {
      axios
        .put(
          Common.API_URL + `userpart2/company/${user_id}`,
          {
            username: this.state.username,
            password: "", //ว่างไว้เพื่อไม่ Update Password
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: phone_match,
            active: 1,
            detail: {
              ud_image_profile: this.state.ud_image_profile,
              ud_image_cover: this.state.ud_image_cover,
              ud_image_cover_position: this.state.ud_image_cover_position,
              ud_verify: this.state.ud_verify,
              ud_bio: this.state.ud_bio,
              ud_birhday: this.state.ud_birhday,
              ud_gender: this.state.ud_gender,
              ud_personal: this.state.ud_personal,
              ud_address: this.state.ud_address,
              tambon_id: this.state.tambon_id,
              country_id: this.state.country_id,
            },
            company: {
              uc_company_name: this.state.uc_company_name,
              uc_company_website: this.state.uc_company_website,
              uc_company_remark1: this.state.uc_company_remark1,
              uc_company_remark2: this.state.uc_company_remark2,
              uc_company_cover: this.state.uc_company_cover,
              bt_id: this.state.bt_id,
            },
          },
          Common.options
        )
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          Swal.fire({
            title: lang_data?.notify_title_error,
            text: lang_data?.notify_text_error,
            icon: "error",
            confirmButtonText: lang_data?.notify_confirm_error,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit2 = (uc_company_cover) => {
    this.DeleteImage(this.state.uc_company_cover);
    if (
      this.state.firstname === "" ||
      this.state.lastname === "" ||
      this.state.phone === "" ||
      this.state.email === "" ||
      this.state.ud_personal === "" ||
      this.state.country_id === 0 ||
      this.state.bt_id === 0 ||
      this.state.uc_company_name === "" ||
      this.state.tambon_id === 0
    ) {
      Swal.fire({
        title: lang_data?.notify_title_error,
        text: lang_data?.notify_text_error,
        icon: "error",
        confirmButtonText: lang_data?.notify_confirm_error,
      });
      this.setState({ validateForm: true });
      return false;
    }
    let phone_match = String(this.state.phone)
      .replace(/\D+/g, "")
      .replace(/^1/, "")
      .match(/([^\d]*\d[^\d]*){1,10}$/)[0];
    try {
      axios
        .put(
          Common.API_URL + `userpart2/company/${user_id}`,
          {
            username: this.state.username,
            password: "", //ว่างไว้เพื่อไม่ Update Password
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: phone_match,
            active: 1,
            detail: {
              ud_image_profile: this.state.ud_image_profile,
              ud_image_cover: this.state.ud_image_cover,
              ud_image_cover_position: this.state.ud_image_cover_position,
              ud_verify: this.state.ud_verify,
              ud_bio: this.state.ud_bio,
              ud_birhday: this.state.ud_birhday,
              ud_gender: this.state.ud_gender,
              ud_personal: this.state.ud_personal,
              ud_address: this.state.ud_address,
              tambon_id: this.state.tambon_id,
              country_id: this.state.country_id,
            },
            company: {
              uc_company_name: this.state.uc_company_name,
              uc_company_website: this.state.uc_company_website,
              uc_company_remark1: this.state.uc_company_remark1,
              uc_company_remark2: this.state.uc_company_remark2,
              uc_company_cover: uc_company_cover,
              bt_id: this.state.bt_id,
            },
          },
          Common.options
        )
        .then((res) => {
          this.refreshData();
          Swal.fire({
            title: lang_data?.notify_title_success,
            text: lang_data?.notify_text_success,
            icon: "success",
            confirmButtonText: lang_data?.notify_confirm_success,
          });
          this.setState({ validateForm: false });
        })
        .catch((err) => {
          Swal.fire({
            title: lang_data?.notify_title_error,
            text: lang_data?.notify_text_error,
            icon: "error",
            confirmButtonText: lang_data?.notify_confirm_error,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  uploadLogo = async (event) => {
    let file = event.target.files[0];
    const formdata = new FormData();
    formdata.append("file", file);
    try {
      await axios
        .post(Common.API_FILE + "file/upload", formdata, Common.options2)
        .then((res) => {
          const r = res.data;
          this.handleSubmit2(r?.path);
        });
    } catch (error) {
      console.log(error);
    }
  };

  DeleteImage = async (image) => {
    if (image === "" || image === null) {
      return false;
    }
    const url = new URL(image);
    const searchParams = url.searchParams;
    const path = searchParams.get("path");
    try {
      await axios.delete(
        Common.API_FILE + `file/delete?path=${path}`,
        Common.options2
      );
    } catch (error) {
      console.log(error);
    }
  };
  onSubmitWithLogo = () => {
    this.uploadLogo(this.state.filecore_logo);
  };

  refreshDataYoutube = async () => {
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/portfolio/${user_id}?portfolio_type=2`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          if (
            res?.portfolio_path !== null &&
            res?.portfolio_path !== undefined
          ) {
            this.setState({
              youtube_path: res.portfolio_path,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmitYoutube = (portfolio_name, portfolio_path, portfolio_type) => {
    try {
      axios
        .post(
          Common.API_URL + `userpart2/portfolio/${user_id}`,
          {
            portfolio_name: portfolio_name,
            portfolio_path: portfolio_path,
            portfolio_type: portfolio_type,
            active: this.state.active,
          },
          Common.options
        )
        .then((res) => {
          this.refreshDataPDF();
          Swal.fire({
            title: lang_data?.notify_title_success,
            text: lang_data?.notify_text_success,
            icon: "success",
            confirmButtonText: lang_data?.notify_confirm_success,
          });
          return false;
        });
    } catch (error) {
      console.log(error);
    }
  };
  formYoutube = () => {
    let portfolio_name = "Portfolio Video Youtube";
    let portfolio_path = this.state.youtube_path;
    let portfolio_type = 2;
    let content = Functions.youtube_parser(portfolio_path);
    if (content === false) {
      Swal.fire({
        title: lang_data?.notify_title_error,
        text: lang_data?.notify_text_error,
        icon: "error",
        confirmButtonText: lang_data?.notify_confirm_error,
      });
      return false;
    } else {
      this.handleSubmitYoutube(portfolio_name, portfolio_path, portfolio_type);
    }
  };
  // Business Operation
  getDetailA = async (uc_id) => {
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/company/detail/${uc_id}?ucd_type=1`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({
            detail_data: res,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  // Welfare and Benefits
  getDetailB = async (uc_id) => {
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/company/detail/${uc_id}?ucd_type=2`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({
            detail_data2: res,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  // File
  getDetailC = async (uc_id) => {
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/company/detail/${uc_id}?ucd_type=3`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({
            detail_data3: res,
          });
          if (this.state.ud_verify === 1) {
            this.openFormVerifySet(false);
          } else if (this.state.ud_verify === 0 && res?.length >= 2) {
            this.openFormVerifySet(false);
          } else {
            this.openFormVerifySet(true);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  CreateDetail = (ucd_type) => {
    let set_el;
    if (ucd_type === 1) {
      set_el = "ucd_value_crt_a";
    } else {
      set_el = "ucd_value_crt_b";
    }
    let ucd_value = document.getElementById(set_el).value;
    if (ucd_value === "") {
      return false;
    }
    try {
      axios
        .post(
          Common.API_URL + "userpart2/company/detail/create",
          {
            ucd_value: ucd_value,
            ucd_type: ucd_type,
            uc_id: this.state.uc_id,
          },
          Common.options
        )
        .then((res) => {
          this.getDetailA(this.state.uc_id);
          this.getDetailB(this.state.uc_id);
          document.getElementById(set_el).value = "";
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  CreateDetail2 = (ucd_value) => {
    try {
      axios
        .post(
          Common.API_URL + "userpart2/company/detail/create",
          {
            ucd_value: ucd_value,
            ucd_type: 3,
            uc_id: this.state.uc_id,
          },
          Common.options
        )
        .then((res) => {
          this.getDetailA(this.state.uc_id);
          this.getDetailB(this.state.uc_id);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  UpdateDetail = (ucd_id, ucd_type) => {
    let set_el;
    if (ucd_type === 1) {
      set_el = "ucd_value_udp_a" + ucd_id;
    } else {
      set_el = "ucd_value_udp_b" + ucd_id;
    }
    let ucd_value = document.getElementById(set_el).value;
    try {
      axios
        .put(
          Common.API_URL + `userpart2/company/detail/${ucd_id}`,
          {
            ucd_value: ucd_value,
            ucd_type: ucd_type,
            uc_id: this.state.uc_id,
          },
          Common.options
        )
        .then((res) => {
          this.getDetailA(this.state.uc_id);
          this.getDetailB(this.state.uc_id);
          this.closeFormDetail(ucd_id, ucd_type);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  ConfirmDeleteDetail = (ucd_id) => {
    Swal.fire({
      title: lang_data?.notify_title_delete,
      text: lang_data?.notify_text_delete,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: lang_data?.notify_confirm_delete,
      cancelButtonText: lang_data?.cancelled,
    }).then((result) => {
      if (result.isConfirmed) {
        this.DeleteDetail(ucd_id);
      }
    });
  };
  DeleteDetail = (ucd_id) => {
    try {
      axios
        .delete(
          Common.API_URL + `userpart2/company/detail/${ucd_id}`,

          Common.options
        )
        .then((res) => {
          this.getDetailA(this.state.uc_id);
          this.getDetailB(this.state.uc_id);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  openFormDetail = (ucd_id, ucd_type) => {
    let show;
    let form;
    let defaule_button;
    let update_button;
    if (ucd_type === 1) {
      show = "show_detail_a" + ucd_id;
      form = "form_detail_a" + ucd_id;
      defaule_button = "default_button_a" + ucd_id;
      update_button = "update_button_a" + ucd_id;
    } else {
      show = "show_detail_b" + ucd_id;
      form = "form_detail_b" + ucd_id;
      defaule_button = "default_button_b" + ucd_id;
      update_button = "update_button_b" + ucd_id;
    }

    document.getElementById(show).style.display = "none";
    document.getElementById(form).style.display = "";

    document.getElementById(defaule_button).style.display = "none";
    document.getElementById(update_button).style.display = "";
  };

  closeFormDetail = (ucd_id, ucd_type) => {
    let show;
    let form;
    let defaule_button;
    let update_button;
    if (ucd_type === 1) {
      show = "show_detail_a" + ucd_id;
      form = "form_detail_a" + ucd_id;
      defaule_button = "default_button_a" + ucd_id;
      update_button = "update_button_a" + ucd_id;
    } else {
      show = "show_detail_b" + ucd_id;
      form = "form_detail_b" + ucd_id;
      defaule_button = "default_button_b" + ucd_id;
      update_button = "update_button_b" + ucd_id;
    }

    document.getElementById(show).style.display = "";
    document.getElementById(form).style.display = "none";

    document.getElementById(defaule_button).style.display = "";
    document.getElementById(update_button).style.display = "none";
  };

  uploadFile = async (event, name) => {
    const file = event.target.files[0];
    const formdata = new FormData();
    formdata.append("file", file);
    if (file.type !== "application/pdf") {
      Swal.fire({
        title: "Fail",
        text: "Only PDFs are valid.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return false;
    }

    try {
      await axios
        .post(Common.API_FILE + "file/upload", formdata, Common.options2)
        .then((res) => {
          const r = res.data;
          console.log(r.path);
          const path = r?.path + `?name=${name}`;
          this.CreateDetail2(path);
          this.getDetailC(this.state.uc_id);
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.getBusinessType();
    this.refreshData();
    this.refreshDataYoutube();
  }
  render() {
    const {
      list_country,
      defaultCountry,
      list_business,
      defaultBusiness,
      list_tambon,
      defaultTambon,
      youtube_path,
      validateForm,
      detail_data,
      detail_data2,
      detail_data3,
      openFormVerify,
      ud_verify,
    } = this.state;
    const totalDetail = detail_data.filter((r) => r.ucd_type === 1).length;
    const totalBenefits = detail_data.filter((r) => r.ucd_type === 2).length;
    return (
      <div>
        <h1>{lang_data?.company_form_head}</h1>
        {detail_data3?.length >= 2 && ud_verify === 0 && (
          <div>
            <Alert variant="warning">{lang_data?.staff_check_msg}</Alert>
          </div>
        )}

        <Card>
          <Card.Body>
            <Tabs defaultActiveKey="profile">
              <Tab eventKey="profile" title={lang_data?.company_form_head}>
                <Row>
                  <Col lg="6" sm="12">
                    <Form.Group>
                      <Form.Label>{lang_data?.company_name_label}</Form.Label>{" "}
                      <label style={{ color: "red" }}> *</label>{" "}
                      <Form.Control
                        type="text"
                        id="uc_company_name"
                        placeholder={lang_data?.company_name_label}
                        value={this.state.uc_company_name}
                        onChange={(e) =>
                          this.setState({ uc_company_name: e.target.value })
                        }
                        isInvalid={
                          validateForm === true &&
                          this.state.uc_company_name === ""
                            ? true
                            : false
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" sm="12">
                    <Form.Group>
                      <Form.Label>{lang_data?.business_type_label}</Form.Label>{" "}
                      <label style={{ color: "red" }}> *</label>
                      <Select
                        id="bt_id"
                        options={list_business}
                        styles={
                          validateForm === true && this.state.bt_id === 0
                            ? customStyles2
                            : customStyles
                        }
                        onChange={this.setBt_id}
                        value={defaultBusiness}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="6" sm="12">
                    <Form.Group>
                      <Form.Label>Tax ID</Form.Label>{" "}
                      <label style={{ color: "red" }}> *</label>
                      <Form.Control
                        type="number"
                        id="ud_personal"
                        defaultValue={this.state.ud_personal}
                        onChange={(e) =>
                          this.setState({ ud_personal: e.target.value })
                        }
                        isInvalid={
                          validateForm === true && this.state.ud_personal === ""
                            ? true
                            : false
                        }
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="6" sm="12">
                    <Form.Group>
                      <Form.Label>{lang_data?.web_site_label}</Form.Label>
                      <Form.Control
                        type="text"
                        id="uc_company_website"
                        placeholder={lang_data?.web_site_label}
                        defaultValue={this.state.uc_company_website}
                        onChange={(e) =>
                          this.setState({ uc_company_website: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="6" sm="12">
                    <Form.Group>
                      <Form.Label>{lang_data?.country_label}</Form.Label>{" "}
                      <label style={{ color: "red" }}> *</label>
                      <Select
                        id="country_id"
                        options={list_country}
                        styles={
                          validateForm === true && this.state.country_id === 0
                            ? customStyles2
                            : customStyles
                        }
                        onInputChange={this.getCountry}
                        onChange={this.setCountry_id}
                        value={defaultCountry}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" sm="12">
                    <Form.Group>
                      <Form.Label>{lang_data?.address_label}</Form.Label>{" "}
                      <label style={{ color: "red" }}> *</label>
                      <Form.Control
                        type="text"
                        id="ud_address"
                        placeholder={lang_data?.address_label}
                        defaultValue={this.state.ud_address}
                        onChange={(e) =>
                          this.setState({ ud_address: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" sm="12">
                    <Form.Group>
                      <Form.Label>{lang_data?.city_label}</Form.Label>{" "}
                      <label style={{ color: "red" }}> *</label>
                      <Select
                        id="tambon_id "
                        options={list_tambon}
                        onInputChange={this.getLocation}
                        styles={
                          validateForm === true && this.state.tambon_id === 0
                            ? customStyles2
                            : customStyles
                        }
                        onChange={this.setLocation_id}
                        defaultValue={defaultTambon}
                        value={defaultTambon}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group>
                      <Form.Label>
                        {/* Company Profile and Business Operation */}
                        {lang_data?.business_opeation_title}
                      </Form.Label>

                      <div>
                        <Table size="sm">
                          <tbody>
                            {detail_data?.map((rs, i) => (
                              <tr key={rs.ucd_id}>
                                <td>
                                  <div id={"show_detail_a" + rs.ucd_id}>
                                    {rs.ucd_value}
                                  </div>
                                  <div
                                    id={"form_detail_a" + rs.ucd_id}
                                    style={{ display: "none" }}
                                  >
                                    <Form.Control
                                      type="text"
                                      id={"ucd_value_udp_a" + rs.ucd_id}
                                      size="sm"
                                      style={{ height: "20px" }}
                                      defaultValue={rs.ucd_value}
                                      maxLength={128}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div id={"default_button_a" + rs.ucd_id}>
                                    <Button
                                      size="sm"
                                      variant="warning"
                                      onClick={() =>
                                        this.openFormDetail(rs.ucd_id, 1)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-pencil-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                      </svg>
                                    </Button>{" "}
                                    <Button
                                      size="sm"
                                      variant="danger"
                                      onClick={() =>
                                        this.ConfirmDeleteDetail(rs.ucd_id)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-trash-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                      </svg>
                                    </Button>
                                  </div>
                                  <div
                                    id={"update_button_a" + rs.ucd_id}
                                    style={{ display: "none" }}
                                  >
                                    <Button
                                      size="sm"
                                      variant="secondary"
                                      onClick={() =>
                                        this.closeFormDetail(rs.ucd_id, 1)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-dash-lg"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
                                        />
                                      </svg>
                                    </Button>{" "}
                                    <Button
                                      size="sm"
                                      variant="success"
                                      onClick={() =>
                                        this.UpdateDetail(rs.ucd_id, 1)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-check"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                      </svg>
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>
                                <Form.Control
                                  type="text"
                                  id="ucd_value_crt_a"
                                  size="sm"
                                  style={{ height: 20 }}
                                  maxLength={128}
                                />
                              </td>
                              <td>
                                <Button
                                  size="sm"
                                  disabled={
                                    this.state.uc_id === 0 || totalDetail >= 12
                                      ? true
                                      : false
                                  }
                                  onClick={() => this.CreateDetail(1)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-check"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                  </svg>
                                </Button>
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group>
                      <Form.Label>
                        {/* Welfare and Benefits */}
                        {lang_data?.welfare_benefits_title}
                      </Form.Label>
                      <div>
                        <Table size="sm">
                          <tbody>
                            {detail_data2?.map((rs, i) => (
                              <tr key={rs.ucd_id}>
                                <td>
                                  <div id={"show_detail_b" + rs.ucd_id}>
                                    {rs.ucd_value}
                                  </div>
                                  <div
                                    id={"form_detail_b" + rs.ucd_id}
                                    style={{ display: "none" }}
                                  >
                                    <Form.Control
                                      type="text"
                                      id={"ucd_value_udp_b" + rs.ucd_id}
                                      size="sm"
                                      style={{ height: "20px" }}
                                      defaultValue={rs.ucd_value}
                                      maxLength={256}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div id={"default_button_b" + rs.ucd_id}>
                                    <Button
                                      size="sm"
                                      variant="warning"
                                      onClick={() =>
                                        this.openFormDetail(rs.ucd_id, 2)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-pencil-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                      </svg>
                                    </Button>{" "}
                                    <Button
                                      size="sm"
                                      variant="danger"
                                      onClick={() =>
                                        this.ConfirmDeleteDetail(rs.ucd_id)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-trash-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                      </svg>
                                    </Button>
                                  </div>
                                  <div
                                    id={"update_button_b" + rs.ucd_id}
                                    style={{ display: "none" }}
                                  >
                                    <Button
                                      size="sm"
                                      variant="secondary"
                                      onClick={() =>
                                        this.closeFormDetail(rs.ucd_id, 2)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-dash-lg"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
                                        />
                                      </svg>
                                    </Button>{" "}
                                    <Button
                                      size="sm"
                                      variant="success"
                                      onClick={() =>
                                        this.UpdateDetail(rs.ucd_id, 2)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-check"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                      </svg>
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>
                                <Form.Control
                                  type="text"
                                  id="ucd_value_crt_b"
                                  size="sm"
                                  style={{ height: "20px" }}
                                  maxLength={256}
                                />
                              </td>
                              <td>
                                <Button
                                  size="sm"
                                  disabled={
                                    this.state.uc_id === 0 ||
                                    totalBenefits >= 12
                                      ? true
                                      : false
                                  }
                                  onClick={() => this.CreateDetail(2)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-check"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                  </svg>
                                </Button>
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6" sm="12">
                    <Form.Group>
                      <Form.Label> {lang_data?.contact_name}</Form.Label>{" "}
                      <label style={{ color: "red" }}> *</label>
                      <InputGroup>
                        <Form.Control
                          placeholder={lang_data?.first_name}
                          id="firstname"
                          defaultValue={this.state.firstname}
                          onChange={(e) =>
                            this.setState({ firstname: e.target.value })
                          }
                          isInvalid={
                            validateForm === true && this.state.firstname === ""
                              ? true
                              : false
                          }
                        />
                        <Form.Control
                          placeholder={lang_data?.last_name}
                          id="lastname"
                          defaultValue={this.state.lastname}
                          onChange={(e) =>
                            this.setState({ lastname: e.target.value })
                          }
                          isInvalid={
                            validateForm === true && this.state.lastname === ""
                              ? true
                              : false
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col lg="6" sm="12">
                    <Form.Group>
                      <Form.Label>{lang_data?.email_label}</Form.Label>{" "}
                      <label style={{ color: "red" }}> *</label>
                      <Form.Control
                        type="email"
                        id="email"
                        placeholder={lang_data?.email_label}
                        defaultValue={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        isInvalid={
                          validateForm === true && this.state.email === ""
                            ? true
                            : false
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" sm="12">
                    <Form.Group>
                      <Form.Label>{lang_data?.phone_number_label}</Form.Label>{" "}
                      <label style={{ color: "red" }}> *</label>
                      <Form.Control
                        type="text"
                        id="phone"
                        placeholder={lang_data?.phone_number_label}
                        value={Functions.formatPhoneNumber(this.state.phone)}
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                        isInvalid={
                          validateForm === true && this.state.phone === ""
                            ? true
                            : false
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <h4>{lang_data?.video_presentation_company}</h4>
                <Row>
                  <Col>
                    <InputGroup>
                      <InputGroup.Text id="inputGroup-sizing-default">
                        URL Youtube
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) =>
                          this.setState({ youtube_path: e.target.value })
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <div>
                  {youtube_path !== "" && (
                    <ReactPlayer
                      url={youtube_path}
                      previewTabIndex={0}
                      controls={true}
                      playsinline={false}
                      width={"100%"}
                      height={450}
                    />
                  )}
                </div>

                <div style={{ paddingTop: "50px" }}>
                  <div align="right" style={{ paddingTop: "25px" }}>
                    <Button
                      variant="primary"
                      size="lg"
                      onClick={this.handleSubmit}
                    >
                      {lang_data?.save}
                    </Button>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="logo"
                title={lang_data?.compay_logo}
                align="center"
              >
                <div style={{ paddingTop: "50px" }}>
                  <Image
                    src={
                      this.state.uc_company_cover !== ""
                        ? this.state.uc_company_cover
                        : EmptyImageProfile
                    }
                    style={{ width: "150px", height: "150px" }}
                    thumbnail
                    id="imglogo"
                  />

                  <Form.Control
                    type="file"
                    size="sm"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => this.readURL(e)}
                    id="logoUpload"
                    style={{ display: "none" }}
                  />
                </div>
                <div style={{ paddingTop: "15px" }}>
                  <Button
                    variant="outline-primary"
                    onClick={this.onClickUploadCover}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-upload"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                    </svg>{" "}
                    {lang_data?.logo_upload}
                  </Button>
                </div>
                <div style={{ paddingTop: "50px" }}>
                  <div align="right" style={{ paddingTop: "25px" }}>
                    <Button
                      variant="primary"
                      size="lg"
                      onClick={this.onSubmitWithLogo}
                    >
                      {lang_data?.save}
                    </Button>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
        {detail_data3?.length < 2 && (
          <div>
            <Modal show={openFormVerify}>
              <Modal.Header>
                <Modal.Title> {lang_data?.document_upload_title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>{lang_data?.document_upload_text1}</Form.Label>

                  <Form.Control
                    type="file"
                    id="file_tmp1"
                    // onChange={(e) => this.uploadFile(e, "หนังสือรับรองบริษัท")}
                    onChange={(e) => {
                      let file = e.target.files[0];
                      if (file.type !== "application/pdf") {
                        Swal.fire({
                          title: "Fail",
                          text: "Only PDFs are valid.",
                          icon: "error",
                          confirmButtonText: "OK",
                        });
                        return false;
                      }
                      new Promise((accept) => {
                        setTimeout(() => {
                          this.setState(
                            {
                              file_tmp1: e,
                              file_name1: lang_data?.document_upload_text1,
                            },
                            accept
                          );
                        }, 1000);
                      });
                    }}
                    accept="application/pdf"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>{lang_data?.document_upload_text2}</Form.Label>

                  <Form.Control
                    type="file"
                    id="file2"
                    // onChange={(e) => this.uploadFile(e, "ภ.พ 20")}
                    onChange={(e) => {
                      let file = e.target.files[0];
                      if (file.type !== "application/pdf") {
                        Swal.fire({
                          title: "Fail",
                          text: "Only PDFs are valid.",
                          icon: "error",
                          confirmButtonText: "OK",
                        });
                        return false;
                      }
                      new Promise((accept) => {
                        setTimeout(() => {
                          this.setState(
                            {
                              file_tmp2: e,
                              file_name2: lang_data?.document_upload_text2,
                            },
                            accept
                          );
                        }, 1000);
                      });
                    }}
                    accept="application/pdf"
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                {/* <Button
                  variant="secondary"
                  onClick={() => this.setState({ openFormVerify: false })}
                  disabled={detail_data3?.length > 0 ? true : false}
                >
                  ปิด
                </Button> */}

                <Button
                  variant="secondary"
                  onClick={() => this.setState({ openFormVerify: false })}
                  disabled={
                    this.state.file_tmp1 !== "" || this.state.file_tmp2 !== ""
                      ? true
                      : false
                  }
                >
                  {lang_data?.close}
                </Button>
                <Button
                  variant="primary"
                  onClick={() => [
                    this.uploadFile(
                      this.state.file_tmp1,
                      this.state.file_name1
                    ),
                    this.uploadFile(
                      this.state.file_tmp2,
                      this.state.file_name2
                    ),
                    Swal.fire({
                      title: lang_data?.notify_title_success,
                      text: lang_data?.notify_text_success,
                      icon: "success",
                      confirmButtonText: lang_data?.notify_confirm_success,
                    }),
                    this.setState({
                      file_tmp1: "",
                      file_name1: "",
                      file_tmp2: "",
                      file_name2: "",
                    }),
                  ]}
                  disabled={
                    this.state.file_tmp1 === "" || this.state.file_tmp2 === ""
                      ? true
                      : false
                  }
                >
                  {lang_data?.save}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}
