import React, { Component } from "react";
import ProfileMain from "./ProfileMain";
import CalendarEvent from "../component_general/CalendarEvent";
export default class ProfileCalendar extends Component {
  render() {
    return (
      <div>
        <div>
          <ProfileMain />
        </div>
        <div>
          <CalendarEvent />
        </div>
      </div>
    );
  }
}
