import React, { Component } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import axios from "axios";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const user_id_authen = Common.token;
export default class AlertList extends Component {
  state = {
    search_value: "",
    page: 1,
    per_page: 25,
    data: [],
    param: [],
  };
  getAlert = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `general/alert/list/${user_id_authen}?accept=3`,
          {
            page: this.state.page,
            per_page: this.state.per_page,
            search_value: this.state.search_value,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;

          this.setState({ data: res.data, param: res });
        });
    } catch (error) {
      console.log(error);
    }
  };
  setAlertAccept = (alert_id, page) => {
    try {
      axios
        .get(Common.API_URL + `general/alert/set/${alert_id}`, Common.options)
        .then((res) => {
          window.location.href = page;
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.getAlert();
  }

  render() {
    const { data, page, param } = this.state;
    return (
      <div>
        <Row style={{ paddingBottom: "30px" }}>
          <Col sm={9}>
            {lang_data?.all} {param.total_filter_data}{" "}
          </Col>
          <Col sm={3}>
            <Form.Control
              type="text"
              placeholder={lang_data?.search}
              onChange={(e) => [
                this.setState({
                  search_value: e.target.value,
                }),
                this.getAlert(),
              ]}
              onKeyUp={(e) => [
                this.setState({
                  search_value: e.target.value,
                }),
                this.getAlert(),
              ]}
            />
          </Col>
        </Row>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th> {lang_data?.date_label}</th>
              <th>{lang_data?.alet_message}</th>
              <th>{lang_data?.target_list}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((rs, index) => (
              <tr key={index}>
                <td> {Functions.format_date_time(rs.create_date)}</td>
                <td>{rs?.alert_description}</td>
                <td>
                  {" "}
                  <Button
                    variant="link"
                    onClick={() =>
                      this.setAlertAccept(rs?.alert_id, rs?.alert_route_page)
                    }
                  >
                    Click
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div style={{ paddingTop: "70px" }} align="center">
          <div style={{ width: "200px" }}>
            <InputGroup className="mb-3" size="sm">
              <InputGroup.Text>{lang_data?.page}</InputGroup.Text>
              <Form.Control
                type="number"
                defaultValue={page}
                onChange={(e) => [
                  this.setState({
                    page: e.target.value,
                  }),
                  this.getAlert(),
                ]}
                onKeyUp={(e) => [
                  this.setState({
                    page: e.target.value,
                  }),
                  this.getAlert(),
                ]}
                onClick={(e) => [
                  this.setState({
                    page: e.target.value,
                  }),
                  this.getAlert(),
                ]}
                style={{ textAlign: "center" }}
              />
              <InputGroup.Text>
                {lang_data?.total_page} : {param.total_page}{" "}
              </InputGroup.Text>
            </InputGroup>
          </div>
        </div>
      </div>
    );
  }
}
