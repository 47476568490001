import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  Button,
  Alert,
  Form,
  Image,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import Common from "../common";
import Swal from "sweetalert2";
import styled from "styled-components";
import Logo from "../asset/images/Jinjijob4.png";
import info from "../asset/images/vnu_l680_3.webp";
import lang from "../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const textbox_radius = { borderRadius: "40px" };
const CardStyle = styled.div`
  .animate {
    // background-image: url("https://i.pinimg.com/736x/26/af/cc/26afcc461a3b9832cc9ab3a478f28f87.jpg");
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
  }
  .animate::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // background: rgba(0, 0, 0, 0.1);
  }
`;

const navStyle = {
  fontSize: "24px",
  fontWeight: "bold",
};
const linkStyle = {
  color: "#0E4C81",
  paddingLeft: "50px",
};

const present_day = new Date().toISOString().split("T")[0];
export default class RegisterCandidate extends Component {
  state = {
    country_id: 19,
    list_country: [],
    defaultCountry: {
      value: 19,
      label: "Thailand - ไทย",
    },
    msg: "",
    password_confirm: "",
    email_confirm: "",
    // main
    user_id: 0,
    username: "",
    password: "",
    firstname: "",
    lastname: "",
    user_type: 3,
    email: "",
    phone: "",
    active: 1,

    // detail
    ud_bio: "",
    ud_birhday: null,
    ud_address: "",
    openFormPrivacy: true,

    // pdpa
    pdpa_con1: 0,
    pdpa_con2: 0,

    show_password1: false,
    show_password2: false,
  };
  getCountry = (newValue) => {
    // console.log(newValue);
    try {
      axios
        .post(
          Common.API_URL + "masterdata/country",
          {
            page: 1,
            per_page: 25,
            search_value: newValue,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          //   console.log(res.data);
          let list = res.data;
          var arr = [];
          for (let i = 0; i < list.length; i++) {
            let obj = list[i];
            arr.push({
              value: obj.country_id,
              label:
                obj.country_name_eng + " - " + obj.country_official_name_eng,
            });
          }
          //   console.log(arr);
          this.setState({
            list_country: arr,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmit = () => {
    if (
      this.state.username === "" ||
      this.state.firstname === "" ||
      this.state.lastname === "" ||
      this.state.password === "" ||
      this.state.email === "" ||
      this.state.phone === ""
    ) {
      // this.setState({ msg: "Invalid data" });
      Swal.fire({
        title: lang_data?.notify_title_error,
        text: lang_data?.notify_text_invalid_data,
        icon: "error",
        confirmButtonText: lang_data?.notify_confirm_success,
      });
      return false;
    }

    if (this.state.password !== this.state.password_confirm) {
      // this.setState({ msg: "Passwords did not match" });
      Swal.fire({
        title: lang_data?.notify_title_error,
        text: lang_data?.notify_text_password2,
        icon: "error",
        confirmButtonText: lang_data?.notify_confirm_success,
      });
      return false;
    }
    if (this.state.password.length < 8) {
      Swal.fire({
        title: lang_data?.notify_title_error,
        text: lang_data?.notify_text_password1,
        icon: "error",
        confirmButtonText: lang_data?.notify_confirm_success,
      });
      return false;
    }

    if (this.state.phone.length < 10) {
      Swal.fire({
        title: lang_data?.notify_title_error,
        text: lang_data?.notify_phone_number,
        icon: "error",
        confirmButtonText: lang_data?.notify_confirm_success,
      });
      return false;
    }

    try {
      axios
        .post(
          Common.API_URL + "userpart2/register/candidate/create",
          {
            username: this.state.username,
            password: this.state.password,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: this.state.phone,
            active: 1,
            detail: {
              ud_image_profile: "",
              ud_image_cover: "",
              ud_image_cover_position: "",
              ud_verify: 0,
              ud_bio: "",
              ud_birhday: present_day,
              ud_gender: 0,
              ud_personal: "",
              ud_address: "",
              tambon_id: 7437, //id กัน bug
              country_id: this.state.country_id,
            },
          },
          Common.options
        )
        .then((res) => {
          const r = res.data;
          localStorage.setItem("token", r?.user_id);
          localStorage.setItem("user_type", r?.user_type);
          window.location = "/";
        })
        .catch((err) => {
          // Handle error
          Swal.fire({
            title: lang_data?.notify_title_error,
            text: lang_data?.notify_user_acconut,
            icon: "error",
            confirmButtonText: lang_data?.notify_confirm_success,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  setZero = (value) => {
    let num = 0;
    if (value === 1) {
      num = 0;
    } else if (value === 0) {
      num = 1;
    }
    return num;
  };

  render() {
    const {
      openFormPrivacy,
      pdpa_con1,
      pdpa_con2,
      show_password1,
      show_password2,
    } = this.state;
    return (
      <div>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
          <Container>
            <Navbar.Brand href="/">
              <Image
                variant="top"
                src={Logo}
                style={{ width: "85px", height: "60px" }}
              />
            </Navbar.Brand>

            <Nav className="justify-content-end" style={navStyle}>
              <Nav.Link href="/login" style={linkStyle}>
                {/* <span style={{ textTransform: "uppercase" }}>{app_name}</span> */}
                {lang_data?.login}
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar>

        <Container>
          <CardStyle>
            <Row>
              <Col lg="6" md="12" sm="12">
                <div
                  className="animate"
                  style={{ backgroundImage: `url(${info})` }}
                ></div>
              </Col>
              <Col lg="6" md="12" sm="12">
                <div style={{ paddingTop: 10 }}>
                  <div align="center">
                    <Alert variant="info">
                      <h1> {lang_data?.register_header_candidate}</h1>
                    </Alert>
                  </div>

                  <Form.Group className="mb-3">
                    <Row>
                      <Col>
                        <div className="form-inside left-inner-addon">
                          <Form.Control
                            type="text"
                            placeholder={lang_data?.account_name}
                            id="username"
                            style={textbox_radius}
                            onChange={(e) =>
                              this.setState({ username: e.target.value })
                            }
                            maxLength={10}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-person-fill-lock icon-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h5v-1a1.9 1.9 0 0 1 .01-.2 4.49 4.49 0 0 1 1.534-3.693C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Zm7 0a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-2Zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1Z" />
                          </svg>
                        </div>
                      </Col>
                      <Col>
                        <div className="form-inside left-inner-addon">
                          <Form.Control
                            type="text"
                            placeholder="example : 0987654321"
                            id="phone"
                            style={textbox_radius}
                            onChange={(e) =>
                              this.setState({ phone: e.target.value })
                            }
                            maxLength={16}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-telephone-fill icon-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                            />
                          </svg>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col>
                        <div className="form-inside left-inner-addon">
                          <Form.Control
                            type="text"
                            placeholder={lang_data?.first_name}
                            id="firstname"
                            style={textbox_radius}
                            onChange={(e) =>
                              this.setState({ firstname: e.target.value })
                            }
                            maxLength={36}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-person-fill icon-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                          </svg>
                        </div>
                      </Col>
                      <Col>
                        <div className="form-inside left-inner-addon">
                          <Form.Control
                            type="text"
                            placeholder={lang_data?.first_last_namename}
                            id="lastname"
                            style={textbox_radius}
                            onChange={(e) =>
                              this.setState({ lastname: e.target.value })
                            }
                            maxLength={36}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-person-fill icon-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                          </svg>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <div className="form-inside left-inner-addon">
                      <Form.Control
                        type="text"
                        placeholder={lang_data?.email_label}
                        id="email"
                        style={textbox_radius}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        maxLength={36}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-envelope icon-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                      </svg>
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <div className="form-inside left-inner-addon">
                      <Form.Control
                        type={show_password1 === false ? "password" : "text"}
                        placeholder={lang_data?.password_label}
                        id="password"
                        style={textbox_radius}
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                      />
                      {show_password1 === false ? (
                        <span
                          onClick={() =>
                            this.setState({ show_password1: true })
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-eye-slash-fill icon-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                          </svg>
                        </span>
                      ) : (
                        <span
                          onClick={() =>
                            this.setState({ show_password1: false })
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-eye-fill icon-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                          </svg>
                        </span>
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="form-inside left-inner-addon">
                      <Form.Control
                        type={show_password2 === false ? "password" : "text"}
                        id="password_confirm"
                        placeholder={lang_data?.confirm_password}
                        style={textbox_radius}
                        onChange={(e) =>
                          this.setState({ password_confirm: e.target.value })
                        }
                      />
                      {show_password2 === false ? (
                        <span
                          onClick={() =>
                            this.setState({ show_password2: true })
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-eye-slash-fill icon-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                          </svg>
                        </span>
                      ) : (
                        <span
                          onClick={() =>
                            this.setState({ show_password2: false })
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-eye-fill icon-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                          </svg>
                        </span>
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3" align="center">
                    <Button variant="success" onClick={this.handleSubmit}>
                      {lang_data?.register_member}
                    </Button>
                  </Form.Group>
                </div>
              </Col>
            </Row>
          </CardStyle>
        </Container>

        <Modal show={openFormPrivacy} animation={false}>
          <Modal.Body>
            <div align="center">
              <Image
                variant="top"
                src={require("../asset/images/shield.png")}
                style={{ width: 75, height: 75 }}
              />
              <span style={{ fontWeight: "bold" }}>
                <pre> {lang_data?.condition_text1}</pre>
              </span>
            </div>

            <div>
              <iframe
                src="https://drive.google.com/file/d/1m_fTqTphjiNSSx6iECuN6PY5RCoG2UO-/preview"
                style={{ width: "100%", height: 480 }}
                title="pdpa"
              ></iframe>
            </div>

            <Form.Group>
              <Form.Check>
                <Form.Check.Input
                  type="checkbox"
                  isValid
                  onClick={() =>
                    this.setState({
                      pdpa_con1: this.setZero(pdpa_con1),
                    })
                  }
                />
                <Form.Check.Label style={{ fontSize: 12 }}>
                  {lang_data?.condition_text2}
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <Form.Group>
              <Form.Check>
                <Form.Check.Input
                  type="checkbox"
                  isValid
                  onClick={() =>
                    this.setState({
                      pdpa_con2: this.setZero(pdpa_con2),
                    })
                  }
                />
                <Form.Check.Label style={{ fontSize: 12 }}>
                  {lang_data?.condition_text3}
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <Form.Group>
              <Form.Check>
                <Form.Check.Input type="checkbox" isValid />
                <Form.Check.Label style={{ fontSize: 12 }}>
                  {lang_data?.condition_text4}
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <div className="d-grid gap-2">
              <Button
                variant="primary"
                onClick={() =>
                  this.setState({
                    openFormPrivacy: false,
                  })
                }
                disabled={pdpa_con1 === 1 && pdpa_con2 === 1 ? false : true}
              >
                {lang_data?.accept}
              </Button>
              <Button variant="secondary" href="/">
                {lang_data?.unaccept}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
