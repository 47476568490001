import React, { Component } from "react";
import { Row, Col, Button, Modal, Image, Card } from "react-bootstrap";
import axios from "axios";
import Common from "../../common";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
export default class ViewAsCandidateRewards extends Component {
  state = {
    data: [],
    isOpenModalPreview: false,

    reward_file_path: "",
  };
  refreshData = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/rewards/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.refreshData();
  }
  render() {
    const { data, isOpenModalPreview } = this.state;

    return (
      <div style={{ paddingTop: 25 }}>
        <Card>
          <Card.Body>
            <Card.Title> {lang_data?.reward_certificate}</Card.Title>
            <div>
              {data?.map((rs, index) => (
                <div
                  style={{
                    paddingTop: "20px",
                  }}
                  key={index}
                >
                  <Row>
                    <Col lg="10" md="6">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="3232"
                          fill="currentColor"
                          className="bi bi-emoji-smile-fill icon-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />
                        </svg>
                        <span
                          style={{
                            padding: "10px",
                            fontSize: "32px",
                          }}
                        >
                          {rs.reward_name}
                        </span>
                      </div>
                    </Col>
                    <Col lg="2">
                      {rs.reward_file_path !== "" ? (
                        <div style={{ padding: "15px" }}>
                          <Button
                            variant="outline-success"
                            size="sm"
                            onClick={() =>
                              this.setState({
                                reward_file_path: rs.reward_file_path,
                                isOpenModalPreview: true,
                              })
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-card-image"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                              <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                            </svg>
                          </Button>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </div>
              ))}

              <Modal
                size="lg"
                show={isOpenModalPreview}
                onHide={() => this.setState({ isOpenModalPreview: false })}
              >
                <Modal.Body>
                  {this.state.reward_file_path !== "" ? (
                    <Image
                      id="img_preview"
                      thumbnail
                      src={this.state.reward_file_path}
                      style={{ width: "100%", height: "auto" }}
                    />
                  ) : (
                    <Image
                      id="img_preview"
                      thumbnail
                      src={require("../../asset/images/6189389_3204121.jpg")}
                      style={{ width: "100%", height: "auto" }}
                    />
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => this.setState({ isOpenModalPreview: false })}
                    variant="secondary"
                  >
                    {lang_data?.close}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
