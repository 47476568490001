import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  Row,
  Col,
  Form,
  Table,
  ButtonGroup,
  Card,
  Alert,
  Modal,
} from "react-bootstrap";

import Common from "../../common";
import Functions from "../../functions";
import axios from "axios";
import Swal from "sweetalert2";
export default class MasterDataSkill extends Component {
  state = {
    data: [],
    isOpenModal: false,
    msg: "",
    skill_id: 0,
    skill_name: "",
    description: "",
    skill_group_type: 0,
    active: 1,

    typeskill: 0,
  };
  refreshData = async () => {
    try {
      await axios
        .get(
          Common.API_URL + `masterdata/skill?typeskill=${this.state.typeskill}`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
          //   console.log(res.data);
          //   alert(JSON.stringify(res.data));
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = () => {
    if (this.state.skill_name === "" || this.state.skill_group_type === 0) {
      this.setState({ msg: "Invalid data" });
      return false;
    }
    try {
      axios
        .post(
          Common.API_URL + "masterdata/skill/create",
          {
            skill_name: this.state.skill_name,
            description: this.state.description,
            skill_group_type: this.state.skill_group_type,
            active: this.state.active,
          },
          Common.options
        )
        .then((res) => {
          this.setState({ isOpenModal: false });
          this.refreshData();
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleClickEdit = (res) => {
    let r = res;
    this.setState({
      skill_id: r.skill_id,
      skill_name: r.skill_name,
      description: r.description,
      skill_group_type: r.skill_group_type,
      active: r.active,
      msg: "",
    });
  };

  handleSubmitEdit = () => {
    if (this.state.skill_name === "" || this.state.skill_group_type === 0) {
      this.setState({ msg: "Invalid data" });
      return false;
    }
    try {
      axios
        .put(
          Common.API_URL + `masterdata/skill/${this.state.skill_id}`,
          {
            skill_name: this.state.skill_name,
            description: this.state.description,
            skill_group_type: this.state.skill_group_type,
            active: this.state.active,
          },
          Common.options
        )
        .then((res) => {
          this.refreshData();
          this.clearState();
          this.setState({ isOpenModal: false });
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDeleteSubmit = async (skill_id) => {
    Swal.fire({
      title: "ยืนยันการลบ",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่, ต้องการลบข้อมูลนี้",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(
              Common.API_URL + `masterdata/skill/${skill_id}`,
              Common.options
            )
            .then((res) => {
              this.clearState();
              this.refreshData();

              Swal.fire({
                title: "เรียบร้อย",
                text: "ทำรายการสำเร็จ",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  clearState = () => {
    this.setState({
      skill_id: 0,
      skill_name: "",
      description: "",
      skill_group_type: 0,
      active: 1,
      msg: "",
    });
  };
  formatGropSkill = (value) => {
    let res;
    if (value === 1) {
      res = "Computer Skill";
    } else if (value === 2) {
      res = "Competency Skill";
    } else if (value === 3) {
      res = "General";
    } else {
      res = "-";
    }
    return res;
  };
  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { data, isOpenModal, msg, skill_id } = this.state;

    return (
      <div>
        <Row>
          <Col sm={8}>
            <h3>ความสามารถ / ทักษะ</h3>
          </Col>
          <Col sm={4}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">หน้าหลัก</Breadcrumb.Item>

              <Breadcrumb.Item active>ความสามารถ / ทักษะ</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Card border="info">
          <Card.Header>
            <Row>
              <Col sm={8}>ความสามารถ / ทักษะ</Col>
              <Col sm={4}>
                <div align="right">
                  <Button
                    onClick={(e) => [
                      this.setState({ isOpenModal: true }),
                      this.clearState(),
                    ]}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                    </svg>{" "}
                    เพิ่มข้อมูล
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Table striped>
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th>ความสามารถ / ทักษะ</th>
                  <th>ประเภท</th>
                  <th>รายละเอียด</th>
                  <th>วันทีสร้าง</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((rs, index) => (
                  <tr key={index}>
                    <td align="center">{index + 1}</td>
                    <td>{rs.skill_name}</td>
                    <td>{this.formatGropSkill(rs.skill_group_type)}</td>
                    <td>{rs.description}</td>
                    <td>{Functions.format_date_time(rs.create_date)}</td>
                    <td align="center">
                      <ButtonGroup aria-label="Basic example">
                        <Button
                          variant="warning"
                          onClick={(e) => [
                            this.setState({
                              isOpenModal: true,
                            }),
                            this.handleClickEdit(rs),
                          ]}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                          </svg>{" "}
                          แก้ไข
                        </Button>

                        <Button
                          variant="danger"
                          onClick={(e) => this.handleDeleteSubmit(rs.skill_id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash3-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                          </svg>{" "}
                          ลบ
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        {/* Main */}
        <Modal
          show={isOpenModal}
          onHide={() => this.setState({ isOpenModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title> ความสามารถ / ทักษะ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {msg !== "" && <Alert variant="danger">{msg}</Alert>}
            <Form.Group className="mb-3">
              <Form.Label>ประเภท</Form.Label>
              <label style={{ color: "red" }}> *</label>
              <Form.Select
                onChange={(e) =>
                  this.setState({ skill_group_type: e.target.value })
                }
                defaultValue={this.state.skill_group_type}
              >
                <option value="0">--เลือก--</option>
                <option value="1">Computer Skills</option>
                <option value="2">Competency Skills</option>
                <option value="3">General</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>ความสามารถ / ทักษะ</Form.Label>
              <label style={{ color: "red" }}> *</label>
              <Form.Control
                type="text"
                required
                onChange={(e) => this.setState({ skill_name: e.target.value })}
                defaultValue={this.state.skill_name}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>รายละเอียด</Form.Label>

              <Form.Control
                type="text"
                required
                onChange={(e) => this.setState({ description: e.target.value })}
                defaultValue={this.state.description}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => this.setState({ isOpenModal: false })}
            >
              ปิด
            </Button>
            <Button
              variant="primary"
              onClick={
                skill_id === 0 ? this.handleSubmit : this.handleSubmitEdit
              }
            >
              บันทึก
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
