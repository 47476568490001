import React, { Component } from "react";
import Common from "../../common";
import axios from "axios";
import { Chart } from "react-google-charts";
import { Form, Row, Col } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const d = new Date();
const year_present = d.getFullYear();
const list_year = Functions.get_year_option(year_present - 25, year_present);
const user_id = Common.token;
const defaultData = [
  [
    `${lang_data?.year_label}`,
    `${lang_data?.total_candidate}`,
    `${lang_data?.total_candidate_interview}`,
    `${lang_data?.total_candidate_interview_pass}`,
  ],
  //   ["2014", 1000, 400, 200],
  //   ["2015", 1170, 460, 250],
  //   ["2016", 660, 1120, 300],
  //   ["2017", 1030, 540, 350],
];
// const options = {
//   chart: {
//     title: "Company Performance",
//     subtitle: "Sales, Expenses, and Profit: 2014-2017",
//   },
// };
const options = {
  colors: ["#10b9e3", "#e3a010", "#1be31f"],
};
export default class BarChartJobFunctions extends Component {
  state = {
    year: year_present,
    jbp_id: 0,

    data: [],
  };
  refreshData = async () => {
    try {
      await axios
        .get(
          Common.API_URL +
            `dashboard/chart/job/apply/${user_id}/${this.state.jbp_id}/${this.state.year}`,
          Common.options
        )
        .then((response) => {
          const res = response.data;
          let obj = [];

          for (let i = 0; i < res.length; i++) {
            let el = res[i];
            let newFormat = [
              Functions.month_text_format(parseInt(el?.month) - 1, "th"),
              el?.total_apply,
              el?.total_interview,
              el?.total_interview_pass,
            ];
            obj.push(newFormat);
          }
          this.setState({
            data: obj,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getJobFunctionMasterData = async (search) => {
    const response = await axios.get(
      Common.API_URL + "masterdata/job_parent?search=" + search,
      Common.options
    );
    const list = response.data;
    let arr = [];
    for (let i = 0; i < list.length; i++) {
      let obj = list[i];
      arr.push({
        value: obj.jbp_id,
        label: obj.jbp_name,
      });
    }
    const options = arr;
    return options;
  };

  filterYear = async (e) => {
    await new Promise((accept) => {
      this.setState(
        {
          year: e.target.value,
        },
        accept
      );
    });
    this.refreshData();
  };
  filterJob = async (e) => {
    await new Promise((accept) => {
      this.setState(
        {
          jbp_id: e.value,
        },
        accept
      );
    });
    this.refreshData();
  };
  componentDidMount() {
    this.refreshData();
  }
  render() {
    const { data, year } = this.state;
    return (
      <div>
        <div style={{ paddingTop: 10, paddingBottom: 15 }}>
          <Row>
            <Col>
              <AsyncSelect
                placeholder={lang_data?.job_category}
                cacheOptions
                defaultOptions
                loadOptions={this.getJobFunctionMasterData}
                getOptionLabel={(option) => option.label}
                onChange={this.filterJob}
              />
            </Col>
            <Col>
              <Form.Select onChange={this.filterYear} defaultValue={year}>
                <option>{lang_data?.year_label}</option>
                {list_year.map((value, index) => (
                  <option value={value} key={index}>
                    {value}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </div>

        <Chart
          chartType="Bar"
          width="100%"
          height="400px"
          data={defaultData.concat(data)}
          options={options}
        />
      </div>
    );
  }
}
