import React, { Component } from "react";

import { Container, Card, Row, Col, Image } from "react-bootstrap";
import Common from "../../common";
import axios from "axios";
import BarChartJobFunctions from "../component_dashboard/BarChartJobFunctions";
// import PieChartEducation from "../component_dashboard/PieChartEducation";
import ReportPopularJobs from "../component_dashboard/ReportPopularJobs";
import ReportPopularPosition from "../component_dashboard/ReportPopularPosition";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const user_id = Common.token;
export default class Dashboard extends Component {
  state = {
    total_apply: 0,
    total_interview: 0,
    total_interview_pass: 0,
    total_job_fuctions: 0,

    //
    chart_job_fuctions: [65, 59, 80, 81, 56, 55, 40],
  };
  getTotalAll = async () => {
    try {
      await axios
        .get(Common.API_URL + `dashboard/total/all/${user_id}`, Common.options)
        .then((response) => {
          const res = response.data;

          this.setState({
            total_apply: res?.total_apply,
            total_interview: res?.total_interview,
            total_interview_pass: res?.total_interview_pass,
            total_job_fuctions: res?.total_job_fuctions,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  componentDidMount() {
    this.getTotalAll();
  }

  render() {
    const {
      total_apply,
      total_interview,
      total_interview_pass,
      total_job_fuctions,
    } = this.state;

    return (
      <div>
        <Container style={{ paddingTop: 25 }}>
          <Row>
            <Col lg="3" md="4" sm="12" style={{ padding: 15 }}>
              <Card style={{ height: 120 }}>
                <Card.Body>
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <Image
                        src={require("../../asset/images/teamwork.png")}
                        style={{ width: 50 }}
                      />
                    </Col>
                    <Col>
                      <div style={{ fontSize: 32 }}>{total_apply}</div>
                      <div>{lang_data?.total_candidate}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="3" md="4" sm="12" style={{ padding: 15 }}>
              <Card style={{ height: 120 }}>
                <Card.Body>
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <Image
                        src={require("../../asset/images/interview.png")}
                        style={{ width: 50 }}
                      />
                    </Col>
                    <Col>
                      <div style={{ fontSize: 32 }}>{total_interview}</div>
                      <div>{lang_data?.total_candidate_interview}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="3" md="4" sm="12" style={{ padding: 15 }}>
              <Card style={{ height: 120 }}>
                <Card.Body>
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <Image
                        src={require("../../asset/images/job-interview.png")}
                        style={{ width: 50 }}
                      />
                    </Col>
                    <Col>
                      <div style={{ fontSize: 32 }}>{total_interview_pass}</div>
                      <div>{lang_data?.total_candidate_interview_pass}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="3" md="4" sm="12" style={{ padding: 15 }}>
              <Card style={{ height: 120 }}>
                <Card.Body>
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <Image
                        src={require("../../asset/images/briefcase.png")}
                        style={{ width: 50 }}
                      />
                    </Col>
                    <Col>
                      <div style={{ fontSize: 32 }}>{total_job_fuctions}</div>
                      <div>{lang_data?.total_candidate_job}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="12" md="12" sm="12" style={{ padding: 15 }}>
              <Card>
                <Card.Body>
                  <Card.Title>{lang_data?.total_candidate}</Card.Title>
                  <BarChartJobFunctions />
                </Card.Body>
              </Card>
            </Col>
            <Col lg="6" md="6" sm="12" style={{ padding: 15 }}>
              <Card>
                <Card.Body>
                  <Card.Title>{lang_data?.report_top_job}</Card.Title>
                  <ReportPopularJobs />
                </Card.Body>
              </Card>
            </Col>
            <Col lg="6" md="6" sm="12" style={{ padding: 15 }}>
              <Card>
                <Card.Body>
                  <Card.Title>{lang_data?.report_posistion_job}</Card.Title>

                  <ReportPopularPosition />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
