import React, { Component, Suspense } from "react";
import {
  Badge,
  Container,
  Form,
  Image,
  Row,
  Col,
  InputGroup,
  Button,
} from "react-bootstrap";
import axios from "axios";
import styled from "styled-components";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const ProfileMain = React.lazy(() => import("./ProfileMain"));
const user_id = Common.token;
const CardStyle = styled.div`
  .feature {
    position: relative;
    width: 100%;
    // height: 28rem;
    height: auto;
    margin: 2rem auto;
    padding: 2em;
    border-radius: 0.75em;
    box-shadow: 5px 5px 20px rgba(0 0 0/0.15);
    text-align: left;
    transition: transform 200ms ease-in;
  }

  // .feature:hover {
  //   transform: scale(1.03);
  // }

  .feature__desc {
    margin-top: 0.5em;
    color: black;
  }

  .feature__img {
    position: absolute;
    bottom: 10%;
    right: 10%;
  }

  .feature-one {
    border-top: 5px solid #cdd7d9;
  }

  /* media queries */

  @media (min-width: 1000px) {
    section {
      max-width: 200rem;
    }

    .section__title {
      margin: 0 auto;
      max-width: 40%;
      font-size: 2rem;
    }

    .section__desc {
      max-width: 55ch;
      margin: 1rem auto 1rem;
    }

    .features {
      display: flex;
      gap: 2rem;
    }

    .feature-one {
      margin: auto 0;
    }
    .footer-content-left {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    }
    .footer-content-right {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 10px;
    }
  }
`;

const StepperStyle = styled.div`
  ol {
    list-style: none;
    text-align: center;
    width: 100%;
  }
  li {
    position: relative;
    display: inline-block;
    &:not(:first-child) {
      margin-left: 4em;
    }
    &:not(:first-child)::after {
      content: "";
      width: 100%;
      background: rgba(55, 235, 235, 0.5);
      position: absolute;
      height: 4px;
      top: 40%;
      left: -65%;
      z-index: -1;
    }
  }

  h2 {
    background: rgba(55, 235, 235);
    border: 0.3em solid #f5f8f9;
    border-bottom-right-radius: 2.5em;
    border-top-left-radius: 2.5em;
    color: white;
    // padding: 0.7em 1.5em;
    padding: 0.3em 1.5em;
  }

  li:not(.active) {
    h2 {
      background: #fef5f6;
      color: #e0e1ec;
    }
    p {
      color: #e0e1ec;
    }
    &::after {
      background: #fef5f6;
    }
  }
`;
export default class ProfileApplyList extends Component {
  state = {
    page: 1,
    per_page: 25,
    search_value: "",
    data: [],
    param: [],
  };
  getApplyList = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `j/job_position/apply/${user_id}`,
          {
            page: this.state.page,
            per_page: this.state.per_page,
            search_value: this.state.search_value,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({
            data: res?.data,
            param: res,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  statusFormat = (value) => {
    let f;
    if (value === 2) {
      f = <Badge bg="success">Applove </Badge>;
    } else if (value === 1) {
      f = <Badge bg="danger"> Refuse </Badge>;
    } else {
      f = <Badge bg="secondary"> In progress </Badge>;
    }
    return f;
  };

  filterSearch = async (e) => {
    await new Promise((accept) => {
      this.setState(
        {
          search_value: e.target.value,
        },
        accept
      );
    });
    this.getApplyList();
  };

  componentDidMount() {
    this.getApplyList();
  }
  render() {
    const { data, param, page } = this.state;
    return (
      <div>
        <div>
          <Suspense
            fallback={
              <div align="center">
                <Image src={require("../../asset/images/Loading_2.gif")} />
              </div>
            }
          >
            <ProfileMain />
          </Suspense>
        </div>
        <Container>
          <div style={{ paddingTop: "40px", height: "100vh" }}>
            <Row>
              <Col>
                <div align="left">
                  <h3>
                    {lang_data?.jobApplication} ({param.total_data})
                  </h3>
                </div>
              </Col>
              <Col>
                <div align="right">
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder={lang_data?.search}
                      onChange={this.filterSearch}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <CardStyle>
              {data?.map((rs, index) => (
                <div
                  key={index}
                  style={{ paddingBottom: "15px" }}
                  onClick={() =>
                    this.setState({
                      get_content: rs,
                    })
                  }
                >
                  <div className="feature feature-one">
                    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                      <StepperStyle>
                        <ol>
                          <li className={rs.jpa_step >= 0 ? "active" : null}>
                            <h2>1</h2>
                            <p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="currentColor"
                                className="bi bi-clipboard-plus-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z" />
                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm4.5 6V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5a.5.5 0 0 1 1 0Z" />
                              </svg>{" "}
                              {lang_data?.apply}
                            </p>
                          </li>
                          <li className={rs.jpa_step >= 1 ? "active" : null}>
                            <h2>2</h2>
                            <p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="currentColor"
                                className="bi bi-person-check-fill"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                                />
                                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              </svg>{" "}
                              {lang_data?.hr_accept}
                            </p>
                          </li>
                          <li className={rs.jpa_step >= 2 ? "active" : null}>
                            <h2>3</h2>
                            <p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="currentColor"
                                className="bi bi-calendar3"
                                viewBox="0 0 16 16"
                              >
                                <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                                <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                              </svg>{" "}
                              {lang_data?.appointment}
                            </p>
                          </li>
                          <li className={rs.jpa_step >= 3 ? "active" : null}>
                            <h2>4</h2>
                            <p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="currentColor"
                                className="bi bi-chat-dots-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                              </svg>{" "}
                              {lang_data?.interview}
                            </p>
                          </li>
                          <li className={rs.jpa_step >= 4 ? "active" : null}>
                            <h2>5</h2>
                            <p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="currentColor"
                                className="bi bi-check-circle-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                              </svg>{" "}
                              {rs.jpa_status === 1 && rs.jpa_step >= 4 && (
                                <span
                                  style={{ fontWeight: "bold", color: "green" }}
                                >
                                  {lang_data?.pass}
                                </span>
                              )}
                              {rs.jpa_status === 0 && rs.jpa_step >= 4 && (
                                <span
                                  style={{ fontWeight: "bold", color: "red" }}
                                >
                                  {lang_data?.fail}
                                </span>
                              )}
                              {rs.jpa_step < 4 && (
                                <span> {lang_data?.done}</span>
                              )}
                            </p>
                          </li>
                        </ol>
                      </StepperStyle>
                    </div>
                    <Row>
                      <Col>
                        <Image
                          src={
                            Functions.check_empty_value(
                              rs.jpa_job?.job_user.user_main_company
                                ?.uc_company_cover
                            ) === true
                              ? require("../../asset/images/1594898327img.png")
                              : rs.jpa_job?.job_user.user_main_company
                                  ?.uc_company_cover
                          }
                          style={{ width: "100px", height: "100px" }}
                        />
                        <div className="feature__title">
                          <span>
                            <Image
                              src={
                                require("../../asset/images/Job-functions-2.svg")
                                  .default
                              }
                              style={{ width: "32px", height: "32px" }}
                            />{" "}
                            <Button
                              variant="link"
                              href={
                                "/job/" +
                                rs.jpa_job?.jp_position +
                                "/" +
                                rs.jpa_job?.jp_id
                              }
                              target="_blank"
                            >
                              <h3>{rs.jpa_job?.job_jbc?.jbc_name}</h3>
                            </Button>
                          </span>
                        </div>
                        <div>
                          <h5>{rs.jpa_job?.jp_position}</h5>
                        </div>
                        <div
                          className="feature__desc"
                          style={{ paddingTop: "15px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-building"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z" />
                            <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z" />
                          </svg>{" "}
                          {
                            rs.jpa_job?.job_user.user_main_company
                              ?.uc_company_name
                          }
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-geo-alt-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                          </svg>{" "}
                          {rs.jpa_job?.job_province?.province_eng} /{" "}
                          {rs.jpa_job?.job_province?.province_thai}
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-cash-coin"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                            />
                            <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                            <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                            <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                          </svg>{" "}
                          {Functions.formatNumberWithComma(
                            rs.jpa_job?.jp_salary_start
                          )}
                          {" - "}
                          {Functions.formatNumberWithComma(
                            rs.jpa_job?.jp_salary_end
                          )}{" "}
                          {lang_data?.bath}
                        </div>
                        <div style={{ paddingTop: "15px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-exclude"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm12 2H5a1 1 0 0 0-1 1v7h7a1 1 0 0 0 1-1V4z" />
                          </svg>{" "}
                          <strong> {lang_data?.main_job}</strong>
                          {rs.jpa_job?.job_detail
                            .filter((e) => e.jpd_type === "1")
                            .map((rd, i) => (
                              <li key={i}>{rd.jpd_name}</li>
                            ))}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <Image
                            src={
                              Functions.check_empty_value(
                                rs.jpa_job?.job_user?.user_main_detail
                                  ?.ud_image_cover
                              ) === true
                                ? require("../../asset/images/4920290.jpg")
                                : rs.jpa_job?.job_user?.user_main_detail
                                    ?.ud_image_cover
                            }
                            style={{ width: "100%", height: "300px" }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <div className="footer-content-left">
                      <span>
                        {Functions.timeDiffCalc(
                          new Date(rs.jpa_job?.jp_posting_date),
                          new Date()
                        )}
                      </span>
                    </div>
                    <div className="footer-content-right">
                      {rs.jpa_job?.jp_code}
                      {/* <h4>{this.statusFormat(rs.status)}</h4> */}
                    </div>
                  </div>
                </div>
              ))}
            </CardStyle>

            <div
              style={{ paddingTop: "70px", paddingBottom: "70px" }}
              align="center"
            >
              <div style={{ width: "200px" }}>
                <InputGroup className="mb-3" size="sm">
                  <InputGroup.Text> {lang_data?.page}</InputGroup.Text>
                  <Form.Control
                    type="number"
                    defaultValue={page}
                    onChange={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.getApplyList(),
                    ]}
                    onKeyUp={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.getApplyList(),
                    ]}
                    onClick={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.getApplyList(),
                    ]}
                    style={{ textAlign: "center" }}
                  />
                  <InputGroup.Text>
                    {lang_data?.total_page} : {param.total_page}{" "}
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
