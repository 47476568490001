import React, { Component } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Row,
  Image,
  Nav,
  Navbar,
  Dropdown,
} from "react-bootstrap";
import Common from "../common";
import Functions from "../functions";
import axios from "axios";
import styled from "styled-components";
import lang from "../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const linkStyle = {
  color: "#0E4C81",
  paddingLeft: "50px",
};
const navStyle = {
  fontSize: "24px",
  fontWeight: "bold",
};

const PricingPackageStyle = styled.div`
  // https://codepen.io/LindaBaloyi/pen/vgvbqd
  h2 {
    font-size: 1.6em;
    display: inline;
    text-transform: uppercase;
  }
  h3 {
    font-size: 1.6em;
    display: inline;
    text-transform: capitalize;
  }
  em {
    font-style: normal;
    text-decoration: underline;
    font-size: 1.7rem;
  }
  section {
    text-align: justify !important;
    text-justify: distribute-all-lines;
    font-size: 0 !important;
  }
  section > * {
    text-align: left;
    font-size: medium;
  }
  section:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
  section > div {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    width: 24%;
    margin-bottom: 1.9%;
  }
  @media only screen and (max-width: 529px) {
    section > div {
      width: 100%;
      margin-bottom: 1.9%;
    }
  }
  section > div.all-plans {
    width: 100%;
    margin-bottom: 1.9%;
  }
  .price-package {
    padding-top: 25px;
    margin-bottom: 10px;
    text-align: center;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-bottomleft: 10px;
    -moz-border-radius-bottomright: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
  }
  .price-package .package-name {
    font-size: 1.4rem;
  }
  @media only screen and (max-width: 529px) {
    .price-package {
      padding-top: 1rem;
    }
  }
  .price-package .package-price {
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-bottomleft: 10px;
    -moz-border-radius-bottomright: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    margin-top: 25px;
  }
  @media only screen and (max-width: 529px) {
    .price-package .package-price {
      margin-top: 1rem;
    }
  }
  .price-package .package-price .price {
    position: relative;
    display: inline-block;
  }
  .price-package .package-price .price sup {
    font-size: 1.6rem;
    position: absolute;
    top: 6px;
    left: -12px;
  }
  .price-package .package-price .price span {
    font-size: 3.5rem;
  }
  .price-package .package-price .term {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    padding-top: 6px;
  }
  .price-package .package-price .term sup {
    font-size: 1.7rem;
    display: block;
    padding: 0;
  }
  .price-package .package-price .term span {
    font-size: 1rem;
    display: block;
    margin-top: -3px;
  }
  .price-package .package-features {
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-bottomleft: 10px;
    -moz-border-radius-bottomright: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    margin-top: 16.666666666667px;
  }
  .price-package .package-features ul {
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .price-package .package-features ul li {
    margin: 20px auto;
    padding: 0;
  }
  @media only screen and (max-width: 529px) {
    .price-package .package-features ul li {
      margin: 10px auto;
    }
  }
  .price-package .sign-up {
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-bottomleft: 10px;
    -moz-border-radius-bottomright: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    padding: 16.666666666667px 0;
    margin-top: 16.666666666667px;
    width: 100%;
    outline: none;
    border: none;
    cursor: pointer;
  }
  .price-package .package-arrow {
    width: 0;
    height: 0;
    margin: 0 auto 12.5px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 8px solid;
  }
  .all-plans .all-plans-features {
    display: table;
    width: 100%;
  }
  .all-plans .all-plans-features text {
    display: table-cell;
    font-size: 1.6rem;
    vertical-align: middle;
    width: 33%;
  }
  @media only screen and (max-width: 529px) {
    .all-plans .all-plans-features text {
      display: block;
      margin: 10px auto;
    }
  }
  .average .price-package {
    background-color: #009a86;
  }
  .average .price-package .package-name {
    color: white;
  }
  .average .price-package .package-price {
    background-color: #66caa6;
    color: white;
  }
  .average .price-package .package-features {
    background-color: #e0f2ed;
    color: #009a86;
  }
  .average .price-package .sign-up {
    background-color: #009a86;
    color: white;
    transition: background-color 0.5s ease;
  }
  .average .price-package .sign-up:hover {
    background-color: #66caa6;
  }
  .average .price-package .sign-up:active {
    background-color: #e0f2ed;
  }
  .average .price-package .package-price .package-arrow {
    border-top-color: #009a86;
  }
  .average .price-package .package-features .package-arrow {
    border-top-color: #66caa6;
  }
  .average .price-package .sign-up .package-arrow {
    border-top-color: #e0f2ed;
  }
  .special .price-package {
    background-color: #8654ff;
  }
  .special .price-package .package-name {
    color: white;
  }
  .special .price-package .package-price {
    background-color: #b191ff;
    color: white;
  }
  .special .price-package .package-features {
    background-color: #efe9fc;
    color: #8654ff;
  }
  .special .price-package .sign-up {
    background-color: #8654ff;
    color: white;
    transition: background-color 0.5s ease;
  }
  .special .price-package .sign-up:hover {
    background-color: #b191ff;
  }
  .special .price-package .sign-up:active {
    background-color: #efe9fc;
  }
  .special .price-package .package-price .package-arrow {
    border-top-color: #8654ff;
  }
  .special .price-package .package-features .package-arrow {
    border-top-color: #b191ff;
  }
  .special .price-package .sign-up .package-arrow {
    border-top-color: #efe9fc;
  }
  .superduper .price-package {
    background-color: #00b9f2;
  }
  .superduper .price-package .package-name {
    color: white;
  }
  .superduper .price-package .package-price {
    background-color: #7dd4fe;
    color: white;
  }
  .superduper .price-package .package-features {
    background-color: #e0f2fa;
    color: #00b9f2;
  }
  .superduper .price-package .sign-up {
    background-color: #00b9f2;
    color: white;
    transition: background-color 0.5s ease;
  }
  .superduper .price-package .sign-up:hover {
    background-color: #7dd4fe;
  }
  .superduper .price-package .sign-up:active {
    background-color: #e0f2fa;
  }
  .superduper .price-package .package-price .package-arrow {
    border-top-color: #00b9f2;
  }
  .superduper .price-package .package-features .package-arrow {
    border-top-color: #7dd4fe;
  }
  .superduper .price-package .sign-up .package-arrow {
    border-top-color: #e0f2fa;
  }
  .all-plans .price-package {
    background-color: #646c7f;
  }
  .all-plans .price-package .package-name {
    color: white;
  }
  .all-plans .price-package .package-price {
    background-color: #8b94ab;
    color: white;
  }
  .all-plans .price-package .package-features {
    background-color: #e5ebf4;
    color: #646c7f;
  }
  .all-plans .price-package .sign-up {
    background-color: #646c7f;
    color: white;
    transition: background-color 0.5s ease;
  }
  .all-plans .price-package .sign-up:hover {
    background-color: #8b94ab;
  }
  .all-plans .price-package .sign-up:active {
    background-color: #e5ebf4;
  }
  .all-plans .price-package .package-price .package-arrow {
    border-top-color: #646c7f;
  }
  .all-plans .price-package .package-features .package-arrow {
    border-top-color: #8b94ab;
  }
  .all-plans .price-package .sign-up .package-arrow {
    border-top-color: #e5ebf4;
  }
  .all-plans .package-features .package-arrow {
    border-top-color: #646c7f !important;
  }
  .all-plans .package-features {
    padding-bottom: 20px;
  }
`;

export default class PackageSelect extends Component {
  state = {
    data: [],
  };

  refreshData = async () => {
    try {
      await axios
        .get(Common.API_URL + "payment/package", Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  templatePackageClass = (val) => {
    let f = "superduper";
    const r = parseInt(val) % 3;
    if (r === 1) {
      f = "average";
    } else if (r === 2) {
      f = "special";
    }
    return f;
  };
  templateAmountContent = (val) => {
    let f = "";

    if (val === 30) {
      f = (
        <div>
          <sup> 1 </sup>
          <span>/{lang_data?.month_label}</span>
        </div>
      );
    } else if (val === 60) {
      f = (
        <div>
          <sup> 2 </sup>
          <span>/{lang_data?.month_label}</span>
        </div>
      );
    } else if (val === 90) {
      f = (
        <div>
          <sup> 3 </sup>
          <span>/{lang_data?.month_label}</span>
        </div>
      );
    } else if (val === 180) {
      f = (
        <div>
          <sup> 6 </sup>
          <span>/{lang_data?.month_label}</span>
        </div>
      );
    } else if (val === 365) {
      f = (
        <div>
          <sup> 1 </sup>
          <span>/{lang_data?.year_label}</span>
        </div>
      );
    }
    return f;
  };
  switchLanguage = (val) => {
    localStorage.setItem("langSet", val);
    window.location.reload();
  };
  componentDidMount() {
    this.refreshData();
  }
  render() {
    const { data } = this.state;
    return (
      <div>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
          <Container>
            <Navbar.Brand href="/">
              <Image
                variant="top"
                src={require("../asset/images/Jinjijob4.png")}
                style={{ width: "85px", height: "60px" }}
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto" style={navStyle}>
                <Nav.Link href="/job-search" style={linkStyle}>
                  {lang_data?.package_label}
                </Nav.Link>
              </Nav>

              <Nav>
                <Dropdown className="d-inline mx-2">
                  <Dropdown.Toggle id="dropdown-autoclose-true" variant="light">
                    <Image
                      variant="top"
                      src={
                        langSet === "en"
                          ? require("../asset/images/united-kingdom.png")
                          : require("../asset/images/thailand.png")
                      }
                      style={{ width: "45px", height: "45px" }}
                      roundedCircle
                      thumbnail
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#"
                      onClick={() => this.switchLanguage("th")}
                    >
                      Thai - ไทย
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => this.switchLanguage("en")}
                    >
                      English - อังกฤษ
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>

              <Nav>
                <div style={{ paddingRight: 10 }}>
                  <Button
                    variant="primary"
                    onClick={() => (window.location = "/")}
                  >
                    {lang_data?.login}
                  </Button>
                </div>
                <div style={{ paddingRight: 10 }}>
                  <Button
                    variant="success"
                    onClick={() => (window.location = "/companies/register")}
                  >
                    {lang_data?.register_member2}
                  </Button>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div style={{ paddingTop: "40px", height: "100vh" }}>
          <Container>
            <Alert variant="success">
              <Row>
                <Col>
                  <div>
                    <h2> {lang_data?.package_header}</h2>
                  </div>
                  <div> {lang_data?.package_desciption}</div>
                </Col>
                <Col>
                  <div align="right">
                    <Image
                      src={require("../asset/images/subscription.webp")}
                      style={{ width: 110, height: 110 }}
                    />
                  </div>
                </Col>
              </Row>
            </Alert>

            <PricingPackageStyle>
              <section>
                {data?.map((rs, index) => (
                  <div
                    className={this.templatePackageClass(index)}
                    style={{ padding: 15 }}
                    key={index}
                  >
                    <div className="price-package">
                      <div className="package-name">
                        <h2>{rs.package_name}</h2>
                      </div>
                      <div className="package-price">
                        <div className="package-arrow"></div>
                        <div className="price">
                          <span>
                            {" ฿"}
                            {Functions.formatNumberWithComma(rs.package_price)}
                          </span>
                        </div>
                        <div className="term">
                          {/* <sup> {rs.package_amount_date}</sup>
                            <span>/เดือน</span> */}
                          {this.templateAmountContent(rs.package_amount_date)}
                        </div>
                        <div className="package-features">
                          <div className="package-arrow"></div>
                          <ul>
                            {rs.pt_pd?.map((items, i) => (
                              <li key={i}> {items.pt_name}</li>
                            ))}
                          </ul>

                          <button
                            className="sign-up"
                            onClick={() =>
                              (window.location = "/companies/register")
                            }
                          >
                            <h3> {lang_data?.register_member2}</h3>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </section>
            </PricingPackageStyle>
          </Container>
        </div>
      </div>
    );
  }
}
