import React, { Component } from "react";
import { Row, Col, Badge } from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const CardStyle = styled.div`
  .feature {
    position: relative;
    // max-width: 21rem;
    width: 100%;
    height: 25rem;
    margin: 2rem auto;
    padding: 2em;
    border-radius: 0.75em;
    box-shadow: 5px 5px 20px rgba(0 0 0/0.15);
    text-align: left;
    transition: transform 200ms ease-in;
  }

  .feature__desc {
    margin-top: 0.5em;
    color: #a3adc9;
  }

  .feature__img {
    position: absolute;
    bottom: 10%;
    right: 10%;
  }

  .feature-one {
    border-top: 5px solid #ea5353;
  }

  /* media queries */

  @media (min-width: 1000px) {
    section {
      max-width: 200rem;
    }

    .section__title {
      margin: 0 auto;
      max-width: 40%;
      font-size: 2rem;
    }

    .section__desc {
      max-width: 55ch;
      margin: 1rem auto 1rem;
    }

    .features {
      display: flex;
      gap: 2rem;
    }

    .feature-one {
      margin: auto 0;
    }
  }
`;

export default class ViewAsCandidateExperience extends Component {
  state = {
    data: [],
  };

  refreshData = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/experience/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { data } = this.state;

    return (
      <div>
        <h1>{lang_data?.workExperience}</h1>

        <CardStyle>
          <Row>
            {data?.map((rs, index) => (
              <Col lg="6" md="6" sm="12" key={index}>
                <div className="feature feature-one">
                  <h2 className="feature__title">{rs.exp_comapany}</h2>
                  <Badge bg="secondary">{rs.exp_last_position}</Badge>

                  <div className="feature__desc">
                    {lang_data?.salary_label} :{" "}
                    {Functions.formatNumberWithComma(rs.exp_last_salary)}{" "}
                    {lang_data?.bath} /{lang_data?.month_label} <br />
                    {lang_data?.role} :
                    {rs?.exp_responsibility.split(",").map((value, index) => (
                      <li key={index}> {value} </li>
                    ))}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </CardStyle>
      </div>
    );
  }
}
