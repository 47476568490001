import React, { Component } from "react";
import { Card, Button, Modal, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import Common from "../../common";
import Swal from "sweetalert2";
import ReactPlayer from "react-player";
export default class CandidateDetailPortfolio extends Component {
  state = {
    active: 1,
    isOpenModalForm: false,
    isOpenModalDelete: false,
    validateForm: false,

    portfolio_name: "Presentation Video",
    portfolio_path: "",
    portfolio_type: 2,
  };

  handleSubmit = () => {
    if (this.state.portfolio_name === "" || this.state.portfolio_path === "") {
      this.setState({ validateForm: true });
      return false;
    }

    try {
      axios
        .post(
          Common.API_URL + `userpart2/portfolio/${this.props.user_id}`,
          {
            portfolio_name: this.state.portfolio_name,
            portfolio_path: this.state.portfolio_path,
            portfolio_type: this.state.portfolio_type,
            active: this.state.active,
          },
          Common.options
        )
        .then((res) => {
          Swal.fire({
            title: "เรียบร้อย",
            text: "ทำรายการสำเร็จ",
            icon: "success",
            confirmButtonText: "ตกลง",
          });
          this.setState({
            portfolio_name: "",
            portfolio_path: "",
            isOpenModalForm: false,
          });
          this.refreshDataVideo();
          return false;
        });
    } catch (error) {
      console.log(error);
    }
  };

  refreshDataVideo = async () => {
    try {
      await axios
        .get(
          Common.API_URL +
            `userpart2/portfolio/${this.props.user_id}?portfolio_type=${this.state.portfolio_type}`,
          Common.options
        )
        .then((response) => {
          let res = response.data;

          if (
            res?.portfolio_path !== null &&
            res?.portfolio_path !== undefined
          ) {
            this.setState({
              portfolio_path: res?.portfolio_path,
              portfolio_name: res?.portfolio_name,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDeleteSubmit = async () => {
    Swal.fire({
      title: "ยืนยันการลบ",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่, ต้องการลบข้อมูลนี้",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(
              Common.API_URL +
                `userpart2/portfolio/${this.props.user_id}?portfolio_type=${this.state.portfolio_type}`,
              Common.options
            )
            .then((res) => {
              this.setState({
                portfolio_path: "",
              });
              this.refreshDataVideo();
            });
        } catch (error) {
          console.log(error);
          this.setState({ msg: "Invalid data" });
        }
      }
    });
  };

  componentDidMount() {
    this.refreshDataVideo();
  }
  render() {
    const { portfolio_name, portfolio_path, isOpenModalForm, validateForm } =
      this.state;

    return (
      <div>
        <Card>
          <Card.Body>
            <Card.Title>
              <Row>
                <Col>วีดีโอนำเสนอตัวเอง</Col>
                <Col align="right">
                  <Button
                    variant="success"
                    onClick={(e) =>
                      this.setState({
                        isOpenModalForm: true,
                      })
                    }
                  >
                    อัปโหลด
                  </Button>{" "}
                  <Button variant="danger" onClick={this.handleDeleteSubmit}>
                    ลบ
                  </Button>
                </Col>
              </Row>
            </Card.Title>

            <ReactPlayer
              url={portfolio_path}
              // previewTabIndex={0}
              controls={true}
              playsinline={true}
              width={"100%"}
              height={600}
              id="presentation_video"
            />
          </Card.Body>
        </Card>

        <Modal
          show={isOpenModalForm}
          onHide={() => this.setState({ isOpenModalForm: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Video URL</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Title</Form.Label>{" "}
              <Form.Label style={{ color: "red" }}> *</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) =>
                  this.setState({ portfolio_name: e.target.value })
                }
                isInvalid={
                  validateForm === true && this.state.portfolio_name === ""
                    ? true
                    : false
                }
                defaultValue={portfolio_name}
                readOnly
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>URL</Form.Label>{" "}
              <Form.Label style={{ color: "red" }}> *</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) =>
                  this.setState({ portfolio_path: e.target.value })
                }
                isInvalid={
                  validateForm === true && this.state.portfolio_path === ""
                    ? true
                    : false
                }
                defaultValue={portfolio_path}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => this.setState({ isOpenModalForm: false })}
            >
              ปิด
            </Button>
            <Button variant="primary" onClick={this.handleSubmit}>
              บันทึก
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
