import React, { Component } from "react";
import { Table, Button, Modal, Image } from "react-bootstrap";
import Common from "../../common";
import axios from "axios";
import Swal from "sweetalert2";
export default class CandidateDetailRewards extends Component {
  state = {
    reward_id: "",
    reward_name: "",
    reward_file_path: "",

    data: [],
    isOpenModalPreview: false,
    filetmp: "",
  };
  refreshData = async () => {
    try {
      await axios
        .get(
          Common.API_URL + `user/rewards/${this.props.user_id}`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  DeleteImage = (image) => {
    const image_id = image.split("/")[4];
    try {
      axios.delete(
        Common.API_URL + `media/delete/image/${image_id}`,
        Common.options
      );
    } catch (error) {
      console.log(error);
    }
  };
  handleDeleteSubmit = async (reward_id) => {
    Swal.fire({
      title: "ยืนยันการลบ",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่, ต้องการลบข้อมูลนี้",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(
              Common.API_URL + `user/rewards/${reward_id}`,
              Common.options
            )
            .then((res) => {
              this.clearState();
              this.refreshData();
              if (this.state.reward_file_path !== "") {
                this.DeleteImage(this.state.reward_file_path);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  clearState = () => {
    this.setState({
      reward_id: 0,
      reward_name: "",
      reward_file_path: "",
    });
  };
  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { data, isOpenModalPreview } = this.state;

    return (
      <div>
        {data !== undefined && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>รางวัล / ใบประกาศนียบัตร </th>
                <th>รูป</th>
                <th>ลบ</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((rs, index) => (
                <tr key={index}>
                  <td>{rs.reward_name}</td>
                  <td align="center">
                    <Button
                      variant="outline-primary"
                      onClick={() => [
                        this.setState({
                          reward_id: rs.reward_id,
                          reward_name: rs.reward_name,
                          reward_file_path: rs.reward_file_path,
                          isOpenModalPreview: true,
                        }),
                      ]}
                      disabled={rs.reward_file_path === "" ? true : false}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-card-image"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                      </svg>
                    </Button>
                  </td>
                  <td align="center">
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => [
                        this.setState({
                          reward_file_path: rs.reward_file_path,
                        }),
                        this.handleDeleteSubmit(rs.reward_id),
                      ]}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                      </svg>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Modal
          size="lg"
          show={isOpenModalPreview}
          onHide={() => this.setState({ isOpenModalPreview: false })}
        >
          <Modal.Body>
            {this.state.reward_file_path !== "" ? (
              <Image
                id="img_preview"
                thumbnail
                src={this.state.reward_file_path}
                style={{ width: "100%", height: "auto" }}
              />
            ) : (
              <Image
                id="img_preview"
                thumbnail
                src={require("../../asset/images/6189389_3204121.jpg")}
                style={{ width: "100%", height: "auto" }}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => this.setState({ isOpenModalPreview: false })}
              variant="secondary"
            >
              ปิด
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
