import React, { Component } from "react";
import { Badge, Row, Col } from "react-bootstrap";
import axios from "axios";
import styled from "styled-components";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const degree_list = Functions.degree_type;
const d = new Date();
const year_present = d.getFullYear();

const CardStyle = styled.div`
  .feature {
    position: relative;
    // max-width: 21rem;
    width: 100%;
    height: 32rem;
    margin: 2rem auto;
    padding: 2em;
    border-radius: 0.75em;
    box-shadow: 5px 5px 20px rgba(0 0 0/0.15);
    text-align: left;
    transition: transform 200ms ease-in;
  }

  .feature__desc {
    margin-top: 0.5em;
    color: #a3adc9;
  }

  .feature__img {
    position: absolute;
    bottom: 10%;
    right: 10%;
  }

  .feature-one {
    border-top: 5px solid #fcaf4a;
  }

  /* media queries */

  @media (min-width: 1000px) {
    section {
      max-width: 200rem;
    }

    .section__title {
      margin: 0 auto;
      max-width: 40%;
      font-size: 2rem;
    }

    .section__desc {
      max-width: 55ch;
      margin: 1rem auto 1rem;
    }

    .features {
      display: flex;
      gap: 2rem;
    }

    .feature-one {
      margin: auto 0;
    }
  }
`;

export default class ViewAsCandidateEducation extends Component {
  state = {
    edu_id: 0,
    edu_degree: 0,
    edu_faculty: "",
    edu_major: "",
    edu_graduation_year: year_present,
    edu_gpa: 0,
    active: 1,

    institution_id: 0,

    defaultInstitution: {
      value: 0,
      label: "Search  institution",
    },

    list_institution: [],
    data: [],
    isOpenModal: false,
    isOpenModalDelete: false,

    isOpenTextBox: false,
    institution_name: "",

    validateForm: false,
  };

  refreshData = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/education/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
        });
    } catch (error) {
      console.log(error);
    }
  };
  filterDegree = (id) => {
    if (id === 0 || id === undefined || id === null) {
      return false;
    }
    var r = degree_list.filter(function (entry) {
      return entry.degree_id === id;
    });

    return r;
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { data } = this.state;

    return (
      <div>
        <h1>{lang_data?.education_history}</h1>

        <div style={{ paddingTop: "25px" }}>
          <CardStyle>
            <Row>
              {data?.map((rs, index) => (
                <Col lg="6" md="6" sm="12" key={index}>
                  <div className="feature feature-one">
                    <h2 className="feature__title">
                      {rs.edu_institution.institution_name}
                    </h2>
                    {/* Year */}
                    <div align="left">
                      <Badge bg="secondary">
                        {lang_data?.graduationYear} : {rs.edu_graduation_year}
                      </Badge>
                    </div>
                    {/*End Year */}
                    <div className="feature__desc">
                      {/* Faculty */}
                      <div style={{ paddingTop: "5px" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-buildings-fill icon-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15 .5a.5.5 0 0 0-.724-.447l-8 4A.5.5 0 0 0 6 4.5v3.14L.342 9.526A.5.5 0 0 0 0 10v5.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V14h1v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V.5ZM2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-1 2v1H2v-1h1Zm1 0h1v1H4v-1Zm9-10v1h-1V3h1ZM8 5h1v1H8V5Zm1 2v1H8V7h1ZM8 9h1v1H8V9Zm2 0h1v1h-1V9Zm-1 2v1H8v-1h1Zm1 0h1v1h-1v-1Zm3-2v1h-1V9h1Zm-1 2h1v1h-1v-1Zm-2-4h1v1h-1V7Zm3 0v1h-1V7h1Zm-2-2v1h-1V5h1Zm1 0h1v1h-1V5Z" />
                        </svg>
                        <strong className="mb-2 text-muted">
                          {" "}
                          {lang_data?.major_label} :{" "}
                        </strong>{" "}
                        {rs.edu_faculty}
                      </div>
                      {/*End Faculty */}
                      {/* Major */}
                      <div style={{ paddingTop: "5px" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-book-fill icon-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                        </svg>{" "}
                        <strong className="mb-2 text-muted">
                          {" "}
                          {lang_data?.field_of_study} :{" "}
                        </strong>{" "}
                        {rs.edu_major}
                      </div>
                      {/*End Major */}
                      {/* Degree */}
                      <div style={{ paddingTop: "5px" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-mortarboard-fill icon-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5Z" />
                          <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Z" />
                        </svg>{" "}
                        <strong className="mb-2 text-muted">
                          {lang_data?.degree_level} :{" "}
                        </strong>{" "}
                        {
                          this.filterDegree(parseInt(rs?.edu_degree))[0]
                            .degree_name_eng
                        }
                      </div>
                      {/*End Degree */}
                      {/* GPA */}
                      <div style={{ paddingTop: "5px" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-award-fill icon-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z" />
                          <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
                        </svg>{" "}
                        <strong className="mb-2 text-muted">GPA : </strong>{" "}
                        {rs.edu_gpa}
                      </div>
                      {/*End GPA */}
                    </div>
                    {/* <Image className="feature__img" src={karmaIcon} /> */}
                  </div>
                </Col>
              ))}
            </Row>
          </CardStyle>
        </div>
      </div>
    );
  }
}
