import React, { Component } from "react";
import { Row, Col, Image } from "react-bootstrap";
import axios from "axios";
import styled from "styled-components";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const language_list = Functions.language_list;

const CardStyle = styled.div`
  h3 {
    font-size: larger;
    color: rgb(66, 66, 66);
  }
  p {
    color: rgb(136, 136, 136);
  }
  // .card-wrapper:hover {
  //   transform: scale(1.03);
  // }
  .card-wrapper {
    position: relative;
    height: 280px;
    width: 100%;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: 0.5s;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
  }

  .user-pic {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;
    top: -60px;
    width: 150px;
    height: 100px;
    background-color: #c850c0;
    border-radius: 10px;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.35);
    overflow: hidden;
    transition: 0.5s;
  }
  .user-pic img {
    height: 100%;
    display: block;
    /* margin-left: -25%; */
  }
  .name {
    text-align: center;
    position: relative;
    top: 70px;
    transition: 0.5s;
  }

  .social-details {
    position: relative;
    top: 100px;
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 30px;
    opacity: 0;
    transition: 0.5s;
  }

  .card-btn {
    position: relative;
    top: 200px;
    display: flex;
    justify-content: space-evenly;
    gap: 30px;
    padding: 0 50px;
    opacity: 0;
    transition: 0.1s;
  }

  .card-btn button {
    width: 100px;
    padding: 5px 10px;
    font-size: medium;
    font-family: "Poppins", sans-serif;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(136, 136, 136);
    color: rgb(136, 136, 136);
    transition: 0.3s;
  }
`;

export default class ViewAsCandidateLanguage extends Component {
  state = {
    data: [],
  };

  refreshData = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/language/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  filterLanguage = (id) => {
    if (id === 0 || id === undefined || id === null) {
      return false;
    }
    var r = language_list.filter(function (entry) {
      return entry.language_code === id;
    });

    return r;
  };

  componentDidMount() {
    this.refreshData();
  }
  render() {
    const { data } = this.state;

    return (
      <div>
        <h1>{lang_data?.language_skills}</h1>

        <div style={{ paddingTop: "5px" }}>
          <CardStyle>
            <Row>
              {data?.map((rs, index) => (
                <Col style={{ paddingTop: "100px" }} lg="4" sm="12" key={index}>
                  <div align="center">
                    <div className="card-wrapper">
                      <div className="user-pic">
                        <Image
                          src={
                            this.filterLanguage(rs.language_code)[0]
                              .language_flag
                          }
                        />
                      </div>

                      <div className="name">
                        <h3>
                          {
                            this.filterLanguage(rs.language_code)[0]
                              .language_name_eng
                          }
                        </h3>
                        <p>
                          Language :{" "}
                          <strong>
                            {
                              this.filterLanguage(rs.language_code)[0]
                                .language_name_eng
                            }
                          </strong>
                        </p>
                        <p>
                          Overall : <strong>{rs.language_overall}</strong>
                        </p>
                        <p>
                          Type : <strong>{rs.language_type}</strong>
                        </p>
                        <p>
                          Score : <strong>{rs.language_score}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </CardStyle>
        </div>
      </div>
    );
  }
}
