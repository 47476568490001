import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Alert,
  Button,
  Dropdown,
  Container,
  Nav,
  NavDropdown,
  Navbar,
  Image,
  Modal,
} from "react-bootstrap";
import styled from "styled-components";
import OtpInput from "react-otp-input";
import axios from "axios";
import JobPosition from "./component_candidate/JobPosition";
import Profile from "./component_candidate/Profile";
import ProfileApplyList from "./component_candidate/ProfileApplyList";
import ProfileCalendar from "./component_candidate/ProfileCalendar";
import ViewAsCandidate from "./component_general/ViewAsCandidate";
import Resume from "./component_general/Resume";
import CompanyProfile from "./component_general/CompanyProfile";
import Setting from "./component_general/Setting";
import Favorite from "./component_candidate/Favorite";
import AlertContent from "./component_candidate/Alert";
import JobView from "./component_general/JobView";
import Messenger from "./component_general/Messenger";
import ErrorPage from "./ErrorPage";
import Logout from "../public_page/Logout";
import lang from "../lang/lang";
import Common from "../common";
import Functions from "../functions";
const user_id = Common.token;
const langSet = Common.langSet;
const lang_data = lang(langSet);
const navStyle = {
  fontSize: "24px",
  fontWeight: "bold",
};
const linkStyle = {
  color: "#0E4C81",
  paddingLeft: "50px",
};

const NotificationButtonStyle = styled.div`
  .notification {
    // background-color: #555;
    color: white;
    text-decoration: none;
    padding: 5px 1px;
    position: relative;
    display: inline-block;
    border-radius: 2px;
  }

  // .notification:hover {
  //   background: #a3adc9;
  // }

  .notification .badge {
    position: absolute;
    top: -1px;
    right: -2px;
    padding: 5px 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: red;
    color: white;
  }
`;

const FooterStyle = styled.div`
position: fixed;
left: 0;
bottom: 0;
width: 100%;
background-color: #289CCB;
color: white;
text-align: center;

  }
`;

export default class SectionSuperAdmin extends Component {
  state = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    ud_verify: 0,
    ud_image_profile: require("../asset/images/profile.png"),
    openFormOTP: false,
    alerFail: false,

    otp_code: 0,
    otp_ref: "",
    total_chat: 0,
    historys: [],
    alerts: [],
    total_alert: 0,
  };
  refreshData = async () => {
    try {
      await axios
        .get(Common.API_URL + `user/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          let user_detail = res.user_main_detail;
          this.setState({
            firstname: res.firstname,
            lastname: res.lastname,
            email: res.email,
            phone: res.phone,
          });
          if (user_detail !== undefined) {
            this.setState({
              ud_image_profile:
                Functions.check_empty_value(user_detail?.ud_image_profile) ===
                false
                  ? user_detail?.ud_image_profile
                  : require("../asset/images/profile.png"),
              ud_verify: user_detail?.ud_verify,
              openFormOTP: user_detail?.ud_verify !== 1 ? true : false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  getUserOTP = async () => {
    try {
      await axios
        .get(
          Common.API_URL + `user/otp/get/${user_id}`,

          Common.options
        )
        .then((response) => {
          const res = response.data;
          this.setState({
            otp_ref: res?.otp_ref,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  verifyUserOTP = async () => {
    if (this.state.otp_code === "" || this.state.otp_ref === "") {
      return false;
    }
    try {
      await axios
        .put(
          Common.API_URL + "user/otp/verify",
          {
            otp_code: this.state.otp_code,
            otp_ref: this.state.otp_ref,
            user_id: user_id,
          },
          Common.options
        )
        .then((response) => {
          this.refreshData();
          // this.setState({
          //   openFormOTP: false,
          // });
        });
    } catch (error) {
      console.log(error);
      this.setState({
        alerFail: true,
      });
    }
  };
  setOtp = (e) => {
    this.setState({
      otp_code: e,
    });
  };
  switchLanguage = (val) => {
    localStorage.setItem("langSet", val);
    window.location.reload();
  };
  getTotalChat = async () => {
    try {
      await axios
        .get(Common.API_URL + `chat/msg/total/${user_id}`, Common.options)
        .then((response) => {
          const res = response.data;
          this.setState({
            total_chat: res?.total,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getHistoryChat = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `chat/msg/history/list/${user_id}`,
          {
            page: 1,
            per_page: 10,
            search_value: "",
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ historys: res.data });
        });
    } catch (error) {
      console.log(error);
      window.location.href = "/";
    }
  };

  setReaded = (user_sender) => {
    try {
      axios
        .put(
          Common.API_URL + "chat/msg/readed/update?all=0",
          {
            user_sender: user_sender,
            user_recipient: user_id,
          },
          Common.options
        )
        .then((res) => {
          window.location.href = "/messenger/" + user_sender;
        });
    } catch (error) {
      console.log(error);
    }
  };
  getAlert = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `general/alert/list/${user_id}?accept=3`,
          {
            page: 1,
            per_page: 10,
            search_value: "",
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;

          this.setState({ alerts: res.data });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getAlertTotalUnAccept = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `general/alert/list/${user_id}?accept=0`,
          {
            page: 1,
            per_page: 1,
            search_value: "",
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;

          this.setState({ total_alert: res.total_data });
        });
    } catch (error) {
      console.log(error);
    }
  };
  setAlertAccept = (alert_id, page) => {
    try {
      axios
        .get(Common.API_URL + `general/alert/set/${alert_id}`, Common.options)
        .then((res) => {
          window.location.href = page;
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.refreshData();
    this.getTotalChat();
    this.getHistoryChat();
    this.getAlert();
    this.getAlertTotalUnAccept();
  }
  render() {
    const {
      otp_code,
      openFormOTP,
      otp_ref,
      alerFail,
      phone,
      total_chat,
      historys,
      alerts,
      total_alert,
    } = this.state;
    return (
      <div>
        <NotificationButtonStyle>
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
            <Container>
              <Navbar.Brand href="/">
                <Image
                  variant="top"
                  src={require("../asset/images/Jinjijob4.png")}
                  style={{ width: "85px", height: "60px" }}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto" style={navStyle}>
                  <Nav.Link href="/job-search" style={linkStyle}>
                    {lang_data.jobSearch}
                  </Nav.Link>
                </Nav>

                <Nav>
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle
                      id="dropdown-autoclose-true"
                      variant="light"
                    >
                      <Image
                        variant="top"
                        src={
                          langSet === "en"
                            ? require("../asset/images/united-kingdom.png")
                            : require("../asset/images/thailand.png")
                        }
                        style={{ width: "45px", height: "45px" }}
                        roundedCircle
                        thumbnail
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#"
                        onClick={() => this.switchLanguage("th")}
                      >
                        Thai - ไทย
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => this.switchLanguage("en")}
                      >
                        English - อังกฤษ
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>

                <Nav>
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle variant="light">
                      <div className="notification">
                        <Image
                          variant="top"
                          src={require("../asset/images/comments.png")}
                          style={{ width: "45px", height: "45px" }}
                          roundedCircle
                          thumbnail
                        />
                        <span className="badge">
                          {total_chat <= 99 ? total_chat : "99+"}
                        </span>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {historys?.map((rs, index) => (
                        <Dropdown.Item
                          href="#"
                          key={index}
                          onClick={() =>
                            this.setReaded(rs.user_recipient_history)
                          }
                        >
                          {Functions.check_empty_value(
                            rs.history_user_recipient?.user_main_detail
                              ?.ud_image_profile
                          ) === false ? (
                            <Image
                              src={
                                rs.history_user_recipient?.user_main_detail
                                  ?.ud_image_profile
                              }
                              style={{
                                width: 50,
                                height: 50,
                              }}
                              alt="avatar"
                              thumbnail
                              roundedCircle
                            />
                          ) : (
                            <Image
                              src={require("../asset/images/no-user-image-icon-26.jpg")}
                              style={{
                                width: 50,
                                height: 50,
                              }}
                              alt="avatar"
                              thumbnail
                              roundedCircle
                            />
                          )}{" "}
                          {rs.history_user_recipient?.firstname}{" "}
                          {rs.history_user_recipient?.lastname}
                          <div
                            style={{
                              fontSize: 12,
                              width: 200,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {Functions.format_date_time(rs.update_date)}
                          </div>
                        </Dropdown.Item>
                      ))}
                      <Dropdown.Divider />
                      <Dropdown.Item href="/messenger/list">
                        <div align="center">{lang_data?.all_message}</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
                <Nav>
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle variant="light">
                      <div className="notification">
                        <Image
                          variant="top"
                          src={require("../asset/images/notification-bell.png")}
                          style={{ width: "45px", height: "45px" }}
                          roundedCircle
                          thumbnail
                        />
                        <span className="badge">
                          {total_alert <= 99 ? total_alert : "99+"}
                        </span>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div
                        style={{
                          width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {alerts?.map((rs, index) => (
                          <Dropdown.Item
                            href="#"
                            key={index}
                            onClick={() =>
                              this.setAlertAccept(
                                rs?.alert_id,
                                rs?.alert_route_page
                              )
                            }
                            style={{
                              backgroundColor:
                                rs?.alert_accept === 0 ? "#dadde3" : "",
                            }}
                          >
                            {rs?.alert_description}

                            <div
                              style={{
                                fontSize: 12,
                                width: 200,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {Functions.format_date_time(rs.create_date)}
                            </div>
                          </Dropdown.Item>
                        ))}
                      </div>
                      <Dropdown.Divider />
                      <Dropdown.Item href="/alert/list">
                        <div align="center"> {lang_data?.all_notify}</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>

                <Nav>
                  <Image
                    variant="top"
                    src={this.state.ud_image_profile}
                    style={{ width: "45px", height: "45px" }}
                    roundedCircle
                    thumbnail
                  />
                  <NavDropdown
                    title={this.state.firstname + " " + this.state.lastname}
                  >
                    <NavDropdown.Item href={"/account"}>
                      {lang_data.account}
                    </NavDropdown.Item>
                    <NavDropdown.Item href={"/setting"}>
                      {lang_data.setting}
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/logout">
                      {lang_data.logout}
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </NotificationButtonStyle>
        {/* Content */}

        <Router>
          <Routes>
            <Route path="/" element={<JobPosition />} />
            <Route path="/job-search" element={<JobPosition />} />
            <Route path="/account" element={<Profile />} />

            <Route path="/applylist" element={<ProfileApplyList />} />
            <Route path="/calendar" element={<ProfileCalendar />} />
            <Route path="/favorite" element={<Favorite />} />
            <Route path="/job/:jp_position/:jp_id" element={<JobView />} />
            <Route
              path="/view-as/:user_id/view"
              element={<ViewAsCandidate />}
            />
            <Route path="/resume/:user_id/view" element={<Resume />} />
            <Route path="/messenger/:user_id" element={<Messenger />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/alert/list" element={<AlertContent />} />
            <Route path="/company/:user_id" element={<CompanyProfile />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
        <Modal show={openFormOTP}>
          <Modal.Header>
            <Modal.Title>
              {lang_data.confirm_phone} {phone}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {alerFail === true ? (
              <Alert variant="danger"> {lang_data.otp_warning} </Alert>
            ) : (
              ""
            )}

            <OtpInput
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              onChange={this.setOtp}
              value={otp_code}
              inputStyle={{ width: "100%", height: 35 }}
            />
            <div align="center" style={{ paddingTop: 10.0 }}>
              {otp_ref !== "" ? "Ref : " + otp_ref : ""}
            </div>
            <div align="left" style={{ paddingTop: 10.0 }}>
              <Button variant="secondary" size="sm" onClick={this.getUserOTP}>
                GET OTP
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" href="/logout">
              {lang_data.logout}
            </Button>
            <Button
              variant="primary"
              onClick={this.verifyUserOTP}
              disabled={otp_ref === "" ? true : false}
            >
              {lang_data.save}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Footer */}
        <div style={{ paddingTop: 40 }}>
          <FooterStyle>
            {/* <div className="footer">
              <p>Footer</p>
            </div> */}
          </FooterStyle>
        </div>
      </div>
    );
  }
}
