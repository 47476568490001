import React, { Component } from "react";
import { Card, Container } from "react-bootstrap";
import AlertList from "../component_general/AlertList";

export default class Alert extends Component {
  render() {
    return (
      <div>
        <Container>
          <div style={{ padding: 45 }}>
            <Card>
              <Card.Body>
                <AlertList />
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    );
  }
}
