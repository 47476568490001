import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SectionSuperAdmin from "../section/SectionSuperAdmin";
import SectionHRCompany from "../section/SectionHRCompany";
import SectionCandidate from "../section/SectionCandidate";
import Resume from "../section/component_general/Resume";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../public_page/Login";
import ForGotPassword from "../public_page/ForGotPassword";
import JobPosition from "../public_page/JobPosition";
import RegisterCompany from "../public_page/RegisterCompany";
import RegisterCandidate from "../public_page/RegisterCandidate";
import Package from "../public_page/Package";
import ErrorPage from "../section/ErrorPage";
import Common from "../common";
import "../asset/image_box.css";
import "../asset/InputInternamIcons.css";
const user_type = parseInt(Common.user_type);
const user_id = Common.token;
export default class BaseLayout extends Component {
  componentDidMount() {
    if (user_id === null || user_id === undefined || user_id === "") {
      localStorage.clear();
    }
  }

  render() {
    return (
      <div>
        {(user_type === null ||
          user_type === undefined ||
          user_type === "" ||
          isNaN(user_type) === true ||
          user_type === 0) && (
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/change_password" element={<ForGotPassword />} />
              <Route path="/logout" element={<Login />} />
              <Route path="/companies/register" element={<RegisterCompany />} />
              <Route path="/resume/:user_id/view" element={<Resume />} />
              <Route path="/job-search" element={<JobPosition />} />
              <Route path="/package" element={<Package />} />
              <Route
                path="/candidate/register"
                element={<RegisterCandidate />}
              />

              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Router>
        )}
        {user_type === 1 && <SectionSuperAdmin />}
        {user_type === 2 && <SectionHRCompany />}
        {user_type === 3 && <SectionCandidate />}
      </div>
    );
  }
}
