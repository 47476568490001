import React, { Component } from "react";
import {
  Badge,
  ButtonGroup,
  Table,
  Card,
  Row,
  Col,
  Form,
  Breadcrumb,
  Button,
  Image,
  Modal,
  InputGroup,
} from "react-bootstrap";

import Common from "../../common";
import Functions from "../../functions";
import axios from "axios";
import Swal from "sweetalert2";

export default class Order extends Component {
  state = {
    data: [],
    search_value: "",
    page: 1,
    per_page: 25,
    param: [],

    isOpenModal: false,
    order_id: "",
    bill_id: "",
    bill_status: 0,
    bill_pay_slip: "",
    order_number: "",
    user_customer: "",
  };
  refreshData = async () => {
    try {
      await axios
        .post(
          Common.API_URL + "payment/bill/list/all",
          {
            page: this.state.page,
            per_page: 25,
            search_value: this.state.search_value,
            bill_status: [],
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;

          this.setState({ data: res.data, param: res });
        });
    } catch (error) {
      console.log(error);
    }
  };
  ConfirmPayment = (bill_status) => {
    try {
      axios
        .get(
          Common.API_URL +
            `payment/bill/${this.state.bill_id}/set?bill_status=${bill_status}`,

          Common.options
        )
        .then((res) => {
          this.setState({ isOpenModal: false, bill_id: "" });
          if (parseInt(bill_status) === 2) {
            this.handleAlert(
              `คำสั่งซื้อที่ ${this.state.order_number} ได้รับการชำระเงินแล้ว`,
              "/payment?tab=2",
              this.state.user_customer
            );
          }
          if (parseInt(bill_status) === 3) {
            this.handleAlert(
              `คำสั่งซื้อที่ ${this.state.order_number} การชำระเงินไม่ถูกต้อง`,
              "/payment?tab=2",
              this.state.user_customer
            );
          }

          this.refreshData();
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDelete = (order_id) => {
    Swal.fire({
      title: "ยืนยันการลบ",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่, ต้องการลบข้อมูลนี้",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(
              Common.API_URL + `payment/order/${order_id}`,
              Common.options
            )
            .then((res) => {
              this.setState({ isOpenModal: false, order_id: "" });
              this.DeleteImage(this.state.bill_pay_slip);
              this.refreshData();
            });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  billStatusFormat = (bill_status) => {
    let f = <Badge bg="secondary">ยังไม่ชำระเงิน</Badge>;
    if (bill_status === 1) {
      f = <Badge bg="warning">รอการตรวจสอบ</Badge>;
    } else if (bill_status === 2) {
      f = <Badge bg="success">ชำระเงินแล้ว</Badge>;
    } else if (bill_status === 3) {
      f = <Badge bg="danger">ชำระเงินไม่สำเร็จ</Badge>;
    }
    return f;
  };

  DeleteImage = (image) => {
    const image_id = image.split("/")[4];
    try {
      axios.delete(
        Common.API_URL + `media/delete/image/${image_id}`,
        Common.options
      );
    } catch (error) {
      console.log(error);
    }
  };
  // แจ้งเตือนไปยัง HR ยืนยันการชำระเงิน
  handleAlert = (alert_description, alert_route_page, user_id) => {
    try {
      axios.post(
        Common.API_URL + "general/alert/create",
        {
          alert_description: alert_description,
          alert_route_page: alert_route_page,
          alert_doc_type: 2,
          user_id: user_id,
        },
        Common.options
      );
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { data, isOpenModal, param, page, bill_pay_slip } = this.state;

    return (
      <div>
        <Row>
          <Col sm={8}>
            <h3>รายการคำสั่งซื้อ</h3>
          </Col>
          <Col sm={4}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">หน้าหลัก</Breadcrumb.Item>

              <Breadcrumb.Item active>เลขคำสั่งซื้อ</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row style={{ paddingBottom: "30px" }}>
          <Col sm={9}>ทั้งหมด {param.total_filter_data} </Col>
          <Col sm={3}>
            <Form.Control
              type="text"
              placeholder="ค้นหา"
              onChange={(e) => [
                this.setState({
                  search_value: e.target.value,
                }),
                this.refreshData(),
              ]}
              onKeyUp={(e) => [
                this.setState({
                  search_value: e.target.value,
                }),
                this.refreshData(),
              ]}
            />
          </Col>
        </Row>

        <Card border="info">
          <Card.Body>
            <Table striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>หมายเลขคำสั่งซื้อ</th>
                  <th>สินค้า</th>
                  <th>ชื่อลูกค้า</th>
                  <th>เลขเสียภาษี</th>
                  <th>เบอรโทร</th>
                  <th>ที่อยู่</th>
                  <th>ยอดรวม</th>
                  <th>ส่วนลด</th>
                  <th>รวมทั้งสิ้น</th>
                  <th>วันที่ชำระเงิน</th>
                  <th>หลักฐานการชำระเงิน</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((rs, index) => (
                  <tr key={index}>
                    <td>
                      {this.billStatusFormat(rs.bill_status)}
                      {rs.bill_order?.order_status === 4 && (
                        <div>
                          <Badge bg="danger">หมดอายุ</Badge>
                        </div>
                      )}
                    </td>
                    <td>{rs.bill_order?.order_number}</td>
                    <td>{rs.bill_order?.order_package?.package_name}</td>
                    <td>{rs.bill_name}</td>
                    <td>{rs.bill_tax}</td>
                    <td>{rs.bill_phone}</td>
                    <td>{rs.bill_address}</td>
                    <td align="right">
                      {Functions.formatNumberWithComma(rs.bill_price)}
                    </td>
                    <td align="right">
                      {Functions.formatNumberWithComma(rs.bill_discount)}
                    </td>
                    <td align="right">
                      {Functions.formatNumberWithComma(rs.bill_total_price)}
                    </td>

                    <td>
                      {rs.bill_status >= 1
                        ? Functions.format_date_time(rs.bill_date_pay)
                        : null}
                    </td>
                    <td align="center">
                      <Button
                        onClick={(e) =>
                          this.setState({
                            isOpenModal: true,
                            order_id: rs?.order_id,
                            bill_id: rs?.bill_id,
                            bill_pay_slip: rs?.bill_pay_slip,
                            order_number: rs?.bill_order?.order_number,
                            user_customer: rs?.bill_order?.user_id,
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-eye-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                        </svg>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ paddingTop: "70px" }} align="center">
              <div style={{ width: "200px" }}>
                <InputGroup className="mb-3" size="sm">
                  <InputGroup.Text>หน้า</InputGroup.Text>
                  <Form.Control
                    type="number"
                    defaultValue={page}
                    onChange={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    onKeyUp={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    onClick={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    style={{ textAlign: "center" }}
                  />
                  <InputGroup.Text>
                    จำนวนหน้า : {param.total_page}{" "}
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
          </Card.Body>
        </Card>
        {/* Main */}
        <Modal
          show={isOpenModal}
          onHide={(e) => this.setState({ isOpenModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title> หลักฐานการชำระเงิน</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Image src={bill_pay_slip} thumbnail />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup aria-label="Basic example">
              <Button
                variant="secondary"
                onClick={() => this.setState({ isOpenModal: false })}
              >
                ปิดหน้าต่าง
              </Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(this.state.order_id)}
                disabled={bill_pay_slip !== "" ? false : true}
              >
                ยกเลิกคำสั่งซื้อ
              </Button>
              <Button
                variant="warning"
                onClick={() => this.ConfirmPayment(3)}
                disabled={bill_pay_slip !== "" ? false : true}
              >
                ไม่อนุติ
              </Button>
              <Button
                variant="success"
                onClick={() => this.ConfirmPayment(2)}
                disabled={bill_pay_slip !== "" ? false : true}
              >
                อนุมัติ
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
