import React, { Component } from "react";
import {
  Button,
  Container,
  Card,
  Form,
  Row,
  Col,
  InputGroup,
  Image,
} from "react-bootstrap";
import axios from "axios";
import styled from "styled-components";
import Common from "../../common";
import Functions from "../../functions";
import ProfileMain from "./ProfileMain";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const user_id = Common.token;

const CardStyle = styled.div`
  ///https://codepen.io/ShortCode/pen/RwyRZKN
  .card {
    // padding: 15px;
    width: 100%;
    height: 350px;
    background: #ffffff;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.7);
    // user-select: none;
  }

  .cover-photo {
    position: relative;
    //background: url(https://i.imgur.com/jxyuizJ.jpeg);
    background-size: cover;
    height: 180px;
    border-radius: 5px 5px 0 0;
  }

  .profile {
    position: absolute;
    width: 120px;
    //   bottom: -60px;
    top: 115px;
    left: 25px;
    border-radius: 50%;
    border: 2px solid #222;
    background: #222;
    padding: 5px;
  }

  .profile-name {
    //font-size: 20px;
    margin: 15px 0 0 120px;
    color: black;
  }

  .about {
    margin-top: 30px;
    line-height: 1.6;
  }

  .button {
    border: none;
    // color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }

  .btn:hover {
    background: #7ce3ff;
    color: #222;
  }

  .icons {
    width: 180px;
    margin: 0 auto 10px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }

  .icons i {
    cursor: pointer;
    padding: 5px;
    font-size: 18px;
    transition: 0.2s;
  }

  .footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    // color: white;
    text-align: center;
  }

  .icons i:hover {
    color: #7ce3ff;
  }
`;

export default class MyCandidate extends Component {
  state = {
    search_value: "",
    page: 1,
    per_page: 25,
    param: [],
    // data
    data: [],
  };

  refreshData = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `j/job_position/my_cadidate/${user_id}`,
          {
            page: this.state.page,
            per_page: this.state.per_page,
            search_value: this.state.search_value,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res.data, param: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { data, param, page } = this.state;
    return (
      <div>
        <div>
          <ProfileMain />
        </div>

        <Container>
          <div style={{ paddingTop: "40px", height: "100vh" }}>
            <div align="right" style={{ paddingBottom: "25px" }}>
              <Row>
                <Col>
                  <div align="left">
                    <h3>{lang_data?.apply_list}</h3>
                  </div>
                </Col>
                <Col>
                  <div align="right"></div>
                </Col>
              </Row>
            </div>

            <div style={{ paddingBottom: "15px" }}>
              <Card>
                <Card.Body>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder={lang_data?.search}
                      onChange={(e) => [
                        this.setState({
                          search_value: e.target.value,
                        }),
                        this.refreshData(),
                      ]}
                      onKeyUp={(e) => [
                        this.setState({
                          search_value: e.target.value,
                        }),
                        this.refreshData(),
                      ]}
                    />
                  </Form.Group>
                </Card.Body>
              </Card>
            </div>

            <CardStyle>
              <Row>
                {data?.map((rs, index) => (
                  <Col
                    lg="4"
                    md="6"
                    sm="12"
                    key={index}
                    style={{ paddingBottom: "25px", paddingTop: "15px" }}
                  >
                    <div className="card">
                      <Image
                        src={
                          Functions.check_empty_value(
                            rs?.jpa_user?.user_main_detail?.ud_image_cover
                          ) === true
                            ? require("../../asset/images/4920290.jpg")
                            : rs?.jpa_user?.user_main_detail?.ud_image_cover
                        }
                        className="cover-photo"
                      />
                      <Image
                        src={
                          Functions.check_empty_value(
                            rs?.jpa_user?.user_main_detail?.ud_image_profile
                          ) === true
                            ? require("../../asset/images/no-user-image-icon-26.jpg")
                            : rs?.jpa_user?.user_main_detail?.ud_image_profile
                        }
                        style={{ width: 110, height: 110 }}
                        className="profile"
                      />
                      <strong className="profile-name">
                        {rs?.jpa_user?.firstname} {rs?.jpa_user?.lastname}
                      </strong>
                      <div className="about" style={{ paddingBottom: "25px" }}>
                        <div>
                          {" "}
                          {lang_data?.apply_position} :{" "}
                          {rs.jpa_job?.jp_position}
                        </div>
                      </div>
                      <div className="footer">
                        <Button
                          variant="primary"
                          size="sm"
                          style={{ width: "100%" }}
                          href={`/candidate/${
                            rs.jpa_user?.firstname + "_" + rs.jpa_user?.lastname
                          }/${rs.jpa_user?.user_id}`}
                          target="_blank"
                        >
                          {lang_data?.profile}
                        </Button>{" "}
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </CardStyle>

            <div style={{ paddingTop: "70px" }} align="center">
              <div style={{ width: "200px" }}>
                <InputGroup className="mb-3" size="sm">
                  <InputGroup.Text>{lang_data?.page}</InputGroup.Text>
                  <Form.Control
                    type="number"
                    defaultValue={page}
                    onChange={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    onKeyUp={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    onClick={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    style={{ textAlign: "center" }}
                  />
                  <InputGroup.Text>
                    {lang_data?.total_page} : {param.total_page}
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
