import th from "./th.json";
import en from "./en.json";
export default function lang(value) {
  if (value === "th") {
    return th;
  } else if (value === "en") {
    return en;
  } else {
    return th;
  }
}
