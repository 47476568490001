import React, { Component } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Container,
  Image,
  OverlayTrigger,
  Popover,
  Table,
  Modal,
  Button,
} from "react-bootstrap";
import axios from "axios";
import ReactPlayer from "react-player";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const language_list = Functions.language_list;

// const FixButtonVideo = styled.div`
//   .feedback {
//     background-color: #31b0d5;
//     color: white;
//     padding: 10px 20px;
//     border-radius: 4px;
//     border-color: #46b8da;
//   }
//   .mybutton {
//     position: fixed;
//     bottom: -4px;
//     right: 10px;
//   }
// `;

const BadgesStyle = styled.div`
  .demo-preview {
    padding-top: 60px;
    padding-bottom: 10px;
    margin: auto;
    text-align: center;
  }
  .demo-preview .badge {
    margin-right: 10px;
  }
  .badge {
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    padding: 3px 6px;
    border: 1px solid transparent;
    min-width: 10px;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 99999px;
  }

  .badge.badge-default {
    background-color: #b0bec5;
  }

  .badge.badge-primary {
    background-color: #2196f3;
  }

  .badge.badge-secondary {
    background-color: #dadee0;
  }

  .badge.badge-success {
    background-color: #64dd17;
  }

  .badge.badge-warning {
    background-color: #ffd600;
  }

  .badge.badge-info {
    background-color: #29b6f6;
  }

  .badge.badge-danger {
    background-color: #ef1c1c;
  }

  .badge.badge-outlined {
    background-color: transparent;
  }

  .badge.badge-outlined.badge-default {
    border-color: #b0bec5;
    color: #b0bec5;
  }

  .badge.badge-outlined.badge-primary {
    border-color: #2196f3;
    color: #0aa9fa;
  }

  .badge.badge-outlined.badge-secondary {
    border-color: #d1cdcd;
    color: #0085fa;
  }

  .badge.badge-outlined.badge-success {
    border-color: #64dd17;
    color: #64dd17;
  }

  .badge.badge-outlined.badge-warning {
    border-color: #ffd600;
    color: #ffd600;
  }

  .badge.badge-outlined.badge-info {
    border-color: #29b6f6;
    color: #29b6f6;
  }

  .badge.badge-outlined.badge-danger {
    border-color: #ef1c1c;
    color: #ef1c1c;
  }
`;
const ContentStyle = styled.div`
  :root {
    --color-gray-light-1: #f8f8f8;
    --color-gray-light-2: #e9e9e9;
    --color-gray-light-3: #dedede;
    --color-gray-dark-1: #545454;
    --color-gray-dark-2: #737373;
    --color-gray-dark-3: #9a9a9a;
    --color-blue-dark-1: #00387f;
    --color-tiffany: #00a6a6;
    --profile-theme: #4682bf;
    --timeline-circle-theme: #14253e;

    --pgbar-length: 100%;

    --MATH-PI: 3.1415px;
    --percent: 100;
  }

  * {
    box-sizing: border-box;
  }

  .wrapper {
    margin: 0 auto;
    max-width: 70rem;
    background-color: #4682bf;
    display: flex;
    box-shadow: 0px 0px 15px 4px #b3b3b3;
  }

  .intro {
    flex: 0 0 250px;
    background-color: var(--color-gray-light-1);
    box-shadow: 5px 0px 15px 0px #b3b3b3;
    z-index: 5;
  }
  .profile {
    position: relative;
    background-color: #ffffff;
    padding: 2rem 1rem;
    margin-bottom: 50px;
    text-align: center;
    user-select: none;
  }
  .profile::after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 100%;
    height: 30px;
    background-color: var(--profile-theme);
    transform: skewY(-5deg);
  }
  .photo img {
    width: 80%;
    border-radius: 50%;
  }
  .bio .name {
    font-size: 1.5rem;
    text-align: center;
    color: var(--color-gray-light-1);
    margin: 0;
    margin-top: 1rem;
  }
  .bio .profession {
    font-size: 1rem;
    text-align: center;
    color: var(--color-gray-light-1);
    margin: 0;
  }

  .intro-section {
    padding: 0 1rem;
    color: var(--color-gray-dark-1);
  }
  .intro-section .title {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .about .paragraph {
    text-align: justify;
  }
  .info-section {
    margin: 1rem 0;
  }
  .info-section span {
    position: relative;
    transition: all 0.3s;
  }
  .info-section i {
    color: var(--profile-theme);
    width: 20px;
    height: 20px;
  }

  .link a {
    text-decoration: none;
    color: inherit;
  }

  .link span::after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: -3px;
    width: 0;
    height: 2px;
    background-color: var(--profile-theme);
    transition: width 0.3s;
    /*transition-timing-function: cubic-bezier(.64,.51,.16,.86);*/
    transition-timing-function: cubic-bezier(0.92, 0.57, 0.33, 1.02);
    transform: translate(-50%, 0);
  }

  .link span:hover {
    color: var(--profile-theme);
  }
  .link span:hover::after {
    width: 100%;
  }

  /* Detail section overall setting*/
  .detail {
    flex: 1 0 0;
    background-color: white;
    padding: 2rem;
  }
  .detail:hover > .detail-section:not(:hover) {
    opacity: 0.3;
  }
  .detail-section {
    transition: all 0.3s;
  }
  .detail-section:not(:last-of-type) {
    padding-bottom: 1rem;
  }
  .detail-title {
    display: flex;
    align-items: center;
  }
  .detail-section > .detail-content {
    padding: 1.5rem;
    padding-left: 2rem;
    user-select: none;
  }
  .detail-section.edu > .detail-content {
    padding-left: calc(1.5rem + 10px);
  }
  .title-icon + span {
    font-size: 1.5rem;
    transition: all 0.3s;
  }
  .title-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--profile-theme);
    border-radius: 50%;
    transition: all 0.3s;
  }
  .title-icon i {
    color: white;
    line-height: 1rem;
    font-size: 1rem;
    text-align: center;
  }
  .detail-section:hover .title-icon {
    transform: scale(1.5);
    margin-right: 1.5rem;
  }
  .detail-section:hover .title-icon + span {
    letter-spacing: 1.5px;
  }

  /* time line block in education section */
  .timeline-block {
    position: relative;
    padding-left: 30px;
  }
  .timeline-block:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  .timeline-block h1 {
    font-size: 1rem;
    margin: 5px 0;
    transition: all 0.3s;
  }
  .timeline-block p {
    font-size: 0.8rem;
    margin: 5px 0;
  }
  .timeline-block time {
    font-size: 0.8rem;
    color: var(--color-gray-dark-2);
  }
  .timeline-block::before {
    position: absolute;
    content: "";
    width: 17px;
    height: 17px;
    background-color: white;
    border: 3px solid #000000;
    border-radius: 50%;
    left: -9px;
    top: -4px;
  }
  .timeline-block::after {
    position: absolute;
    content: "";
    width: 3px;
    height: 100%;
    background-color: #3b92e2;
    left: -1px;
    top: 13px;
  }
  .timeline-block:hover h1 {
    color: var(--color-tiffany);
  }
  .timeline-block:hover::before {
    animation: circle 1.2s infinite;
  }

  /* Programming skills section */
  .pg-list,
  .tool-list,
  .favor-list {
    padding: 0;
    list-style: none;
  }
  .pg-list > li {
    margin: 1rem 0;
    display: flex;
    align-items: center;
  }
  .sb-skeleton {
    position: relative;
    flex: 1 0 auto;
    height: 2px;
    background-color: var(--color-gray-dark-3);
  }
  .pg-list > li > span {
    flex: 0 0 100px;
  }
  .sb-skeleton > .skillbar {
    position: absolute;
    left: 0;
    top: -1px;
    width: var(--pgbar-length);
    height: 4px;
    background-color: var(--profile-theme);
  }
  .tool-list {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  .tool-list > li {
    position: relative;
    text-align: center;
    flex: 0 0 25%;
  }
  .tool-list > li > svg {
    position: relative;
    fill: transparent;
    width: 95%;
    transform: rotate(-90deg);
  }
  .tool-list > li > svg > circle {
    stroke-width: 1px;
    stroke: #cdcdcd;
  }
  .tool-list > li > svg > circle.cbar {
    stroke-width: 3px;
    stroke: var(--profile-theme);
    stroke-linecap: round;
    stroke-dashoffset: 0;
    stroke-dasharray: calc(var(--MATH-PI) * 45 * 2);
    transition: all 0.8s;
    transition-timing-function: cubic-bezier(0.64, 0.51, 0.16, 0.86);
  }
  .tool-list > li:hover > svg > circle.cbar {
    stroke-width: 4px;
    stroke: var(--color-tiffany);
    stroke-dashoffset: calc(var(--MATH-PI) * 45 * 2 * (1 - var(--percent)));
  }
  .tool-list > li > .tl-name,
  .tool-list > li > .tl-exp {
    position: absolute;
    left: 50%;
    color: var(--color-gray-dark-1);
  }
  .tool-list > li > .tl-name {
    top: 50%;
    font-size: 1.2rem;
    transform: translate(-50%, -50%);
  }
  .tool-list > li > .tl-exp {
    top: calc(50% + 1.4rem);
    font-size: 1rem;
    transform: translate(-50%, -50%);
  }
  /* Interests Section */
  .outer-frame {
    border: 1px solid var(--color-gray-dark-3);
    border-radius: 5px;
  }
  .favor-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }
  .favor-list > li {
    display: flex;
    flex: 1 0 0;
    align-items: center;
    justify-content: baseline;
    flex-direction: column;
    color: var(--profile-theme);
    padding: 1rem 0;
    transition: all 0.3s;
  }
  .favor-list > li > i {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    width: 50px;
    height: 50px;
  }
  .favor-list > li:hover {
    transform: scale(1.2);
    color: var(--color-tiffany);
  }
  .favor-list > li > span {
    letter-spacing: 1px;
  }

  @keyframes circle {
    0% {
      box-shadow: 0 0 0 0px rgba(51, 52, 57, 1);
    }
    100% {
      box-shadow: 0 0 0 6px rgba(51, 52, 57, 0);
    }
  }

  @media (max-width: 1024px) {
    html {
      font-size: 90%;
    }
    .wrapper {
      flex-direction: column;
    }
    .intro,
    .detail {
      flex: 0 0 100%;
      box-shadow: none;
    }
    .intro-section {
      padding: 0 2rem;
    }
    .profile {
      margin-bottom: 1rem;
    }
    .profile::after {
      display: none;
    }
    .photo img {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 768px) {
    html {
      font-size: 80%;
    }
    .tool-list {
      flex-wrap: wrap;
    }
    .tool-list > li {
      flex: 0 0 33.33%;
    }
    .favor-list > li {
      flex: 0 0 33.3333%;
    }
  }
  @media (max-width: 500px) {
    html {
      font-size: 70%;
    }
  }
`;

const GetDataForm = () => {
  const { user_id, name } = useParams();

  return <Resume name={name} user_id={user_id} />;
};
export default GetDataForm;
class Resume extends Component {
  state = {
    data: [],
    us_salary_start: 0,
    us_salary_end: 0,
    job_type_data: [],
    job_fuction_data: [],
    edus: [],
    exps: [],
    skills: [],
    languages: [],
    strengths: [],
    hobbies: [],
    rewards: [],
    isOpenModalPreview: false,
    isOpenModalVideo: false,
    reward_file_path: "",
    youtube_path: "",
  };

  getProfile = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;

          this.setState({
            data: res,
          });
          // console.log(user_detail.ud_email);
        });
    } catch (error) {
      // console.log(error);
      window.location = "/";
    }
  };
  getRating = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `userpart2/rating/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          let rating_result = res.result;
          this.renderStar(rating_result);
        });
    } catch (error) {
      console.log(error);
    }
  };

  renderStar = (value) => {
    let count = value;
    let remaining = 5 - count;
    let half_start = String(remaining).slice(2, 3);

    let obj = [];
    // star fill
    for (let i = 1; i <= count; i++) {
      let star = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      className="bi bi-star-fill"
      viewBox="0 0 16 16"
    >
      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
    </svg>`;
      obj.push(star);
    }
    // star half
    let r_h_s = "";
    if (half_start >= 1) {
      r_h_s = `<svg xmlns="http://www.w3.org/2000/svg" 
      width="32"
      height="32" 
      fill="currentColor" 
      className="bi bi-star-half" 
      viewBox="0 0 16 16">
      <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z"/>
    </svg>`;
    }
    // star blank

    for (let i = 1; i <= remaining; i++) {
      let star = `<svg xmlns="http://www.w3.org/2000/svg" 
      width="32"
      height="32" 
      fill="currentColor" 
      className="bi bi-star" viewBox="0 0 16 16">
      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
    </svg>`;
      obj.push(star);
    }

    document.getElementById("show_star").innerHTML = obj.join("") + r_h_s;
  };

  getSalaryData = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `userpart2/salary/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          // console.log(res);

          this.setState({
            us_salary_start: res.us_salary_start,
            us_salary_end: res.us_salary_end,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getJobType = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `userpart2/job_type/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({
            job_type_data: res,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getJobFunction = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/job_function/${user_id}`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({
            job_fuction_data: res,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getEducations = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/education/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ edus: res });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getExperience = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/experience/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ exps: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getSkills = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/skill_profile/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ skills: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getLanguages = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/language/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ languages: res });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getStrenght = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/strength/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ strengths: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getHobbies = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/hobbies/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ hobbies: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getRewards = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/rewards/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ rewards: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  refreshDataVideo = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/portfolio/${user_id}?portfolio_type=2`,
          Common.options
        )
        .then((response) => {
          let res = response.data;

          if (
            res?.portfolio_path !== null &&
            res?.portfolio_path !== undefined
          ) {
            this.setState({
              youtube_path: res.portfolio_path,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  groupConvert = (value) => {
    let text;
    if (value === 1) {
      text = "Computer Skills";
    } else if (value === 2) {
      text = "Competency Skills ";
    } else if (value === 3) {
      text = "Car";
    } else {
      text = "-";
    }
    return text;
  };
  filterLanguage = (id) => {
    if (id === 0 || id === undefined || id === null) {
      return false;
    }
    var r = language_list.filter(function (entry) {
      return entry.language_code === id;
    });

    return r;
  };
  componentDidMount() {
    this.getRating();
    this.getProfile();
    this.getSalaryData();
    this.getJobType();
    this.getJobFunction();
    this.getEducations();
    this.getExperience();
    this.getSkills();
    this.getLanguages();
    this.getStrenght();
    this.getHobbies();
    this.getRewards();
    this.refreshDataVideo();
  }
  render() {
    const {
      data,
      edus,
      exps,
      skills,
      job_fuction_data,
      job_type_data,
      languages,
      strengths,
      hobbies,
      rewards,
      isOpenModalPreview,
      youtube_path,
      isOpenModalVideo,
    } = this.state;
    return (
      <div style={{ paddingTop: 50, paddingBottom: 50 }}>
        <Container>
          <div align="center" style={{ paddingTop: 5, paddingBottom: 15 }}>
            {lang_data?.resume_waring}
          </div>
          <ContentStyle>
            <div className="wrapper">
              <div className="intro">
                <div className="profile">
                  <div className="photo">
                    {/* <Image src={"https://i.imgur.com/zh9eNsh.jpg"} /> */}
                    {Functions.check_empty_value(
                      data?.user_main_detail?.ud_image_profile
                    ) === false ? (
                      <Image
                        src={data?.user_main_detail?.ud_image_profile}
                        style={{ width: "200px", height: "200px" }}
                      />
                    ) : (
                      <Image
                        src={require("../../asset/images/no-user-image-icon-26.jpg")}
                        style={{ width: "200px", height: "200px" }}
                      />
                    )}
                  </div>
                  <div className="bio">
                    <div>
                      {/* Star Rating */}
                      <div>
                        <div
                          style={{
                            paddingLeft: "160px",
                            cursor: "pointer",
                            color: "#0085FA",
                          }}
                        >
                          <OverlayTrigger
                            trigger="click"
                            placement="right"
                            overlay={
                              <Popover>
                                {/* <Popover.Header as="h3">top</Popover.Header> */}
                                <Popover.Body>
                                  {/* <strong>Holy guacamole!</strong> */}
                                  <div>
                                    <span
                                      style={{
                                        color: "orange",
                                        fontSize: "18px",
                                      }}
                                    >
                                      ★
                                    </span>{" "}
                                    Complete your general information
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        color: "orange",
                                        fontSize: "18px",
                                      }}
                                    >
                                      ★★
                                    </span>{" "}
                                    Complete your education background
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        color: "orange",
                                        fontSize: "18px",
                                      }}
                                    >
                                      ★★★
                                    </span>{" "}
                                    Complete work experience
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        color: "orange",
                                        fontSize: "18px",
                                      }}
                                    >
                                      ★★★★
                                    </span>{" "}
                                    Upload your self introduction VDO clip
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        color: "orange",
                                        fontSize: "18px",
                                      }}
                                    >
                                      ★★★★★
                                    </span>{" "}
                                    Answer questions from the company via VDO
                                    clips
                                  </div>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-info-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                            </svg>
                          </OverlayTrigger>
                        </div>
                        <span
                          id="show_star"
                          style={{ color: "#FFCA0B" }}
                        ></span>
                      </div>
                      {/*End Star Rating */}
                    </div>
                    <h1 className="name">
                      {data?.firstname} {data?.lastname}
                    </h1>
                    <div className="profession">
                      {/* Tag */}
                      <BadgesStyle>
                        <div>
                          {job_fuction_data?.map((rs, i) => (
                            <span key={i}>
                              <div className="badge badge-secondary badge-outlined">
                                {rs?.user_job_function?.jbc_name}
                              </div>{" "}
                            </span>
                          ))}
                        </div>
                        <div>
                          {job_type_data?.map((rs, i) => (
                            <span key={i}>
                              <div className="badge badge-secondary badge-outlined">
                                {rs?.user_job_type?.jt_name}
                              </div>{" "}
                            </span>
                          ))}
                        </div>
                        <div>
                          <span>
                            <div className="badge badge-secondary badge-outlined">
                              {lang_data?.expected_salary}{" "}
                              {Functions.formatNumberWithComma(
                                this.state.us_salary_start
                              )}
                              {" - "}
                              {Functions.formatNumberWithComma(
                                this.state.us_salary_end
                              )}
                            </div>
                          </span>
                        </div>
                      </BadgesStyle>
                      {/* End Tag */}
                    </div>
                  </div>
                </div>
                {/* <div className="intro-section about">
                  <h1 className="title">about me</h1>
                  <p className="paragraph">
                    Hi everyone, I am a web front-end developer, graduated from
                    NTHU, Taiwan and my major is CS. I recently try my best to
                    improve my skills on web front-end. My favorite thing is to
                    observe others' portfolio.
                  </p>
                </div> */}
                <div
                  className="intro-section contact"
                  style={{ color: "white" }}
                >
                  <h1 className="title"> {lang_data?.contact_label}</h1>
                  <div className="info-section">
                    <i className="fas fa-phone"></i>
                    <span>{data?.phone} </span>
                  </div>
                  <div className="info-section">
                    <i className="fas fa-map-marker-alt"></i>
                    <span>
                      <div>
                        {data?.user_main_detail?.user_tambon?.tambon_thai} /{" "}
                        {data?.user_main_detail?.user_tambon?.tambon_eng}
                      </div>
                      <div>
                        {data?.user_main_detail?.user_tambon?.district_thai} /{" "}
                        {data?.user_main_detail?.user_tambon?.district_eng}
                      </div>
                      <div>
                        {data?.user_main_detail?.user_tambon?.province_thai} /{" "}
                        {data?.user_main_detail?.user_tambon?.province_eng}
                      </div>
                    </span>
                  </div>
                  <div className="info-section">
                    <i className="fas fa-paper-plane"></i>
                    <span>{data?.email} </span>
                  </div>
                  <div className="info-section link">
                    <i className="fab fa-facebook"></i>
                  </div>
                </div>
              </div>
              <div className="detail">
                {/* ประวัติการศึกษา */}
                <div className="detail-section edu">
                  <div className="detail-title">
                    <div className="title-icon">
                      <Image
                        src={require("../../asset/images/education.webp")}
                        style={{ width: 50, height: 50 }}
                      />
                    </div>
                    <span> {lang_data?.education_history}</span>
                  </div>
                  <div className="detail-content">
                    {edus?.map((rs, i) => (
                      <div className="timeline-block" key={i}>
                        <h1>{rs.edu_faculty}</h1>
                        <div>{rs.edu_institution.institution_name}</div>
                        <time>{rs.edu_graduation_year}</time>
                      </div>
                    ))}
                  </div>
                </div>
                {/* End ประวัติการศึกษา */}
                {/* ประวัติการทำงาน */}
                <div className="detail-section edu">
                  <div className="detail-title">
                    <div className="title-icon">
                      <Image
                        src={require("../../asset/images/rating.webp")}
                        style={{ width: 50, height: 50 }}
                      />
                    </div>
                    <span> {lang_data?.workExperience}</span>
                  </div>
                  <div className="detail-content">
                    {exps?.map((rs, i) => (
                      <div className="timeline-block" key={i}>
                        <h1>{rs.exp_last_position}</h1>
                        <div>{rs.exp_comapany}</div>
                        <time>
                          {rs.exp_year_start} -{" "}
                          {Functions.checkPresentday(rs.exp_year_end)}
                        </time>
                      </div>
                    ))}
                  </div>
                </div>
                {/* End ประวัติการทำงาน */}
                {/* ทักษะทางเฉพาะทาง */}
                <div className="detail-section pg-skill">
                  <div className="detail-title">
                    <div className="title-icon">
                      <Image
                        src={require("../../asset/images/personal-development.webp")}
                        style={{ width: 50, height: 50 }}
                      />
                    </div>
                    <span> {lang_data?.specializedSkills}</span>
                  </div>
                  <div className="detail-content">
                    <ul className="pg-list">
                      {skills?.map((rs, i) => (
                        <li key={i}>
                          {this.groupConvert(
                            parseInt(rs.skill_profile_child.skill_group_type)
                          )}
                          {rs.skill_profile_detail !== ""
                            ? " : " + rs.skill_profile_detail
                            : ""}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/*End  ทักษะทางเฉพาะทาง */}
                {/* ทักษะทางภาษา */}
                <div className="detail-section pg-skill">
                  <div className="detail-title">
                    <div className="title-icon">
                      <Image
                        src={require("../../asset/images/translation.webp")}
                        style={{ width: 50, height: 50 }}
                      />
                    </div>
                    <span> {lang_data?.language_skills}</span>
                  </div>
                  <div className="detail-content">
                    <Table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Language </th>
                          <th>Overall </th>
                          <th>Type </th>
                          <th>Score </th>
                        </tr>
                      </thead>
                      <tbody>
                        {languages?.map((rs, i) => (
                          <tr key={i}>
                            <td style={{ width: "10%" }}>
                              <Image
                                src={
                                  this.filterLanguage(rs.language_code)[0]
                                    .language_flag
                                }
                                style={{ width: 70, height: "auto" }}
                              />
                            </td>
                            <td>
                              {
                                this.filterLanguage(rs.language_code)[0]
                                  .language_name_eng
                              }
                            </td>
                            <td>{rs.language_overall}</td>
                            <td>{rs.language_type}</td>
                            <td>{rs.language_score}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
                {/*End  ทักษะทางภาษา */}
                {/* จุดแข็ง */}
                <div className="detail-section pg-skill">
                  <div className="detail-title">
                    <div className="title-icon">
                      <Image
                        src={require("../../asset/images/muscle.webp")}
                        style={{ width: 50, height: 50 }}
                      />
                    </div>
                    <span> {lang_data?.strength_title}</span>
                  </div>
                  <div className="detail-content">
                    <ul className="pg-list">
                      {strengths?.map((rs, i) => (
                        <li key={i}>{rs.strength_name}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/*End  จุดแข็ง */}
                {/* งานอดิเรก */}
                <div className="detail-section pg-skill">
                  <div className="detail-title">
                    <div className="title-icon">
                      <Image
                        src={require("../../asset/images/puzzle.webp")}
                        style={{ width: 50, height: 50 }}
                      />
                    </div>
                    <span> {lang_data?.hobbie_header}</span>
                  </div>
                  <div className="detail-content">
                    <ul className="pg-list">
                      {hobbies?.map((rs, i) => (
                        <li key={i}>{rs.hobby_name}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/*End  งานอดิเรก */}
                {/* รางวัล */}
                <div className="detail-section pg-skill">
                  <div className="detail-title">
                    <div className="title-icon">
                      <Image
                        src={require("../../asset/images/trophy.webp")}
                        style={{ width: 50, height: 50 }}
                      />
                    </div>
                    <span> {lang_data?.reward_certificate}</span>
                  </div>
                  <div className="detail-content">
                    <ul className="pg-list">
                      {rewards?.map((rs, i) => (
                        <li key={i}>
                          {rs.reward_file_path === "" && (
                            <div>{rs.reward_name}</div>
                          )}
                          {rs.reward_file_path !== "" && (
                            <div
                              onClick={() =>
                                this.setState({
                                  reward_file_path: rs.reward_file_path,
                                  isOpenModalPreview: true,
                                })
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <u>{rs.reward_name}</u>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/*End  รางวัล */}

                {/* Video แนะนำตัว */}
                {youtube_path !== "" && (
                  <div className="detail-section pg-skill">
                    <div className="detail-title">
                      <div className="title-icon">
                        <Image
                          src={require("../../asset/images/presentation.png")}
                          style={{ width: 50, height: 50 }}
                        />
                      </div>
                      <span> {lang_data?.video_presentation_candidate}</span>
                    </div>
                    <div className="detail-content">
                      <ReactPlayer
                        url={youtube_path}
                        controls={true}
                        playsinline={true}
                        width={"100%"}
                        height={450}
                        config={{
                          youtube: {
                            playerVars: { showinfo: 0 },
                          },
                        }}
                      />
                    </div>
                  </div>
                )}

                {/* Video แนะนำตัว*/}
              </div>
            </div>
          </ContentStyle>
        </Container>
        <Modal
          size="lg"
          show={isOpenModalPreview}
          onHide={() => this.setState({ isOpenModalPreview: false })}
        >
          <Modal.Body>
            {this.state.reward_file_path !== "" ? (
              <Image
                id="img_preview"
                thumbnail
                src={this.state.reward_file_path}
                style={{ width: "100%", height: "auto" }}
              />
            ) : (
              <Image
                id="img_preview"
                thumbnail
                src={require("../../asset/images/6189389_3204121.jpg")}
                style={{ width: "100%", height: "auto" }}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => this.setState({ isOpenModalPreview: false })}
              variant="secondary"
            >
              {lang_data?.close}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={isOpenModalVideo}
          onHide={() => this.setState({ isOpenModalVideo: false })}
        >
          <Modal.Body>
            <div>
              <ReactPlayer
                url={youtube_path}
                controls={true}
                playsinline={true}
                width={"100%"}
                height={450}
                config={{
                  youtube: {
                    playerVars: { showinfo: 0 },
                  },
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => this.setState({ isOpenModalVideo: false })}
              variant="secondary"
            >
              {lang_data?.close}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <FixButtonVideo>
          <div className="mybutton">
            <Button
              variant="info"
              onClick={() => this.setState({ isOpenModalVideo: true })}
              disabled={youtube_path === "" ? true : false}
            >
              {lang_data?.video_presentation_candidate}
            </Button>
          </div>
        </FixButtonVideo> */}
      </div>
    );
  }
}
