import React, { Component, Suspense } from "react";
import { useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import CandidateDetailEducation from "./CandidateDetailEducation";
import CandidateDetailExperience from "./CandidateDetailExperience";
import CandidateDetailLanguage from "./CandidateDetailLanguage";
import CandidateDetailSkill from "./CandidateDetailSkill";
import CandidateDetailRewards from "./CandidateDetailRewards";
import CandidateDetailHobby from "./CandidateDetailHobby";
import CandidateDetailStrenght from "./CandidateDetailStrenght";
import CandidateDetailVideoPresent from "./CandidateDetailVideoPresent";
import CandidateDetailVideoInterview from "./CandidateDetailVideoInterview";
import Loading_2 from "../../asset/images/Loading_2.gif";

const GetDataForm = () => {
  const { user_id, name } = useParams();

  return <CandidateDetail user_id={user_id} name={name} />;
};
export default GetDataForm;

class CandidateDetail extends Component {
  render() {
    return (
      <div>
        <Suspense
          fallback={
            <div align="center">
              <Card.Img
                variant="top"
                src={Loading_2}
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          }
        >
          <Row>
            <Col sm={8}>
              <h3>{this.props.name}</h3>
            </Col>
            <Col sm={4}>
              <Breadcrumb>
                <Breadcrumb.Item href="/">หน้าหลัก</Breadcrumb.Item>

                <LinkContainer to="/candidate">
                  <Breadcrumb.Item>ผู้สมัคร</Breadcrumb.Item>
                </LinkContainer>

                <Breadcrumb.Item active>รายละเอียด</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Tabs
            defaultActiveKey="education"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="education" title="ประวัติการศึกษา">
              <CandidateDetailEducation user_id={this.props.user_id} />
            </Tab>
            <Tab eventKey="experience" title="ประวัติการทำงาน">
              <CandidateDetailExperience user_id={this.props.user_id} />
            </Tab>
            <Tab eventKey="language" title="ทักษะด้านภาษา">
              <CandidateDetailLanguage user_id={this.props.user_id} />
            </Tab>
            <Tab eventKey="skills" title="ทักษะเฉพาะทาง">
              <CandidateDetailSkill user_id={this.props.user_id} />
            </Tab>
            <Tab eventKey="rewards" title="รางวัล / ใบประกาศนียบัตร">
              <CandidateDetailRewards user_id={this.props.user_id} />
            </Tab>
            <Tab eventKey="hobby" title="งานอดิเรก">
              <CandidateDetailHobby user_id={this.props.user_id} />
            </Tab>
            <Tab eventKey="strenght" title="จุดแข็ง">
              <CandidateDetailStrenght user_id={this.props.user_id} />
            </Tab>
            <Tab eventKey="video_presentation" title="วีดีโอนำเสนอตัวเอง">
              <CandidateDetailVideoPresent user_id={this.props.user_id} />
            </Tab>
            <Tab eventKey="video_interview" title="วีดีโอสัมภาษณ์**">
              <CandidateDetailVideoInterview user_id={this.props.user_id} />
            </Tab>
          </Tabs>
        </Suspense>
      </div>
    );
  }
}
