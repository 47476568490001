import React, { Component } from "react";
import { Button, Card, Container, Modal, Image } from "react-bootstrap";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import Functions from "../../functions";
import Common from "../../common";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const user_id = Common.token;
export default class CalendarEvent extends Component {
  state = {
    openModal: false,
    data: [],
    loading_cover: true,
  };
  getDataEvent = async (fetchInfo, successCallback) => {
    //
    let startStr;
    let endStr;
    if (fetchInfo) {
      // console.log(fetchInfo.endStr);
      let start_set = new Date(fetchInfo?.startStr);
      let end_set = new Date(fetchInfo?.endStr);
      startStr = start_set.toISOString().split("T")[0];
      endStr = end_set.toISOString().split("T")[0];
    }
    // console.log(endStr);
    try {
      await axios
        .post(
          Common.API_URL + `general/calendar_event/${user_id}`,
          {
            start: startStr,
            end: endStr,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          // console.log(res);
          if (res !== undefined) {
            successCallback(
              res?.map((event) => {
                return {
                  id: event.id,
                  title: event.title,
                  start: event.start,
                  end: event.end,
                  color: event.color,
                  // url: event.url,
                };
              })
            );
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  eventDetail = async (e) => {
    // console.log(e);
    // console.log(e?.event?.title);
    // console.log(e?.event?.id);
    const jpa_id = e?.event?.id;
    this.setState({
      loading_cover: true,
    });
    try {
      await axios
        .get(
          Common.API_URL + `j/job_position/apply/detail/${jpa_id}`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({
            data: res,
            openModal: true,
          });
        });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        loading_cover: false,
      });
    }
  };

  render() {
    const { data, openModal, loading_cover } = this.state;
    return (
      <div>
        <Container>
          <div style={{ paddingTop: "40px", height: "100vh" }}>
            <Card>
              <Card.Body>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin]}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  weekends={true}
                  eventClick={this.eventDetail}
                  events={(fetchInfo, successCallback, failureCallback) =>
                    this.getDataEvent(
                      fetchInfo,
                      successCallback,
                      failureCallback
                    )
                  }
                  editable={true}
                  eventOrder="start"
                />
              </Card.Body>
            </Card>
          </div>
        </Container>

        <Modal
          show={openModal}
          onHide={() => this.setState({ openModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title> {lang_data?.appointment}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loading_cover === true ? (
              <div align="center">
                <Image src={require("../../asset/images/Loading_2.gif")} />
              </div>
            ) : (
              <div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-calendar-week"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z" />
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                  </svg>{" "}
                  {lang_data?.appointment_position}{" "}
                  <strong>{data?.jpa_job?.jp_position}</strong>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-person-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                  </svg>{" "}
                  {lang_data?.interview_candidate}{" "}
                  <strong>
                    {data?.jpa_user?.firstname} {data?.jpa_user?.lastname}
                  </strong>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-clock-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                  </svg>{" "}
                  {lang_data?.date_label}{" "}
                  <strong>
                    {Functions.format_date_time_picker(data?.jpa_appointment)}
                  </strong>
                </div>
                {data?.jpa_conf_url !== "" ? (
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-camera-video-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2z"
                      />
                    </svg>{" "}
                    {lang_data?.interview_online}
                    <Button
                      variant="link"
                      href={data?.jpa_conf_url}
                      target="_blank"
                    >
                      Link
                    </Button>
                  </div>
                ) : (
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-buildings-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 .5a.5.5 0 0 0-.724-.447l-8 4A.5.5 0 0 0 6 4.5v3.14L.342 9.526A.5.5 0 0 0 0 10v5.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V14h1v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zM2 11h1v1H2zm2 0h1v1H4zm-1 2v1H2v-1zm1 0h1v1H4zm9-10v1h-1V3zM8 5h1v1H8zm1 2v1H8V7zM8 9h1v1H8zm2 0h1v1h-1zm-1 2v1H8v-1zm1 0h1v1h-1zm3-2v1h-1V9zm-1 2h1v1h-1zm-2-4h1v1h-1zm3 0v1h-1V7zm-2-2v1h-1V5zm1 0h1v1h-1z" />
                    </svg>{" "}
                    {lang_data?.interview_office_location}
                  </div>
                )}
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-person-lines-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z" />
                  </svg>{" "}
                  {lang_data?.by}
                  <Button
                    variant="link"
                    target="_blank"
                    href={"/company/" + data?.jpa_job?.job_user?.user_id}
                  >
                    {
                      data?.jpa_job?.job_user?.user_main_company
                        ?.uc_company_name
                    }{" "}
                  </Button>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ openModal: false })}
            >
              {lang_data?.close}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
