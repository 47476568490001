import React, { Component, Suspense } from "react";
import {
  Card,
  Container,
  Col,
  Row,
  Button,
  Form,
  ListGroup,
  OverlayTrigger,
  Popover,
  Image,
} from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";
import "rc-slider/assets/index.css";
import Common from "../../common";
import Functions from "../../functions";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const ProfileDetail = React.lazy(() => import("./ProfileDetail"));
const ProfileExperience = React.lazy(() => import("./ProfileExperience"));
const ProfileEducation = React.lazy(() => import("./ProfileEducation"));
const ProfileLanguage = React.lazy(() => import("./ProfileLanguage"));
const ProfileSkills = React.lazy(() => import("./ProfileSkills"));
const ProfilePortfolio = React.lazy(() => import("./ProfilePortfolio"));
const ProfileMain = React.lazy(() => import("./ProfileMain"));

const user_id = Common.token;
const present_day = new Date().toISOString().split("T")[0];

const BadgesStyle = styled.div`
  .demo-preview {
    padding-top: 60px;
    padding-bottom: 10px;
    margin: auto;
    text-align: center;
  }
  .demo-preview .badge {
    margin-right: 10px;
  }
  .badge {
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    padding: 3px 6px;
    border: 1px solid transparent;
    min-width: 10px;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 99999px;
  }

  .badge.badge-default {
    background-color: #b0bec5;
  }

  .badge.badge-primary {
    background-color: #2196f3;
  }

  .badge.badge-secondary {
    background-color: #dadee0;
  }

  .badge.badge-success {
    background-color: #64dd17;
  }

  .badge.badge-warning {
    background-color: #ffd600;
  }

  .badge.badge-info {
    background-color: #29b6f6;
  }

  .badge.badge-danger {
    background-color: #ef1c1c;
  }

  .badge.badge-outlined {
    background-color: transparent;
  }

  .badge.badge-outlined.badge-default {
    border-color: #b0bec5;
    color: #b0bec5;
  }

  .badge.badge-outlined.badge-primary {
    border-color: #2196f3;
    color: #0aa9fa;
  }

  .badge.badge-outlined.badge-secondary {
    border-color: #d1cdcd;
    color: #0085fa;
  }

  .badge.badge-outlined.badge-success {
    border-color: #64dd17;
    color: #64dd17;
  }

  .badge.badge-outlined.badge-warning {
    border-color: #ffd600;
    color: #ffd600;
  }

  .badge.badge-outlined.badge-info {
    border-color: #29b6f6;
    color: #29b6f6;
  }

  .badge.badge-outlined.badge-danger {
    border-color: #ef1c1c;
    color: #ef1c1c;
  }
`;

export default class Profile extends Component {
  state = {
    filecore: "",
    filecore_cover: "",

    profilePageControl: "profile",

    job_type_data: [],
    job_fuction_data: [],

    us_salary_start: 0,
    us_salary_end: 0,

    ud_image_profile: "",
    ud_image_cover: "",
    ud_image_cover_position: "",
    ud_verify: 0,
    ud_bio: "",
    ud_birhday: present_day,
    ud_gender: 0,
    ud_address: "",
    ud_code: "",
    tambon_id: 0,
    country_id: 0,
  };

  refreshData = async () => {
    try {
      await axios
        .get(Common.API_URL + `user/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          let user_detail = res?.user_main_detail;
          // username
          this.setState({
            username: res?.username,
          });
          if (user_detail !== undefined) {
            this.setState({
              ud_image_profile: user_detail?.ud_image_profile,
              ud_image_cover: user_detail?.ud_image_cover,
              ud_image_cover_position: user_detail?.ud_image_cover_position,
              ud_verify: user_detail?.ud_verify,
              ud_bio: user_detail?.ud_bio,
              ud_birhday: user_detail?.ud_birhday,
              ud_gender: user_detail?.ud_gender,
              ud_address: user_detail?.ud_address,
              ud_code: user_detail?.ud_code,
              tambon_id: user_detail?.tambon_id,
              country_id: user_detail?.country_id,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleOffcanvasClose = () => {
    this.setState({
      openOffcanvas: false,
    });
  };
  readURL = (event) => {
    if (event.target.files && event.target.files[0]) {
      var output = document.getElementById("imgprofile");
      output.src = URL.createObjectURL(event.target.files[0]);
      output.onload = function () {
        URL.revokeObjectURL(output.src); // free memory
      };
      this.setState({
        filecore: event,
      });
    }
  };

  uploadImageProfile = async (event) => {
    let file = event.target.files[0];
    const formdata = new FormData();
    formdata.append("file", file);
    try {
      await axios
        .post(Common.API_FILE + "file/upload", formdata, Common.options2)
        .then((res) => {
          const r = res?.data;
          const path = r?.path;
          this.handleSubmit(path, this.state.ud_image_cover);
        });
    } catch (error) {
      console.log(error);
    }
  };

  DeleteImage = async (image) => {
    if (image === "" || image === null) {
      return false;
    }
    const url = new URL(image);
    const searchParams = url.searchParams;
    const path = searchParams.get("path");
    try {
      await axios.delete(
        Common.API_FILE + `file/delete?path=${path}`,
        Common.options2
      );
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = async (new_image_prifile, new_image_cover) => {
    this.DeleteImage(this.state.ud_image_profile);
    try {
      await axios
        .put(
          Common.API_URL + `user/detail/${user_id}`,
          {
            ud_image_profile: new_image_prifile,
            ud_image_cover: new_image_cover,
            ud_image_cover_position: this.state.ud_image_cover_position,
            ud_verify: this.state.ud_verify,
            ud_bio: this.state.ud_bio,
            ud_birhday: this.state.ud_birhday,
            ud_gender: this.state.ud_gender,
            ud_address: this.state.ud_address,
            tambon_id: this.state.tambon_id,
            country_id: this.state.country_id,
          },
          Common.options
        )
        .then((res) => {
          window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };
  getRating = async () => {
    try {
      await axios
        .get(Common.API_URL + `userpart2/rating/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          let rating_result = res.result;
          this.renderStar(rating_result);
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClickUploadImg = () => {
    document.getElementById("image_profile_upload").click();
  };

  onSubmit = () => {
    this.uploadImageProfile(this.state.filecore);
  };

  renderStar = (value) => {
    let count = value;
    let remaining = 5 - count;
    let half_start = String(remaining).slice(2, 3);

    let obj = [];
    // star fill
    for (let i = 1; i <= count; i++) {
      let star = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      className="bi bi-star-fill"
      viewBox="0 0 16 16"
    >
      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
    </svg>`;
      obj.push(star);
    }
    // star half
    let r_h_s = "";
    if (half_start >= 1) {
      r_h_s = `<svg xmlns="http://www.w3.org/2000/svg" 
      width="32"
      height="32" 
      fill="currentColor" 
      className="bi bi-star-half" 
      viewBox="0 0 16 16">
      <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z"/>
    </svg>`;
    }
    // star blank

    for (let i = 1; i <= remaining; i++) {
      let star = `<svg xmlns="http://www.w3.org/2000/svg" 
      width="32"
      height="32" 
      fill="currentColor" 
      className="bi bi-star" viewBox="0 0 16 16">
      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
    </svg>`;
      obj.push(star);
    }

    document.getElementById("show_star").innerHTML = obj.join("") + r_h_s;
  };

  getSalaryData = async () => {
    try {
      await axios
        .get(Common.API_URL + `userpart2/salary/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          // console.log(res);

          this.setState({
            us_salary_start: res.us_salary_start,
            us_salary_end: res.us_salary_end,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getJobType = async () => {
    try {
      await axios
        .get(Common.API_URL + `userpart2/job_type/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({
            job_type_data: res,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getJobFunction = async () => {
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/job_function/${user_id}`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({
            job_fuction_data: res,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.getRating();
    this.getSalaryData();
    this.getJobFunction();
    this.getJobType();
    this.refreshData();
  }

  render() {
    const { username, profilePageControl, job_fuction_data, job_type_data } =
      this.state;

    return (
      <div>
        <div>
          <Suspense
            fallback={
              <div align="center">
                <Image
                  src={require("../../asset/images/Loading_2.gif")}
                  style={{ width: "200px", height: "200px" }}
                  id="imgprofile"
                  thumbnail
                  roundedCircle
                />
              </div>
            }
          >
            <ProfileMain />
          </Suspense>
        </div>

        <Container>
          <Row
            style={{
              paddingTop: "20px",
              padding: "10px",
            }}
          >
            {/* User Profile Card */}
            <Col sm="12" md="6" lg="3">
              <Card>
                <Card.Header align="center">
                  <div className="gallery">
                    <li>
                      {Functions.check_empty_value(
                        this.state.ud_image_profile
                      ) === false ? (
                        <Image
                          src={this.state.ud_image_profile}
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "cover",
                          }}
                          id="imgprofile"
                          thumbnail
                          roundedCircle
                        />
                      ) : (
                        <Image
                          src={require("../../asset/images/no-user-image-icon-26.jpg")}
                          style={{ width: "200px", height: "200px" }}
                          id="imgprofile"
                          thumbnail
                          roundedCircle
                        />
                      )}

                      <span
                        style={{ cursor: "pointer" }}
                        onClick={this.onClickUploadImg}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          className="bi bi-camera-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                          <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                        </svg>
                      </span>
                    </li>
                  </div>

                  <Form.Control
                    type="file"
                    size="sm"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => this.readURL(e)}
                    id="image_profile_upload"
                    style={{ display: "none" }}
                  />
                  {this.state.filecore !== "" && (
                    <Button
                      variant="success"
                      onClick={this.onSubmit}
                      disabled={this.state.filecore === "" ? true : false}
                    >
                      {lang_data?.save}
                    </Button>
                  )}
                  {/* Star Rating */}
                  <div>
                    <div
                      style={{
                        paddingLeft: "160px",
                        cursor: "pointer",
                        color: "#0085FA",
                      }}
                    >
                      <OverlayTrigger
                        trigger="click"
                        placement="right"
                        overlay={
                          <Popover>
                            {/* <Popover.Header as="h3">top</Popover.Header> */}
                            <Popover.Body>
                              {/* <strong>Holy guacamole!</strong> */}
                              <div>
                                <span
                                  style={{ color: "orange", fontSize: "18px" }}
                                >
                                  ★
                                </span>{" "}
                                Complete your general information
                              </div>
                              <div>
                                <span
                                  style={{ color: "orange", fontSize: "18px" }}
                                >
                                  ★★
                                </span>{" "}
                                Complete your education background
                              </div>
                              <div>
                                <span
                                  style={{ color: "orange", fontSize: "18px" }}
                                >
                                  ★★★
                                </span>{" "}
                                Complete work experience
                              </div>
                              <div>
                                <span
                                  style={{ color: "orange", fontSize: "18px" }}
                                >
                                  ★★★★
                                </span>{" "}
                                Upload your self introduction VDO clip
                              </div>
                              <div>
                                <span
                                  style={{ color: "orange", fontSize: "18px" }}
                                >
                                  ★★★★★
                                </span>{" "}
                                Answer questions from the company via VDO clips
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-info-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                        </svg>
                      </OverlayTrigger>
                    </div>
                    <span id="show_star" style={{ color: "#FFCA0B" }}></span>
                  </div>
                  {/*End Star Rating */}
                  {/* Tag */}
                  <BadgesStyle>
                    <div>
                      {job_fuction_data?.map((rs, i) => (
                        <span key={i}>
                          <div className="badge badge-secondary badge-outlined">
                            {rs?.user_job_function?.jbc_name}
                          </div>{" "}
                        </span>
                      ))}
                    </div>
                    <div>
                      {job_type_data?.map((rs, i) => (
                        <span key={i}>
                          <div className="badge badge-secondary badge-outlined">
                            {rs?.user_job_type?.jt_name}
                          </div>{" "}
                        </span>
                      ))}
                    </div>
                    <div>
                      <span>
                        <div className="badge badge-secondary badge-outlined">
                          {lang_data?.expected_salary}{" "}
                          {Functions.formatNumberWithComma(
                            this.state.us_salary_start
                          )}
                          {" - "}
                          {Functions.formatNumberWithComma(
                            this.state.us_salary_end
                          )}
                        </div>
                      </span>
                    </div>
                  </BadgesStyle>
                  {/* End Tag */}
                  <span> @{username}</span>
                </Card.Header>
                {/* Menu */}
                <ListGroup variant="flush">
                  <ListGroup.Item
                    onClick={(e) =>
                      this.setState({ profilePageControl: "profile" })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-person-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    </svg>{" "}
                    {lang_data?.profile}
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={(e) =>
                      this.setState({ profilePageControl: "ProfileEducation" })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-mortarboard-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5Z" />
                      <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Z" />
                    </svg>{" "}
                    {lang_data?.education_history}
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={(e) =>
                      this.setState({ profilePageControl: "experience" })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-building-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h3v-3.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V16h3a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H3Zm1 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Z" />
                    </svg>{" "}
                    {lang_data?.workExperience}
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={(e) =>
                      this.setState({ profilePageControl: "language" })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-translate"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />
                      <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" />
                    </svg>{" "}
                    {lang_data?.language_skills}
                  </ListGroup.Item>

                  <ListGroup.Item
                    onClick={(e) =>
                      this.setState({ profilePageControl: "skills" })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-gear-wide"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z" />
                    </svg>{" "}
                    {lang_data?.specializedSkills}
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={(e) =>
                      this.setState({ profilePageControl: "portfolio" })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-person-video2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                      <path d="M2 1a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2ZM1 3a1 1 0 0 1 1-1h2v2H1V3Zm4 10V2h9a1 1 0 0 1 1 1v9c0 .285-.12.543-.31.725C14.15 11.494 12.822 10 10 10c-3.037 0-4.345 1.73-4.798 3H5Zm-4-2h3v2H2a1 1 0 0 1-1-1v-1Zm3-1H1V8h3v2Zm0-3H1V5h3v2Z" />
                    </svg>{" "}
                    {lang_data?.video_presentation_candidate}
                  </ListGroup.Item>
                </ListGroup>

                {/*End Menu */}
              </Card>
            </Col>
            {/* End User Profile Card */}
            {/*Show Content  */}
            <Col sm="12" md="6" lg="8">
              <Suspense
                fallback={
                  <div align="center">
                    <Image
                      src={require("../../asset/images/Loading_2.gif")}
                      style={{ width: "200px", height: "200px" }}
                      id="imgprofile"
                      thumbnail
                      roundedCircle
                    />
                  </div>
                }
              >
                <div>
                  {profilePageControl === "profile" && <ProfileDetail />}
                  {profilePageControl === "ProfileEducation" && (
                    <ProfileEducation />
                  )}
                  {profilePageControl === "experience" && <ProfileExperience />}
                  {profilePageControl === "language" && <ProfileLanguage />}
                  {profilePageControl === "skills" && <ProfileSkills />}
                  {profilePageControl === "portfolio" && <ProfilePortfolio />}
                </div>
              </Suspense>
            </Col>
            {/*Show Content  */}
          </Row>
        </Container>
      </div>
    );
  }
}
