import React, { Component } from "react";
import {
  Badge,
  Button,
  Container,
  Card,
  Form,
  Modal,
  Row,
  Col,
  InputGroup,
  Tab,
  Tabs,
  Image,
} from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import axios from "axios";
import Swal from "sweetalert2";
import styled from "styled-components";
import Common from "../../common";
import Functions from "../../functions";
import ProfileMain from "./ProfileMain";
import JobPositionFormDetail from "./JobPositionFormDetail";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const user_id = Common.token;
const present_day = new Date().toISOString().split("T")[0];
const customStyles = {
  control: (base) => ({
    ...base,
    height: 45,
    minHeight: 45,
  }),
};

const customStyles2 = {
  control: (base) => ({
    ...base,
    height: 45,
    minHeight: 45,
    border: "1px solid red",
  }),
};
const cropText = {
  display: "inline-block",
  overflow: "hidden",
  whiteSpace: "nowrap",
  overflowWrap: "ellipsis",
  width: "100%",
};
const CardStyle = styled.div`
  .feature {
    position: relative;
    // max-width: 21rem;
    width: 100%;
    height: 25rem;
    margin: 2rem auto;
    padding: 2em;
    border-radius: 0.75em;
    box-shadow: 5px 5px 20px rgba(0 0 0/0.15);
    text-align: left;
    transition: transform 200ms ease-in;
  }

  // .feature:hover {
  //   transform: scale(1.03);
  // }

  .feature__desc {
    margin-top: 0.5em;
    color: #a3adc9;
  }

  .feature__img {
    position: absolute;
    bottom: 10%;
    right: 10%;
  }

  .feature-one {
    border-top: 5px solid #289ecc;
  }

  /* media queries */

  @media (min-width: 1000px) {
    section {
      max-width: 200rem;
    }

    .section__title {
      margin: 0 auto;
      max-width: 40%;
      font-size: 2rem;
    }

    .section__desc {
      max-width: 55ch;
      margin: 1rem auto 1rem;
    }

    .features {
      display: flex;
      gap: 2rem;
    }

    .feature-one {
      margin: auto 0;
    }
    .footer {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      color: white;
      text-align: center;
    }
  }
`;

export default class JobPosition extends Component {
  state = {
    isOpenModal: false,
    province_data: [],
    cs_data: [],

    defaultCountry: {
      value: 19,
      label: "Thai - ไทย",
    },
    defaultProvince: {
      value: 0,
      label: "",
    },
    validateForm: false,
    defauleTab: "step1",

    search_value: "",
    page: 1,
    per_page: 25,
    param: [],
    // data
    data: [],
    jp_id: 0,
    jp_position: "",
    jp_quota: 0,
    jp_salary_start: 0,
    jp_salary_end: 0,
    jp_posting_date: "",
    jp_expired_date: "",
    active: 1,
    province_id: 0,
    country_id: 19,
    skill_id: 0,
    jbc_id: 0,

    defaultJobFunction: [],

    // ประเภทเวลาการทำงาน
    job_type_post: [],
    list_job_type: [],
    default_job_type: [],

    // รูปแบบการทำงาน
    working_style_post: [],
    list_working_style: [],
    default_working_style: [],

    // โควต้า
    quota_position: 0,
    used_position: 0,
    quota_video: 0,
    used_video: 0,
    package_name: "",
    exire_date: present_day,
  };

  checkQuota = async () => {
    try {
      await axios
        .get(Common.API_URL + `payment/check/quota/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({
            quota_position: res?.quota_position,
            used_position: res?.used_position,
            quota_video: res?.quota_video,
            used_video: res?.used_video,
            package_name: res?.package_name,
            exire_date: res?.exire_date,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  refreshData = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `j/job_position/u/${user_id}`,
          {
            page: this.state.page,
            per_page: this.state.per_page,
            search_value: this.state.search_value,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res.data, param: res });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getWorkingStyleMasterData = () => {
    try {
      axios
        .get(Common.API_URL + "masterdata/working_style", Common.options)
        .then((response) => {
          let list = response.data;
          let arr = [];
          // Main
          for (let i = 0; i < list.length; i++) {
            let obj = list[i];
            arr.push({
              value: obj.ws_id,
              label: obj.ws_name,
            });
          }

          new Promise((accept) => {
            setTimeout(() => {
              this.setState(
                {
                  list_working_style: arr,
                },
                accept
              );
            }, 1000);
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getJobTypeMasterData = () => {
    try {
      axios
        .get(Common.API_URL + "masterdata/job_type", Common.options)
        .then((response) => {
          let list = response.data;
          let arr = [];
          // Main
          for (let i = 0; i < list.length; i++) {
            let obj = list[i];
            arr.push({
              value: obj.jt_id,
              label: obj.jt_name,
            });
          }

          new Promise((accept) => {
            setTimeout(() => {
              this.setState(
                {
                  list_job_type: arr,
                },
                accept
              );
            }, 1000);
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  // getJobFunction = async () => {
  //   try {
  //     await axios
  //       .get(Common.API_URL + "masterdata/job_parent?search=", Common.options)
  //       .then((response) => {
  //         let res = response.data;
  //         this.setState({ jp_data: res });
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  getJobFunctionMasterData = async (search) => {
    const response = await axios.get(
      Common.API_URL + "masterdata/job_parent?search=" + search,
      Common.options
    );
    const list = response.data;
    let arr = [];
    for (let i = 0; i < list.length; i++) {
      let obj = list[i];
      let jbp_jbc = obj.jbp_jbc;

      // Sub
      let arr2 = [];
      for (let i2 = 0; i2 < jbp_jbc.length; i2++) {
        let obj2 = jbp_jbc[i2];
        arr2.push({
          value: obj2.jbc_id,
          label: obj2.jbc_name,
          group: obj.jbp_name,
        });
      }

      arr.push({
        label: obj.jbp_name,
        options: arr2,
      });
    }
    const options = arr;
    return options;
  };

  getCountry = async (newValue) => {
    const response = await axios.post(
      Common.API_URL + "masterdata/country",
      {
        page: 1,
        per_page: 25,
        search_value: newValue,
      },
      Common.options
    );
    const options = response?.data?.data?.map((item) => ({
      value: item.country_id,
      label: item.country_name_eng + " - " + item.country_name_th,
    }));
    return options;
  };

  getProvince = async () => {
    try {
      await axios
        .get(Common.API_URL + "masterdata/province", Common.options)
        .then((response) => {
          let list = response.data;

          var arr = [];
          for (let i = 0; i < list.length; i++) {
            let obj = list[i];
            arr.push({
              value: obj.province_id,
              label: obj.province_eng + " - " + obj.province_thai,
            });
          }
          //   console.log(arr);

          new Promise((accept) => {
            setTimeout(() => {
              this.setState(
                {
                  province_data: arr,
                },
                accept
              );
            }, 1000);
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getCompencySkill = async () => {
    try {
      await axios
        .get(Common.API_URL + "masterdata/skill?typeskill=2", Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ cs_data: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  setCountry_id = async (e) => {
    this.setState({ country_id: e.value, defaultCountry: e });
  };
  setProvince_id = async (e) => {
    this.setState({ province_id: e.value, defaultProvince: e });
  };

  PostingDate = async (e) => {
    let getmonth = e.month.number;
    let getyear = e.year;
    let getday = e.day;
    let time =
      Functions.twoDigit(e.hour) +
      ":" +
      Functions.twoDigit(e.minute) +
      ":" +
      "00";
    let fulldate = getyear + "-" + getmonth + "-" + getday + " " + time;
    this.setState({ jp_posting_date: fulldate });
    // console.log(fulldate);
  };

  ExpiredDate = async (e) => {
    let getmonth = e.month.number;
    let getyear = e.year;
    let getday = e.day;
    let time =
      Functions.twoDigit(e.hour) +
      ":" +
      Functions.twoDigit(e.minute) +
      ":" +
      "00";
    let fulldate = getyear + "-" + getmonth + "-" + getday + " " + time;
    this.setState({ jp_expired_date: fulldate });
  };

  handleSubmit = () => {
    if (
      this.state.jp_position === "" ||
      this.state.jp_quota === 0 ||
      this.state.jp_salary_start === 0 ||
      this.state.jp_salary_end === 0 ||
      this.state.jp_posting_date === "" ||
      this.state.jp_expired_date === "" ||
      this.state.province_id === 0 ||
      this.state.country_id === 0 ||
      this.state.skill_id === 0 ||
      this.state.jbc_id === 0
    ) {
      this.setState({ validateForm: true });
      return false;
    }
    if (this.state.used_position >= this.state.quota_position) {
      Swal.fire({
        title: lang_data?.notify_title_error,
        text: lang_data?.notify_text_error,
        icon: "error",
        confirmButtonText: lang_data?.notify_confirm_error,
      });
      return false;
    }

    try {
      axios
        .post(
          Common.API_URL + "j/job_position/create",
          {
            jp_position: this.state.jp_position,
            jp_quota: this.state.jp_quota,
            jp_salary_start: this.state.jp_salary_start,
            jp_salary_end: this.state.jp_salary_end,
            jp_location: this.state.jp_location,
            jp_contract_type: this.state.jp_contract_type,
            jp_posting_date: this.state.jp_posting_date,
            jp_expired_date: this.state.jp_expired_date,
            active: 1,
            province_id: this.state.province_id,
            country_id: this.state.country_id,
            skill_id: this.state.skill_id,
            jbc_id: this.state.jbc_id,
            user_id: user_id,
          },
          Common.options
        )
        .then((res) => {
          let rs = res.data;

          this.handleSubmitJobType(rs.jp_id);
          this.handleSubmitWorkingStyle(rs.jp_id);
          this.setState({ jp_id: rs.jp_id, defauleTab: "step2" });
          this.checkQuota();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleUpdateSubmit = () => {
    if (
      this.state.jp_position === "" ||
      this.state.jp_quota === 0 ||
      this.state.jp_salary_start === 0 ||
      this.state.jp_salary_end === 0 ||
      this.state.jp_posting_date === "" ||
      this.state.jp_expired_date === "" ||
      this.state.province_id === 0 ||
      this.state.country_id === 0 ||
      this.state.skill_id === 0 ||
      this.state.jbc_id === 0
    ) {
      this.setState({ validateForm: true });
      return false;
    }

    try {
      axios
        .put(
          Common.API_URL + `j/job_position/${this.state.jp_id}`,
          {
            jp_position: this.state.jp_position,
            jp_quota: this.state.jp_quota,
            jp_salary_start: this.state.jp_salary_start,
            jp_salary_end: this.state.jp_salary_end,
            jp_posting_date: this.state.jp_posting_date,
            jp_expired_date: this.state.jp_expired_date,
            active: this.state.active,
            province_id: this.state.province_id,
            country_id: this.state.country_id,
            skill_id: this.state.skill_id,
            jbc_id: this.state.jbc_id,
            user_id: user_id,
          },
          Common.options
        )
        .then((res) => {
          this.handleSubmitJobType(this.state.jp_id);
          this.handleSubmitWorkingStyle(this.state.jp_id);
          this.setState({ defauleTab: "step2" });
          this.checkQuota();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleUpdateSwitch = (active) => {
    if (
      this.state.jp_position === "" ||
      this.state.jp_quota === 0 ||
      this.state.jp_salary_start === 0 ||
      this.state.jp_salary_end === 0 ||
      this.state.jp_posting_date === "" ||
      this.state.jp_expired_date === "" ||
      this.state.province_id === 0 ||
      this.state.country_id === 0 ||
      this.state.skill_id === 0 ||
      this.state.jbc_id === 0
    ) {
      this.setState({ validateForm: true });
      return false;
    }
    let setactvie;
    if (active === 1) {
      setactvie = 0;
    } else {
      setactvie = 1;
    }

    try {
      axios
        .put(
          Common.API_URL + `j/job_position/${this.state.jp_id}`,
          {
            jp_position: this.state.jp_position,
            jp_quota: this.state.jp_quota,
            jp_salary_start: this.state.jp_salary_start,
            jp_salary_end: this.state.jp_salary_end,
            jp_posting_date: this.state.jp_posting_date,
            jp_expired_date: this.state.jp_expired_date,
            active: setactvie,
            province_id: this.state.province_id,
            country_id: this.state.country_id,
            skill_id: this.state.skill_id,
            jbc_id: this.state.jbc_id,
            user_id: user_id,
          },
          Common.options
        )
        .then((res) => {
          this.refreshData();
          this.checkQuota();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDeleteSubmitDetail = (jpd_id) => {
    if (!window.confirm("Confirm Delete")) {
      return true;
    }
    try {
      axios
        .delete(
          Common.API_URL + `j/job_position/detail/${jpd_id}`,

          Common.options
        )
        .then((res) => {
          this.getDetail(this.state.jp_id);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleEditClick = (res) => {
    // Job Type
    let jp_data = res.job_jt;
    let arr_form_jp = [];
    let arr_post_jp = [];
    for (let i = 0; i < jp_data.length; i++) {
      let obj = jp_data[i];

      // Set Select Form
      arr_form_jp.push({
        value: obj.jt_id,
        label: obj.jp_job_type?.jt_name,
      });
      // Set Post
      arr_post_jp.push({
        jt_id: obj.jp_job_type?.jt_id,
      });
    }
    // console.log(JSON.stringify(arr_form_jp));
    // Working Style
    let ws_data = res.job_ws;
    let arr_form_ws = [];
    let arr_post_ws = [];
    for (let i = 0; i < ws_data.length; i++) {
      let obj = ws_data[i];

      // Set Select Form
      arr_form_ws.push({
        value: obj.ws_id,
        label: obj.jp_job_ws?.ws_name,
      });
      // Set Post
      arr_post_ws.push({
        ws_id: obj.ws_id,
      });
    }

    //

    this.setState({
      jp_id: res.jp_id,
      jp_position: res.jp_position,
      jp_quota: res.jp_quota,
      jp_salary_start: res.jp_salary_start,
      jp_salary_end: res.jp_salary_end,
      jp_description: res.jp_description,
      jp_benefit: res.jp_benefit,
      jp_location: res.jp_location,
      jp_contract_type: res.jp_contract_type,
      jp_posting_date: res.jp_posting_date,
      jp_expired_date: res.jp_expired_date,
      active: res.active,
      province_id: res.province_id,
      country_id: res.country_id,
      skill_id: res.skill_id,
      jbc_id: res.jbc_id,
      defaultCountry: {
        value: res.country_id,
        label:
          res.job_country?.country_name_eng +
          " " +
          res.job_country?.country_name_th,
      },
      defaultProvince: {
        value: res.province_id,
        label:
          res.job_province?.province_thai +
          " " +
          res.job_province?.province_eng,
      },
      default_job_type: arr_form_jp,
      job_type_post: arr_post_jp,
      default_working_style: arr_form_ws,
      working_style_post: arr_post_ws,
      defaultJobFunction: {
        value: res?.job_jbc?.jbc_id,
        label: res?.job_jbc?.jbc_name,
      },
    });
  };

  handleSubmitJobType = (jp_id) => {
    try {
      axios.post(
        Common.API_URL + `j/job_position/job_type/${jp_id}`,
        this.state.job_type_post,
        Common.options
      );
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmitWorkingStyle = (jp_id) => {
    try {
      axios.post(
        Common.API_URL + `j/job_position/workingstyle/${jp_id}`,
        this.state.working_style_post,
        Common.options
      );
    } catch (error) {
      console.log(error);
    }
  };

  setJobType = async (e) => {
    let arr = [];
    for (let i = 0; i < e.length; i++) {
      let obj = e[i];

      // console.log(obj.value);

      arr.push({
        jt_id: obj.value,
      });
    }
    new Promise((accept) => {
      this.setState(
        {
          job_type_post: arr,
        },
        accept
      );
    });

    // console.log(this.state.uet_type_post);
  };
  setWorkingStyle = async (e) => {
    let arr = [];
    for (let i = 0; i < e.length; i++) {
      let obj = e[i];

      // console.log(obj.value);
      arr.push({
        ws_id: obj.value,
      });
    }

    new Promise((accept) => {
      this.setState(
        {
          working_style_post: arr,
        },
        accept
      );
    });

    // console.log(this.state.uet_type_post);
  };

  confirmDelete = async (jp_id) => {
    Swal.fire({
      title: "ยืนยันการลบ",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่, ต้องการลบข้อมูลนี้",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        this.handleDelete(jp_id);
      }
    });
  };

  handleDelete = (jp_id) => {
    try {
      axios
        .delete(Common.API_URL + `j/job_position/${jp_id}`, Common.options)
        .then((res) => {
          this.clearState();
          this.refreshData();
          this.checkQuota();
        });
    } catch (error) {
      console.log(error);
    }
  };

  clearState = () => {
    this.setState({
      jp_id: 0,
      jp_position: "",
      jp_quota: 0,
      jp_salary_start: 0,
      jp_salary_end: 0,
      jp_posting_date: "",
      jp_expired_date: "",
      active: 1,
      province_id: 0,
      country_id: 19,
      skill_id: 0,
      jbc_id: 0,
      validateForm: false,
      defauleTab: "step1",

      // ประเภทการจ้างงาน
      job_type_post: [],
      default_job_type: [],

      // รูปแบบการทำงาน
      working_style_post: [],
      default_working_style: [],
      defaultJobFunction: [],
    });
  };

  componentDidMount() {
    this.checkQuota();
    this.refreshData();
    this.getProvince();
    this.getCompencySkill();
    this.getJobTypeMasterData();
    this.getWorkingStyleMasterData();
  }

  render() {
    const {
      jp_id,
      data,
      validateForm,
      cs_data,
      province_data,
      defaultCountry,
      defaultProvince,
      isOpenModal,
      param,
      page,
      list_job_type,
      default_job_type,
      job_type_post,
      list_working_style,
      default_working_style,
      working_style_post,
      quota_position,
      used_position,
      exire_date,
      defaultJobFunction,
    } = this.state;
    return (
      <div>
        <div>
          <ProfileMain />
        </div>

        <Container>
          <div style={{ paddingTop: "40px", height: "100vh" }}>
            <div align="right" style={{ paddingBottom: "25px" }}>
              <Row>
                <Col>
                  <div align="left">
                    <h3>
                      {lang_data?.jobPost} [{used_position} {" / "}{" "}
                      {quota_position}]
                    </h3>
                  </div>
                </Col>
                <Col>
                  <div align="right">
                    <Button
                      variant="success"
                      onClick={(e) => this.setState({ isOpenModal: true })}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                      </svg>{" "}
                      {lang_data?.newJobPost}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>

            <div style={{ paddingBottom: "15px" }}>
              <Card>
                <Card.Body>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder={lang_data?.search}
                      onChange={(e) => [
                        this.setState({
                          search_value: e.target.value,
                        }),
                        this.refreshData(),
                      ]}
                      onKeyUp={(e) => [
                        this.setState({
                          search_value: e.target.value,
                        }),
                        this.refreshData(),
                      ]}
                    />
                  </Form.Group>
                </Card.Body>
              </Card>
            </div>

            <CardStyle>
              <Row>
                {data?.map((rs, index) => (
                  <Col lg="4" md="6" sm="12" key={index}>
                    <div className="feature feature-one">
                      <div align="right">{rs.jp_code}</div>
                      <h2 className="feature__title">
                        <span style={cropText}>
                          <Image
                            src={
                              require("../../asset/images/Job-functions-2.svg")
                                .default
                            }
                            style={{ width: "32px", height: "32px" }}
                          />{" "}
                          {rs.job_jbc?.jbc_name}
                        </span>
                      </h2>
                      <div>
                        <h4> {rs.jp_position}</h4>
                      </div>
                      <div style={{ paddingBottom: "10px" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-geo-alt-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>{" "}
                        {rs.job_province?.province_eng} /{" "}
                        {rs.job_province?.province_thai}
                      </div>
                      <div style={{ paddingBottom: "10px" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-cash-coin"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                          />
                          <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                          <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                          <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                        </svg>{" "}
                        {Functions.formatNumberWithComma(rs.jp_salary_start)}
                        {" - "}
                        {Functions.formatNumberWithComma(rs.jp_salary_end)}{" "}
                        {lang_data?.bath}
                      </div>
                      <div style={{ paddingBottom: "10px" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-exclude"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm12 2H5a1 1 0 0 0-1 1v7h7a1 1 0 0 0 1-1V4z" />
                        </svg>{" "}
                        <strong> {lang_data?.main_job}</strong>
                        {rs?.job_detail
                          .filter((e) => e.jpd_type === "1")
                          .map((rd, i) => (
                            <li key={i}>{rd.jpd_name}</li>
                          ))}
                      </div>

                      <div className="footer">
                        {rs.active !== 2 ? (
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            defaultChecked={rs.active === 1 ? true : false}
                            onChange={async () => [
                              await Promise.resolve(this.handleEditClick(rs)),
                              this.handleUpdateSwitch(rs.active),
                            ]}
                          />
                        ) : (
                          <Badge bg="success">
                            {lang_data?.confirm_candidate}
                          </Badge>
                        )}
                      </div>
                    </div>

                    <div align="center" style={{ paddingBottom: "10px" }}>
                      <p style={{ fontSize: "10px" }}>
                        {" "}
                        Create : {Functions.format_date_time(rs.create_date)} ,
                        Update : {Functions.format_date_time(rs.update_date)}
                      </p>
                      <Button
                        variant="info"
                        size="sm"
                        href={`/apply-list/${rs.jp_id}`}
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-person-fill-up"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.354-5.854 1.5 1.5a.5.5 0 0 1-.708.708L13 11.707V14.5a.5.5 0 0 1-1 0v-2.793l-.646.647a.5.5 0 0 1-.708-.708l1.5-1.5a.5.5 0 0 1 .708 0ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                          <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                        </svg>{" "}
                        {lang_data?.listApplicants}
                      </Button>{" "}
                      {rs.active !== 2 ? (
                        <Button
                          variant="warning"
                          size="sm"
                          onClick={() => [
                            this.setState({
                              isOpenModal: true,
                            }),
                            this.handleEditClick(rs),
                          ]}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                          </svg>{" "}
                          {lang_data?.editing}
                        </Button>
                      ) : (
                        <Button
                          variant="warning"
                          size="sm"
                          href={`/job/${rs.jp_position}/${rs.jp_id}`}
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-info-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                          </svg>{" "}
                          {lang_data?.detail}
                        </Button>
                      )}{" "}
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => this.confirmDelete(rs.jp_id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trash-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                        </svg>{" "}
                        {lang_data?.delete}
                      </Button>
                    </div>
                  </Col>
                ))}
              </Row>
            </CardStyle>

            <div
              style={{ paddingTop: "70px", paddingBottom: "70px" }}
              align="center"
            >
              <div style={{ width: "200px" }}>
                <InputGroup className="mb-3" size="sm">
                  <InputGroup.Text> {lang_data?.page}</InputGroup.Text>
                  <Form.Control
                    type="number"
                    defaultValue={page}
                    onChange={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    onKeyUp={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    onClick={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    style={{ textAlign: "center" }}
                  />
                  <InputGroup.Text>
                    {lang_data?.total_page} : {param.total_page}
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>

            <Modal show={isOpenModal} size="lg">
              <Modal.Header>
                <Modal.Title> {lang_data?.jobPost}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Tabs transition={false} activeKey={this.state.defauleTab}>
                  <Tab
                    eventKey="step1"
                    title={lang_data?.jobPostStep1}
                    disabled={jp_id === 0 ? true : false}
                  >
                    <Row>
                      <Col lg="6" sm="12">
                        <Form.Group>
                          <Form.Label></Form.Label>{" "}
                          <label style={{ color: "red" }}>*</label>
                          <AsyncSelect
                            placeholder={lang_data?.job_category}
                            styles={
                              validateForm === true && this.state.jbc_id <= 0
                                ? customStyles2
                                : customStyles
                            }
                            cacheOptions
                            defaultOptions
                            loadOptions={this.getJobFunctionMasterData}
                            getOptionLabel={(option) => option.label}
                            formatGroupLabel={(data) => (
                              <div style={{ fontWeight: "bold", fontSize: 22 }}>
                                {data.label}
                              </div>
                            )}
                            onChange={
                              (e) => this.setState({ jbc_id: e.value })
                              // console.log(e)
                            }
                            defaultValue={defaultJobFunction}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="6" sm="12">
                        <Form.Group>
                          <Form.Label> {lang_data?.position}</Form.Label>{" "}
                          <label style={{ color: "red" }}>*</label>
                          <Form.Control
                            type="text"
                            id="jp_position"
                            onChange={(e) =>
                              this.setState({ jp_position: e.target.value })
                            }
                            isInvalid={
                              validateForm === true &&
                              this.state.jp_position === ""
                                ? true
                                : false
                            }
                            value={this.state.jp_position}
                            maxLength={48}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="4" sm="12">
                        <Form.Group>
                          <Form.Label>{lang_data?.applicants_quota}</Form.Label>{" "}
                          <label style={{ color: "red" }}>*</label>
                          <Form.Control
                            type="number"
                            id="jp_quota"
                            onChange={(e) =>
                              this.setState({ jp_quota: e.target.value })
                            }
                            isInvalid={
                              validateForm === true && this.state.jp_quota === 0
                                ? true
                                : false
                            }
                            value={this.state.jp_quota}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="8" sm="12">
                        <Form.Group>
                          <Form.Label>{lang_data?.salary_label}</Form.Label>{" "}
                          <label style={{ color: "red" }}>*</label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              type="number"
                              id="jp_salary_start"
                              placeholder={lang_data?.salary_start}
                              onChange={(e) =>
                                this.setState({
                                  jp_salary_start: e.target.value,
                                })
                              }
                              isInvalid={
                                validateForm === true &&
                                this.state.jp_salary_start === 0
                                  ? true
                                  : false
                              }
                              value={this.state.jp_salary_start}
                            />
                            <Form.Control
                              type="number"
                              id="jp_salary_end"
                              placeholder={lang_data?.salary_end}
                              onChange={(e) =>
                                this.setState({ jp_salary_end: e.target.value })
                              }
                              isInvalid={
                                validateForm === true &&
                                this.state.jp_salary_end === 0
                                  ? true
                                  : false
                              }
                              value={this.state.jp_salary_end}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Col>

                      <Col lg="6" sm="12">
                        <Form.Group>
                          <Form.Label>{lang_data?.working_style}</Form.Label>{" "}
                          <label style={{ color: "red" }}>*</label>
                          <Select
                            options={list_working_style}
                            isMulti
                            styles={
                              validateForm === true &&
                              this.state.working_style_post.length <= 0
                                ? customStyles2
                                : customStyles
                            }
                            placeholder={lang_data?.working_style}
                            onChange={this.setWorkingStyle}
                            defaultValue={default_working_style}
                            key={default_working_style}
                            isOptionDisabled={() =>
                              working_style_post.length >= 2
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="6" sm="12">
                        <Form.Group>
                          <Form.Label>{lang_data?.job_type_time}</Form.Label>{" "}
                          <label style={{ color: "red" }}>*</label>
                          <Select
                            options={list_job_type}
                            isMulti
                            styles={
                              validateForm === true &&
                              this.state.job_type_post.length <= 0
                                ? customStyles2
                                : customStyles
                            }
                            placeholder={lang_data?.job_type_time}
                            onChange={this.setJobType}
                            defaultValue={default_job_type}
                            key={default_job_type}
                            isOptionDisabled={() => job_type_post.length >= 2}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="6" sm="12">
                        <Form.Group>
                          <Form.Label> {lang_data?.country_label} </Form.Label>{" "}
                          <label style={{ color: "red" }}>*</label>
                          <AsyncSelect
                            placeholder={lang_data?.country_label}
                            styles={
                              validateForm === true &&
                              this.state.country_id === 0
                                ? customStyles2
                                : customStyles
                            }
                            cacheOptions
                            defaultOptions
                            loadOptions={this.getCountry}
                            getOptionLabel={(option) => option.label}
                            value={defaultCountry}
                            onChange={(e) =>
                              this.setState({ country_id: e.value })
                            }
                          />
                          {/* <Select
                            id="country_id"
                            options={list_country}
                            onInputChange={this.getCountry}
                            onChange={this.setCountry_id}
                            value={defaultCountry}
                            cacheOptions
                            styles={
                              validateForm === true &&
                              this.state.country_id === 0
                                ? customStyles2
                                : customStyles
                            }
                          /> */}
                        </Form.Group>
                      </Col>
                      <Col lg="6" sm="12">
                        <Form.Group>
                          <Form.Label>{lang_data?.province_label} </Form.Label>{" "}
                          <label style={{ color: "red" }}>*</label>
                          <Select
                            id="province_id"
                            options={province_data}
                            cacheOptions
                            onChange={this.setProvince_id}
                            styles={
                              validateForm === true &&
                              this.state.province_id === 0
                                ? customStyles2
                                : customStyles
                            }
                            value={defaultProvince}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="6" sm="12">
                        <Form.Group>
                          <Form.Label>Competency Skills</Form.Label>{" "}
                          <label style={{ color: "red" }}>*</label>
                          <Form.Select
                            size="lg"
                            id="skill_id"
                            onChange={(e) =>
                              this.setState({ skill_id: e.target.value })
                            }
                            isInvalid={
                              validateForm === true && this.state.skill_id === 0
                                ? true
                                : false
                            }
                            value={this.state.skill_id}
                          >
                            <option value="">--</option>

                            {cs_data?.map((r, x) => (
                              <option value={r.skill_id} key={x}>
                                {r.skill_name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col lg="3" sm="12">
                        <Form.Group>
                          <Form.Label>{lang_data?.posting_date}</Form.Label>{" "}
                          <label style={{ color: "red" }}>*</label>
                          <DatePicker
                            id="jp_posting_date"
                            style={{
                              height: "45px",
                              width: "100%",
                              border:
                                validateForm === true &&
                                this.state.jp_posting_date === ""
                                  ? "1px solid red"
                                  : "",
                            }}
                            containerStyle={{
                              width: "100%",
                            }}
                            format="DD/MM/YYYY HH:mm"
                            plugins={[
                              <TimePicker position="bottom" hideSeconds />,
                            ]}
                            minDate={present_day}
                            maxDate={exire_date}
                            onChange={(e) => this.PostingDate(e)}
                            editable={false}
                            value={
                              jp_id === 0
                                ? ""
                                : Functions.format_date_time_picker(
                                    this.state.jp_posting_date
                                  )
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="3" sm="12">
                        <Form.Group>
                          <Form.Label>{lang_data?.expired_date}</Form.Label>{" "}
                          <label style={{ color: "red" }}>*</label>
                          <DatePicker
                            id="jp_expired_date"
                            style={{
                              height: "45px",
                              width: "100%",
                              border:
                                validateForm === true &&
                                this.state.jp_expired_date === ""
                                  ? "1px solid red"
                                  : "",
                            }}
                            containerStyle={{
                              width: "100%",
                            }}
                            format="DD/MM/YYYY HH:mm"
                            plugins={[
                              <TimePicker position="bottom" hideSeconds />,
                            ]}
                            minDate={present_day}
                            maxDate={exire_date}
                            onChange={(e) => this.ExpiredDate(e)}
                            editable={false}
                            value={
                              jp_id === 0
                                ? ""
                                : Functions.format_date_time_picker(
                                    this.state.jp_expired_date
                                  )
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div align="right" style={{ paddingTop: "25px" }}>
                      <Button
                        onClick={
                          jp_id === 0
                            ? this.handleSubmit
                            : this.handleUpdateSubmit
                        }
                        variant="success"
                      >
                        {lang_data?.save_step}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-double-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </Button>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="step2"
                    title={lang_data?.jobPostStep2}
                    disabled={jp_id === 0 ? true : false}
                  >
                    <JobPositionFormDetail
                      jp_id={jp_id}
                      jpd_type={1}
                      page_code="Cr2b"
                    />

                    <div align="right">
                      <Button
                        onClick={(e) => this.setState({ defauleTab: "step1" })}
                        variant="success"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-double-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          />
                        </svg>{" "}
                        {lang_data?.previous}
                      </Button>{" "}
                      <Button
                        onClick={(e) => this.setState({ defauleTab: "step3" })}
                        variant="success"
                      >
                        {lang_data?.next}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-double-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </Button>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="step3"
                    title={lang_data?.jobPostStep3}
                    disabled={jp_id === 0 ? true : false}
                  >
                    <JobPositionFormDetail
                      jp_id={jp_id}
                      jpd_type={2}
                      page_code="M9yA"
                    />

                    <div align="right">
                      <Button
                        onClick={(e) => this.setState({ defauleTab: "step2" })}
                        variant="success"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-double-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          />
                        </svg>{" "}
                        {lang_data?.previous}
                      </Button>{" "}
                      <Button
                        onClick={(e) => this.setState({ defauleTab: "step4" })}
                        variant="success"
                      >
                        {lang_data?.next}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-double-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </Button>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="step4"
                    title={lang_data?.jobPostStep4}
                    disabled={jp_id === 0 ? true : false}
                  >
                    <JobPositionFormDetail
                      jp_id={jp_id}
                      jpd_type={3}
                      page_code="C7ga"
                    />

                    <div align="right">
                      <Button
                        onClick={(e) => this.setState({ defauleTab: "step3" })}
                        variant="success"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-double-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          />
                        </svg>{" "}
                        {lang_data?.previous}
                      </Button>{" "}
                      <Button
                        onClick={(e) => [
                          this.setState({ isOpenModal: false }),
                          this.clearState(),
                          this.refreshData(),
                        ]}
                        variant="primary"
                      >
                        {lang_data?.finish}
                      </Button>
                    </div>
                  </Tab>
                </Tabs>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={(e) => [
                    this.setState({ isOpenModal: false }),
                    this.clearState(),
                    this.refreshData(),
                  ]}
                  variant="info"
                >
                  {lang_data?.cancelled}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Container>
      </div>
    );
  }
}
