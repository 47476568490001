import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";
import axios from "axios";
import Common from "../../common";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
export default class ViewAsCandidateStrength extends Component {
  state = {
    data: [],
  };
  refreshData = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/strength/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.refreshData();
  }
  render() {
    const { data } = this.state;

    return (
      <div style={{ paddingTop: 25 }}>
        <Card>
          <Card.Body>
            <Card.Title>{lang_data?.strength_title}</Card.Title>

            <div>
              {data?.map((rs, index) => (
                <div
                  style={{
                    paddingTop: "20px",
                  }}
                  key={index}
                >
                  <Row>
                    <Col lg="11" md="6">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          className="bi bi-emoji-smile-fill icon-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />
                        </svg>
                        <span
                          style={{
                            padding: "10px",
                            fontSize: "32px",
                          }}
                        >
                          {rs.strength_name}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
