import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";
import Common from "../../common";
import axios from "axios";
import Swal from "sweetalert2";
export default class CandidateDetailHobby extends Component {
  state = {
    skill_profile_id: 0,
    skill_id: 0,
    skill_profile_detail: "",
    skilldata: [],
  };

  refreshData = async () => {
    try {
      await axios
        .get(
          Common.API_URL + `user/hobbies/${this.props.user_id}`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleDeleteSubmit = async (hobby_id) => {
    Swal.fire({
      title: "ยืนยันการลบ",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่, ต้องการลบข้อมูลนี้",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(Common.API_URL + `user/hobbies/${hobby_id}`, Common.options)
            .then((res) => {
              this.clearState();
              this.refreshData();
            });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  clearState = () => {
    this.setState({
      hobby_id: 0,
      hobby_name: "",
    });
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { data } = this.state;

    return (
      <div>
        {data !== undefined && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>งานอดิเรก</th>
                <th>ลบ</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((rs, index) => (
                <tr key={index}>
                  <td>{rs.hobby_name}</td>
                  <td align="center">
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => this.handleDeleteSubmit(rs.hobby_id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                      </svg>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    );
  }
}
