import React, { Component, Suspense } from "react";
import { Container, Tab, Tabs, Image } from "react-bootstrap";
import axios from "axios";
import Common from "../../common";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const PaymentSaleQuotation = React.lazy(() => import("./PaymentSaleQuotation"));
const PaymentConfirm = React.lazy(() => import("./PaymentConfirm"));
const PaymentComplete = React.lazy(() => import("./PaymentComplete"));
const user_id = Common.token;
const searchParams = new URLSearchParams(window.location.search);
const tabChk = searchParams.has("tab");
// console.log(searchParams.has("tab")); // true
// console.log(searchParams.get('tab')); // 3
const tab = tabChk === true ? searchParams.get("tab") : 0;
export default class PaymentForm extends Component {
  state = {
    order_status: 0,
    order_id: "",
    loading: true,
  };

  refreshData = async () => {
    try {
      this.setState({
        loading: true,
      });
      await axios
        .get(Common.API_URL + `payment/order/owner/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({
            order_status: res?.order_status,
            order_id: res?.order_id,
          });
        });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { order_status, order_id, loading } = this.state;

    // console.log(tab);
    return (
      <div>
        <Suspense fallback={<div align="center">Loading..</div>}>
          <div style={{ paddingTop: "40px", height: "100vh" }}>
            <Container>
              {loading === true ? (
                <div align="center">
                  <Image src={require("../../asset/images/Loading_2.gif")} />
                </div>
              ) : (
                <Tabs
                  defaultActiveKey={order_status === 0 ? tab : order_status}
                  fill
                >
                  <Tab
                    eventKey={0}
                    title={lang_data?.payment_tab1}
                    disabled={
                      order_id !== undefined && order_id !== "" ? false : true
                    }
                  >
                    {order_id !== "" && (
                      <PaymentSaleQuotation order_id={order_id} />
                    )}
                  </Tab>

                  <Tab eventKey={1} title={lang_data?.payment_tab2}>
                    {order_id !== "" && <PaymentConfirm order_id={order_id} />}
                  </Tab>
                  <Tab eventKey={2} title={lang_data?.payment_tab3}>
                    <PaymentComplete />
                  </Tab>
                </Tabs>
              )}
            </Container>
          </div>
        </Suspense>
      </div>
    );
  }
}
