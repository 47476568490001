import React, { Component } from "react";
import {
  Button,
  Container,
  Card,
  Form,
  Row,
  Col,
  InputGroup,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Common from "../../common";
import Functions from "../../functions";
import ProfileMain from "./ProfileMain";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const user_id = Common.token;

const CardStyle = styled.div`
  ///https://codepen.io/ShortCode/pen/RwyRZKN
  .card {
    // padding: 15px;
    width: 100%;
    height: 350px;
    background: #ffffff;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.7);
    // user-select: none;
  }

  .cover-photo {
    position: relative;
    //background: url(https://i.imgur.com/jxyuizJ.jpeg);
    background-size: cover;
    height: 180px;
    border-radius: 5px 5px 0 0;
  }

  .profile {
    position: absolute;
    width: 120px;
    //   bottom: -60px;
    top: 115px;
    left: 25px;
    border-radius: 50%;
    border: 2px solid #222;
    background: #222;
    padding: 5px;
  }

  .profile-name {
    //font-size: 20px;
    margin: 15px 0 0 120px;
    color: black;
  }

  .about {
    margin-top: 30px;
    line-height: 1.6;
  }

  .button {
    border: none;
    // color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }

  .btn:hover {
    background: #7ce3ff;
    color: #222;
  }

  .icons {
    width: 180px;
    margin: 0 auto 10px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }

  .icons i {
    cursor: pointer;
    padding: 5px;
    font-size: 18px;
    transition: 0.2s;
  }

  .footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    // color: white;
    text-align: center;
  }

  .icons i:hover {
    color: #7ce3ff;
  }
`;

export default class Favorite extends Component {
  state = {
    search_value: "",
    page: 1,
    per_page: 25,
    param: [],
    // data
    data: [],
  };

  refreshData = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `general/favorite/${user_id}`,
          {
            page: this.state.page,
            per_page: this.state.per_page,
            search_value: this.state.search_value,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({ data: res.data, param: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  favoritDelete = async (favorite_id) => {
    try {
      await axios
        .delete(
          Common.API_URL + `general/favorite/${favorite_id}`,
          Common.options
        )
        .then((response) => {
          this.refreshData();
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { data, param, page } = this.state;
    return (
      <div>
        <div>
          <ProfileMain />
        </div>

        <Container>
          <div style={{ paddingTop: "40px", height: "100vh" }}>
            <div align="right" style={{ paddingBottom: "25px" }}>
              <Row>
                <Col>
                  <div align="left">
                    <h3> {lang_data?.favorite}</h3>
                  </div>
                </Col>
                <Col>
                  <div align="right"></div>
                </Col>
              </Row>
            </div>

            <div style={{ paddingBottom: "15px" }}>
              <Card>
                <Card.Body>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder={lang_data?.search}
                      onChange={(e) => [
                        this.setState({
                          search_value: e.target.value,
                        }),
                        this.refreshData(),
                      ]}
                      onKeyUp={(e) => [
                        this.setState({
                          search_value: e.target.value,
                        }),
                        this.refreshData(),
                      ]}
                    />
                  </Form.Group>
                </Card.Body>
              </Card>
            </div>

            <CardStyle>
              <Row>
                {data?.map((rs, index) => (
                  <Col lg="4" md="6" sm="12" key={index}>
                    <div className="card">
                      <Image
                        src={
                          Functions.check_empty_value(
                            rs?.favorite_user?.user_main_detail?.ud_image_cover
                          ) === true
                            ? require("../../asset/images/4920290.jpg")
                            : rs?.favorite_user?.user_main_detail
                                ?.ud_image_cover
                        }
                        className="cover-photo"
                      />

                      <Image
                        src={
                          Functions.check_empty_value(
                            rs?.favorite_user?.user_main_detail
                              ?.ud_image_profile
                          ) === true
                            ? require("../../asset/images/no-user-image-icon-26.jpg")
                            : rs?.favorite_user?.user_main_detail
                                ?.ud_image_profile
                        }
                        className="profile"
                        style={{ width: 110, height: 110 }}
                      />

                      <strong className="profile-name">
                        {rs?.favorite_user?.firstname}{" "}
                        {rs?.favorite_user?.lastname}
                      </strong>
                      <div className="about" style={{ paddingBottom: "25px" }}>
                        <div>
                          {
                            rs?.favorite_user?.user_main_detail?.user_tambon
                              ?.province_thai
                          }{" "}
                          {" / "}{" "}
                          {
                            rs?.favorite_user?.user_main_detail?.user_tambon
                              ?.province_eng
                          }
                        </div>
                      </div>
                      <div className="footer">
                        <Link
                          to={`/candidate/${
                            rs.favorite_user?.firstname +
                            "_" +
                            rs.favorite_user?.lastname
                          }/${rs.favorite_user?.user_id}`}
                          target="_blank"
                        >
                          <Button variant="primary" size="sm">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-person-lines-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                            </svg>
                          </Button>
                        </Link>{" "}
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => this.favoritDelete(rs?.favorite_id)}
                          title="Favorite"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-suit-heart-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                          </svg>{" "}
                        </Button>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </CardStyle>

            <div style={{ paddingTop: "70px" }} align="center">
              <div style={{ width: "200px" }}>
                <InputGroup className="mb-3" size="sm">
                  <InputGroup.Text>{lang_data?.page}</InputGroup.Text>
                  <Form.Control
                    type="number"
                    defaultValue={page}
                    onChange={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    onKeyUp={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    onClick={(e) => [
                      this.setState({
                        page: e.target.value,
                      }),
                      this.refreshData(),
                    ]}
                    style={{ textAlign: "center" }}
                  />
                  <InputGroup.Text>
                    {lang_data?.total_page} : {param.total_page}
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
