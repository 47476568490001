import React, { Component } from "react";
import {
  Badge,
  Card,
  Breadcrumb,
  Button,
  ButtonGroup,
  Row,
  Col,
  Form,
  Table,
  Modal,
  ListGroup,
} from "react-bootstrap";

import Common from "../../common";
import axios from "axios";
import Swal from "sweetalert2";
import Functions from "../../functions";
export default class Package extends Component {
  state = {
    data: [],
    isOpenModal: false,
    package_id: "",
    package_name: "",
    package_amount_date: 0,
    package_desctiption: "",
    package_price: 0,
    package_banner: "",
    active: 1,

    pt_id: 0,
    pt_quota: 0,
    pt_item: 0,
    pt_name: "",
  };

  refreshData = async () => {
    try {
      await axios
        .get(Common.API_URL + "payment/package", Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({ data: res });
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmit = () => {
    if (
      this.state.package_name === "" ||
      this.state.package_amount_date === 0 ||
      this.state.package_price === 0
    ) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถทำรายการได้",
        icon: "error",
        confirmButtonText: "ตกลง",
        timer: 1500,
      });
      return false;
    }

    try {
      axios
        .post(
          Common.API_URL + "payment/package/create",
          {
            package_name: this.state.package_name,
            package_amount_date: this.state.package_amount_date,
            package_desctiption: this.state.package_desctiption,
            package_price: this.state.package_price,
            package_banner: this.state.package_banner,
            active: this.state.active,
          },
          Common.options
        )
        .then((res) => {
          this.setState({ isOpenModal: false });
          this.clearState();
          this.refreshData();
          Swal.fire({
            title: "เรียบร้อย",
            text: "ทำรายการสำเร็จ",
            icon: "success",
            confirmButtonText: "ตกลง",
          });
        });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถทำรายการได้",
        icon: "error",
        confirmButtonText: "ตกลง",
      });
    }
  };

  handleClickEdit = (res) => {
    let r = res;
    this.setState({
      package_id: r.package_id,
      package_name: r.package_name,
      package_amount_date: r.package_amount_date,
      package_desctiption: r.package_desctiption,
      package_price: r.package_price,
      package_banner: r.package_banner,
      active: r.active,
    });
  };
  handleSubmitEdit = () => {
    if (
      this.state.package_name === "" ||
      this.state.package_amount_date === 0 ||
      this.state.package_price === 0
    ) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถทำรายการได้",
        icon: "error",
        confirmButtonText: "ตกลง",
      });

      return false;
    }
    try {
      axios
        .put(
          Common.API_URL + `payment/package/${this.state.package_id}`,
          {
            package_name: this.state.package_name,
            package_amount_date: this.state.package_amount_date,
            package_desctiption: this.state.package_desctiption,
            package_price: this.state.package_price,
            package_banner: this.state.package_banner,
            active: this.state.active,
          },
          Common.options
        )
        .then((res) => {
          this.clearState();
          this.refreshData();
          this.setState({ isOpenModal: false });
          Swal.fire({
            title: "เรียบร้อย",
            text: "ทำรายการสำเร็จ",
            icon: "success",
            confirmButtonText: "ตกลง",
          });
        });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถทำรายการได้",
        icon: "error",
        confirmButtonText: "ตกลง",
      });
    }
  };

  handleDelete = (package_id) => {
    Swal.fire({
      title: "ยืนยันการลบ",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่, ต้องการลบข้อมูลนี้",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(
              Common.API_URL + `payment/package/${package_id}`,
              Common.options
            )
            .then((res) => {
              this.refreshData();
              this.clearState();
              Swal.fire({
                title: "เรียบร้อย",
                text: "ทำรายการสำเร็จ",
                icon: "success",
                confirmButtonText: "ตกลง",
              });
            });
        } catch (error) {
          console.log(error);
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: "ไม่สามารถทำรายการได้",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
        }
      }
    });
  };

  handleSubmitItem = () => {
    if (this.state.pt_item === 0 || this.state.package_id === "") {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถทำรายการได้",
        icon: "error",
        confirmButtonText: "ตกลง",
      });

      return false;
    }
    try {
      axios
        .post(
          Common.API_URL + "payment/package/item/create",
          {
            pt_quota: this.state.pt_quota,
            pt_item: this.state.pt_item,
            pt_name: this.state.pt_name,
            package_id: this.state.package_id,
          },
          Common.options
        )
        .then((res) => {
          this.clearState();
          this.refreshData();
          this.setState({ isOpenFormItemModal: false });
          Swal.fire({
            title: "เรียบร้อย",
            text: "ทำรายการสำเร็จ",
            icon: "success",
            confirmButtonText: "ตกลง",
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: "ไม่สามารถทำรายการได้",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
        });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถทำรายการได้",
        icon: "error",
        confirmButtonText: "ตกลง",
      });
    }
  };
  handleSubmitItemEdit = () => {
    if (this.state.pt_item === 0 || this.state.package_id === "") {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถทำรายการได้",
        icon: "error",
        confirmButtonText: "ตกลง",
      });

      return false;
    }
    try {
      axios
        .put(
          Common.API_URL + `payment/package/item/${this.state.pt_id}`,
          {
            pt_quota: this.state.pt_quota,
            pt_item: this.state.pt_item,
            pt_name: this.state.pt_name,
            package_id: this.state.package_id,
          },
          Common.options
        )
        .then((res) => {
          this.clearState();
          this.refreshData();
          this.setState({ isOpenFormItemModal: false });
          Swal.fire({
            title: "เรียบร้อย",
            text: "ทำรายการสำเร็จ",
            icon: "success",
            confirmButtonText: "ตกลง",
          });
        });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถทำรายการได้",
        icon: "error",
        confirmButtonText: "ตกลง",
      });
    }
  };
  handleDeleteItem = (pt_id) => {
    Swal.fire({
      title: "ยืนยันการลบ",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่, ต้องการลบข้อมูลนี้",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(
              Common.API_URL + `payment/package/item/${pt_id}`,
              Common.options
            )
            .then((res) => {
              this.refreshData();
              this.clearState();
            });
        } catch (error) {
          console.log(error);
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: "ไม่สามารถทำรายการได้",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
        }
      }
    });
  };

  handleItemClickEdit = (res) => {
    let r = res;
    this.setState({
      pt_id: r.pt_id,
      pt_quota: r.pt_quota,
      pt_item: r.pt_item,
      pt_name: r.pt_name,
      package_id: r.package_id,
    });
  };
  clearState = () => {
    this.setState({
      package_id: "",
      package_name: "",
      package_amount_date: 0,
      package_desctiption: "",
      package_banner: "",
      package_price: 0,
      active: 1,

      pt_id: 0,
      pt_quota: 0,
      pt_item: 0,
      pt_name: "",
    });
  };
  itemConvert = (pt_item) => {
    let f;
    if (pt_item === 1) {
      f = "Position";
    } else if (pt_item === 2) {
      f = "Video";
    } else {
      f = "-";
    }
    return f;
  };

  amountDate = (val) => {
    let f;
    if (val === 30) {
      f = "1 เดือน";
    } else if (val === 60) {
      f = "2 เดือน";
    } else if (val === 90) {
      f = "3 เดือน";
    } else if (val === 180) {
      f = "6 เดือน";
    } else if (val === 365) {
      f = "1 ปี";
    } else {
      f = "-";
    }
    return f;
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { data, isOpenModal, package_id, pt_id, isOpenFormItemModal } =
      this.state;

    return (
      <div>
        <Row>
          <Col sm={8}>
            <h3>แพ็กเกจ</h3>
          </Col>
          <Col sm={4}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">หน้าหลัก</Breadcrumb.Item>
              <Breadcrumb.Item active>แพ็กเกจ</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Card border="info">
          <Card.Header>
            <Row>
              <Col sm={8}>แพ็กเกจ</Col>
              <Col sm={4}>
                <div align="right">
                  <Button
                    onClick={(e) => [
                      this.setState({ isOpenModal: true }),
                      this.clearState(),
                    ]}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                    </svg>{" "}
                    เพิ่มข้อมูล
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>แพ็กเกจ</th>
                  <th>ระยะเวลา</th>
                  <th>ราคา (บาท)</th>
                  <th>ป้าย</th>
                  <th>รายละเอียด</th>
                  <th>Items</th>
                  <th>สถานะ</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((rs, index) => (
                  <tr key={index}>
                    <td>{rs.package_name}</td>
                    <td align="right">
                      {this.amountDate(rs.package_amount_date)}
                    </td>
                    <td align="right">
                      {Functions.formatNumberWithComma(rs.package_price)}
                    </td>
                    <td>{rs.package_banner}</td>
                    <td>{rs.package_desctiption}</td>
                    <td>
                      <ListGroup variant="flush">
                        {rs.pt_pd?.map((r, i) => (
                          <ListGroup.Item
                            key={i}
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                          >
                            <div className="ms-2 me-auto">
                              {r.pt_name}{" "}
                              {r.pt_quota !== 0
                                ? ` โควต้า : ${r.pt_quota}`
                                : ""}
                            </div>
                            <Badge
                              bg="warning"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => [
                                this.handleItemClickEdit(r),
                                this.setState({
                                  isOpenFormItemModal: true,
                                }),
                              ]}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                              </svg>
                            </Badge>{" "}
                            <Badge
                              bg="danger"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => this.handleDeleteItem(r.pt_id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-trash3-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                              </svg>
                            </Badge>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                      <div style={{ paddingTop: "10px" }}>
                        <Badge
                          bg="primary"
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            this.setState({
                              isOpenFormItemModal: true,
                              package_id: rs.package_id,
                              pt_id: 0,
                              pt_quota: 0,
                              pt_item: 0,
                              pt_name: "",
                            })
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                          </svg>
                        </Badge>
                      </div>
                    </td>
                    <td align="center">
                      {rs.active === 1 ? (
                        <Badge bg="success">Active</Badge>
                      ) : (
                        <Badge bg="danger">Unactive</Badge>
                      )}
                    </td>
                    <td align="center">
                      <ButtonGroup>
                        <Button
                          variant="warning"
                          onClick={(e) => [
                            this.setState({
                              isOpenModal: true,
                            }),
                            this.handleClickEdit(rs),
                          ]}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                          </svg>{" "}
                          แก้ไข
                        </Button>
                        <Button
                          variant="danger"
                          onClick={(e) => this.handleDelete(rs.package_id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash3-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                          </svg>{" "}
                          ลบ
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Modal
          show={isOpenModal}
          onHide={() => this.setState({ isOpenModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>แพ็กเกจ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>ชื่อแพ็กเกจ</Form.Label>
              <label style={{ color: "red" }}> *</label>
              <Form.Control
                type="text"
                required
                onChange={(e) =>
                  this.setState({ package_name: e.target.value })
                }
                value={this.state.package_name}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>ระยะเวลา</Form.Label>
              <label style={{ color: "red" }}> *</label>
              <Form.Select
                onChange={(e) =>
                  this.setState({ package_amount_date: e.target.value })
                }
                value={this.state.package_amount_date}
              >
                <option value={0}>--เลือกระยะเวลา--</option>
                <option value="30">1 เดือน</option>
                <option value="60">2 เดือน</option>
                <option value="90">3 เดือน</option>
                <option value="180">6 เดือน</option>
                <option value="365">1 ปี</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>ราคา (บาท)</Form.Label>
              <label style={{ color: "red" }}> *</label>
              <Form.Control
                type="number"
                onChange={(e) =>
                  this.setState({ package_price: e.target.value })
                }
                value={this.state.package_price}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>ป้าย</Form.Label>
              <Form.Control
                type="text"
                value={this.state.package_banner}
                onChange={(e) =>
                  this.setState({ package_banner: e.target.value })
                }
                maxLength={12}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>รายละเอียด</Form.Label>
              <Form.Control
                type="text"
                value={this.state.package_desctiption}
                onChange={(e) =>
                  this.setState({ package_desctiption: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Active</Form.Label>
              <label style={{ color: "red" }}> *</label>
              <Form.Select
                onChange={(e) => this.setState({ active: e.target.value })}
                value={this.state.active}
              >
                <option value="0">ปิด</option>
                <option value="1">เปิด</option>
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) =>
                this.setState({
                  isOpenModal: false,
                })
              }
            >
              ปิด
            </Button>
            <Button
              variant="primary"
              onClick={
                package_id === "" ? this.handleSubmit : this.handleSubmitEdit
              }
            >
              บันทึก
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={isOpenFormItemModal}
          size="sm"
          onHide={() => this.setState({ isOpenFormItemModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Items</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Item</Form.Label>
              <label style={{ color: "red" }}> *</label>
              <Form.Select
                onChange={(e) => this.setState({ pt_item: e.target.value })}
                value={this.state.pt_item}
              >
                <option value="0">--Selected--</option>
                <option value="1">Position</option>
                <option value="2">Video</option>
                <option value="3">PI</option>
                <option value="4">General</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>ชื่อรายการ</Form.Label>
              <label style={{ color: "red" }}> *</label>
              <Form.Control
                type="text"
                onChange={(e) => this.setState({ pt_name: e.target.value })}
                value={this.state.pt_name}
                maxLength={128}
              />
            </Form.Group>

            {this.state.pt_item !== 4 && this.state.pt_item !== "4" ? (
              <Form.Group>
                <Form.Label>Quota</Form.Label>
                <label style={{ color: "red" }}> *</label>
                <Form.Control
                  type="number"
                  onChange={(e) => this.setState({ pt_quota: e.target.value })}
                  value={this.state.pt_quota}
                />
              </Form.Group>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) =>
                this.setState({
                  isOpenFormItemModal: false,
                })
              }
            >
              ปิด
            </Button>
            <Button
              variant="primary"
              onClick={
                pt_id === 0 ? this.handleSubmitItem : this.handleSubmitItemEdit
              }
            >
              บันทึก
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
