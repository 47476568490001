import React, { Component } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";
import Common from "../../common";
import ReactPlayer from "react-player";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
export default class ViewAsCandidatePortfolio extends Component {
  state = {
    pdf_path: "",
    youtube_path: "",
  };

  refreshDataPDF = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/portfolio/${user_id}?portfolio_type=1`,
          Common.options
        )
        .then((response) => {
          let res = response.data;

          if (
            res?.portfolio_path !== null &&
            res?.portfolio_path !== undefined
          ) {
            this.setState({
              pdf_path: res.portfolio_path,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  refreshDataVideo = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/portfolio/${user_id}?portfolio_type=2`,
          Common.options
        )
        .then((response) => {
          let res = response.data;

          if (
            res?.portfolio_path !== null &&
            res?.portfolio_path !== undefined
          ) {
            this.setState({
              youtube_path: res.portfolio_path,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.refreshDataPDF();
    this.refreshDataVideo();
  }

  render() {
    const { youtube_path } = this.state;
    return (
      <div>
        <h1>{lang_data?.video_presentation_candidate}</h1>

        <div style={{ paddingTop: "50px" }}>
          <Card>
            <Card.Body>
              <h4>{lang_data?.video_presentation_candidate}</h4>

              <div>
                <ReactPlayer
                  url={youtube_path}
                  previewTabIndex={0}
                  controls={true}
                  playsinline={false}
                  width={"100%"}
                  height={450}
                />
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}
