import React, { Component, Suspense } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import Common from "../../common";
import Functions from "../../functions";
import ViewAsCandidateDetail from "./ViewAsCandidateDetail";
import ViewAsCandidateEducation from "./ViewAsCandidateEducation";
import ViewAsCandidateExperience from "./ViewAsCandidateExperience";
import ViewAsCandidateLanguage from "./ViewAsCandidateLanguage";
import ViewAsCandidateSkills from "./ViewAsCandidateSkills";
import ViewAsCandidatePortfolio from "./ViewAsCandidatePortfolio";
import {
  Container,
  Card,
  Col,
  Row,
  Nav,
  Image,
  OverlayTrigger,
  Popover,
  ListGroup,
} from "react-bootstrap";
import lang from "../../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
const BadgesStyle = styled.div`
  .demo-preview {
    padding-top: 60px;
    padding-bottom: 10px;
    margin: auto;
    text-align: center;
  }
  .demo-preview .badge {
    margin-right: 10px;
  }
  .badge {
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    padding: 3px 6px;
    border: 1px solid transparent;
    min-width: 10px;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 99999px;
  }

  .badge.badge-default {
    background-color: #b0bec5;
  }

  .badge.badge-primary {
    background-color: #2196f3;
  }

  .badge.badge-secondary {
    background-color: #dadee0;
  }

  .badge.badge-success {
    background-color: #64dd17;
  }

  .badge.badge-warning {
    background-color: #ffd600;
  }

  .badge.badge-info {
    background-color: #29b6f6;
  }

  .badge.badge-danger {
    background-color: #ef1c1c;
  }

  .badge.badge-outlined {
    background-color: transparent;
  }

  .badge.badge-outlined.badge-default {
    border-color: #b0bec5;
    color: #b0bec5;
  }

  .badge.badge-outlined.badge-primary {
    border-color: #2196f3;
    color: #0aa9fa;
  }

  .badge.badge-outlined.badge-secondary {
    border-color: #d1cdcd;
    color: #0085fa;
  }

  .badge.badge-outlined.badge-success {
    border-color: #64dd17;
    color: #64dd17;
  }

  .badge.badge-outlined.badge-warning {
    border-color: #ffd600;
    color: #ffd600;
  }

  .badge.badge-outlined.badge-info {
    border-color: #29b6f6;
    color: #29b6f6;
  }

  .badge.badge-outlined.badge-danger {
    border-color: #ef1c1c;
    color: #ef1c1c;
  }
`;
const RibbonStyle = styled.div`
  // https://codepen.io/nxworld/pen/oLdoWb
  /* common */
  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #2980b9;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: green;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font: 700 18px/1 "Lato", sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
  }

  /* top left*/
  .ribbon-top-left {
    top: -10px;
    left: -10px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }

  /* top right*/
  .ribbon-top-right {
    top: -10px;
    right: -10px;
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }
  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }
  .ribbon-top-right span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
  }

  /* bottom left*/
  .ribbon-bottom-left {
    bottom: -10px;
    left: -10px;
  }
  .ribbon-bottom-left::before,
  .ribbon-bottom-left::after {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-bottom-left::before {
    bottom: 0;
    right: 0;
  }
  .ribbon-bottom-left::after {
    top: 0;
    left: 0;
  }
  .ribbon-bottom-left span {
    right: -25px;
    bottom: 30px;
    transform: rotate(225deg);
  }

  /* bottom right*/
  .ribbon-bottom-right {
    bottom: -10px;
    right: -10px;
  }
  .ribbon-bottom-right::before,
  .ribbon-bottom-right::after {
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-bottom-right::before {
    bottom: 0;
    left: 0;
  }
  .ribbon-bottom-right::after {
    top: 0;
    right: 0;
  }
  .ribbon-bottom-right span {
    left: -25px;
    bottom: 30px;
    transform: rotate(-225deg);
  }
`;
const user_id_authen = Common.token;
const GetDataForm = () => {
  const { user_id, name } = useParams();

  return <ViewAsCandidate name={name} user_id={user_id} />;
};
export default GetDataForm;
class ViewAsCandidate extends Component {
  state = {
    data: [],
    profilePageControl: "profile",
    us_salary_start: 0,
    us_salary_end: 0,
    job_type_data: [],
    job_fuction_data: [],
    favorite_id: 0,
  };

  getProfile = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `user/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;

          this.setState({
            data: res,
          });
          // console.log(user_detail.ud_email);
        });
    } catch (error) {
      // console.log(error);
      window.location = "/";
    }
  };
  getRating = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `userpart2/rating/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          let rating_result = res.result;
          this.renderStar(rating_result);
        });
    } catch (error) {
      console.log(error);
    }
  };

  renderStar = (value) => {
    let count = value;
    let remaining = 5 - count;
    let half_start = String(remaining).slice(2, 3);

    let obj = [];
    // star fill
    for (let i = 1; i <= count; i++) {
      let star = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      className="bi bi-star-fill"
      viewBox="0 0 16 16"
    >
      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
    </svg>`;
      obj.push(star);
    }
    // star half
    let r_h_s = "";
    if (half_start >= 1) {
      r_h_s = `<svg xmlns="http://www.w3.org/2000/svg" 
      width="32"
      height="32" 
      fill="currentColor" 
      className="bi bi-star-half" 
      viewBox="0 0 16 16">
      <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z"/>
    </svg>`;
    }
    // star blank

    for (let i = 1; i <= remaining; i++) {
      let star = `<svg xmlns="http://www.w3.org/2000/svg" 
      width="32"
      height="32" 
      fill="currentColor" 
      className="bi bi-star" viewBox="0 0 16 16">
      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
    </svg>`;
      obj.push(star);
    }

    document.getElementById("show_star").innerHTML = obj.join("") + r_h_s;
  };

  getSalaryData = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `userpart2/salary/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          // console.log(res);

          this.setState({
            us_salary_start: res.us_salary_start,
            us_salary_end: res.us_salary_end,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getJobType = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(Common.API_URL + `userpart2/job_type/${user_id}`, Common.options)
        .then((response) => {
          let res = response.data;
          this.setState({
            job_type_data: res,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getJobFunction = async () => {
    let user_id = this.props.user_id;
    try {
      await axios
        .get(
          Common.API_URL + `userpart2/job_function/${user_id}`,
          Common.options
        )
        .then((response) => {
          let res = response.data;
          this.setState({
            job_fuction_data: res,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  favoriteCreate = async (user_select) => {
    if (user_select === user_id_authen) {
      return false;
    }
    try {
      await axios
        .post(
          Common.API_URL + "general/favorite/create",
          {
            user_select: user_select,
            user_owner: user_id_authen,
          },
          Common.options
        )
        .then((response) => {
          this.refreshFavorit();
        });
    } catch (error) {
      console.log(error);
    }
  };
  favoritDelete = async (favorite_id) => {
    try {
      await axios
        .delete(
          Common.API_URL + `general/favorite/${favorite_id}`,
          Common.options
        )
        .then((response) => {
          this.refreshFavorit();
        });
    } catch (error) {
      console.log(error);
    }
  };
  refreshFavorit = async () => {
    try {
      await axios
        .post(
          Common.API_URL + `general/favorite/${user_id_authen}`,
          {
            page: 1,
            per_page: 1,
            search_value: this.props.user_id,
          },
          Common.options
        )
        .then((response) => {
          let res = response.data;
          let favorite_id =
            res?.data[0]?.favorite_id === undefined
              ? 0
              : res?.data[0]?.favorite_id;
          // console.log(favorite_id);
          this.setState({ favorite_id: favorite_id });
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.getRating();
    this.getProfile();
    this.getSalaryData();
    this.getJobType();
    this.getJobFunction();
    this.refreshFavorit();
  }
  render() {
    const {
      data,
      job_fuction_data,
      job_type_data,
      profilePageControl,
      favorite_id,
    } = this.state;
    return (
      <div>
        {/* Cover */}

        {Functions.check_empty_value(data?.user_main_detail?.ud_image_cover) ===
        false ? (
          <Image
            src={data?.user_main_detail?.ud_image_cover}
            style={{
              width: "100%",
              height: "450px",
              objectFit: "cover",
              objectPosition:
                "100% " + data?.user_main_detail?.ud_image_cover_position + "%",
            }}
            variant="top"
            id="imgcover"
          />
        ) : (
          <Image
            src={require("../../asset/images/4920290.jpg")}
            style={{
              width: "100%",
              height: "450px",
              objectFit: "cover",
              objectPosition: "100% 0",
            }}
            variant="top"
            id="imgcover"
          />
        )}
        {/* End Cover */}
        {/* Nav Menu */}

        <Nav fill className="justify-content-center">
          <Nav.Item>
            <Nav.Link
              eventKey="link-0"
              style={{ color: favorite_id !== 0 ? "#DC3545" : "#0D6EFD" }}
              onClick={() =>
                favorite_id === 0
                  ? this.favoriteCreate(this.props.user_id)
                  : this.favoritDelete(favorite_id)
              }
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-suit-heart-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                </svg>
              </div>{" "}
              {lang_data?.liked}
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="link-1"
              href={`/resume/${this.props.user_id}/view`}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-book-half"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
              </div>
              Resume
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link eventKey="link-2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-chat-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9.06 9.06 0 0 0 8 15z" />
                </svg>
              </div>

              {lang_data?.send_message}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {/*End Nav Menu */}
        <Container>
          <Row
            style={{
              paddingTop: "20px",
              padding: "10px",
            }}
          >
            {/* User Profile Card */}
            <Col sm="12" md="6" lg="3">
              <Card>
                <Card.Header align="center">
                  <div className="gallery">
                    <li>
                      {Functions.check_empty_value(
                        data?.user_main_detail?.ud_image_profile
                      ) === false ? (
                        <Image
                          src={data?.user_main_detail?.ud_image_profile}
                          style={{ width: "200px", height: "200px" }}
                          id="imgprofile"
                          thumbnail
                          roundedCircle
                        />
                      ) : (
                        <Image
                          src={require("../../asset/images/no-user-image-icon-26.jpg")}
                          style={{ width: "200px", height: "200px" }}
                          id="imgprofile"
                          thumbnail
                          roundedCircle
                        />
                      )}
                    </li>
                  </div>

                  {/* Star Rating */}
                  <div>
                    <div
                      style={{
                        paddingLeft: "160px",
                        cursor: "pointer",
                        color: "#0085FA",
                      }}
                    >
                      <OverlayTrigger
                        trigger="click"
                        placement="right"
                        overlay={
                          <Popover>
                            {/* <Popover.Header as="h3">top</Popover.Header> */}
                            <Popover.Body>
                              {/* <strong>Holy guacamole!</strong> */}
                              <div>
                                <span
                                  style={{ color: "orange", fontSize: "18px" }}
                                >
                                  ★
                                </span>{" "}
                                Complete your general information
                              </div>
                              <div>
                                <span
                                  style={{ color: "orange", fontSize: "18px" }}
                                >
                                  ★★
                                </span>{" "}
                                Complete your education background
                              </div>
                              <div>
                                <span
                                  style={{ color: "orange", fontSize: "18px" }}
                                >
                                  ★★★
                                </span>{" "}
                                Complete work experience
                              </div>
                              <div>
                                <span
                                  style={{ color: "orange", fontSize: "18px" }}
                                >
                                  ★★★★
                                </span>{" "}
                                Upload your self introduction VDO clip
                              </div>
                              <div>
                                <span
                                  style={{ color: "orange", fontSize: "18px" }}
                                >
                                  ★★★★★
                                </span>{" "}
                                Answer questions from the company via VDO clips
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-info-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                        </svg>
                      </OverlayTrigger>
                    </div>
                    <span id="show_star" style={{ color: "#FFCA0B" }}></span>
                  </div>
                  {/*End Star Rating */}
                  {/* Tag */}

                  <BadgesStyle>
                    <div>
                      {job_fuction_data?.map((rs, i) => (
                        <span key={i}>
                          <div className="badge badge-secondary badge-outlined">
                            {rs?.user_job_function?.jbc_name}
                          </div>{" "}
                        </span>
                      ))}
                    </div>
                    <div>
                      {job_type_data?.map((rs, i) => (
                        <span key={i}>
                          <div className="badge badge-secondary badge-outlined">
                            {rs?.user_job_type?.jt_name}
                          </div>{" "}
                        </span>
                      ))}
                    </div>
                    <div>
                      <span>
                        <div className="badge badge-secondary badge-outlined">
                          {lang_data?.expected_salary}{" "}
                          {Functions.formatNumberWithComma(
                            this.state.us_salary_start
                          )}
                          {" - "}
                          {Functions.formatNumberWithComma(
                            this.state.us_salary_end
                          )}
                        </div>
                      </span>
                    </div>
                  </BadgesStyle>
                  {/* End Tag */}
                </Card.Header>
                {/* Menu */}
                <ListGroup variant="flush">
                  <ListGroup.Item
                    onClick={(e) =>
                      this.setState({ profilePageControl: "profile" })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-person-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    </svg>{" "}
                    {lang_data?.profile}
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={(e) =>
                      this.setState({ profilePageControl: "ProfileEducation" })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-mortarboard-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5Z" />
                      <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Z" />
                    </svg>{" "}
                    {lang_data?.education_history}
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={(e) =>
                      this.setState({ profilePageControl: "experience" })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-building-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h3v-3.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V16h3a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H3Zm1 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Z" />
                    </svg>{" "}
                    {lang_data?.workExperience}
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={(e) =>
                      this.setState({ profilePageControl: "language" })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-translate"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />
                      <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" />
                    </svg>{" "}
                    {lang_data?.language_skills}
                  </ListGroup.Item>

                  <ListGroup.Item
                    onClick={(e) =>
                      this.setState({ profilePageControl: "skills" })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-gear-wide"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z" />
                    </svg>{" "}
                    {lang_data?.specializedSkills}
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={(e) =>
                      this.setState({ profilePageControl: "portfolio" })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-person-rolodex"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                      <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
                    </svg>{" "}
                    {lang_data?.video_presentation_candidate}
                  </ListGroup.Item>
                </ListGroup>

                {/*End Menu */}
              </Card>
            </Col>
            {/* End User Profile Card */}
            {/*Show Content  */}
            <Col sm="12" md="6" lg="8">
              <Suspense
                fallback={
                  <div align="center">
                    <h1>Loading...</h1>
                  </div>
                }
              >
                <div>
                  {profilePageControl === "profile" && (
                    <ViewAsCandidateDetail user_id={this.props.user_id} />
                  )}
                  {profilePageControl === "ProfileEducation" && (
                    <ViewAsCandidateEducation user_id={this.props.user_id} />
                  )}
                  {profilePageControl === "experience" && (
                    <ViewAsCandidateExperience user_id={this.props.user_id} />
                  )}
                  {profilePageControl === "language" && (
                    <ViewAsCandidateLanguage user_id={this.props.user_id} />
                  )}
                  {profilePageControl === "skills" && (
                    <ViewAsCandidateSkills user_id={this.props.user_id} />
                  )}
                  {profilePageControl === "portfolio" && (
                    <ViewAsCandidatePortfolio user_id={this.props.user_id} />
                  )}
                </div>
              </Suspense>
            </Col>
            {/*Show Content  */}
          </Row>
        </Container>
        <RibbonStyle>
          <div className="box">
            <div className="ribbon ribbon-top-left">
              <span> {lang_data?.viewAs} </span>
            </div>
            {/* <div className="ribbon ribbon-top-right">
              <span>ribbon</span>
            </div>
            <div className="ribbon ribbon-bottom-left">
              <span>ribbon</span>
            </div>
            <div className="ribbon ribbon-bottom-right">
              <span>ribbon</span>
            </div> */}
          </div>
        </RibbonStyle>
      </div>
    );
  }
}
