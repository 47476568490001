import React, { Component } from "react";
import { Alert, Button, Image, InputGroup, Form } from "react-bootstrap";
import Common from "../common";
import axios from "axios";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import "../asset/Login.css";
import lang from "../lang/lang";
const langSet = Common.langSet;
const lang_data = lang(langSet);
// https://medium.com/makers-byte/30-codepens-for-your-login-form-layouts-bc700c02ae59
export default class Login extends Component {
  state = {
    user_name: "",
    password: "",
    msg: "",
    show_pass: false,
  };

  handleSubmit = () => {
    if (this.state.user_name.length < 2 || this.state.password.length < 2) {
      this.setState({
        msg: "Invalid Credentials Error With Correct Username/Password",
      });
      return false;
    }
    // console.log(this.state.school_id);
    try {
      axios
        .post(
          Common.API_URL + "user/login",
          {
            username: this.state.user_name,
            password: this.state.password,
          },
          Common.options
        )
        .then((res) => {
          let user = res.data;

          localStorage.setItem("token", user?.user_id);
          localStorage.setItem("user_type", user?.user_type);
          window.location = "/";
        })
        .catch((err) => {
          // Handle error
          this.setState({
            msg: "Invalid Credentials Error With Correct Username/Password",
          });
          // console.log(err);
        });
    } catch (error) {
      this.setState({
        msg: "Invalid Credentials Error With Correct Username/Password",
      });
      console.log(error);
    }
  };
  componentDidMount() {
    CookieConsent.run({
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        analytics: {},
      },

      language: {
        default: "en",
        translations: {
          en: {
            consentModal: {
              title: "We use cookies",
              description: "Cookie modal description",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage Individual preferences",
            },
            preferencesModal: {
              title: "Manage cookie preferences",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Accept current selection",
              closeIconLabel: "Close modal",
              sections: [
                {
                  title: "Somebody said ... cookies?",
                  description: "I want one!",
                },
                {
                  title: "Strictly Necessary cookies",
                  description:
                    "These cookies are essential for the proper functioning of the website and cannot be disabled.",

                  //this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: "necessary",
                },
                {
                  title: "Performance and Analytics",
                  description:
                    "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
                  linkedCategory: "analytics",
                },
                {
                  title: "More information",
                  description:
                    'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>',
                },
              ],
            },
          },
        },
      },
    });
  }
  render() {
    const { msg, show_pass } = this.state;
    return (
      <div>
        <div className="wrapper">
          <div className="form-signin">
            <div
              align="center"
              onClick={() =>
                (window.location.href = "https://www.jinjijob.com/")
              }
              style={{ cursor: "pointer" }}
            >
              <Image
                variant="top"
                src={require("../asset/images/Jinjijob4.png")}
                style={{ width: 85, height: 85 }}
              />
            </div>
            <h3 className="form-signin-heading"> {lang_data?.login}</h3>

            <div className="inner-addon">
              {msg !== "" && <Alert variant="danger">{msg}</Alert>}
              <label className="control-label"> {lang_data?.account}</label>

              <input
                type="text"
                className="form-control"
                onChange={(e) => this.setState({ user_name: e.target.value })}
                placeholder={lang_data?.user_name_label}
              />
            </div>
            <div className="inner-addon">
              <label className="control-label">
                {" "}
                {lang_data?.password_label}
              </label>

              <InputGroup>
                <Form.Control
                  type={show_pass === false ? "password" : "text"}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  id="password"
                  placeholder={lang_data?.password_label}
                />
                {show_pass === false ? (
                  <Button
                    variant="outline-secondary"
                    onClick={() => this.setState({ show_pass: true })}
                    style={{ height: 45 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye-slash-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                      <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                    </svg>
                  </Button>
                ) : (
                  <Button
                    variant="outline-secondary"
                    onClick={() => this.setState({ show_pass: false })}
                    style={{ height: 45 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                  </Button>
                )}
              </InputGroup>
            </div>

            <div align="center">
              <Button variant="success" onClick={this.handleSubmit}>
                {lang_data?.login}
              </Button>
            </div>

            <div align="center">
              {/* <Button variant="link" href="/candidate/register">
                สำหรับผู้สมัครงาน
              </Button>
              <Button variant="link" href="/candidate/register">
                สำหรับบริษัท
              </Button> */}
              <Button variant="link" href="/change_password">
                {lang_data?.forgot_password}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
